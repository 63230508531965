import {
  FETCH_GOALS,
  SET_LOADING_GOALS,
  FETCH_YESTERDAY_GOALS,
  SET_LOADING_YESTERDAY_GOALS,
} from "../types";

const initialState = {
  goals: [],
  isLoading: false,
  yesterdayGoals: [],
  isLoadingYesterdayGoals: false,
};

const goalReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_GOALS:
      return {
        ...state,
        goals: payload,
      };

    case SET_LOADING_GOALS:
      return {
        ...state,
        isLoading: payload,
      };

    case FETCH_YESTERDAY_GOALS:
      return {
        ...state,
        yesterdayGoals: payload,
      };

    case SET_LOADING_YESTERDAY_GOALS:
      return {
        ...state,
        isLoadingYesterdayGoals: payload,
      };

    default:
      return state;
  }
};

export default goalReducer;
