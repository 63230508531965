import firebase from "firebase/compat/app";
import { firestore, auth } from "../firebase";
import moment from "moment";

import { getTimestamp, guessTimezone } from "../helper";
import { version } from "../constants";

import { captureException } from "@sentry/react";

export const addUser = async (uid, userReceived) => {
  try {
    const user = {
      ...userReceived,
      ...(userReceived.createdTime && {
        createdTime: getTimestamp(moment(userReceived.createdTime)),
      }),
      ...(userReceived.lastTimeOpenedApp && {
        lastTimeOpenedApp: getTimestamp(moment(userReceived.lastTimeOpenedApp)),
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new user
    const userRef = firestore.collection("users").doc(uid);
    batch.set(userRef, user);

    // Create goalsTotal and targetsTotal counter
    const counterRef = firestore.collection("counters").doc(uid);
    batch.set(
      counterRef,
      {
        goalsTotal: 0,
        targetsTotal: 0,
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    await batch.commit();
  } catch (error) {
    captureException("Error while adding user", error);
    console.error("Error while adding user: " + error);
  }
};

export const updateUser = (userReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    const { id, ...userDestructured } = userReceived; // Destructuring to remove all the fields we don't want to add

    const user = {
      ...userDestructured,
      ...(userDestructured.createdTime && {
        createdTime: getTimestamp(moment(userDestructured.createdTime)),
      }),
      ...(userDestructured.lastTimeOpenedApp && {
        lastTimeOpenedApp: getTimestamp(
          moment(userDestructured.lastTimeOpenedApp)
        ),
      }),
      ...(userDestructured.usedDuplicateBtn && {
        usedDuplicateBtn: getTimestamp(
          moment(userDestructured.usedDuplicateBtn)
        ),
      }),
      ...(userDestructured.finishedTutorial && {
        finishedTutorial: getTimestamp(
          moment(userDestructured.finishedTutorial)
        ),
      }),
      ...(userDestructured.welcomeMessageCustomizedByCoach && {
        welcomeMessageCustomizedByCoach: getTimestamp(
          moment(userDestructured.welcomeMessageCustomizedByCoach)
        ),
      }),
      ...(userDestructured.usedSortGoals && {
        usedSortGoals: getTimestamp(moment(userDestructured.usedSortGoals)),
      }),
      ...(userDestructured.usedSortTargets && {
        usedSortTargets: getTimestamp(moment(userDestructured.usedSortTargets)),
      }),
      ...(userDestructured.usedDescriptionShortcut && {
        usedDescriptionShortcut: getTimestamp(
          moment(userDestructured.usedDescriptionShortcut)
        ),
      }),
      ...(userDestructured.lastTimeUsedPartiallyDoneGoals && {
        lastTimeUsedPartiallyDoneGoals: getTimestamp(
          moment(userDestructured.lastTimeUsedPartiallyDoneGoals)
        ),
      }),
      ...(userDestructured.addGoalsReminderTime && {
        addGoalsReminderTime: getTimestamp(
          moment(userDestructured.addGoalsReminderTime)
        ),
      }),
      ...(userDestructured.firstClientMessage && {
        firstClientMessage: getTimestamp(
          moment(userDestructured.firstClientMessage)
        ),
      }),
      ...(userDestructured.lastTimeSeenSubmitMonthReminder && {
        lastTimeSeenSubmitMonthReminder: getTimestamp(
          moment(userDestructured.lastTimeSeenSubmitMonthReminder)
        ),
      }),
      ...(userDestructured.lastTimeCheckedTargetsReminders && {
        lastTimeCheckedTargetsReminders: getTimestamp(
          moment(userDestructured.lastTimeCheckedTargetsReminders)
        ),
      }),
      ...(userDestructured.holidayMode?.since && {
        holidayMode: {
          ...userDestructured.holidayMode,
          since: getTimestamp(moment(userDestructured.holidayMode?.since)),
        },
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new user
    const userRef = firestore.collection("users").doc(userUid);
    batch.set(userRef, user, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting user transaction", error);
      console.error("Error while commiting user transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating user", error);
    console.error("Error while updating user: " + error);
  }
};

export const updateUserAwait = async (userReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    const { id, ...userDestructured } = userReceived; // Destructuring to remove all the fields we don't want to add

    const user = {
      ...userDestructured,
      ...(userDestructured.createdTime && {
        createdTime: getTimestamp(moment(userDestructured.createdTime)),
      }),
      ...(userDestructured.lastTimeOpenedApp && {
        lastTimeOpenedApp: getTimestamp(
          moment(userDestructured.lastTimeOpenedApp)
        ),
      }),
      ...(userDestructured.usedDuplicateBtn && {
        usedDuplicateBtn: getTimestamp(
          moment(userDestructured.usedDuplicateBtn)
        ),
      }),
      ...(userDestructured.finishedTutorial && {
        finishedTutorial: getTimestamp(
          moment(userDestructured.finishedTutorial)
        ),
      }),
      ...(userDestructured.welcomeMessageCustomizedByCoach && {
        welcomeMessageCustomizedByCoach: getTimestamp(
          moment(userDestructured.welcomeMessageCustomizedByCoach)
        ),
      }),
      ...(userDestructured.usedSortGoals && {
        usedSortGoals: getTimestamp(moment(userDestructured.usedSortGoals)),
      }),
      ...(userDestructured.usedSortTargets && {
        usedSortTargets: getTimestamp(moment(userDestructured.usedSortTargets)),
      }),
      ...(userDestructured.usedDescriptionShortcut && {
        usedDescriptionShortcut: getTimestamp(
          moment(userDestructured.usedDescriptionShortcut)
        ),
      }),
      ...(userDestructured.lastTimeUsedPartiallyDoneGoals && {
        lastTimeUsedPartiallyDoneGoals: getTimestamp(
          moment(userDestructured.lastTimeUsedPartiallyDoneGoals)
        ),
      }),
      ...(userDestructured.addGoalsReminderTime && {
        addGoalsReminderTime: getTimestamp(
          moment(userDestructured.addGoalsReminderTime)
        ),
      }),
      ...(userDestructured.firstClientMessage && {
        firstClientMessage: getTimestamp(
          moment(userDestructured.firstClientMessage)
        ),
      }),
      ...(userDestructured.lastTimeSeenSubmitMonthReminder && {
        lastTimeSeenSubmitMonthReminder: getTimestamp(
          moment(userDestructured.lastTimeSeenSubmitMonthReminder)
        ),
      }),
      ...(userDestructured.lastTimeCheckedTargetsReminders && {
        lastTimeCheckedTargetsReminders: getTimestamp(
          moment(userDestructured.lastTimeCheckedTargetsReminders)
        ),
      }),
      ...(userDestructured.holidayMode?.since && {
        holidayMode: {
          ...userDestructured.holidayMode,
          since: getTimestamp(moment(userDestructured.holidayMode?.since)),
        },
      }),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new user
    const userRef = firestore.collection("users").doc(userUid);
    batch.set(userRef, user, { merge: true });

    // ---- Finish transaction ----
    await batch.commit().catch((error) => {
      captureException("Error while commiting user transaction", error);
      console.error("Error while commiting user transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating user", error);
    console.error("Error while updating user: " + error);
  }
};

export const assignUserStartingValues = async (user) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new user
    const userRef = firestore.collection("users").doc(userUid);
    batch.set(userRef, user, { merge: true });

    // ---- Finish transaction ----
    await batch.commit().catch((error) => {
      captureException("Error while commiting user transaction", error);
      console.error("Error while commiting user transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating user", error);
    console.error("Error while updating user: " + error);
  }
};

export const saveToken = (token) => {
  try {
    const userUid = auth.currentUser.uid;

    firestore
      .collection("users")
      .doc(userUid)
      .update({
        tokens: firebase.firestore.FieldValue.arrayUnion(token),
      });
  } catch (error) {
    captureException("Error while saving token", error);
    console.error("Error while saving token: " + error);
  }
};

export const deleteToken = (token) => {
  try {
    const userUid = auth.currentUser.uid;

    firestore
      .collection("users")
      .doc(userUid)
      .update({
        tokens: firebase.firestore.FieldValue.arrayRemove(token),
      });
  } catch (error) {
    captureException("Error while removing token", error);
    console.error("Error while removing token: " + error);
  }
};

export const updateLastTimeOpenedApp = (user) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new user
    const userRef = firestore.collection("users").doc(userUid);
    batch.set(
      userRef,
      {
        lastTimeOpenedApp: getTimestamp(),
        platform: "web",
        version: version,
        timezone: guessTimezone(),
        // ...(!user?.manualTimezone && { timezone: guessTimezone() }),
        ...(user?.subscribedAt &&
          !user.backOnDay2 &&
          moment(
            user?.lastTimeOpenedApp &&
              user.lastTimeOpenedApp.toDate &&
              user.lastTimeOpenedApp.toDate()
          ).format("YYYYMMDD") !== moment().format("YYYYMMDD") &&
          moment(
            user?.subscribedAt &&
              user.subscribedAt.toDate &&
              user.subscribedAt.toDate()
          )
            .add(1, "days")
            .format("YYYYMMDD") === moment().format("YYYYMMDD") && {
            backOnDay2: getTimestamp(),
          }),
        ...(user?.subscribedAt &&
          !user.backOnDay3 &&
          moment(
            user?.lastTimeOpenedApp &&
              user.lastTimeOpenedApp.toDate &&
              user.lastTimeOpenedApp.toDate()
          ).format("YYYYMMDD") !== moment().format("YYYYMMDD") &&
          moment(
            user?.subscribedAt &&
              user.subscribedAt.toDate &&
              user.subscribedAt.toDate()
          )
            .add(2, "days")
            .format("YYYYMMDD") === moment().format("YYYYMMDD") && {
            backOnDay3: getTimestamp(),
          }),
        ...(user?.subscribedAt &&
          !user.backOnDay4 &&
          moment(
            user?.lastTimeOpenedApp &&
              user.lastTimeOpenedApp.toDate &&
              user.lastTimeOpenedApp.toDate()
          ).format("YYYYMMDD") !== moment().format("YYYYMMDD") &&
          moment(
            user?.subscribedAt &&
              user.subscribedAt.toDate &&
              user.subscribedAt.toDate()
          )
            .add(3, "days")
            .format("YYYYMMDD") === moment().format("YYYYMMDD") && {
            backOnDay4: getTimestamp(),
          }),
        ...(user?.subscribedAt &&
          !user.backOnDay5 &&
          moment(
            user?.lastTimeOpenedApp &&
              user.lastTimeOpenedApp.toDate &&
              user.lastTimeOpenedApp.toDate()
          ).format("YYYYMMDD") !== moment().format("YYYYMMDD") &&
          moment(
            user?.subscribedAt &&
              user.subscribedAt.toDate &&
              user.subscribedAt.toDate()
          )
            .add(4, "days")
            .format("YYYYMMDD") === moment().format("YYYYMMDD") && {
            backOnDay5: getTimestamp(),
          }),
        ...(user?.subscribedAt &&
          !user.backOnDay6 &&
          moment(
            user?.lastTimeOpenedApp &&
              user.lastTimeOpenedApp.toDate &&
              user.lastTimeOpenedApp.toDate()
          ).format("YYYYMMDD") !== moment().format("YYYYMMDD") &&
          moment(
            user?.subscribedAt &&
              user.subscribedAt.toDate &&
              user.subscribedAt.toDate()
          )
            .add(5, "days")
            .format("YYYYMMDD") === moment().format("YYYYMMDD") && {
            backOnDay6: getTimestamp(),
          }),
        ...(user?.subscribedAt &&
          !user.backOnDay7 &&
          moment(
            user?.lastTimeOpenedApp &&
              user.lastTimeOpenedApp.toDate &&
              user.lastTimeOpenedApp.toDate()
          ).format("YYYYMMDD") !== moment().format("YYYYMMDD") &&
          moment(
            user?.subscribedAt &&
              user.subscribedAt.toDate &&
              user.subscribedAt.toDate()
          )
            .add(6, "days")
            .format("YYYYMMDD") === moment().format("YYYYMMDD") && {
            backOnDay7: getTimestamp(),
          }),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting updateLastTimeOpenedApp transaction",
        error
      );
      console.error(
        "Error while commiting updateLastTimeOpenedApp transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while updateLastTimeOpenedApp", error);
    console.error("Error while updateLastTimeOpenedApp: " + error);
  }
};

export const updateLastTimeActive = () => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new user
    const userRef = firestore.collection("users").doc(userUid);
    batch.set(
      userRef,
      {
        lastTimeActive: getTimestamp(),
        platform: "web",
        version: version,
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting updateLastTimeActive transaction",
        error
      );
      console.error(
        "Error while commiting updateLastTimeActive transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while updateLastTimeActive", error);
    console.error("Error while updateLastTimeActive: " + error);
  }
};

export const updateLastTimeGoalsInteraction = () => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new user
    const userRef = firestore.collection("users").doc(userUid);
    batch.set(
      userRef,
      {
        lastTimeGoalsInteraction: getTimestamp(),
        platform: "web",
        version: version,
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting updateLastTimeGoalsInteraction transaction",
        error
      );
      console.error(
        "Error while commiting updateLastTimeGoalsInteraction transaction: " +
          error
      );
    });
  } catch (error) {
    captureException("Error while updateLastTimeGoalsInteraction", error);
    console.error("Error while updateLastTimeGoalsInteraction: " + error);
  }
};

export const getPreregisteredUser = async (email) => {
  try {
    return await firestore
      .collection("preregisteredUsers")
      .where("email", "==", email)
      .get()
      .then(async (query) => {
        let preregisteredUser;

        if (!query.empty) {
          const snapshot = query.docs[0];
          preregisteredUser = snapshot.data();
        } else {
          preregisteredUser = null;
        }

        return preregisteredUser;
      });
  } catch (error) {
    captureException("Failed to get preregistered user", error);
    console.error("Failed to get preregistered user: " + error);
  }
};

export const deletePreregisteredUser = async (email) => {
  try {
    // ---- Start transaction ----
    const batch = firestore.batch();

    // Get preregistered user ref
    const preregisteredUserRef = await firestore
      .collection("preregisteredUsers")
      .where("email", "==", email)
      .get()
      .then(async (preregisteredUsers) => {
        return preregisteredUsers.docs[0].ref;
      });

    // Delete preregistered user
    batch.delete(preregisteredUserRef);

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting delete preregistered user transaction",
        error
      );
      console.error(
        "Error while commiting delete preregistered user transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while deleting preregistered user", error);
    console.error("Error while deleting preregistered user: " + error);
  }
};

export const getCounters = async () => {
  try {
    const userUid = auth.currentUser.uid;

    return await firestore
      .collection("counters")
      .doc(userUid)
      .get()
      .then(async (query) => {
        return query.data();
      });
  } catch (error) {
    captureException("Failed to get counters", error);
    console.error("Failed to get counters: " + error);
  }
};
