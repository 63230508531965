import React from "react";
import { useSelector } from "react-redux";

import { useTheme, TextField, Button } from "@mui/material";

import { Devices } from "../../api/constants";
import "./style.scss";

const DataInput = (props) => {
  const theme = useTheme();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const handleOnSendClicked = () => {
    if (props.value.trim()) {
      props.onSubmitHandler(props.value.trim());
      document.getElementById("goalInput").focus();
    }
  };

  const hideInput = () => {
    props.handleLastTimeShowedExampleAt &&
      props.handleLastTimeShowedExampleAt();
    props.setIsVisible(false);
    props.setAllowNavigation(true);
  };

  // --- Events ---

  // Keyboard
  const keyDownHandler = (e) => {
    if (e.keyCode === 27) {
      hideInput();
    } else if (e.keyCode === 13) {
      handleOnSendClicked();
    }
  };

  return (
    <div
      className="dataInput-container"
      style={{
        backgroundColor: theme.palette.background.main,
        position: currentDevice === Devices.PHONE ? "fixed" : "absolute",
        bottom: currentDevice === Devices.PHONE ? 50 : 0,
      }}
    >
      <TextField
        variant="standard"
        color="secondary"
        type="text"
        id="goalInput"
        name="goalInput"
        placeholder={props.placeholder}
        value={props.value}
        autoComplete="off"
        fullWidth
        margin="dense"
        autoFocus
        required
        onChange={() =>
          props.setValue(document.getElementById("goalInput").value)
        }
        onKeyDown={(e) => {
          keyDownHandler(e);
        }}
        // onBlur={hideInput}
      />
      <div className="btns-container">
        <div className="btn-container">
          <Button
            variant="contained"
            color="secondary"
            onMouseDown={(e) => {
              handleOnSendClicked();
              e.preventDefault();
            }}
            sx={{
              height: 30,
              width: 150,
            }}
          >
            {props.btnAddTitle}
          </Button>
        </div>
        <div className="btn-container">
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              hideInput();
            }}
            sx={{
              height: 30,
              width: 100,
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DataInput);
