import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import moment from "moment";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ShepherdTourContext } from "react-shepherd";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  addGoalsReminder,
  chatNotifications,
  darkMode,
  goalCompleteSound,
  sortByIsDone,
  sortBy,
  orderBy,
  soundEffects,
  submitDayReminder,
  targetsSortByIsDone,
  targetsSortBy,
  targetsOrderBy,
  setShowCurrentMonthTargets,
  changeCurrentScreen,
  setCurrentDevice,
  setSupporterSettings,
  setIsMoodButtonsMandatory,
  // setManualTimezone,
  setHolidayMode,
} from "../redux/actions/local";
import { fetchCounters } from "../redux/actions/counter";
import { fetchInboxes } from "../redux/actions/inbox";
import { fetchCoach } from "../redux/actions/coach";
import { fetchToday, fetchYesterday } from "../redux/actions/day";
import { fetchYesterdayGoals } from "../redux/actions/goal";
import { fetchYesterdayRecurrentGoals } from "../redux/actions/recurrentGoal";
import { fetchLastMonth, fetchCurrentMonth } from "../redux/actions/month";
import {
  fetchLastMonthTargets,
  fetchCurrentMonthTargets,
  fetchNextMonthTargets,
} from "../redux/actions/target";
import { fetchAlerts } from "../redux/actions/alert";

import { autoSubmit } from "../api/firestore/days";
import { autoSubmitMonths } from "../api/firestore/months";
import { updateUser, updateLastTimeOpenedApp } from "../api/firestore/users";
import { updateClientAlert } from "../api/firestore/clientAlert";

// Screens
import GoalsScreen from "../screens/goals";
import EditGoalScreen from "../screens/goals/editGoal";
import ViewGoalScreen from "../screens/goals/viewGoal";
import InboxScreen from "../screens/inbox";
import EditInboxScreen from "../screens/inbox/editInbox";
import TargetsScreen from "../screens/targets";
import EditTargetScreen from "../screens/targets/editTarget";
import ViewTargetScreen from "./targets/viewTarget";
import MilestonesScreen from "../screens/milestones";
import ChatScreen from "../screens/chat";
import SettingsScreen from "../screens/settings";
import ProfileScreen from "../screens/settings/profile";
// import ExportScreen from "../screens/settings/export";
import ShortcutsScreen from "../screens/settings/shortcuts";
import PlansScreen from "../screens/settings/plans";
import GoalsSortingScreen from "../screens/settings/sortingGoals";
import TargetsSortingScreen from "../screens/settings/sortingTargets";
import SoundsSettingsScreen from "../screens/settings/soundsSettings";
import NominateSupporter from "./settings/nominateSupporter";
import HolidayMode from "./settings/holidayMode";
// import DateAndTime from "./settings/dateAndTime";
import AlertOverlay from "../components/AlertOverlay";

// Components
import NavBarWeb from "../components/NavBarWeb";
import NavBarMobile from "../components/NavBarMobile";

// Utils
import { getFilteredGoalsForTheDay } from "../api/utils/goals";

// Own api
import welcome from "../assets/sounds/welcome.wav";
import { getDeviceSize } from "../api/helper";
import { playSound } from "../api/filesHandler";
import {
  setRandomNumberMotivateMessageStart,
  setRandomNumberMotivateMessage,
  setRandomNumberGoalsExample,
  setRandomNumberTargetsExample,
} from "../redux/actions/local";
import {
  Devices,
  DeviceSize,
  motivateMessagesStart,
  motivateMessages,
  goalsExamples,
  targetsExamples,
  ScreenNames,
} from "../api/constants";
import "./style.scss";

const MainScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tour = useContext(ShepherdTourContext);

  const effectRan = useRef(false);
  const didMountRef = useRef(false);
  const didMountRefForAlerts = useRef(false);

  // Redux
  const user = useSelector((state) => state.user.user);
  const coach = useSelector((state) => state.coach.coach);
  const counters = useSelector((state) => state.counter.counters);
  const yesterday = useSelector((state) => state.day.yesterday);
  const yesterdayGoals = useSelector((state) => state.goal.yesterdayGoals);
  const yesterdayRecurrentGoals = useSelector(
    (state) => state.recurrentGoal.yesterdayRecurrentGoals
  );
  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );
  const currentScreenReducer = useSelector(
    (state) => state.local.currentScreen
  );
  const lastMonth = useSelector((state) => state.month.lastMonth);
  const lastMonthTargets = useSelector(
    (state) => state.target.lastMonthTargets
  );
  const currentMonthTargets = useSelector(
    (state) => state.target.currentMonthTargets
  );
  const alerts = useSelector((state) => state.alert.alerts);

  const isLoadingLastMonthTargets = useSelector(
    (state) => state.target.isLoadingLastMonthTargets
  );
  const isLoadingCurrentMonthTargets = useSelector(
    (state) => state.target.isLoadingCurrentMonthTargets
  );
  const isLoadingLastMonth = useSelector(
    (state) => state.month.isLoadingLastMonth
  );
  const isLoadingAlerts = useSelector((state) => state.alert.isLoading);

  // States
  const [currentDeviceSize, setCurrentDeviceSize] = useState(getDeviceSize);
  const [isShowSideNav, setIsShowSideNav] = useState(true);
  const [isShowChat, setIsShowChat] = useState(true);
  const [isCoachInfoOverlayVisible, setIsCoachInfoOverlayVisible] =
    useState(false);
  const [alertsArr, setAlertsArr] = useState([]);
  const [haveToSubmitYesterday, setHaveToSubmitYesterday] = useState(false);

  // ---------------------- Necessary stuff (see main.js from mobile) ---------------------- //
  useEffect(() => {
    if (user?.id && user?.coach) {
      dispatch(fetchCoach(user?.coach));
    }
  }, [user?.id, user?.coach]);

  useEffect(() => {
    if (!effectRan.current) {
      handleURL();

      dispatch(fetchCounters());
      dispatch(fetchInboxes());
      dispatch(fetchToday());
      dispatch(fetchYesterday());
      dispatch(fetchYesterdayGoals());
      dispatch(fetchYesterdayRecurrentGoals());
      dispatch(fetchCurrentMonth());
      dispatch(fetchLastMonth());
      dispatch(fetchLastMonthTargets());
      dispatch(fetchCurrentMonthTargets());
      if (moment() > moment().endOf("month").subtract(6, "days")) {
        dispatch(fetchNextMonthTargets());
      }
      dispatch(fetchAlerts());

      checkRandomsAndSounds();
      autoSubmit("first open web");
      autoSubmitMonths();

      updateLastTimeOpenedApp(user);

      // Guide
      ((user?.guide?.length && !user?.guide?.includes("chat")) ||
        !user?.guide?.length ||
        user?.guide === undefined) &&
        tour.start();
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  //////////////////////////////////// ONLINE SYNC ////////////////////////////////////
  // Run every time one of this varaibles changes
  // TODO - Is okay to be running this here? Should I make multiple useEffects/useCallbacks for each of this settings?
  useEffect(() => {
    if (user) {
      dispatch(darkMode(user.isDarkModeEnabled || false));
      dispatch(
        setIsMoodButtonsMandatory(
          user.isMoodButtonsMandatory !== undefined &&
            user.isMoodButtonsMandatory !== null
            ? user.isMoodButtonsMandatory
            : true
        )
      );
      // dispatch(
      //   setManualTimezone(
      //     user.manualTimezone !== undefined && user.manualTimezone !== null
      //       ? user.manualTimezone
      //       : false
      //   )
      // );

      dispatch(
        submitDayReminder(
          user.isSubmitDayReminderEnabled !== undefined &&
            user.isSubmitDayReminderEnabled !== null
            ? user.isSubmitDayReminderEnabled
            : true
        )
      );
      dispatch(
        addGoalsReminder(
          user.isAddGoalsReminderEnabled !== undefined &&
            user.isAddGoalsReminderEnabled !== null
            ? user.isAddGoalsReminderEnabled
            : true
        )
      );
      dispatch(
        chatNotifications(
          user.isChatNotificationsEnabled !== undefined &&
            user.isChatNotificationsEnabled !== null
            ? user.isChatNotificationsEnabled
            : true
        )
      );

      dispatch(sortByIsDone(user.sortByIsDone || "ASC"));
      dispatch(sortBy(user.sortBy || "position"));
      dispatch(orderBy(user.orderBy || "ASC"));
      dispatch(targetsSortByIsDone(user.targetsSortByIsDone || "ASC"));
      dispatch(targetsSortBy(user.targetsSortBy || "name"));
      dispatch(targetsOrderBy(user.targetsOrderBy || "ASC"));

      dispatch(
        setShowCurrentMonthTargets(
          user.showCurrentMonthTargets === undefined &&
            user.showCurrentMonthTargets !== null
            ? true
            : user.showCurrentMonthTargets
        )
      );

      dispatch(
        goalCompleteSound(
          user.isGoalCompleteSoundEnabled !== undefined &&
            user.isGoalCompleteSoundEnabled !== null
            ? user.isGoalCompleteSoundEnabled
            : true
        )
      );
      dispatch(
        soundEffects(
          user.isSoundEffectsEnabled !== undefined &&
            user.isSoundEffectsEnabled !== null
            ? user.isSoundEffectsEnabled
            : true
        )
      );

      dispatch(
        setSupporterSettings({
          name:
            user.supporterSettings &&
            user.supporterSettings.name !== undefined &&
            user.supporterSettings.name !== null
              ? user.supporterSettings.name
              : "",
          email:
            user.supporterSettings &&
            user.supporterSettings.email !== undefined &&
            user.supporterSettings.email !== null
              ? user.supporterSettings.email
              : "",
          getCcd:
            user.supporterSettings &&
            user.supporterSettings.getCcd !== undefined &&
            user.supporterSettings.getCcd !== null
              ? user.supporterSettings.getCcd
              : true,
          dailyResults:
            user.supporterSettings &&
            user.supporterSettings.dailyResults !== undefined &&
            user.supporterSettings.dailyResults !== null
              ? user.supporterSettings.dailyResults
              : true,
          monthlyResults:
            user.supporterSettings &&
            user.supporterSettings.monthlyResults !== undefined &&
            user.supporterSettings.monthlyResults !== null
              ? user.supporterSettings.monthlyResults
              : true,
          milestonesAwarded:
            user.supporterSettings &&
            user.supporterSettings.milestonesAwarded !== undefined &&
            user.supporterSettings.milestonesAwarded !== null
              ? user.supporterSettings.milestonesAwarded
              : true,
          targetAdded:
            user.supporterSettings &&
            user.supporterSettings.targetAdded !== undefined &&
            user.supporterSettings.targetAdded !== null
              ? user.supporterSettings.targetAdded
              : false,
        })
      );
    }

    dispatch(
      setHolidayMode(
        user.holidayMode !== undefined && user.isHolidayMode !== null
          ? user.isHolidayMode
          : null
      )
    );
  }, [
    user?.isDarkModeEnabled,
    user?.isMoodButtonsMandatory,
    user?.isSubmitDayReminderEnabled,
    user?.isAddGoalsReminderEnabled,
    user?.sortByIsDone,
    user?.sortBy,
    user?.orderBy,
    user?.targetsSortByIsDone,
    user?.targetsSortBy,
    user?.targetsOrderBy,
    user?.showCurrentMonthTargets,
    user?.isGoalCompleteSoundEnabled,
    user?.isSoundEffectsEnabled,
    user?.supporterSettings?.name,
    user?.supporterSettings?.email,
    user?.supporterSettings?.getCcd,
    user?.supporterSettings?.dailyResults,
    user?.supporterSettings?.monthlyResults,
    user?.supporterSettings?.milestonesAwarded,
    user?.supporterSettings?.targetAdded,
    // user?.manualTimezone,
    user?.holidayMode,
  ]);

  // Targets reminders
  // - should skip first render, as isLoadingLastMonthTargets and isLoadingCurrentMonthTargets are initialized as false
  // - should skip on sign up
  useEffect(() => {
    if (didMountRef.current) {
      if (
        !isLoadingLastMonthTargets &&
        !isLoadingCurrentMonthTargets &&
        !isLoadingLastMonth
      ) {
        if (user?.guide?.length) {
          if (
            // - Last time checked targets reminders is older than today (so once per day until they submit)
            moment(
              user?.lastTimeCheckedTargetsReminders?.toDate &&
                user.lastTimeCheckedTargetsReminders.toDate()
            ).isBefore(moment(), "day") ||
            user?.lastTimeCheckedTargetsReminders === undefined
          ) {
            if (
              lastMonth &&
              !lastMonth.isSubmitted &&
              lastMonthTargets.length
            ) {
              // - Last month is unsubmitted and has targets
              const submitMonthAlertObj = {
                id: "submitMonthAlert",
                title:
                  "It's time to submit " +
                  moment().subtract(1, "months").endOf("month").format("MMMM") +
                  " results!",
                description:
                  "How did the " +
                  (lastMonthTargets && lastMonthTargets.length > 1
                    ? lastMonthTargets.length + " targets"
                    : "target") +
                  " for the month go?",
                okBtnLabel: "Let's do it",
              };
              if (!alertsArr.some((obj) => obj.id === submitMonthAlertObj.id)) {
                setAlertsArr([...alertsArr, submitMonthAlertObj]);
              }
              updateUser({ lastTimeSeenSubmitMonthReminder: moment() });
            } else {
              if (
                // - Last time they saw submit month reminder is older than today (so user doesn't see this modal right after the other one)
                (moment(
                  user?.lastTimeSeenSubmitMonthReminder?.toDate &&
                    user.lastTimeSeenSubmitMonthReminder.toDate()
                ).isBefore(moment(), "day") ||
                  user?.lastTimeSeenSubmitMonthReminder === undefined) &&
                // - There are no targets this month
                !currentMonthTargets?.length
              ) {
                const addTargetsAlertObj = {
                  id: "addTargetsAlert",
                  title:
                    "It's time to add targets for " + moment().format("MMMM"),
                  okBtnLabel: "Let's do it",
                };
                if (
                  !alertsArr.some((obj) => obj.id === addTargetsAlertObj.id)
                ) {
                  setAlertsArr([...alertsArr, addTargetsAlertObj]);
                }
              }
            }
          }

          // Limitation: no reminders for new users without months or targets
          updateUser({ lastTimeCheckedTargetsReminders: moment() });
        }
      }
    }

    didMountRef.current = true;
  }, [
    isLoadingLastMonthTargets,
    isLoadingCurrentMonthTargets,
    isLoadingLastMonth,
  ]);

  // Alerts
  // - should skip first render, as isLoadingAlerts is initialized as false
  // - should skip on sign up
  useEffect(() => {
    if (didMountRefForAlerts.current) {
      if (!isLoadingAlerts) {
        // Check client has also finished guide/onboarding process (tutorial, coach highlight)
        if (alerts?.length && user?.guide?.includes("chat")) {
          // Make sure to always add these first in the array
          setAlertsArr([
            ...alerts.filter(
              (obj2) => !alertsArr.some((obj1) => obj1.id === obj2.id)
            ),
            ...alertsArr,
          ]);
        }
      }
    }
    didMountRefForAlerts.current = true;
  }, [isLoadingAlerts, alerts, user?.guide]);

  /////////////////// URL ///////////////////
  const handleURL = () => {
    switch (window.location.pathname) {
      case "/":
        navigate("/");
        dispatch(changeCurrentScreen(ScreenNames.GOALS_SCREEN));
        break;
      case "/inbox":
        dispatch(changeCurrentScreen(ScreenNames.INBOX_SCREEN));
        break;
      case "/targets":
        dispatch(changeCurrentScreen(ScreenNames.TARGETS_SCREEN));
        break;
      case "/milestones":
        dispatch(changeCurrentScreen(ScreenNames.MILESTONES_SCREEN));
        break;
      case "/settings":
        dispatch(changeCurrentScreen(ScreenNames.SETTINGS_SCREEN));
        break;
      default:
        navigate("/");
        break;
    }
  };

  /////////////////// Welcome sound & Random motivate message and goals example ///////////////////
  const checkRandomsAndSounds = useCallback(() => {
    console.log("Last time opened app: ", user?.lastTimeOpenedApp);
    console.log(
      "Last time opened app: ",
      moment(
        user?.lastTimeOpenedApp?.toDate && user.lastTimeOpenedApp.toDate()
      ).toString()
    );
    if (user?.lastTimeOpenedApp) {
      console.log(
        "Last time opened app is at least a day before today? ",
        moment(
          user?.lastTimeOpenedApp?.toDate && user.lastTimeOpenedApp.toDate()
        ).isBefore(moment(), "day")
      );
    }
    console.log("Are sound effects enabled? ", isSoundEffectsEnabled);
    if (
      moment(
        user?.lastTimeOpenedApp?.toDate && user.lastTimeOpenedApp.toDate()
      ).isBefore(moment(), "day") ||
      user?.lastTimeOpenedApp === undefined
    ) {
      if (isSoundEffectsEnabled) {
        console.log("Welcome sound played!");
        playSound(welcome);
      }

      // Motivate Message Start
      let newRandomNumberMotivateMessageStart = Math.floor(
        Math.random() * motivateMessagesStart.length
      );
      dispatch(
        setRandomNumberMotivateMessageStart(newRandomNumberMotivateMessageStart)
      );

      // Motivate Message
      let newRandomNumberMotivateMessage = Math.floor(
        Math.random() * motivateMessages.length
      );
      dispatch(setRandomNumberMotivateMessage(newRandomNumberMotivateMessage));

      // Goals example
      let newRandomNumberGoalsExample = Math.floor(
        Math.random() * goalsExamples.length
      );
      dispatch(setRandomNumberGoalsExample(newRandomNumberGoalsExample));

      // Targets example
      let newRandomNumberTargetsExample = Math.floor(
        Math.random() * targetsExamples.length
      );
      dispatch(setRandomNumberTargetsExample(newRandomNumberTargetsExample));
    }
  }, [isSoundEffectsEnabled, user?.lastTimeOpenedApp]);

  // -------------------------------- HAVE TO SUBMIT YESTERDAY -------------------------------- //
  useEffect(() => {
    if (
      yesterday &&
      !yesterday.isSubmitted &&
      (yesterdayRecurrentGoals?.length || yesterdayGoals.length)
    ) {
      const unsubmittedGoals = getFilteredGoalsForTheDay(
        yesterday.date,
        yesterdayGoals,
        yesterdayRecurrentGoals,
        user?.holidayMode
      );

      if (unsubmittedGoals?.length) {
        setHaveToSubmitYesterday(true);
      } else {
        setHaveToSubmitYesterday(false);
      }
    } else {
      setHaveToSubmitYesterday(false);
    }
  }, [
    yesterday?.isSubmitted,
    yesterdayGoals,
    yesterdayRecurrentGoals,
    user?.holidayMode,
  ]);

  // -------------------------------- RESIZING AND SCREEN SELECTION -------------------------------- //
  const handleResize = useCallback(
    (e) => {
      let temp = window.innerWidth;
      switch (true) {
        case temp < DeviceSize.PHONE:
          if (currentDeviceSize !== Devices.PHONE) {
            setCurrentDeviceSize(Devices.PHONE);
          }
          dispatch(setCurrentDevice(Devices.PHONE));
          break;
        case temp > DeviceSize.PHONE && temp < DeviceSize.TABLET:
          if (currentDeviceSize !== Devices.TABLET) {
            setCurrentDeviceSize(Devices.TABLET);
          }
          dispatch(setCurrentDevice(Devices.TABLET));
          break;
        case temp > DeviceSize.TABLET:
          if (currentDeviceSize !== Devices.DESKTOP) {
            setCurrentDeviceSize(Devices.DESKTOP);
            if (currentScreenReducer === ScreenNames.CHAT_SCREEN) {
              dispatch(changeCurrentScreen(ScreenNames.GOALS_SCREEN));
              navigate("/");
            }
          }
          dispatch(setCurrentDevice(Devices.DESKTOP));
          break;
        default:
      }
    },
    [currentDeviceSize, currentScreenReducer]
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const commonToggleMethod = (methodName, value) => {
    methodName(value);
  };

  // -------------------------------- ALERTS -------------------------------- //
  const handleSubmitMonthReminderConfirmation = () => {
    navigate("/targets", {
      state: {
        date: moment().subtract(1, "months").toDate(),
      },
    });
  };

  const handleAddTargetsReminderConfirmation = () => {
    navigate("/targets");
  };

  const handleActionAlertModal = (alertId, action) => {
    // Remove the just seen alert object from array
    setAlertsArr(alertsArr.filter((obj) => obj.id !== alertId));

    switch (alertId) {
      case "submitMonthAlert":
        if (action === "confirm") {
          handleSubmitMonthReminderConfirmation();
        }
        break;
      case "addTargetsAlert":
        if (action === "confirm") {
          handleAddTargetsReminderConfirmation();
        }
        break;
      default:
        updateClientAlert({ id: alertId, seenAt: moment() });
        break;
    }
  };

  // -------------------------------- RENDER -------------------------------- //
  return (
    <>
      {currentDeviceSize === Devices.PHONE ? (
        <>
          <NavBarMobile
            currentScreen={currentScreenReducer}
            setCurrentScreen={(page) => dispatch(changeCurrentScreen(page))}
            unreadMilestones={counters?.unreadMilestones}
            unreadCoachMessages={counters.unreadCoachMessages}
            haveToSubmitYesterday={haveToSubmitYesterday}
            haveToSubmitLastMonth={lastMonth && !lastMonth.isSubmitted}
            lastMonthHasTargets={lastMonthTargets.length}
            coachAvatar={coach?.avatar}
          />
          <div className="product-wrapper">
            <Routes>
              {/* Goals */}
              <Route
                path="/"
                element={
                  <GoalsScreen
                    isCoachInfoOverlayVisible={isCoachInfoOverlayVisible}
                    setIsCoachInfoOverlayVisible={setIsCoachInfoOverlayVisible}
                    haveToSubmitYesterday={haveToSubmitYesterday}
                  />
                }
              />
              <Route
                path="goal/edit"
                element={<EditGoalScreen user={user} />}
              />
              <Route path="goal/view" element={<ViewGoalScreen />} />
              {/* Inbox */}
              <Route path="inbox" element={<InboxScreen />} />
              <Route path="inbox/edit" element={<EditInboxScreen />} />
              {/* Targets */}
              <Route path="targets" element={<TargetsScreen />} />
              <Route
                path="targets/edit"
                element={
                  <EditTargetScreen
                    minDate={moment(
                      user?.createdTime &&
                        user.createdTime.toDate &&
                        user.createdTime.toDate()
                    ).startOf("month")}
                  />
                }
              />
              <Route path="targets/view" element={<ViewTargetScreen />} />
              {/* Milestones */}
              <Route path="milestones" element={<MilestonesScreen />} />
              {/* Coach */}
              <Route
                path="coach"
                element={
                  <ChatScreen
                    isShow={isShowChat}
                    user={user}
                    coach={coach}
                    tour={tour}
                    isCoachInfoOverlayVisible={isCoachInfoOverlayVisible}
                    setIsCoachInfoOverlayVisible={setIsCoachInfoOverlayVisible}
                  />
                }
              />
              {/* Settings */}
              <Route
                path="settings"
                element={
                  <SettingsScreen
                    subscribedAt={
                      user?.subscribedAt
                        ? moment(
                            user.subscribedAt?.toDate &&
                              user.subscribedAt.toDate()
                          )
                        : null
                    }
                  />
                }
              />
              <Route path="settings/profile" element={<ProfileScreen />} />
              {/* <Route path="/settings/exports" element={<ExportScreen />} /> */}
              <Route path="/settings/shortcuts" element={<ShortcutsScreen />} />
              <Route path="settings/plans" element={<PlansScreen />} />
              <Route
                path="settings/sortGoals"
                element={<GoalsSortingScreen />}
              />
              <Route
                path="settings/sortTargets"
                element={<TargetsSortingScreen />}
              />
              <Route
                path="settings/soundsSettings"
                element={<SoundsSettingsScreen />}
              />
              <Route
                path="settings/nominateSupporter"
                element={<NominateSupporter />}
              />
              <Route path="settings/holidayMode" element={<HolidayMode />} />
              {/* <Route
                path="settings/dateAndTime"
                element={<DateAndTime userTimezone={user?.timezone} />}
              /> */}
              {/* Path doesn't exist */}
              <Route path="*" element={<h1>Error 404</h1>} />
            </Routes>
          </div>
        </>
      ) : (
        <div className="mainWrapper">
          <NavBarWeb
            currentScreen={currentScreenReducer}
            setCurrentScreen={(page) => dispatch(changeCurrentScreen(page))}
            isShow={isShowSideNav}
            isShowChat={true}
            TogglSideNav={() =>
              commonToggleMethod(setIsShowSideNav, !isShowSideNav)
            }
            unreadMilestones={counters?.unreadMilestones}
            unreadCoachMessages={counters.unreadCoachMessages}
            haveToSubmitYesterday={haveToSubmitYesterday}
            haveToSubmitLastMonth={lastMonth && !lastMonth.isSubmitted}
            lastMonthHasTargets={lastMonthTargets.length}
          />
          <Routes>
            {/* Goals */}
            <Route
              path="/"
              element={
                <GoalsScreen
                  isCoachInfoOverlayVisible={isCoachInfoOverlayVisible}
                  setIsCoachInfoOverlayVisible={setIsCoachInfoOverlayVisible}
                  haveToSubmitYesterday={haveToSubmitYesterday}
                />
              }
            />
            <Route path="goal/edit" element={<EditGoalScreen user={user} />} />
            <Route path="goal/view" element={<ViewGoalScreen />} />
            {/* Inbox */}
            <Route path="inbox" element={<InboxScreen />} />
            <Route path="inbox/edit" element={<EditInboxScreen />} />
            {/* Targets */}
            <Route path="targets" element={<TargetsScreen />} />
            <Route
              path="targets/edit"
              element={
                <EditTargetScreen
                  minDate={moment(
                    user?.createdTime &&
                      user.createdTime.toDate &&
                      user.createdTime.toDate()
                  ).startOf("month")}
                />
              }
            />
            <Route path="targets/view" element={<ViewTargetScreen />} />
            {/* Milestones */}
            <Route path="milestones" element={<MilestonesScreen />} />
            {/* Coach */}
            <Route
              path="coach"
              element={
                <ChatScreen
                  isShow={isShowChat}
                  user={user}
                  coach={coach}
                  tour={tour}
                  isCoachInfoOverlayVisible={isCoachInfoOverlayVisible}
                  setIsCoachInfoOverlayVisible={setIsCoachInfoOverlayVisible}
                />
              }
            />
            {/* Settings */}
            <Route
              path="settings"
              element={
                <SettingsScreen
                  subscribedAt={
                    user?.subscribedAt
                      ? moment(
                          user.subscribedAt?.toDate &&
                            user.subscribedAt.toDate()
                        )
                      : null
                  }
                />
              }
            />
            <Route path="settings/profile" element={<ProfileScreen />} />
            {/* <Route path="/settings/exports" element={<ExportScreen />} /> */}
            <Route path="/settings/shortcuts" element={<ShortcutsScreen />} />
            <Route path="settings/plans" element={<PlansScreen />} />
            <Route path="settings/sortGoals" element={<GoalsSortingScreen />} />
            <Route
              path="settings/sortTargets"
              element={<TargetsSortingScreen />}
            />
            <Route
              path="settings/soundsSettings"
              element={<SoundsSettingsScreen />}
            />
            <Route
              path="settings/nominateSupporter"
              element={<NominateSupporter />}
            />
            <Route path="settings/holidayMode" element={<HolidayMode />} />
            {/* <Route
              path="settings/dateAndTime"
              element={<DateAndTime userTimezone={user?.timezone} />}
            /> */}
            {/* Path doesn't exist */}
            <Route path="*" element={<h1>Error 404</h1>} />
          </Routes>
          {currentDeviceSize !== Devices.TABLET && (
            <ChatScreen
              isShow={isShowChat}
              isShowToggleIcon={true}
              toggleChat={setIsShowChat}
              currentScreen={currentScreenReducer}
              user={user}
              coach={coach}
              tour={tour}
              isCoachInfoOverlayVisible={isCoachInfoOverlayVisible}
              setIsCoachInfoOverlayVisible={setIsCoachInfoOverlayVisible}
            />
          )}
        </div>
      )}

      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        // pauseOnFocusLoss
        // pauseOnHover
        theme="light"
      />

      {alertsArr?.length ? (
        <AlertOverlay
          alert={alertsArr?.length ? alertsArr[0] : null}
          handleAction={handleActionAlertModal}
        />
      ) : null}
    </>
  );
};

export default React.memo(MainScreen);
