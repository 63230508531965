import {
  FETCH_MESSAGES,
  SET_LOADING_MESSAGES,
  SET_LOADING_MORE_MESSAGES,
} from "../types";

const initialState = {
  messages: [],
  isLoadingMessages: false,
  isLoadingMoreMessages: false,
};

const chatReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_MESSAGES:
      return {
        ...state,
        messages: payload,
      };

    case SET_LOADING_MESSAGES:
      return {
        ...state,
        isLoadingMessages: payload,
      };

    case SET_LOADING_MORE_MESSAGES:
      return {
        ...state,
        isLoadingMoreMessages: payload,
      };

    default:
      return state;
  }
};

export default chatReducer;
