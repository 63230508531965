import { firestore } from "../../api/firebase";

import { FETCH_TAGS, SET_LOADING_TAGS } from "../types";

import { captureException } from "@sentry/react";

let unsubscribers = [];

const fetchTagsSuccess = (tags) => ({
  type: FETCH_TAGS,
  payload: tags,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_TAGS,
  payload: isLoading,
});

export const fetchTags = () => (dispatch) => {
  try {
    dispatch(setLoading(true));

    const tagsSubscriber = firestore.collection("tags").onSnapshot(
      (snapshot) => {
        const tags = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id, // References the UID
        }));
        dispatch(fetchTagsSuccess(tags));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setLoading(false));
        captureException("Error fetching tags onSnapshot", error);
        console.log("Error fetching tags onSnapshot: " + error);
      }
    );
    unsubscribers.push(tagsSubscriber);
  } catch (error) {
    dispatch(setLoading(false));
    captureException("Error fetching tags", error);
    console.log("Error fetching tags: " + error);
  }
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeTagsActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
