import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

// Components
import { FaCheckCircle } from "react-icons/fa";
import { useTheme, TextField, Switch } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setSupporterSettings } from "../../../redux/actions/local";

// Firestore
import { updateUser } from "../../../api/firestore/users";

// Analytics
import { analytics } from "../../../api/firebase";

// Own Api
import { validateEmail } from "../../../api/helper";
import { Devices } from "../../../api/constants";
import "./style.scss";

const NominateSupporter = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const DEBOUNCE_TIME = 300;

  // -------------------------------- Redux -------------------------------- //
  const currentDevice = useSelector((state) => state.local.currentDevice);
  const user = useSelector((state) => state.user.user);
  const reduxSupporterSettings = useSelector(
    (state) => state.local.supporterSettings
  );

  // -------------------------------- States -------------------------------- //
  const [supporterSettings, setSupporterSettingsState] = useState({
    name: reduxSupporterSettings?.name || "",
    email: reduxSupporterSettings?.email || "",
    getCcd: reduxSupporterSettings?.getCcd || false,
    dailyResults: reduxSupporterSettings?.dailyResults || false,
    monthlyResults: reduxSupporterSettings?.monthlyResults || false,
    milestonesAwarded: reduxSupporterSettings?.milestonesAwarded || false,
    targetAdded: reduxSupporterSettings?.targetAdded || false,
  });

  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");

  useEffect(() => {
    validateSupporterName(supporterSettings.name || "");
    validateSupporterEmail(supporterSettings.email || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateSupporterName = (nameSet) => {
    if (nameSet === "") {
      setNameError("Name is required");
    } else {
      setNameError("");
    }
  };

  const validateSupporterEmail = (emailSet) => {
    const validatedEmail = validateEmail(emailSet);

    if (
      emailSet?.length &&
      emailSet === (user && (user.contactEmail || user.email))
    ) {
      setEmailError("Their email cannot be the same as your contact email");
    } else {
      setEmailError(validatedEmail.message);
    }

    return validatedEmail.isValid;
  };

  const debounceUpdateUser = useCallback(
    debounce((updatedSettings) => {
      updateUser({ supporterSettings: updatedSettings });
      dispatch(
        setSupporterSettings({
          supporterSettings: updatedSettings,
        })
      );
      analytics.logEvent("used_feat_nominate_supporter");
    }, DEBOUNCE_TIME),
    []
  );

  const handleSupporterSettingsChange = (property, value) => {
    const updatedSettings = { ...supporterSettings, [property]: value };
    setSupporterSettingsState(updatedSettings);
    debounceUpdateUser(updatedSettings);
  };

  // Navigation
  const btnBackClicked = () => {
    navigate("/settings");
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="nominate-supporter-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
      }}
    >
      <div className="nominate-supporter-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Settings"
          title="Nominate a supporter"
        />
        <div className="content">
          <div className="data">
            <TextField
              id="userNameInput"
              variant="outlined"
              color="primary"
              value={supporterSettings.name}
              error={nameError ? true : false}
              helperText={nameError}
              label="Their first name eg- Joe"
              fullWidth
              sx={{ marginBottom: 3 }}
              onKeyUp={() => {
                validateSupporterName(
                  document.getElementById("userNameInput").value
                );
              }}
              onChange={(e) => {
                handleSupporterSettingsChange("name", e.target.value);
              }}
              // InputProps={{
              //   endAdornment: <FaCheckCircle />,
              // }}
            />
            <TextField
              id="userEmailInput"
              variant="outlined"
              color="primary"
              value={supporterSettings.email}
              error={emailError ? true : false}
              helperText={emailError}
              label="Their email"
              fullWidth
              sx={{ marginBottom: 3 }}
              onKeyUp={() => {
                validateSupporterEmail(
                  document.getElementById("userEmailInput").value
                );
              }}
              onChange={(e) => {
                handleSupporterSettingsChange("email", e.target.value);
              }}
              InputProps={{
                endAdornment:
                  supporterSettings.name && !nameError && !emailError ? (
                    <FaCheckCircle color={theme.palette.primary.main} />
                  ) : null,
              }}
            />
          </div>

          <div className="personal-option">
            <label
              className="repeat-option-text"
              style={{ color: theme.palette.text.main }}
              onClick={() => {
                handleSupporterSettingsChange(
                  "getCcd",
                  !supporterSettings.getCcd
                );
              }}
            >
              Get cc’d on the emails
            </label>
            <Switch
              color={
                supporterSettings.name && !emailError && !nameError
                  ? "primary"
                  : "gray"
              }
              checked={supporterSettings.getCcd}
              onChange={() => {
                handleSupporterSettingsChange(
                  "getCcd",
                  !supporterSettings.getCcd
                );
              }}
            />
          </div>

          <label className="title" style={{ color: theme.palette.text.main }}>
            Share my
          </label>
          <div>
            <div className="option">
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleSupporterSettingsChange(
                    "dailyResults",
                    !supporterSettings.dailyResults
                  );
                }}
              >
                Daily results
              </label>
              <Switch
                color={
                  supporterSettings.name && !emailError && !nameError
                    ? "primary"
                    : "gray"
                }
                checked={supporterSettings.dailyResults}
                onChange={() => {
                  handleSupporterSettingsChange(
                    "dailyResults",
                    !supporterSettings.dailyResults
                  );
                }}
              />
            </div>

            <div className="option">
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleSupporterSettingsChange(
                    "monthlyResults",
                    !supporterSettings.monthlyResults
                  );
                }}
              >
                Monthly results
              </label>
              <Switch
                color={
                  supporterSettings.name && !emailError && !nameError
                    ? "primary"
                    : "gray"
                }
                checked={supporterSettings.monthlyResults}
                onChange={() => {
                  handleSupporterSettingsChange(
                    "monthlyResults",
                    !supporterSettings.monthlyResults
                  );
                }}
              />
            </div>

            <div className="option">
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleSupporterSettingsChange(
                    "milestonesAwarded",
                    !supporterSettings.milestonesAwarded
                  );
                }}
              >
                Milestones awarded
              </label>
              <Switch
                color={
                  supporterSettings.name && !emailError && !nameError
                    ? "primary"
                    : "gray"
                }
                checked={supporterSettings.milestonesAwarded}
                onChange={() => {
                  handleSupporterSettingsChange(
                    "milestonesAwarded",
                    !supporterSettings.milestonesAwarded
                  );
                }}
              />
            </div>

            <div className="option">
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleSupporterSettingsChange(
                    "targetAdded",
                    !supporterSettings.targetAdded
                  );
                }}
              >
                Targets added
              </label>
              <Switch
                color={
                  supporterSettings.name && !emailError && !nameError
                    ? "primary"
                    : "gray"
                }
                checked={supporterSettings.targetAdded}
                onChange={() => {
                  handleSupporterSettingsChange(
                    "targetAdded",
                    !supporterSettings.targetAdded
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NominateSupporter);
