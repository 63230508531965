import { firestore } from "../../api/firebase";

import { FETCH_QUIZ, SET_LOADING_QUIZ } from "../types";

import { captureException } from "@sentry/react";

let unsubscribers = [];

const fetchQuizSuccess = (quiz) => ({
  type: FETCH_QUIZ,
  payload: quiz,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_QUIZ,
  payload: isLoading,
});

export const fetchQuiz = (userEmail) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const querySnapshot = await firestore
      .collection("quizzes")
      .where("email", "==", userEmail)
      .limit(1)
      .get();

    if (!querySnapshot.empty) {
      const quizData = querySnapshot.docs[0].data();
      dispatch(fetchQuizSuccess(quizData));
    } else {
      dispatch(fetchQuizSuccess(null));
    }

    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    captureException("Error fetching quiz", error);
    console.error("Error fetching quiz: " + error);
  }
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeQuizActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
