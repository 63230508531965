import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

// Splide
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../../api/splide-goalswon.css";

// Circular progress bar
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useTheme, CircularProgress } from "@mui/material";

import { motivateMessagesStart, motivateMessages } from "../../api/constants";

import "./style.scss";

const GoalsHeader = (props) => {
  const theme = useTheme();

  const coach = useSelector((state) => state.coach.coach);

  return (
    <Splide
      options={{
        arrows: false,
        // height: 140 // CHECK: Why did I add a fixed height?
      }}
    >
      {props.day && !!props.day.motivateMessage ? (
        <SplideSlide>
          <div
            className="goalsHeaderContainer"
            style={{ backgroundColor: theme.palette.quaternary.main }}
          >
            <div className="contentContainer">
              <div className="textContainer">
                <label
                  className="motivateMessage"
                  style={{ color: theme.palette.text.main }}
                >
                  {props.day.motivateMessage}
                </label>
              </div>
              <div className="imageContainer">
                <img
                  className="coachImage"
                  src={coach?.avatar}
                  onLoad={() => {
                    return (
                      <CircularProgress
                        color="primary"
                        className="loading-day-spinner"
                      />
                    );
                  }}
                  alt="coach"
                  width={75}
                  height={75}
                />
              </div>
            </div>
          </div>
        </SplideSlide>
      ) : null}
      <SplideSlide>
        <div
          className="goalsHeaderContainer"
          style={{ backgroundColor: theme.palette.quaternary.main }}
        >
          {props.day && props.day.isSubmitted ? (
            <div className="contentContainer">
              <div className="textContainer">
                <label
                  className="motivateMessage"
                  style={{ color: theme.palette.text.main }}
                >
                  {props.progress === 100
                    ? "Congrats on a perfect day! 🎉"
                    : "Submitted. See you tomorrow!"}
                </label>
              </div>
              <div className="imageContainer">
                <img
                  src={require("../../assets/img/goalswon_logo_rounded.png")}
                  onLoad={() => {
                    return (
                      <CircularProgress
                        color="primary"
                        className="loading-day-spinner"
                      />
                    );
                  }}
                  alt="Goalswon logo"
                  width={75}
                  height={75}
                />
              </div>
            </div>
          ) : (
            <div className="contentContainer">
              <div className="textContainer">
                <label
                  className="motivateMessage"
                  style={{ color: theme.palette.text.main }}
                >
                  {
                    motivateMessagesStart[
                      props.randomNumberMotivateMessageStart || 0
                    ]
                  }{" "}
                  {motivateMessages[props.randomNumberMotivateMessage || 0]}{" "}
                  {moment().format("dddd")}
                </label>
                <label
                  className="motivateMessageDayAndName"
                  style={{ color: theme.palette.text.main }}
                >
                  {props.user?.name || null}
                  {"!"}
                </label>
              </div>

              {props.isLoadingGoals ||
              props.isLoadingRecurrentGoals ||
              props.isLoadingDay ? null : props.progress > 0 ? (
                <div className="logo-graph-container">
                  <CircularProgressbar
                    value={props.progress}
                    text={`${Math.round(props.progress)}%`}
                    styles={buildStyles({
                      textSize: "24px",
                      textColor: theme.palette.text.main,
                      // textColor:
                      //   props.progress === 100
                      //     ? theme.palette.primary.main
                      //     : theme.palette.tertiary.main,
                      pathColor:
                        props.progress === 100
                          ? theme.palette.primary.main
                          : theme.palette.tertiary.main,
                      // How long animation takes to go from one percentage to another, in seconds
                      // pathTransitionDuration: 0.5,
                    })}
                  />
                </div>
              ) : (
                <div className="imageContainer">
                  <img
                    src={require("../../assets/img/goalswon_logo_rounded.png")}
                    onLoad={() => {
                      return (
                        <CircularProgress
                          color="primary"
                          className="loading-day-spinner"
                        />
                      );
                    }}
                    alt="Goalswon logo"
                    width={75}
                    height={75}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </SplideSlide>
    </Splide>
  );
};

export default React.memo(GoalsHeader);
