import moment from "moment";
import "moment-timezone";

export const getFilteredGoalsForTheDay = (
  dayDate,
  goals,
  recurrentGoals,
  holidayMode = null
) => {
  let newGoalsList;

  const pausedGoalsIds = recurrentGoals
    .filter((recurrentGoal) => recurrentGoal.isPaused)
    .map((recurrentGoal) => recurrentGoal.id);

  newGoalsList = goals
    // Remove goals that are paused for the day
    .filter((goal) => !pausedGoalsIds.includes(goal?.fromRecurrentGoal))
    // Remove goals that are skipped for the day
    .filter((goal) => {
      const recurrentGoal = recurrentGoals.find(
        (rg) => rg.id === goal.fromRecurrentGoal
      );
      const isSkipped =
        recurrentGoal &&
        recurrentGoal.skippedDates &&
        recurrentGoal.skippedDates.includes(moment(dayDate).format("YYYYMMDD"));
      return !isSkipped;
    })
    // Concatenate recurrent goals
    .concat(
      // Discards recurrent goals that
      // - Are paused and dayDate is same or after holiday mode start
      // - Are already instanced as goals
      // - Have today set as a skippedDate
      recurrentGoals
        .filter(
          (el) =>
            !el?.isPaused ||
            (el.isPaused &&
              holidayMode &&
              holidayMode?.isEnabled &&
              holidayMode?.since &&
              moment(dayDate).isBefore(
                moment(holidayMode.since?.toDate && holidayMode.since.toDate()),
                "day"
              ))
        )
        .filter((el) => goals.every((it) => it.fromRecurrentGoal !== el.id))
        .filter(
          (el) =>
            !el.skippedDates ||
            (el.skippedDates &&
              !el.skippedDates.includes(moment(dayDate).format("YYYYMMDD")))
        )
    );

  return newGoalsList;
};
