import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { captureException } from "@sentry/react";

import { CircularProgress, useTheme } from "@mui/material";

import { auth } from "../../api/firebase";

import { store } from "../../redux/store";

import SentryService from "../../utils/sentry";
import { unsubscribeAllSnapshots } from "../../api/utils/snapshots";
import "./style.scss";

function SignOutButton() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [loadingSignOut, setLoadingSignOut] = useState(false);

  const logOut = async () => {
    setLoadingSignOut(true);
    try {
      // await removeToken(); // TODO - Notifications

      // Check 0 and 1 because user can be registered with Apple AND Google
      if (
        auth.currentUser.providerData.filter(
          (el) => el.providerId === "google.com"
        ).length
      ) {
        googleLogout();
      }

      auth
        .signOut()
        .then(() => {
          unsubscribeAllSnapshots();

          store.dispatch({ type: "SIGNOUT_REQUEST" });

          // Clear Sentry User
          SentryService.clearSentryUser();

          navigate("/");
        })
        .catch((error) => {
          captureException("Error on sign out", error);
          console.log("Error on sign out: " + error);
        });

      setLoadingSignOut(false);
    } catch (error) {
      setLoadingSignOut(false);
      captureException("Error on sign out", error);
      console.error("Error on sign out: " + error);
    }
  };

  return (
    <li
      style={{
        cursor: "pointer",
        borderTop: "1px solid",
        borderTopColor: theme.palette.gray.contrast,
        borderBottom: "none",
      }}
      onClick={logOut}
    >
      {loadingSignOut ? (
        <CircularProgress color="error" size={25} />
      ) : (
        <label style={{ cursor: "pointer", color: theme.palette.error.main }}>
          Sign out
        </label>
      )}
    </li>
  );
}

export default React.memo(SignOutButton);
