import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";

// Components
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import autoAnimate from "@formkit/auto-animate";

import { FaAngleRight, FaAngleDown } from "react-icons/fa";

import { useTheme, Divider } from "@mui/material";

import Goal from "./Goal";

// Redux
import { changeCurrentScreen } from "../../redux/actions/local";

// Firebase
import { updateGoal } from "../../api/firestore/goals";
import { updateRecurrentGoal } from "../../api/firestore/recurrentGoals";
import { updateInbox } from "../../api/firestore/inboxes";
import { updateTarget } from "../../api/firestore/targets";

// Own api
import "./style.scss";

const GoalsList = (props) => {
  const theme = useTheme();
  const goalsListRef = useRef();

  const [sortedGoals, setSortedGoals] = useState([]);
  const [isSorting, setIsSorting] = useState(false);

  const [itemToCancelEdit, setItemToCancelEdit] = useState(null); // Used to save the initial state of item, before making changes and cancelling
  const [inputHoveredId, setInputHoveredId] = useState(false);

  useEffect(() => {
    if (!isSorting) {
      // Goals and inboxes sorting, and targets without separation sorting
      const sorted = props.goals
        // Instantiate sorting variables in case they don't exist (might cause app crash)
        .map((el) => ({
          ...el,
          name: el.name || "",
          isDone: !!el.isDone, // If isDone is undefined (non-instantiated recurrent goals) '!' initializes it to true, and the other '!' to false
          isPartiallyDone: !!el.isPartiallyDone, // If isPartiallyDone is undefined (non-instantiated recurrent goals) '!' initializes it to true, and the other '!' to false
          tag: el.tag || "",
          position: el.position || 0,
        }))
        // Sort goals
        .sort(
          (a, b) =>
            // First. Sort by done if set
            (props.sortByIsDone === "ASC"
              ? b["isDone"]
                  .toString()
                  .localeCompare(a["isDone"].toString(), undefined, {
                    numeric: true,
                  })
              : props.sortByIsDone === "DESC"
              ? a["isDone"]
                  .toString()
                  .localeCompare(b["isDone"].toString(), undefined, {
                    numeric: true,
                  })
              : null) ||
            // Then, sort by isPartiallyDone
            (props.sortByIsDone === "ASC"
              ? b["isPartiallyDone"]
                  .toString()
                  .localeCompare(a["isPartiallyDone"].toString(), undefined, {
                    numeric: true,
                  })
              : props.sortByIsDone === "DESC"
              ? a["isPartiallyDone"]
                  .toString()
                  .localeCompare(b["isPartiallyDone"].toString(), undefined, {
                    numeric: true,
                  })
              : null) ||
            // Then, client's custom sort and order (created time (position), or name, or color (tag))
            (props.orderBy === "ASC"
              ? a[props.sortBy || "name"]
                  .toString()
                  .localeCompare(
                    b[props.sortBy || "name"].toString(),
                    undefined,
                    {
                      numeric: true,
                    }
                  )
              : b[props.sortBy || "name"]
                  .toString()
                  .localeCompare(
                    a[props.sortBy || "name"].toString(),
                    undefined,
                    {
                      numeric: true,
                    }
                  )) ||
            // Last, sort by name ASC if sort by name is not selected already
            (props.sortBy !== "name"
              ? a["name"]
                  .toString()
                  .localeCompare(b["name"].toString(), undefined, {
                    numeric: true,
                  })
              : null)
        );
      setSortedGoals(sorted);
    }
  }, [
    props.goals,
    isSorting,
    props.sortByIsDone,
    props.sortBy,
    props.orderBy,
    props.setAllowNavigation,
  ]);

  useEffect(() => {
    if (goalsListRef.current) {
      autoAnimate(goalsListRef.current, { duration: 400 }).enable();
    }
  }, []);

  // For drag and drop
  // const sort = async (newSortedGoals) => {
  //   // Prevents the UI from refreshing while it's sorting
  //   setIsSorting(true);
  //   const temp = [];
  //   newSortedGoals.forEach((el, index) => {
  //     temp.push({ ...el, position: index });
  //   });
  //   setSortedGoals(temp);
  //   await props.sortHandler(temp);
  //   dispatch(sortBy("position"));
  //   updateUser({ sortBy: "position" });
  //   dispatch(orderBy("ASC"));
  //   updateUser({ orderBy: "ASC" });
  //   setIsSorting(false);
  // };

  // Used for delete shortcut
  // const deleteGoalPressed = (item) => {
  //   props.setGoalToDelete(item);
  //   props.deleteGoalPressedHandler(item);
  // };

  const saveChanges = useCallback(
    (item) => {
      if (props.isGoal) {
        // If it is a goal
        if (item.date) {
          // If it is a normal instantiated goal
          updateGoal({
            ...item,
            name: item.name.trim(),
            date: moment(item.date),
          });
          if (item.fromRecurrentGoal) {
            // If it is instantiated from a previous recurrent
            updateRecurrentGoal(
              {
                name: item.name.trim(),
              },
              item.fromRecurrentGoal
            );
          }
        } else {
          // If it is a recurrent
          updateRecurrentGoal({
            ...item,
            name: item.name.trim(),
          });
        }
      } else if (props.isInbox) {
        // If it is an inbox
        updateInbox(item);
      } else if (props.isTarget) {
        // If it is a target
        updateTarget(item);
      }

      setItemToCancelEdit(null);
      props.setAllowNavigation(true);
      if (document.getElementById(`item-name-input-${item.id}`)) {
        document.getElementById(`item-name-input-${item.id}`).blur();
      }
    },
    [props.isGoal, props.isInbox, props.isTarget, props.setAllowNavigation]
  );

  const cancelChanges = useCallback(
    (item) => {
      setSortedGoals(
        [...sortedGoals].map((el) => {
          if (el.id === item?.id) {
            return {
              ...el,
              name: itemToCancelEdit?.name,
            };
          } else {
            return { ...el };
          }
        })
      );
      setItemToCancelEdit(null);
      props.setAllowNavigation(true);
      if (document.getElementById(`item-name-input-${item?.id}`)) {
        document.getElementById(`item-name-input-${item?.id}`).blur();
      }
    },
    [sortedGoals, itemToCancelEdit?.name, props.setAllowNavigation]
  );

  // Set isDragging to true when dragging starts
  const onBeforeDragStart = () => {
    autoAnimate(goalsListRef.current).disable();
  };

  // Set isDragging to false when dragging ends
  const onDragEnd = () => {
    autoAnimate(goalsListRef.current, { duration: 400 }).enable();
  };

  const downHandler = useCallback(
    (key, item) => {
      switch (key.keyCode) {
        // Enter
        case 13:
          saveChanges(item);
          break;
        // Escape
        case 27:
          cancelChanges(item);
          break;
        default:
          break;
      }
    },
    [saveChanges, cancelChanges]
  );

  const renderGoal = useCallback(
    (item, index) => {
      return (
        <Goal
          key={item.id}
          index={index}
          item={item}
          itemToHighlight={props.itemToHighlight}
          date={props.date}
          goalBoxPressedHandler={props.goalBoxPressedHandler}
          isSubmitted={props.isSubmitted}
          isGoal={props.isGoal}
          navigateToTargetsScreen={props.navigateToTargetsScreen}
          setItemToCancelEdit={setItemToCancelEdit}
          itemToCancelEdit={itemToCancelEdit}
          setInputHoveredId={setInputHoveredId}
          inputHoveredId={inputHoveredId}
          setAllowNavigation={props.setAllowNavigation}
          setSortedGoals={setSortedGoals}
          sortedGoals={sortedGoals}
          setGoalToEdit={props.setGoalToEdit}
          setIsRepeatPatternPickerOverlayVisible={
            props.setIsRepeatPatternPickerOverlayVisible
          }
          saveChanges={saveChanges}
          cancelChanges={cancelChanges}
          downHandler={downHandler}
          changeCurrentScreen={changeCurrentScreen}
        />
      );
    },
    [
      sortedGoals,
      inputHoveredId,
      itemToCancelEdit,
      props.date,
      props.goalBoxPressed,
      props.isGoal,
      props.isSubmitted,
      props.itemToHighlight,
      props.navigateToTargetsScreen,
      props.setAllowNavigation,
      props.setGoalToEdit,
      props.setIsRepeatPatternPickerOverlayVisible,
      saveChanges,
      cancelChanges,
      downHandler,
    ]
  );

  return (
    <div>
      {/* Title for collapsible items */}
      {props.isCollapsible && (
        <div className="collapsible-divider-and-btn-container">
          <Divider />

          <button
            className="collapsible-targets-btn"
            onClick={() => {
              props.setShowItems();
            }}
          >
            <label
              style={{ cursor: "pointer", color: theme.palette.text.main }}
            >
              {props.title}
            </label>

            <div size={"small"} className="counter-btn">
              {props.goals?.length ? (
                props.showItems ? (
                  <>
                    <label
                      className="counter-label"
                      style={{ color: theme.palette.gray.light }}
                    >
                      {props.counter}
                    </label>
                    <FaAngleDown
                      size={18}
                      style={{ color: theme.palette.gray.light }}
                    />
                  </>
                ) : (
                  <>
                    <label
                      className="counter-label"
                      style={{ color: theme.palette.gray.light }}
                    >
                      {props.counter}
                    </label>
                    <FaAngleRight
                      size={18}
                      style={{ color: theme.palette.gray.light }}
                    />
                  </>
                )
              ) : null}
            </div>
          </button>
        </div>
      )}
      {/* Goals list */}
      <DragDropContext
        onBeforeDragStart={onBeforeDragStart}
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="goals">
          {(provided) => (
            <ul
              ref={(el) => {
                goalsListRef.current = el; // Assign the 'goalsListRef' reference
                provided.innerRef(el); // Assign the 'provided.innerRef' reference
              }}
              {...provided.droppableProps}
              className="items-container"
              style={{
                paddingBottom:
                  props.isDataInputVisible ||
                  (props.isTarget && props.isCollapsible)
                    ? 0
                    : null,
              }}
            >
              {(!props.isCollapsible ||
                (props.isCollapsible && props.showItems)) &&
                sortedGoals.map(renderGoal)}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default React.memo(GoalsList);
