import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import * as Sentry from "@sentry/react";

import appReducer from "./reducers";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    // "user",
    // "coach",
    // "goal",
    // "recurrentGoal",
    // "inbox",
    // "milestone",
    // "target",
    // "chat",
    // "counter",
    // "release",
    // "day",
    // "month",
  ],
  // TODO Check - Do we need to persist all these or just local?
  // Note: when I added them to blacklist, some users reported the coach show in chat wasn't right (couldn't replicate it)
  // and welcome sound was playing once a day but in all platforms (before, it was cross platform)
};

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_REQUEST") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(thunk), sentryReduxEnhancer)
);

export const persistor = persistStore(store);
