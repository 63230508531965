import { firestore, auth } from "../firebase";
import moment from "moment";
import "moment-timezone";
import { captureException } from "@sentry/react";

// Firestore
import { deleteMessage } from "./chats";
import { deleteGoal, getOriginalGoalId } from "./goals";

// Utils
// import { getFilteredGoalsForTheDay } from "../utils/goals";

// Own api
import { getTimestamp } from "../helper";
import { recurrencePatternEnum } from "../constants";

export const addDay = (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMMDD");

    const day = {
      isSubmitted: false,
      date: getTimestamp(moment(date).startOf("day").utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const dayRef = firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(id);
    batch.set(dayRef, day, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting day transaction", error);
      console.error("Error while commiting day transaction: " + error);
    });
  } catch (error) {
    captureException("Error while adding day", error);
    console.error("Error while adding day: " + error);
  }
};

export const updateDay = (date = moment(), dayReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMMDD");

    const day = {
      clientComment: dayReceived.clientComment,
      date: getTimestamp(moment(date).startOf("day").utcOffset(0, true)),
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const dayRef = firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(id);
    batch.set(dayRef, day, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting day transaction", error);
      console.error("Error while commiting day transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating day", error);
    console.error("Error while updating day: " + error);
  }
};

export const undoSubmitResults = async (date, day) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date).format("YYYYMMDD");

    const unsubmittedDay = {
      isSubmitted: false, // Set back to false
      date: getTimestamp(moment(date).startOf("day").utcOffset(0, true)),
      movedGoals: [], // Set movedGoals back to none, because none would have been moved
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new day
    const dayRef = firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(id);
    batch.set(dayRef, unsubmittedDay, { merge: true });

    // ---- Delete messages ----
    if (day?.messageId) {
      deleteMessage({ id: day.messageId }, false);
    }
    if (day?.imagesId?.length) {
      day.imagesId.forEach((imageId) => {
        deleteMessage({ id: imageId }, false);
      });
    }

    // ---- Delete moved goals ----
    if (day?.movedGoals?.length) {
      day.movedGoals.forEach(async (movedGoal) => {
        try {
          const originalGoalId = await getOriginalGoalId(movedGoal);

          if (originalGoalId) {
            firestore
              .collection("goals")
              .doc(userUid)
              .collection("goals")
              .where("date", ">", moment(day.date).utcOffset(0, true).toDate())
              .where("id", "==", originalGoalId)
              .get()
              .then(async (goalsToDelete) => {
                goalsToDelete.docs.map((goal) => deleteGoal({ id: goal.id }));
              })
              .catch((error) => {
                captureException("Error fetching goals to delete", error);
                console.log("Error fetching goals to delete: " + error);
              });
          }
        } catch (error) {
          captureException("Error while fetching goals to delete", error);
          console.error("Error while fetching goals to delete: " + error);
        }
      });
    }

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting undo submit day transaction",
        error
      );
      console.error(
        "Error while commiting undo submit day transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while submitting undo submit day", error);
    console.error("Error while submitting undo submit day: " + error);
  }
};

// TODO - Find an alternative, this way is the correct but is way too heavy
// TODO - if no recurrent goals are retrieved, some of them that didn't get instantiated are ignored
// TODO - Also apply fix to prevent duplicated, just remind client about the goal, don't accumulate
// export const autoSubmit = async (firedFrom) => {
//   try {
//     const userUid = auth.currentUser.uid;
//     const yesterday = moment()
//       .subtract(1, "days")
//       .startOf("day")
//       .utcOffset(0, true)
//       .toDate();

//     // Get unsubmitted days older than yesterday
//     const unsubmittedDaysQuery = await firestore
//       .collection("days")
//       .doc(userUid)
//       .collection("days")
//       .where("isSubmitted", "==", false)
//       .where("date", "<", yesterday)
//       .get();

//     const unsubmittedDays = unsubmittedDaysQuery.docs.map((doc) => {
//       return {
//         id: doc.id,
//         date: moment(doc.data().date?.toDate && doc.data().date.toDate())
//           .utc()
//           .utcOffset(moment().utcOffset(), true),
//       };
//     });

//     if (unsubmittedDays.length === 0) {
//       console.log("No unsubmitted days found. (", firedFrom, ").");
//       return;
//     }

//     // Start transaction
//     const batch = firestore.batch();

//     const userQuery = await firestore.collection("users").doc(userUid).get();
//     const user = { ...userQuery.data(), id: userQuery.id };

//     // Check day goals are going to move to
//     // 1) If today is not submitted, roll over goals to today.
//     // 2) If today is already submitted, roll over goals to tomorrow.
//     let dayToRollOverGoalsTo;
//     const isTodaySubmitted = await isDaySubmitted();
//     dayToRollOverGoalsTo = moment()
//       .add(isTodaySubmitted ? 1 : 0, "days")
//       .startOf("day");
//     // Create day to which goals will move to, if it's not created already.
//     const isDayToRollOverGoalsToCreated = await isDayCreated(
//       dayToRollOverGoalsTo
//     );
//     if (!isDayToRollOverGoalsToCreated) {
//       addDay(dayToRollOverGoalsTo);
//     }

//     // Loop through unsubmitted days and get their undone goals
//     for (const day of unsubmittedDays) {
//       // Get recurrentGoals that belong to the day
//       const recurrentGoalsQuery = await firestore
//         .collection("recurrentGoals")
//         .doc(userUid)
//         .collection("recurrentGoals")
//         .where("startDate", "<=", moment(day.date).utcOffset(0, true).toDate())
//         .get();

//       const recurrentGoals = recurrentGoalsQuery.docs
//         .map((doc) => {
//           const data = doc.data();

//           const getDate = (field) =>
//             moment(data[field]?.toDate && data[field].toDate())
//               .utc()
//               .utcOffset(moment().utcOffset(), true);

//           return {
//             ...data,
//             id: data?.id || doc.id, // data.id if goal is copy of original (rolled more than once). Or goal.id if goal IS the original (first time rolled)
//             ...(data?.endDate && { endDate: getDate("endDate") }), // Only convert endDate which is the one we use
//           };
//         })
//         .filter(
//           // Keep goals that:
//           // - Are "roll over"
//           // - EndDate is in the future
//           // - Correspond today (except for dailies, dailies are not rolled over)
//           (el) =>
//             (el.isRolledOver === true || el.isRolledOver === undefined) &&
//             (!el.endDate || moment(el.endDate).isAfter(moment(day.date))) &&
//             (el.recurrencePattern !== recurrencePatternEnum.DAILY ||
//               (el.recurrencePattern === recurrencePatternEnum.WEEKLY &&
//                 el?.days.includes(moment(day.date).isoWeekday())) ||
//               (el.recurrencePattern === recurrencePatternEnum.MONTHLY &&
//                 moment(el.startDate.toDate()).date() ===
//                   moment(day.date).date()) ||
//               (el.recurrencePattern === recurrencePatternEnum.YEARLY &&
//                 moment(el.startDate.toDate()).date() ===
//                   moment(day.date).date() &&
//                 moment(el.startDate.toDate()).month() ===
//                   moment(day.date).month()))
//         );

//       const goalsQuery = await firestore
//         .collection("goals")
//         .doc(userUid)
//         .collection("goals")
//         .where("isDone", "==", false)
//         .where("date", ">=", moment(day.date).utcOffset(0, true).toDate())
//         .where(
//           "date",
//           "<",
//           moment(day.date).add(1, "days").utcOffset(0, true).toDate()
//         )
//         .get();

//       const goals = goalsQuery.docs
//         .map((doc) => {
//           const data = doc.data();

//           // Here it's not necessary to convert (like in fetchGoals()) because we don't handle using moment or anything. We just get them in timestamp, and reupload without viewing or modifying anything
//           return {
//             ...data,
//             id: data?.id || doc.id, // data.id if goal is copy of original (rolled more than once). Or goal.id if goal IS the original (first time rolled)
//           };
//         })
//         .filter(
//           // Keep goals that:
//           // - Are "roll over"
//           // - Are not daily
//           (el) =>
//             (el.isRolledOver === true || el.isRolledOver === undefined) &&
//             (!el.fromRecurrentGoal ||
//               el.recurrencePattern !== recurrencePatternEnum.DAILY)
//         );

//       // Concatenate recurrent goals
//       const goalsToRollOver = getFilteredGoalsForTheDay(
//         day.date,
//         goals,
//         recurrentGoals,
//         user?.holidayMode
//       );

//       if (goalsToRollOver.length) {
//         for (const goal of goalsToRollOver) {
//           const newMovedGoalRef = firestore
//             .collection("goals")
//             .doc(userUid)
//             .collection("goals")
//             .doc();

//           batch.set(newMovedGoalRef, {
//             ...goal,
//             date: getTimestamp(moment(dayToRollOverGoalsTo).utcOffset(0, true)),
//             originalDate:
//               goal.originalDate ||
//               getTimestamp(moment(day.date).utcOffset(0, true)),
//           });

//           if (goal.isPartiallyDone) {
//             batch.update(firestore.doc(`users/${userUid}`), {
//               lastTimeUsedPartiallyDoneGoals: getTimestamp(),
//             });
//           }
//         }

//         batch.update(firestore.doc(`days/${userUid}/days/${day.id}`), {
//           isSubmitted: true,
//           movedGoals: goals.map((goal) => goal.id),
//           lastSubmissionType: "auto",
//         });
//       } else {
//         batch.update(firestore.doc(`days/${userUid}/days/${day.id}`), {
//           isSubmitted: true,
//           lastSubmissionType: "auto",
//         });
//       }
//     }

//     await batch.commit();
//     console.log("Day autosubmit completed.");
//   } catch (error) {
//     captureException("Error while autosubmitting day", error);
//     console.error("Error while autosubmitting day: " + error);
//   }
// };

// Wrong/broken autosubmit
export const autoSubmit = async (firedFrom) => {
  try {
    const userUid = auth.currentUser.uid;
    const yesterday = moment()
      .subtract(1, "days")
      .startOf("day")
      .utcOffset(0, true)
      .toDate();

    // Get unsubmitted days older than yesterday
    const unsubmittedDaysQuery = await firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .where("isSubmitted", "==", false)
      .where("date", "<", yesterday)
      .get();

    const unsubmittedDays = unsubmittedDaysQuery.docs.map((doc) => {
      return {
        id: doc.id,
        date: moment(doc.data().date?.toDate && doc.data().date.toDate())
          .utc()
          .utcOffset(moment().utcOffset(), true),
      };
    });

    if (unsubmittedDays.length === 0) {
      console.log("No unsubmitted days found. (", firedFrom, ").");
      return;
    }

    // Start transaction
    const batch = firestore.batch();

    // Check day goals are going to move to
    // 1) If today is not submitted, roll over goals to today.
    // 2) If today is already submitted, roll over goals to tomorrow.
    let dayToRollOverGoalsTo;
    const isTodaySubmitted = await isDaySubmitted();
    dayToRollOverGoalsTo = moment()
      .add(isTodaySubmitted ? 1 : 0, "days")
      .startOf("day");
    // Create day to which goals will move to, if it's not created already.
    const isDayToRollOverGoalsToCreated = await isDayCreated(
      dayToRollOverGoalsTo
    );
    if (!isDayToRollOverGoalsToCreated) {
      addDay(dayToRollOverGoalsTo);
    }

    // Commenting this could roll-over recurrent goals that are paused but were marked as done and then undone while on holiday mode
    // Get paused recurrentGoals
    // const recurrentGoalsQuery = await firestore
    //   .collection("recurrentGoals")
    //   .doc(userUid)
    //   .collection("recurrentGoals")
    //   .where("isPaused", "==", true)
    //   .get();

    // // Fetch the paused recurrent goals
    // let pausedRecurrentGoalsIds = [];
    // if (!recurrentGoalsQuery.empty) {
    //   pausedRecurrentGoalsIds = recurrentGoalsQuery.docs.map((doc) => doc.id);
    // }

    let rolledOverGoalsIds = [];

    // Loop through unsubmitted days and get their undone goals
    for (const day of unsubmittedDays) {
      const goalsQuery = await firestore
        .collection("goals")
        .doc(userUid)
        .collection("goals")
        .where("isDone", "==", false)
        .where("date", ">=", moment(day.date).utcOffset(0, true).toDate())
        .where(
          "date",
          "<",
          moment(day.date).add(1, "days").utcOffset(0, true).toDate()
        )
        .get();

      const goals = goalsQuery.docs
        .map((doc) => {
          const data = doc.data();

          // Here it's not necessary to convert (like in fetchGoals()) because we don't handle using moment or anything. We just get them in timestamp, and reupload without viewing or modifying anything
          return {
            ...data,
            id: data?.id || doc.id, // data.id if goal is copy of original (rolled more than once). Or goal.id if goal IS the original (first time rolled)
          };
        })
        // Remove goals that have already been rolled over
        .filter(
          (goal) =>
            !rolledOverGoalsIds.includes(goal.fromRecurrentGoal || goal.id)
        )
        // Remove goals that are paused for the day
        .filter((goal) => !goal.isPaused)
        // .filter(
        //   (goal) => !pausedRecurrentGoalsIds.includes(goal.fromRecurrentGoal)
        // )
        // Remove goals that don't roll-over
        .filter(
          (goal) =>
            goal.isRolledOver === true || goal.isRolledOver === undefined
        )
        // Remove goals that have already ended
        .filter(
          (goal) =>
            !goal.endDate || moment(goal.endDate).isAfter(moment(day.date))
        )
        // Remove daily goals
        .filter(
          (goal) =>
            !goal?.recurrencePattern ||
            goal?.recurrencePattern !== recurrencePatternEnum.DAILY
        )
        // Remove goals that are due for the day where goals will roll over to (so they don't repeat)
        .filter(
          (goal) =>
            !goal?.recurrencePattern ||
            (goal?.recurrencePattern === recurrencePatternEnum.WEEKLY &&
              !goal?.days.includes(
                moment(dayToRollOverGoalsTo).isoWeekday()
              )) ||
            (goal?.recurrencePattern === recurrencePatternEnum.MONTHLY &&
              !(
                moment(goal.startDate.toDate()).date() ===
                moment(dayToRollOverGoalsTo).date()
              )) ||
            (goal?.recurrencePattern === recurrencePatternEnum.YEARLY &&
              !(
                moment(goal.startDate.toDate()).date() ===
                  moment(dayToRollOverGoalsTo).date() &&
                moment(goal.startDate.toDate()).month() ===
                  moment(dayToRollOverGoalsTo).month()
              ))
        );

      if (goals.length) {
        // Add the ids of just rolled over goals to prevent re-adding them later
        rolledOverGoalsIds.push(
          ...goals.map((goal) => goal.fromRecurrentGoal || goal.id)
        );

        for (const goal of goals) {
          const newMovedGoalRef = firestore
            .collection("goals")
            .doc(userUid)
            .collection("goals")
            .doc();

          batch.set(newMovedGoalRef, {
            ...goal,
            date: getTimestamp(moment(dayToRollOverGoalsTo).utcOffset(0, true)),
            originalDate:
              goal.originalDate ||
              getTimestamp(moment(day.date).utcOffset(0, true)),
          });

          if (goal.isPartiallyDone) {
            batch.update(firestore.doc(`users/${userUid}`), {
              lastTimeUsedPartiallyDoneGoals: getTimestamp(),
            });
          }
        }

        batch.update(firestore.doc(`days/${userUid}/days/${day.id}`), {
          isSubmitted: true,
          movedGoals: goals.map((goal) => goal.id),
          lastSubmissionType: "auto",
        });
      } else {
        batch.update(firestore.doc(`days/${userUid}/days/${day.id}`), {
          isSubmitted: true,
          lastSubmissionType: "auto",
        });
      }
    }

    await batch.commit();
    console.log("Day autosubmit completed.");
  } catch (error) {
    captureException("Error while autosubmitting day", error);
    console.error("Error while autosubmitting day: " + error);
  }
};

export const isDayCreated = async (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const id = moment(date)
      .startOf("day")
      .utcOffset(0, true)
      .format("YYYYMMDD");

    return await firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .doc(id)
      .get()
      .then((query) => {
        if (query.exists) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        captureException("Error checking if day is instantiated", error);
        console.log("Error checking if day is instantiated: " + error);
      });
  } catch (error) {
    captureException("Error checking if day is instantiated", error);
    console.log("Error checking if day is instantiated: " + error);
  }
};

export const isDaySubmitted = async (date = moment()) => {
  try {
    const userUid = auth.currentUser.uid;

    const startOfDay = moment(date).startOf("day").utcOffset(0, true).toDate();

    const dayQuery = await firestore
      .collection(`days/${userUid}/days`)
      .where("date", "==", startOfDay)
      .where("isSubmitted", "==", true)
      .limit(1)
      .get();

    return !dayQuery.empty;
  } catch (error) {
    captureException("Error while checking if day is submitted", error);
    console.log("Error while checking if day is submitted: " + error);
    return false;
  }
};

export const noDayAlreadySubmitted = async () => {
  try {
    const userUid = auth.currentUser.uid;

    return await firestore
      .collection("days")
      .doc(userUid)
      .collection("days")
      .where("isSubmitted", "==", true)
      .limit(1)
      .get()
      .then(async (query) => {
        return query.empty;
      });
  } catch (error) {
    captureException(
      "Failed to check if there's any day already submitted",
      error
    );
    console.error(
      "Failed to check if there's any day already submitted: " + error
    );
  }
};
