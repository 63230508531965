import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import Linkify from "react-linkify";

// Analytics
import { analytics } from "../../api/firebase";

import { FaAngleDown, FaPlayCircle, FaStar } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { useTheme, IconButton, Button, CircularProgress } from "@mui/material";

import { getVerticalPos } from "../../api/helper";
import { colors, EMOJIS } from "../../api/constants";
import "./style.scss";

const MessageBubble = ({
  item,
  setShowImageFullScreenModal,
  setImageForFullScreenModal,
  setShowVideoFullScreenModal,
  setVideoForFullScreenModal,
  handleSelectedMessage,
  elementIdToScrollTo,
  setElementIdToScrollTo,
  setIsInactiveInfoOverlayVisible,
  userId,
  coachName,
  userBecameInactive,
  userType,
  handleDeleteReaction,
  handleAddEditReaction,
  clientStarred,
  setIsBubbleOptionsOpen,
}) => {
  const theme = useTheme();

  const messageOptionsRef = useRef(null);

  const [showMessageOptions, setShowMessageOptions] = useState(false);

  // Message options (copy, reply, delete)
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        messageOptionsRef.current &&
        !messageOptionsRef.current.contains(event.target)
      ) {
        toggleContextMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [messageOptionsRef]);

  // Bubble options/context menu
  const toggleContextMenu = (value) => {
    setIsBubbleOptionsOpen(value);
    setShowMessageOptions(value);
  };

  // Hyperlinks
  const componentDecorator = (href, text, key, item, isMessageSent = false) => (
    <a
      href={href}
      key={key}
      target="_blank"
      rel="noreferrer"
      style={{
        color: item.isMonthlyResults
          ? colors.hyperlink2
          : isMessageSent
          ? theme.palette.hyperlinkSent.main
          : theme.palette.hyperlinkReceived.main,
      }}
    >
      {text}
    </a>
  );

  const onReactionPressed = (messageId) => {
    handleDeleteReaction(messageId);
  };

  const onVideoMessageClicked = (video) => {
    setShowVideoFullScreenModal(true);
    setVideoForFullScreenModal(video?.url);

    // Log analytics event
    if (video.isWelcomeVideo) {
      analytics.logEvent("opened_video", {
        type: "welcomeVideo",
      });
    } else if (video.isWelcomeVideoWithCta) {
      analytics.logEvent("opened_video", {
        type: "welcomeVideoWithCta",
      });
    } else {
      analytics.logEvent("opened_video");
    }
  };

  return item.sentBy === userId ? (
    //////////////////// Message SENT View ////////////////////
    <div
      id={`message-${item.id}`}
      className="chatMessageSentContainer"
      style={{
        marginBottom: item?.clientReaction || item?.coachReaction ? 15 : 2, // To prevent covering next message with reaction
      }}
    >
      {/* /// Bubble /// */}
      <div
        className="chatMessageSentBubbleContainer"
        style={{
          background: item.isMilestone
            ? "linear-gradient(90deg, " +
              colors.milestoneGradientFirst +
              " 5%, " +
              colors.milestoneGradientSecond +
              " 100%)"
            : item.isMonthlyResults
            ? "linear-gradient(90deg, " +
              colors.monthlyResultsGradientFirst +
              " 0%, " +
              colors.monthlyResultsGradientSecond +
              " 100%)"
            : theme.palette.messageBubbleSentBackground.main,
          border:
            item.isTargetAdded || item.isTargetAchieved ? "solid" : "none",
          borderWidth: 2,
          borderColor: item.isTargetAdded
            ? theme.palette.primary.main
            : item.isTargetAchieved
            ? colors.milestoneGradientSecond
            : "transparent",
          minWidth:
            item.text && item.text.length > 35 && window.innerWidth < 500
              ? "75%"
              : "auto",
        }}
      >
        {/* /// Replied Message /// */}
        {item.repliedMessage && (
          <div
            className="message-replied-container"
            style={{
              backgroundColor: theme.palette.chatBackground.main,
              borderLeftColor:
                item.repliedMessage.sentBy === userId
                  ? theme.palette.primary.main
                  : theme.palette.reply.main,
            }}
            onClick={() => {
              setElementIdToScrollTo(`message-${item.repliedMessage.id}`);
            }}
          >
            <>
              <label
                className="message-sender"
                style={{
                  color:
                    item.repliedMessage.sentBy === userId
                      ? theme.palette.primary.main
                      : theme.palette.reply.main,
                }}
              >
                {item.repliedMessage.sentBy === userId ? "You" : coachName}
                {elementIdToScrollTo &&
                  elementIdToScrollTo ===
                    `message-${item.repliedMessage.id}` && (
                    <div className="replied-message-loader">
                      <CircularProgress color="secondary" size={14} />
                    </div>
                  )}
              </label>
              {item.repliedMessage.text && (
                <>
                  <label
                    className="message-text"
                    style={{ color: theme.palette.text.main }}
                  >
                    {item.repliedMessage.text}
                  </label>
                  {item.repliedMessage.text.length > 100 && (
                    <label style={{ color: theme.palette.text.main }}>
                      ...
                    </label>
                  )}
                </>
              )}
              {item.repliedMessage.image && (
                <div className="message-img-wrap">
                  <img
                    src={item.repliedMessage.image}
                    className="replied-image"
                    onClick={() => {
                      setShowImageFullScreenModal(true);
                      setImageForFullScreenModal(item.repliedMessage.image);
                    }}
                    alt="[Image]"
                  />
                </div>
              )}
              {item.repliedMessage.video ? (
                item.repliedMessage.video?.thumbnail ? (
                  <div
                    className="message-img-wrap"
                    style={{ height: 100, width: 100 }}
                  >
                    <img
                      src={item.repliedMessage.video.thumbnail}
                      className="replied-image"
                      alt="[Image]"
                    />
                    <div className="play-icon-container">
                      <FaPlayCircle size={20} color={colors.light} />
                    </div>
                  </div>
                ) : (
                  <div className="message-video-wrap">
                    <div
                      className="replied-video"
                      style={{ backgroundColor: theme.palette.tertiary.main }}
                    >
                      <FaPlayCircle size={40} color={colors.light} />
                    </div>
                  </div>
                )
              ) : null}
            </>
          </div>
        )}
        {/* /// Message text /// */}
        {!!item.text && (
          <label
            className="chat-message-text"
            style={{
              color: item.isMilestone
                ? theme.palette.light.main
                : item.isMonthlyResults
                ? theme.palette.light.main
                : theme.palette.text.main,
              lineHeight:
                item.isMilestone || item.isTargetAdded || item.isTargetAchieved
                  ? 1.5
                  : null,
            }}
          >
            <Linkify
              componentDecorator={(href, text, key) =>
                componentDecorator(href, text, key, item)
              }
            >
              {item.isMilestone ||
              item.isTargetAdded ||
              item.isTargetAchieved ||
              item.isDailyResults ||
              item.isMonthlyResults ? (
                <>
                  {/* This is to make the first line of message bold */}
                  <strong>
                    {item.text.substring(0, item.text.indexOf("\n"))}
                  </strong>
                  {item.text.substring(item.text.indexOf("\n"))}
                </>
              ) : (
                item.text
              )}
            </Linkify>
          </label>
        )}
        {/* /// Message subjective rate /// */}
        {!!item.subjectiveWellBeingScale && (
          <div className="subjective-well-being-scale">
            {item.subjectiveWellBeingScale === 1 ? (
              <label style={{ fontSize: 21 }}>😭</label>
            ) : item.subjectiveWellBeingScale === 2 ? (
              <label style={{ fontSize: 21 }}>🙁</label>
            ) : item.subjectiveWellBeingScale === 3 ? (
              <label style={{ fontSize: 21 }}>😐</label>
            ) : item.subjectiveWellBeingScale === 4 ? (
              <label style={{ fontSize: 21 }}>🙂</label>
            ) : item.subjectiveWellBeingScale === 5 ? (
              <label style={{ fontSize: 21 }}>😀</label>
            ) : null}
            <label
              style={{
                color: item.isMilestone
                  ? theme.palette.light.main
                  : item.isMonthlyResults
                  ? theme.palette.light.main
                  : theme.palette.text.main,
                marginLeft: 5,
              }}
            >
              {item.subjectiveWellBeingScale}/5
            </label>
          </div>
        )}
        {/* /// Message image /// */}
        {!!item.image && (
          <div className="message-img-wrap">
            <img
              src={item.image}
              className="message-with-image"
              onClick={() => {
                setShowImageFullScreenModal(true);
                setImageForFullScreenModal(item.image);
              }}
              alt="[Image]"
            />
          </div>
        )}
        {/* /// Message video /// */}
        {!!item.video ? (
          item.video?.thumbnail ? (
            <div className="message-img-wrap">
              <img
                src={item.video.thumbnail}
                className="message-with-image"
                onClick={() => {
                  onVideoMessageClicked(item.video);
                }}
                alt="[Image]"
              />
              <div className="play-icon-container">
                <FaPlayCircle size={50} color={colors.light} />
              </div>
            </div>
          ) : (
            <div
              className="message-video-wrap"
              onClick={() => {
                onVideoMessageClicked(item.video);
              }}
            >
              <div
                className="message-with-video"
                style={{ backgroundColor: theme.palette.tertiary.main }}
              >
                <FaPlayCircle size={80} color={colors.light} />
              </div>
            </div>
          )
        ) : null}
        {/* /// Message date and icons/// */}
        <div
          className="message-date-and-icons-container"
          style={{
            justifyContent: "flex-end",
            marginBottom: item?.clientReaction || item?.coachReaction ? 5 : 0,
          }}
        >
          {clientStarred ? (
            <FaStar
              size={10}
              color={theme.palette.tertiary.main}
              style={{ marginRight: 3 }}
            />
          ) : null}
          <span
            className="messageDate"
            style={{
              color: item.isMilestone
                ? theme.palette.light.main
                : item.isMonthlyResults
                ? theme.palette.gray.light
                : theme.palette.gray.main,
            }}
          >
            {moment(item.sentAt).format("h:mm a")}
          </span>
        </div>
        {/* /// Message options /// */}
        <div className="show-message-options-btn-on-hover">
          <IconButton size="small" onClick={() => toggleContextMenu(true)}>
            <FaAngleDown />
          </IconButton>
        </div>
        {showMessageOptions && (
          <div
            ref={messageOptionsRef}
            className="message-sent-options-container"
            style={{
              backgroundColor: theme.palette.gray.main,
              top:
                getVerticalPos(document.getElementById(`message-${item.id}`)) >
                320 // If position of bubble is more than 310 to top, show it below so context menu does not get covered by checklist
                  ? !!item.text
                    ? -210 // For text messages
                    : -170 // For image messages
                  : 60, // For messages where context menu is displayed top-down
            }}
          >
            <div
              className="chat-message-sent-reactions-option-container"
              style={{ backgroundColor: theme.palette.gray.main }}
            >
              {EMOJIS.map((emoji) => (
                <IconButton
                  key={`reaction-${emoji}`}
                  size="small"
                  onClick={() => {
                    handleAddEditReaction(item.id, emoji);
                    toggleContextMenu(false);
                  }}
                >
                  <div className="emoji-option-container">
                    <span className="emoji-option-text">{emoji}</span>
                  </div>
                </IconButton>
              ))}
            </div>
            <Button
              color="light"
              sx={{ borderRadius: 0 }}
              onClick={() => {
                handleSelectedMessage(item, "react");
                toggleContextMenu(false);
              }}
            >
              React
            </Button>
            {!!item.text && (
              <Button
                color="light"
                sx={{ borderRadius: 0 }}
                onClick={() => {
                  handleSelectedMessage(item, "copy");
                  toggleContextMenu(false);
                }}
              >
                Copy
              </Button>
            )}
            <Button
              color="light"
              sx={{ borderRadius: 0 }}
              onClick={() => {
                handleSelectedMessage(item, "reply");
                toggleContextMenu(false);
              }}
            >
              Reply
            </Button>
            <Button
              color="light"
              sx={{ borderRadius: 0 }}
              onClick={() => {
                handleSelectedMessage(item, "star");
                toggleContextMenu(false);
              }}
            >
              {clientStarred ? "Unstar" : "Star"}
            </Button>
            <Button
              color="light"
              sx={{ borderRadius: 0 }}
              onClick={() => {
                handleSelectedMessage(item, "edit");
                toggleContextMenu(false);
              }}
            >
              Edit
            </Button>
            <Button
              color="light"
              sx={{ borderRadius: 0 }}
              onClick={() => {
                handleSelectedMessage(item, "delete");
                toggleContextMenu(false);
              }}
            >
              Delete
            </Button>
          </div>
        )}
        {/* Reactions */}
        {item?.clientReaction || item?.coachReaction ? (
          <div className="chatMessageSentReactionsContainer">
            {item?.coachReaction ? (
              <div
                className="reaction-container"
                style={{
                  backgroundColor: theme.palette.reactionContainer.main,
                }}
              >
                <span className="reaction-text">{item?.coachReaction}</span>
              </div>
            ) : null}
            {item?.clientReaction ? (
              <div
                className="reaction-container"
                style={{
                  cursor: "pointer",
                  backgroundColor: theme.palette.reactionContainer.main,
                }}
                onClick={() => onReactionPressed(item?.id)}
              >
                <span className="reaction-text">{item?.clientReaction}</span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {/* /// Inactive client alert - coach not receiving messages (subscription expired/is free user) /// */}
      {(userBecameInactive &&
        moment(item.sentAt).isAfter(moment(userBecameInactive))) ||
      userType === undefined ? (
        <div className="alert-icon-container">
          <IconButton
            size="small"
            onClick={() => setIsInactiveInfoOverlayVisible(true)}
          >
            <FiAlertCircle color={theme.palette.error.main} />
          </IconButton>
        </div>
      ) : null}
    </div>
  ) : (
    //////////////////// Message Received View ////////////////////
    <div
      id={`message-${item.id}`}
      className="chatMessageReceivedBubbleContainer"
      style={{
        background: !item.isMilestone
          ? theme.palette.messageBubbleReceivedBackground.main
          : "linear-gradient(90deg, " +
            colors.milestoneGradientFirst +
            " 5%, " +
            colors.milestoneGradientSecond +
            " 100%)",
        minWidth:
          item.text && item.text.length > 35 && window.innerWidth < 500
            ? "75%"
            : 130,
        marginBottom: item?.clientReaction || item?.coachReaction ? 15 : 2, // To prevent covering next message with reaction
      }}
    >
      {/* /// Replied Message /// */}
      {item.repliedMessage && (
        <div
          className="message-replied-container"
          style={{
            backgroundColor: theme.palette.chatBackground.main,
            borderLeftColor:
              item.repliedMessage.sentBy === userId
                ? theme.palette.primary.main
                : theme.palette.reply.main,
          }}
          onClick={() => {
            setElementIdToScrollTo(`message-${item.repliedMessage.id}`);
          }}
        >
          <>
            <label
              className="message-sender"
              style={{
                color:
                  item.repliedMessage.sentBy === userId
                    ? theme.palette.primary.main
                    : theme.palette.reply.main,
              }}
            >
              {item.repliedMessage.sentBy === userId ? "You" : coachName}
              {elementIdToScrollTo &&
                elementIdToScrollTo === `message-${item.repliedMessage.id}` && (
                  <div className="replied-message-loader">
                    <CircularProgress color="secondary" size={14} />
                  </div>
                )}
            </label>
            {item.repliedMessage.text && (
              <>
                <label
                  className="message-text"
                  style={{ color: theme.palette.text.main }}
                >
                  {item.repliedMessage.text}
                </label>
                {item.repliedMessage.text.length > 100 && (
                  <label style={{ color: theme.palette.text.main }}>...</label>
                )}
              </>
            )}
            {item.repliedMessage.image && (
              <div className="message-img-wrap">
                <img
                  src={item.repliedMessage.image}
                  className="replied-image"
                  onClick={() => {
                    setShowImageFullScreenModal(true);
                    setImageForFullScreenModal(item.repliedMessage.image);
                  }}
                  alt="[Image]"
                />
              </div>
            )}
            {item.repliedMessage.video ? (
              item.repliedMessage.video?.thumbnail ? (
                <div
                  className="message-img-wrap"
                  style={{ height: 100, width: 100 }}
                >
                  <img
                    src={item.repliedMessage.video.thumbnail}
                    className="replied-image"
                    alt="[Image]"
                  />
                  <div className="play-icon-container">
                    <FaPlayCircle size={20} color={colors.light} />
                  </div>
                </div>
              ) : (
                <div className="message-video-wrap">
                  <div
                    className="replied-video"
                    style={{ backgroundColor: theme.palette.tertiary.main }}
                  >
                    <FaPlayCircle size={40} color={colors.light} />
                  </div>
                </div>
              )
            ) : null}
          </>
        </div>
      )}
      {/* /// Message text /// */}
      {!!item.text && (
        <label
          className="chat-message-text"
          style={{
            color: !item.isMilestone
              ? theme.palette.text.main
              : theme.palette.light.main,
            fontWeight: !item.isMilestone ? "normal" : "bold",
          }}
        >
          <Linkify
            componentDecorator={(href, text, key) =>
              componentDecorator(href, text, key, item)
            }
          >
            {item.isMilestone ||
            item.isTargetAdded ||
            item.isTargetAchieved ||
            item.isDailyResults ||
            item.isMonthlyResults ? (
              <>
                {/* This is to make the first line of message bold */}
                <strong>
                  {item.text.substring(0, item.text.indexOf("\n"))}
                </strong>
                {item.text.substring(item.text.indexOf("\n"))}
              </>
            ) : (
              item.text
            )}
          </Linkify>
        </label>
      )}
      {/* /// Message image /// */}
      {!!item.image && (
        <div className="message-img-wrap">
          <img
            src={item.image}
            className="message-with-image"
            onClick={() => {
              setShowImageFullScreenModal(true);
              setImageForFullScreenModal(item.image);
            }}
            alt="[Image]"
          />
        </div>
      )}
      {/* /// Message video /// */}
      {!!item.video ? (
        item.video?.thumbnail ? (
          <div className="message-img-wrap">
            <img
              src={item.video.thumbnail}
              className="message-with-image"
              onClick={() => {
                onVideoMessageClicked(item.video);
              }}
              alt="[Image]"
            />
            <div className="play-icon-container">
              <FaPlayCircle size={50} color={colors.light} />
            </div>
          </div>
        ) : (
          <div
            className="message-video-wrap"
            onClick={() => {
              onVideoMessageClicked(item.video);
            }}
          >
            <div
              className="message-with-video"
              style={{ backgroundColor: theme.palette.tertiary.main }}
            >
              <FaPlayCircle size={80} color={colors.light} />
            </div>
          </div>
        )
      ) : null}
      {/* /// Message date and icons /// */}
      <div
        className="message-date-and-icons-container"
        style={{
          marginBottom: item?.clientReaction || item?.coachReaction ? 5 : 0,
        }}
      >
        <span
          className="messageDate"
          style={{
            color: !item.isMilestone
              ? theme.palette.gray.main
              : theme.palette.light.main,
          }}
        >
          {moment(item.sentAt).format("h:mm a")}
        </span>
        {clientStarred ? (
          <FaStar
            size={10}
            color={theme.palette.tertiary.main}
            style={{ marginLeft: 3 }}
          />
        ) : null}
      </div>
      {/* /// Message options /// */}
      <div className="show-message-options-btn-on-hover">
        <IconButton size="small" onClick={() => toggleContextMenu(true)}>
          <FaAngleDown />
        </IconButton>
      </div>
      {showMessageOptions && (
        <div
          ref={messageOptionsRef}
          className="message-received-options-container"
          style={{
            backgroundColor: theme.palette.gray.main,
            top:
              getVerticalPos(document.getElementById(`message-${item.id}`)) >
              260 // If position of bubble is more than 260 to top, so context menu does not get covered by chat header
                ? !!item.text
                  ? -140 // For text messages
                  : -105 // For image messages
                : 60, // For messages where context menu is displayed top-to-bottom
          }}
        >
          <div
            className="chat-message-received-reactions-option-container"
            style={{ backgroundColor: theme.palette.gray.main }}
          >
            {EMOJIS.map((emoji) => (
              <IconButton
                key={`reaction-${emoji}`}
                size="small"
                onClick={() => {
                  handleAddEditReaction(item.id, emoji);
                  toggleContextMenu(false);
                }}
              >
                <div className="emoji-option-container">
                  <span className="emoji-option-text">{emoji}</span>
                </div>
              </IconButton>
            ))}
          </div>
          <Button
            color="light"
            sx={{ borderRadius: 0 }}
            onClick={() => {
              handleSelectedMessage(item, "react");
              toggleContextMenu(false);
            }}
          >
            React
          </Button>
          {!!item.text && (
            <Button
              color="light"
              sx={{ borderRadius: 0 }}
              onClick={() => {
                handleSelectedMessage(item, "copy");
                toggleContextMenu(false);
              }}
            >
              Copy
            </Button>
          )}
          <Button
            color="light"
            sx={{ borderRadius: 0 }}
            onClick={() => {
              handleSelectedMessage(item, "reply");
              toggleContextMenu(false);
            }}
          >
            Reply
          </Button>
          <Button
            color="light"
            sx={{ borderRadius: 0 }}
            onClick={() => {
              handleSelectedMessage(item, "star");
              toggleContextMenu(false);
            }}
          >
            {clientStarred ? "Unstar" : "Star"}
          </Button>
        </div>
      )}
      {/* Reactions */}
      {item?.clientReaction || item?.coachReaction ? (
        <div className="chatMessageReceivedReactionsContainer">
          {item?.coachReaction ? (
            <div
              className="reaction-container"
              style={{
                backgroundColor: theme.palette.reactionContainer.main,
              }}
            >
              <span className="reaction-text">{item?.coachReaction}</span>
            </div>
          ) : null}
          {item?.clientReaction ? (
            <div
              className="reaction-container"
              style={{
                cursor: "pointer",
                backgroundColor: theme.palette.reactionContainer.main,
              }}
              onClick={() => onReactionPressed(item?.id)}
            >
              <span className="reaction-text">{item?.clientReaction}</span>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const equal = (prev, next) => {
  // This conditional should have every visual (relevant) property of the obj and its id
  return (
    prev.item.id === next.item.id &&
    prev.item?.text === next.item?.text && // In this case, text is necessary because the coach can edit messages
    prev.item?.clientReaction === next.item?.clientReaction &&
    prev.item?.coachReaction === next.item?.coachReaction &&
    prev.item?.clientStarred === next.item?.clientStarred &&
    prev?.userBecameInactive === next?.userBecameInactive
  );
};

export default React.memo(MessageBubble, equal);
