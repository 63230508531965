import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

// Components
import { FaReply } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { FiInfo } from "react-icons/fi";

import { useTheme, CircularProgress, Button } from "@mui/material";

import GoalsList from "../../components/GoalsList";
import DataInput from "../../components/DataInput";
import FAB from "../../components/FAB";
import InfoOverlay from "../../components/InfoOverlay";

// Redux
import { useSelector } from "react-redux";
import {
  addInbox,
  sortInboxes,
  toggleInboxDone,
  undoDeleteInbox,
  undoMarkAsDoneInbox,
} from "../../api/firestore/inboxes";
import { updateUser } from "../../api/firestore/users";

// Own api
import { useWindowSize } from "../../api/helper";
import { playSound } from "../../api/filesHandler";
import goal_done from "../../assets/sounds/goal_done.wav";
import { en } from "../../api/languages/en";
import { Devices, goalsExamples } from "../../api/constants";
import "./style.scss";

const InboxScreen = () => {
  const theme = useTheme();
  const inboxScreenRef = useRef();
  const [, windowHeight] = useWindowSize();
  const location = useLocation();

  // Redux
  const inboxes = useSelector((state) => state.inbox.inboxes);
  const isLoadingInboxes = useSelector((state) => state.inbox.isLoading);
  const isGoalCompleteSoundEnabled = useSelector(
    (state) => state.local.isGoalCompleteSoundEnabled
  );
  const currentDevice = useSelector((state) => state.local.currentDevice);
  const user = useSelector((state) => state.user.user);
  const isSortByIsDone = useSelector((state) => state.local.isSortByIsDone);
  const isSortBy = useSelector((state) => state.local.isSortBy);
  const isOrderBy = useSelector((state) => state.local.isOrderBy);
  const randomNumberGoalsExample = useSelector(
    (state) => state.local.randomNumberGoalsExample
  );

  // States
  const [showInput, setShowInput] = useState(false);
  const [allowNavigation, setAllowNavigation] = useState(true);
  const [deletedInbox, setDeletedInbox] = useState(null);
  const [markedAsDoneInbox, setMarkedAsDoneInbox] = useState(null);
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [inboxToHighlight, setInboxToHighlight] = useState(null);
  const [isInfoOverlayVisible, setIsInfoOverlayVisible] = useState(false);

  /////////////////// Show info popup on first time user ///////////////////
  useEffect(() => {
    if (user && user.seenInboxInfo === undefined) {
      setIsInfoOverlayVisible(true);
      updateUser({ seenInboxInfo: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /////////////////// Data Input ///////////////////
  const addInboxPressed = () => {
    setShowInput(true);
    setAllowNavigation(false);
  };

  /////////////////// Submit new inbox ///////////////////
  const submitNewInbox = async (name) => {
    const inbox = {
      name,
      isDone: false,
      position: inboxes.length.toString(),
      tag: tag,
      description: "",
    };

    let lastInboxAddedId = await addInbox(inbox);

    // Reset
    setName("");
    setTag("");

    setInboxToHighlight(lastInboxAddedId);
  };

  /////////////////// Highligh duplicated inbox ///////////////////
  useEffect(() => {
    if (location.state && location.state.duplicatedInboxId) {
      setInboxToHighlight(location.state.duplicatedInboxId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (inboxToHighlight) {
      const highlightInboxTimeout = setTimeout(() => {
        setInboxToHighlight(null);
      }, 3000);

      return () => {
        clearTimeout(highlightInboxTimeout);
      };
    }
  }, [inboxToHighlight]);

  /////////////////// Toggle Inbox Done ///////////////////
  const inboxBoxPressed = (itemPressed) => {
    if (isGoalCompleteSoundEnabled && !itemPressed.isDone) {
      playSound(goal_done);
    }

    toggleInboxDone({ ...itemPressed, isDone: !itemPressed.isDone });

    setMarkedAsDoneInbox(itemPressed); // For undo mark as done
  };

  /////////////////// Undo delete / marked as done inbox ///////////////////
  useEffect(() => {
    if (location.state && location.state.goal) {
      setDeletedInbox({
        ...location.state.goal,
        createdAt: moment(location.state.goal.createdAt), // is received as "date" and converted back into moment
        ...(location.state.goal.updatedAt && {
          updatedAt: moment(),
        }), // is received as "date" and converted back into moment
      });
    }
  }, [location.state]);

  // Forget last deleted goal after 5 seconds
  useEffect(() => {
    if (markedAsDoneInbox || deletedInbox) {
      const markedAsDoneOrDeletedInboxTimeout = setTimeout(() => {
        setDeletedInbox(null);
        setMarkedAsDoneInbox(null);
      }, 5000);

      return () => {
        clearTimeout(markedAsDoneOrDeletedInboxTimeout);
      };
    }
  }, [markedAsDoneInbox, deletedInbox]);

  const restoreDeletedInbox = () => {
    undoDeleteInbox(deletedInbox);
    setDeletedInbox(null);
  };

  const restoreMarkedAsDoneInbox = () => {
    undoMarkAsDoneInbox(markedAsDoneInbox);
    setMarkedAsDoneInbox(null);
  };

  /////////////////// Shortcuts ///////////////////
  useEffect(() => {
    if (allowNavigation) {
      inboxScreenRef?.current?.focus();
    }
  }, [allowNavigation]);

  useEffect(() => {
    let listenerRefValue = null;

    if (inboxScreenRef.current) {
      inboxScreenRef.current.addEventListener("keydown", downHandler);
      listenerRefValue = inboxScreenRef.current;
    }

    return () => {
      if (listenerRefValue) {
        listenerRefValue.removeEventListener("keydown", downHandler);
      }
    };
  });

  const downHandler = (key) => {
    if (allowNavigation) {
      switch (key.keyCode) {
        case 13:
          setShowInput(true);
          setAllowNavigation(false);
          break;
        default:
          break;
      }
    } else {
      if (key.keyCode === 27) {
        setShowInput(false);
        setAllowNavigation(true);
      }
    }
  };

  // Styles for screen height
  const styles = {
    // notForPhones: {
    //   height:
    //     currentDevice === "PHONE"
    //       ? "calc(100vh - 205px)"
    //       : "calc(100vh - 140px)",
    // },
    notForPhones: {
      height: currentDevice !== "PHONE" && "calc(100vh - 75px)",
      overflowY: currentDevice !== "PHONE" && "scroll",
    },
  };

  /////////////////// Render ///////////////////
  return (
    <div
      tabIndex="0"
      ref={inboxScreenRef}
      id="inbox-screen"
      className="inboxes-screen-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        height: currentDevice === "PHONE" ? windowHeight - 70 : null,
      }}
    >
      <div
        className="inboxes-screen-content-container"
        style={{ backgroundColor: theme.palette.background.main }}
      >
        <div className="title-container">
          <Button
            color="contrast"
            onClick={() => setIsInfoOverlayVisible(true)}
            sx={{ height: 20, fontSize: 20, fontWeight: "bold", padding: 3 }}
          >
            Undated
            <FiInfo
              size={18}
              color={theme.palette.gray.dark}
              style={{ marginLeft: 5 }}
            />
          </Button>
        </div>

        <div
          className="inboxes-screen-scroll-section-container"
          style={styles.notForPhones}
        >
          {/* Goals List */}
          {isLoadingInboxes ? (
            <div className="loading-spinner-container">
              <CircularProgress color="primary" className="loading-spinner" />
            </div>
          ) : (
            <div
              style={{
                paddingBottom: currentDevice === Devices.PHONE ? 170 : 120,
              }}
            >
              {inboxes?.length &&
              inboxes?.filter((el) => !el.isDone)?.length ? (
                <GoalsList
                  goals={
                    isLoadingInboxes ? [] : inboxes.filter((el) => !el.isDone)
                  }
                  goalBoxPressedHandler={inboxBoxPressed}
                  screenName="Edit Goal"
                  sortHandler={sortInboxes}
                  sortByIsDone={isSortByIsDone}
                  sortBy={isSortBy}
                  orderBy={isOrderBy}
                  setAllowNavigation={setAllowNavigation}
                  isDataInputVisible={showInput}
                  itemToHighlight={inboxToHighlight}
                  isInbox // Use this instead of editing in this file in order to prevent doing weird things passing dates
                />
              ) : !showInput ? (
                <div className="no-goals-container">
                  <div className="no-goals-content">
                    <label
                      className="no-goals-title"
                      style={{ color: theme.palette.text.main }}
                    >
                      Nothing yet
                    </label>
                    <label
                      className="no-goals-text"
                      style={{ color: theme.palette.gray.light }}
                    >
                      {"Add your first undated goal \n eg " +
                        goalsExamples[randomNumberGoalsExample]}
                    </label>
                  </div>
                </div>
              ) : null}

              {!showInput &&
              inboxes?.length &&
              inboxes?.filter((el) => !el.isDone)?.length ? (
                <div
                  style={{
                    marginLeft: 35,
                    marginTop: 10,
                  }}
                >
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={addInboxPressed}
                    sx={{
                      height: 30,
                      // width: 150,
                    }}
                  >
                    Add goal
                  </Button>
                </div>
              ) : null}
            </div>
          )}

          {!isLoadingInboxes && showInput && (
            <DataInput
              onSubmitHandler={submitNewInbox}
              placeholder="What's your next goal?"
              value={name}
              setValue={setName}
              setIsVisible={setShowInput}
              setAllowNavigation={setAllowNavigation}
              btnAddTitle="Add goal"
              autoFocus
            />
          )}
        </div>
      </div>

      <div
        className="float-btns-container"
        style={{
          position: currentDevice === Devices.PHONE ? "fixed" : "absolute",
          bottom: currentDevice === Devices.PHONE ? 50 : 0,
        }}
      >
        {/* Add button */}
        <FAB
          position="right"
          icon={<FiPlus size={38} />}
          // title={currentDevice !== Devices.PHONE ? "Create" : ""}
          isVisible={!showInput}
          handleClick={addInboxPressed}
        />
        {/* Undo marked as done button */}
        <FAB
          position="left"
          icon={<FaReply size={30} />}
          isVisible={markedAsDoneInbox}
          handleClick={restoreMarkedAsDoneInbox}
        />

        {/* Undo delete button */}
        <FAB
          position="left"
          icon={<FaReply size={30} />}
          isVisible={deletedInbox}
          handleClick={restoreDeletedInbox}
        />
      </div>

      <InfoOverlay
        isVisible={isInfoOverlayVisible}
        setIsVisible={setIsInfoOverlayVisible}
        title={en.INFO_TITLE_INBOX}
        subtitle={en.INFO_INBOX}
        textOk={"Got it"}
      />
    </div>
  );
};

export default React.memo(InboxScreen);
