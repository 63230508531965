import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import moment from "moment";

// Components
import { FaDotCircle, FaRegCircle } from "react-icons/fa";
import { useTheme, Switch, Checkbox, CircularProgress } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setHolidayMode } from "../../../redux/actions/local";
import {
  unsubscribeRecurrentGoals,
  fetchRecurrentGoalsForHolidayMode,
} from "../../../redux/actions/recurrentGoal";

// Firestore
import { updateUser } from "../../../api/firestore/users";
import { updateRecurrentGoal } from "../../../api/firestore/recurrentGoals";

// Analytics
import { analytics } from "../../../api/firebase";

// Utils
import { unpauseAllGoalsAndAddSkippedDates } from "../../../api/utils/holidayMode";

// Own Api
import { Devices } from "../../../api/constants";
import { tagColors } from "../../../api/constants";
import "./style.scss";

const HolidayMode = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const DEBOUNCE_TIME = 500;

  // -------------------------------- Redux -------------------------------- //
  const currentDevice = useSelector((state) => state.local.currentDevice);
  const user = useSelector((state) => state.user.user);
  const reduxRecurrentGoals = useSelector(
    (state) => state.recurrentGoal.recurrentGoals
  );
  const isLoadingRecurrentGoals = useSelector(
    (state) => state.recurrentGoal.isLoading
  );

  // -------------------------------- States -------------------------------- //
  const [localHolidayMode, setLocalHolidayMode] = useState(
    user?.holidayMode || null
  );
  const [displayedGoals, setDisplayedGoals] = useState([]);

  useEffect(() => {
    unsubscribeRecurrentGoals();
    dispatch(fetchRecurrentGoalsForHolidayMode());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingRecurrentGoals) {
      setDisplayedGoals(reduxRecurrentGoals);
    }
  }, [isLoadingRecurrentGoals, reduxRecurrentGoals]);

  const debounceUpdateUser = useCallback(
    debounce(async (holidayMode) => {
      if (holidayMode?.isEnabled) {
        analytics.logEvent("used_holiday_mode");

        updateUser({ holidayMode });
      } else {
        if (displayedGoals?.length) {
          let daysToSkip = [];
          const today = moment().startOf("day"); // Start of today
          const yesterday = today.clone().subtract(1, "days"); // We use yesterday because if we disable holiday mode today, it means we're back to work since today
          const currentDate = moment(
            user.holidayMode.since?.toDate && user.holidayMode.since.toDate()
          )
            .startOf("day")
            .clone();

          // Only add skippedDates if client enabled it and disabled it in different days
          if (
            !moment(
              user?.holidayMode?.since?.toDate &&
                user?.holidayMode.since.toDate()
            ).isSame(moment(), "day")
          ) {
            // Calculate days to skip
            while (currentDate.isSameOrBefore(yesterday, "day")) {
              daysToSkip.push(currentDate.format("YYYYMMDD"));
              currentDate.add(1, "days");
            }
          }

          // Unpause all paused goals and add days spent on holiday to skippedDates
          const goalsToUnpause = displayedGoals.filter(
            (goal) => goal?.isPaused
          );

          await unpauseAllGoalsAndAddSkippedDates(
            goalsToUnpause,
            daysToSkip,
            holidayMode
          );
        } else {
          updateUser({ holidayMode });
        }
      }

      dispatch(
        setHolidayMode({
          holidayMode: holidayMode,
        })
      );
    }, DEBOUNCE_TIME),
    [user?.holidayMode, displayedGoals]
  );

  const toggleHolidayMode = (value) => {
    let updatedHolidayMode;

    if (value) {
      updatedHolidayMode = { isEnabled: true, since: moment() };
    } else {
      updatedHolidayMode = { isEnabled: false, since: null };
    }

    setLocalHolidayMode(updatedHolidayMode);
    debounceUpdateUser(updatedHolidayMode);
  };

  const toggleGoalPause = (goalModified) => {
    // This is the way it's done to prevent a race condition. Right?
    setDisplayedGoals((prevGoals) => {
      return prevGoals.map((goal) =>
        goal.id === goalModified.id
          ? { ...goal, isPaused: goalModified.isPaused }
          : goal
      );
    });

    // TODO - Should I debounce this?
    updateRecurrentGoal(goalModified);
  };

  // Navigation
  const btnBackClicked = () => {
    navigate("/settings");
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="holiday-mode-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
      }}
    >
      <div className="holiday-mode-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Settings"
          title="Holiday mode"
        />
        <div className="content">
          <div className="option">
            <span
              className="option-text"
              style={{ color: theme.palette.text.main }}
              onClick={() => {
                toggleHolidayMode(!localHolidayMode?.isEnabled);
              }}
            >
              Is enabled?
            </span>
            <Switch
              color={localHolidayMode?.isEnabled ? "primary" : "gray"}
              checked={localHolidayMode?.isEnabled}
              onChange={() => {
                toggleHolidayMode(!localHolidayMode?.isEnabled);
              }}
            />
          </div>

          {localHolidayMode?.isEnabled ? (
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <span
                className="title"
                style={{ color: theme.palette.text.main }}
              >
                Select the repeating goals you want to pause
              </span>
              <div className="goals-container">
                {isLoadingRecurrentGoals ? (
                  <div className="loading-day-spinner-container">
                    <CircularProgress
                      color="primary"
                      className="loading-day-spinner"
                    />
                  </div>
                ) : displayedGoals?.length ? (
                  displayedGoals
                    .map((el) => ({
                      ...el,
                      isPaused: !!el.isPaused, // Initialize isPaused in case it doesn't exist (might cause app crash)
                    }))
                    .sort((a, b) =>
                      // First. Sort by isPaused first
                      b["isPaused"]
                        .toString()
                        .localeCompare(a["isPaused"].toString(), undefined, {
                          numeric: true,
                        })
                    )
                    .map((goal) => (
                      <div key={`goal-${goal?.id}`} className="goal">
                        <Checkbox
                          color="primary"
                          icon={<FaRegCircle size={22} />}
                          checkedIcon={<FaDotCircle size={22} />}
                          checked={
                            goal?.isPaused !== undefined ||
                            goal?.isPaused !== null
                              ? goal?.isPaused
                              : false
                          }
                          onChange={() => {
                            toggleGoalPause({
                              ...goal,
                              isPaused: !goal?.isPaused,
                            });
                          }}
                        />

                        <span
                          className="goal-name"
                          style={{ color: theme.palette.text.main }}
                          onClick={() => {
                            toggleGoalPause({
                              ...goal,
                              isPaused: !goal?.isPaused,
                            });
                          }}
                        >
                          {goal?.name || "NO_TITLE"}
                        </span>

                        <div
                          className="goal-tag"
                          style={{
                            backgroundColor: goal.tag
                              ? tagColors[goal.tag - 1].color
                              : "transparent",
                          }}
                        />
                      </div>
                    ))
                ) : (
                  <div className="no-goals-container">
                    You have no repeating goals yet
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(HolidayMode);
