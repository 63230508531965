import React from "react";
import { useSelector } from "react-redux";

import { useTheme, Button, LinearProgress } from "@mui/material";
import { FaAngleRight } from "react-icons/fa";

import tutorial3Male from "../../assets/img/tutorial_3_male.png";
import tutorial3Female from "../../assets/img/tutorial_3_female.png";

import { useWindowSize } from "../../api/helper";
import { Devices } from "../../api/constants";
import { en } from "../../api/languages/en";
import "./style.scss";

const Screen3 = (props) => {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  return (
    <div
      className="tutorial-container"
      style={{ backgroundColor: theme.palette.background.main }}
    >
      {currentDevice === Devices.PHONE ? (
        <img
          src={
            props?.quiz?.gender === "Female" ? tutorial3Female : tutorial3Male
          }
          width="100%"
          height={529 * (windowWidth / 375)}
          alt="3rd tutorial"
        />
      ) : (
        <img
          src={
            props?.quiz?.gender === "Female" ? tutorial3Female : tutorial3Male
          }
          width={375}
          height={529}
          alt="3rd tutorial"
        />
      )}

      <div className="body">
        <label className="text" style={{ color: theme.palette.text.main }}>
          {en.TUTORIAL_3}
        </label>
      </div>

      <div className="footer">
        <LinearProgress
          variant="determinate"
          color="tertiary"
          value={(3 * 100) / 7}
          style={{ width: 150 }}
        />
        <Button
          id="right-click-btn"
          variant="contained"
          color="quaternary"
          onClick={() => props.tutorialRef?.current?.go(4)}
          sx={{ height: 50, width: 50 }}
        >
          <FaAngleRight size={24} />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Screen3);
