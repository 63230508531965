import * as Sentry from "@sentry/react";
import type { TransactionContext } from "@sentry/types";
// import getDeviceInfo from './deviceInfo';
// import deviceInfoModule from 'react-native-device-info';
import { sentryDSN } from "../api/credentials";
import * as SentryBrowser from "@sentry/browser";
import { version } from "../api/constants";

class SentryService {
  public static init() {
    Sentry.init({
      dsn: sentryDSN,
      normalizeDepth: 10,
      integrations: [new SentryBrowser.BrowserTracing(), new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      release: version,
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      enabled: process.env.NODE_ENV !== "development", // To prevent sending reports while on development?
    });
  }

  public static setSentryUser(user: any) {
    Sentry.setUser({
      id: String(user.uid),
      email: user.email,
      username: user.displayName || "",
      extra: {
        ...user,
      },
    });
  }

  public static clearSentryUser() {
    Sentry.setUser(null);
  }

  public static captureException(error: Error) {
    return Sentry.captureException(error);
  }

  public static captureMessage(message: string) {
    Sentry.captureMessage(message);
  }

  public static captureBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    Sentry.addBreadcrumb(breadcrumb);
  }

  public static captureEvent(event: Sentry.Event) {
    Sentry.captureEvent(event);
  }

  // public static captureUserFeedback(userFeedback: Sentry.UserFeedback) {
  //     Sentry.captureUserFeedback(userFeedback);
  // }

  public static startTransaction(context: TransactionContext) {
    return Sentry.startTransaction(context);
  }

  public static setContext(context: string, data: any) {
    Sentry.setContext(context, data);
  }

  public static setExtra(key: string, value: any) {
    Sentry.setExtra(key, value);
  }

  // public static async addBuildContext() {
  //     Sentry.setContext('DeviceInfo', {
  //         appVersion: deviceInfoModule.getVersion(),
  //         buildNumber: deviceInfoModule.getBuildNumber(),
  //         deviceInfo: await getDeviceInfo(),
  //     })
  // }
}

export default SentryService;
