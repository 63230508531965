import { firestore, auth } from "../../api/firebase";
import moment from "moment";

import {
  FETCH_MESSAGES,
  SET_LOADING_MESSAGES,
  SET_LOADING_MORE_MESSAGES,
} from "../types";

import { captureException } from "@sentry/react";

let unsubscribers = [];

var fetchedMessages = [];
var start = null;

const fetchMessagesSuccess = (messages) => ({
  type: FETCH_MESSAGES,
  payload: messages,
});

const setLoadingMessages = (isLoadingMessages) => ({
  type: SET_LOADING_MESSAGES,
  payload: isLoadingMessages,
});

const setLoadingMoreMessages = (isLoadingMoreMessages) => ({
  type: SET_LOADING_MORE_MESSAGES,
  payload: isLoadingMoreMessages,
});

export const fetchMessages = (filterProperty) => (dispatch) => {
  try {
    dispatch(setLoadingMessages(true));

    const userUid = auth.currentUser.uid;

    var query = firestore
      .collection("chats")
      .doc(userUid)
      .collection("messages");

    if (filterProperty) {
      query = query.where(filterProperty, "==", true);
    }

    const messagesSubscriber = query
      .orderBy("sentAt", "desc")
      .limit(50)
      .onSnapshot(
        (snapshot) => {
          // previous starting boundary becomes new ending boundary
          if (snapshot.docs?.length > 0) {
            start = snapshot.docs[snapshot.docs?.length - 1];
          }

          fetchedMessages = []; // Reset fetched messages

          // append new messages to array
          snapshot.docs.forEach((message) => {
            // filter out any duplicates (from modify/delete events)
            fetchedMessages = fetchedMessages.filter(
              (x) => x.id !== message.id
            );

            // Keep only if it's not a systemMessage
            if (!message.data()?.isSystemMessage) {
              fetchedMessages.push({
                ...message.data(),
                id: message.id,
                ...(message.data()?.isDailyResults && {
                  isDailyResults: moment(
                    message.data().isDailyResults?.toDate &&
                      message.data().isDailyResults.toDate()
                  )
                    .utc()
                    .utcOffset(moment().utcOffset(), true),
                }),
                ...(message.data()?.isMonthlyResults && {
                  isMonthlyResults: moment(
                    message.data().isMonthlyResults?.toDate &&
                      message.data().isMonthlyResults.toDate()
                  )
                    .utc()
                    .utcOffset(moment().utcOffset(), true),
                }),
              });
            }
          });

          dispatch(fetchMessagesSuccess(fetchedMessages));
          dispatch(setLoadingMessages(false));
        },
        (error) => {
          dispatch(setLoadingMessages(false));
          captureException("Error fetching messages onSnapshot", error);
          console.log("Error fetching messages onSnapshot: " + error);
        }
      );
    unsubscribers.push(messagesSubscriber);
  } catch (error) {
    dispatch(setLoadingMessages(false));
    captureException("Error fetching messages", error);
    console.log("Error fetching messages: " + error);
  }
};

export const fetchMoreMessages = (filterProperty) => async (dispatch) => {
  try {
    dispatch(setLoadingMoreMessages(true));

    const userUid = auth.currentUser.uid;

    var query = firestore
      .collection("chats")
      .doc(userUid)
      .collection("messages");

    if (filterProperty) {
      query = query.where(filterProperty, "==", true);
    }

    const messagesSubscriber = await query
      .orderBy("sentAt", "desc")
      .startAfter(start)
      .limit(50)
      .get();

    // previous starting boundary becomes new ending boundary
    if (messagesSubscriber.docs?.length > 0) {
      start = messagesSubscriber.docs[messagesSubscriber.docs?.length - 1];
    }

    // append new messages to array
    messagesSubscriber.docs.forEach((message) => {
      // filter out any duplicates (from modify/delete events)
      fetchedMessages = fetchedMessages.filter((x) => x.id !== message.id);

      // Keep only if it's not a systemMessage
      if (!message.data()?.isSystemMessage) {
        fetchedMessages.push({
          ...message.data(),
          id: message.id,
          ...(message.data()?.isDailyResults && {
            isDailyResults: moment(
              message.data().isDailyResults?.toDate &&
                message.data().isDailyResults.toDate()
            )
              .utc()
              .utcOffset(moment().utcOffset(), true),
          }),
          ...(message.data()?.isMonthlyResults && {
            isMonthlyResults: moment(
              message.data().isMonthlyResults?.toDate &&
                message.data().isMonthlyResults.toDate()
            )
              .utc()
              .utcOffset(moment().utcOffset(), true),
          }),
        });
      }
    });

    dispatch(fetchMessagesSuccess(fetchedMessages));
    dispatch(setLoadingMoreMessages(false));

    let hasMore = false;
    if (!messagesSubscriber.empty) {
      hasMore = true;
    }

    return { hasMore };
  } catch (error) {
    dispatch(setLoadingMoreMessages(false));
    captureException("Error fetching more messages", error);
    console.log("Error fetching more messages: " + error);
    throw error;
  }
};

export const unsubscribeChatActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
