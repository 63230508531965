import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  useTheme,
  Alert,
  CircularProgress,
  Button,
  TextField,
  Box,
} from "@mui/material";

import { ReactComponent as SignIn } from "../../assets/svg/signIn.svg";
import { ReactComponent as Email } from "../../assets/svg/email.svg";

import { validateEmail, useWindowSize } from "../../api/helper";
import { sendPasswordResetEmail } from "../../api/auth";
import { en } from "../../api/languages/en";
import { Devices } from "../../api/constants";
import "./style.scss";

function ResetPasswordScreen() {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sendPasswordResetEmailError, setsendPasswordResetEmailError] =
    useState(undefined);
  const [LoadingSendPasswordResetEmail, setLoadingSendPasswordResetEmail] =
    useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validate = () => {
    const validatedEmail = validateEmail(email);
    setEmailError(validatedEmail.message);

    return validatedEmail.isValid;
  };

  const resetPassword = async () => {
    if (validate()) {
      const response = await sendPasswordResetEmail(email);
      if (response) {
        setsendPasswordResetEmailError(response);
      }
    }
    setLoadingSendPasswordResetEmail(false);
  };

  return (
    <div
      className="auth-container"
      style={{ backgroundColor: theme.palette.background.main }}
    >
      {currentDevice === Devices.PHONE ? (
        <SignIn width="100%" height={256 * (windowWidth / 375)} />
      ) : (
        <SignIn width={375} height={256} />
      )}

      <div className="auth-content-container">
        <label className="title">Reset password</label>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            resetPassword();
          }}
        >
          <div className="auth-inputs-container">
            <Box className="auth-input-container">
              <Box className="adornment-container">
                <Email />
              </Box>
              <TextField
                variant="standard"
                fullWidth
                margin="dense"
                type="text"
                id="email"
                label="Your email"
                name="email"
                autoComplete="email"
                value={email}
                error={emailError ? true : false}
                helperText={emailError}
                onChange={handleEmailChange}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                  "& .MuiInput-underline:hover": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                }}
              />
            </Box>
          </div>

          {sendPasswordResetEmailError && (
            <Alert variant="filled" severity="error">
              {sendPasswordResetEmailError}
            </Alert>
          )}

          <div className="btn-auth-container">
            <Button
              onClick={resetPassword}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: 45, borderRadius: 2 }}
              type="submit"
            >
              <div className="btn-auth-text">
                {LoadingSendPasswordResetEmail ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Reset password"
                )}
              </div>
            </Button>
          </div>
        </form>

        <div className="btns-auth-nav-container">
          <div className="btn-auth-nav-container">
            <Link to="/" className="btn-auth-nav">
              <label
                className="clear_btn_text_1"
                style={{
                  color: theme.palette.gray.main,
                }}
              >
                {en.NO_ACCOUNT_1}
              </label>
              <label
                className="clear_btn_text_2"
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                {" "}
                {en.NO_ACCOUNT_2}
              </label>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default React.memo(ResetPasswordScreen);
