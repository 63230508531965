import React, { useEffect, useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import { toast } from "react-toastify";

// Components
import { FaReply, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { FiInfo, FiPlus, FiCornerDownLeft } from "react-icons/fi";

import { useTheme, CircularProgress, Button, Badge } from "@mui/material";

import GoalsList from "../../components/GoalsList";
import DataInput from "../../components/DataInput";
import FAB from "../../components/FAB";
import InfoOverlay from "../../components/InfoOverlay";
import SubmitMonthOverlay from "../../components/SubmitMonthOverlay";
import ImageModal from "../../components/ImageModal";
import CustomTooltip from "../../components/CustomTooltip";
import MessageBubble from "../../components/MessageBubble";
import MonthCalendarPickerOverlay from "../../components/MonthCalendarPickerOverlay";

// Assets
import { ReactComponent as TargetsHeader } from "../../assets/svg/targets_header.svg";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { lastTimeShowedTargetExampleAt } from "../../redux/actions/local";
import { fetchTargets, unsubscribeTargets } from "../../redux/actions/target";
import { fetchMonth, unsubscribeMonths } from "../../redux/actions/month";

// Firestore
import {
  addTarget,
  sortTargets,
  toggleTargetDone,
  undoDeleteTarget,
} from "../../api/firestore/targets";
import {
  undoSubmitMonthResults,
  addMonth,
  isMonthCreated,
  // instantiateMonth,
} from "../../api/firestore/months";
import { addMessage, deleteMessage } from "../../api/firestore/chats";
import { updateUser } from "../../api/firestore/users";

// Analytics
import { analytics } from "../../api/firebase";

// Own api
import { useWindowSize } from "../../api/helper";
import { playSound } from "../../api/filesHandler";
import target_done from "../../assets/sounds/target_done.wav";
import { en } from "../../api/languages/en";
import { Devices, targetsExamples } from "../../api/constants";
import "./style.scss";

const TargetsScreen = () => {
  const theme = useTheme();
  const targetScreenRef = useRef();
  const [windowWidth, windowHeight] = useWindowSize();
  const location = useLocation();
  const dispatch = useDispatch();
  const didMountRefCheckDate = useRef(false);
  const effectRanCheckDate = useRef(false);

  // Redux
  const targets = useSelector((state) => state.target.targets);
  const isLoadingTargets = useSelector((state) => state.target.isLoading);
  const month = useSelector((state) => state.month.month);
  const isLoadingMonth = useSelector((state) => state.month.isLoadingMonth);
  const lastMonth = useSelector((state) => state.month.lastMonth);
  // const months = useSelector((state) => state.month.months);
  const lastMonthTargets = useSelector(
    (state) => state.target.lastMonthTargets
  );
  const isSortByIsDone = useSelector(
    (state) => state.local.isTargetsSortByIsDone
  );
  const isSortBy = useSelector((state) => state.local.isTargetsSortBy);
  const isOrderBy = useSelector((state) => state.local.isTargetsOrderBy);
  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );
  const currentDevice = useSelector((state) => state.local.currentDevice);
  const showedTargetExampleAt = useSelector(
    (state) => state.local.showedTargetExampleAt
  );
  const randomNumberTargetsExample = useSelector(
    (state) => state.local.randomNumberTargetsExample
  );
  const user = useSelector((state) => state.user.user);

  // States
  const [showMonthCalendar, setShowMonthCalendar] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [allowNavigation, setAllowNavigation] = useState(true);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [deletedTarget, setDeletedTarget] = useState(null);
  const [targetName, setTargetName] = useState(""); // Needed to add this one, so DataInput does not reset it after close
  const [tag, setTag] = useState("");
  const [targetToHighlight, setTargetToHighlight] = useState("");
  const [isInfoOverlayVisible, setIsInfoOverlayVisible] = useState(false);
  const [isSubmitMonthOverlayVisible, setIsSubmitMonthOverlayVisible] =
    useState(false);
  const [isLoadingUndoSubmitMonth, setIsLoadingUndoSubmitMonth] =
    useState(false);
  const [showImageFullScreenModal, setShowImageFullScreenModal] =
    useState(false);
  const [imageForFullScreenModal, setImageForFullScreenModal] = useState([{}]);

  useEffect(() => {
    dispatch(fetchTargets());
    dispatch(fetchMonth());
  }, []);

  // If late in the month, show the next month by default (if no targets for the current month)
  // Only run after targets are fetch and ONCE
  useEffect(() => {
    if (didMountRefCheckDate.current) {
      if (!effectRanCheckDate.current) {
        if (
          !targets?.length &&
          moment().startOf("day").format("YYYYMMDD") >
            moment().endOf("month").subtract(6, "days").format("YYYYMMDD")
        ) {
          selectDate(moment().add(1, "month"));
        }
      }

      effectRanCheckDate.current = true;
    }

    return () => {
      didMountRefCheckDate.current = true;
    };
  }, [targets]);

  /////////////////// Show info popup on first time user ///////////////////
  useEffect(() => {
    if (user && user.seenTargetsInfo === undefined) {
      setIsInfoOverlayVisible(true);
      updateUser({ seenTargetsInfo: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /////////////////// Data Input ///////////////////
  const addTargetPressed = () => {
    setShowInput(true);
    setAllowNavigation(false);
  };

  /////////////////// Submit new target ///////////////////
  const submitNewTarget = async () => {
    const target = {
      name: targetName,
      isDone: false,
      tag: tag,
      description: "",
      date: selectedDate,
    };

    const message = `🎯 Committed to \n "${
      target.name
    }"\nby the end of ${moment(
      target.date && target.date.toDate && target.date.toDate()
    ).format("MMMM")}`;

    const messageAddId = await addMessage({
      text: message,
      isTargetAdded: moment(),
      isSystemMessage: false,
    });

    let lastTargetAddedId = await addTarget({ ...target, messageAddId });

    // Check if selected month is created
    const isTheMonthCreated = await isMonthCreated(selectedDate);
    if (!isTheMonthCreated) {
      addMonth(moment(selectedDate));
    }

    // Reset
    setTargetName("");
    setTag("");

    // Update last time showed target example at
    dispatch(lastTimeShowedTargetExampleAt(moment()));

    setTargetToHighlight(lastTargetAddedId);

    analytics.logEvent("target_created");
  };

  /////////////////// Highligh duplicated target ///////////////////
  useEffect(() => {
    if (location.state && location.state.duplicatedTargetId) {
      setTargetToHighlight(location.state.duplicatedTargetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (targetToHighlight) {
      const highlightTargetTimeout = setTimeout(() => {
        setTargetToHighlight(null);
      }, 3000);

      return () => {
        clearTimeout(highlightTargetTimeout);
      };
    }
  }, [targetToHighlight]);

  /////////////////// Toggle Target Done ///////////////////
  const targetBoxPressed = async (itemPressed) => {
    if (
      isSoundEffectsEnabled &&
      !itemPressed.isDone &&
      (!itemPressed.isPartiallyDone ||
        itemPressed.isPartiallyDone === undefined)
    ) {
      playSound(target_done);
    }

    let messageDoneId;

    if (!itemPressed.isDone && !itemPressed.isPartiallyDone) {
      // Marked as done
      const message = `🎯 Target achieved\n${itemPressed.name}`;
      messageDoneId = await addMessage({
        text: message,
        isTargetAchieved: moment(),
        isSystemMessage: false,
      });
    } else {
      // Marked as undone
      if (itemPressed.messageDoneId) {
        deleteMessage({ id: itemPressed.messageDoneId }, false);
      }
    }

    if (itemPressed.isDone) {
      // Set to "in progress"
      toggleTargetDone({
        ...itemPressed,
        isDone: false,
        isPartiallyDone: true,
        messageDoneId: messageDoneId,
      });
    } else if (
      !itemPressed.isDone &&
      (!itemPressed.isPartiallyDone ||
        itemPressed.isPartiallyDone === undefined)
    ) {
      // Set to "done"
      toggleTargetDone({
        ...itemPressed,
        isDone: true,
        isPartiallyDone: false,
        messageDoneId: messageDoneId,
      });
    } else {
      // Set to undone
      toggleTargetDone({
        ...itemPressed,
        isDone: false,
        isPartiallyDone: false,
        messageDoneId: messageDoneId,
      });
    }

    const isThisMonthCreated = await isMonthCreated(itemPressed.date);
    if (!isThisMonthCreated) {
      addMonth(itemPressed.date);
    }
  };

  /////////////////// Undo Delete target ///////////////////
  useEffect(() => {
    if (location.state && location.state.goal) {
      setDeletedTarget({
        ...location.state.goal,
        date: moment(location.state.goal.date)
          .utc()
          .utcOffset(moment().utcOffset(), true), // is received as moment.toString() in utc and converted back into moment with the corresponding offset
        createdAt: moment(location.state.goal.createdAt), // is received as "date" and converted back into moment
        ...(location.state.goal.updatedAt && {
          updatedAt: moment(), // is received as "date" and converted back into moment
        }),
      });
    }
  }, [location.state]);

  // Forget last deleted goal after 5 seconds
  useEffect(() => {
    if (deletedTarget) {
      const deletedTargetTimeout = setTimeout(() => {
        setDeletedTarget(null);
      }, 5000);

      return () => {
        clearTimeout(deletedTargetTimeout);
      };
    }
  }, [deletedTarget]);

  const restoreDeletedTarget = () => {
    console.log(deletedTarget);
    undoDeleteTarget(deletedTarget);
    setDeletedTarget(null);
  };

  /////////////////// Month picker ///////////////////
  const selectDate = useCallback((date = moment()) => {
    // Start a new listener every time the date changes
    unsubscribeTargets();
    unsubscribeMonths();
    dispatch(fetchTargets(moment(date)));
    dispatch(fetchMonth(moment(date)));

    setSelectedDate(date);
    setShowMonthCalendar(false);
  }, []);

  /////////////////// Undo Submit Month ///////////////////
  const undoSubmitMonth = async () => {
    setIsLoadingUndoSubmitMonth(true);
    await undoSubmitMonthResults(selectedDate, month);
    setIsLoadingUndoSubmitMonth(false);
  };

  // To go back to corresponding month after editing/deleting goal or going back
  useEffect(() => {
    if (location.state?.date) {
      selectDate(moment(location.state.date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.date]);

  /////////////////// Shortcuts ///////////////////
  useEffect(() => {
    if (allowNavigation) {
      targetScreenRef?.current?.focus();
    }
  }, [allowNavigation]);

  useEffect(() => {
    let listenerRefValue = null;

    if (targetScreenRef.current) {
      targetScreenRef.current.addEventListener("keydown", downHandler);
      listenerRefValue = targetScreenRef.current;
    }

    return () => {
      if (listenerRefValue) {
        listenerRefValue.removeEventListener("keydown", downHandler);
      }
    };
  });

  const downHandler = (key) => {
    if (allowNavigation) {
      switch (key.keyCode) {
        case 13:
          setShowInput(true);
          setAllowNavigation(false);
          break;
        case 37:
          selectDate(moment(selectedDate).subtract(1, "months"));
          break;
        case 39:
          selectDate(moment(selectedDate).add(1, "months"));
          break;
        default:
          break;
      }
    } else {
      if (key.keyCode === 27) {
        handleLastTimeShowedExampleAt();
        setShowInput(false);
        setAllowNavigation(true);
      }
    }
  };

  const handleLastTimeShowedExampleAt = () => {
    dispatch(lastTimeShowedTargetExampleAt(moment()));
  };

  // Styles for screen height
  const styles = {
    // notForPhones: {
    //   height:
    //     currentDevice === "PHONE"
    //       ? "calc(100vh - 295px)"
    //       : "calc(100vh - 225px)",
    // },
    notForPhones: {
      height: currentDevice !== "PHONE" && "calc(100vh - 150px)",
      overflowY: currentDevice !== "PHONE" && "scroll",
    },
  };

  /////////////////// Render ///////////////////
  return (
    <div
      tabIndex="0"
      ref={targetScreenRef}
      id="targets-screen"
      className="targets-screen-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        height: currentDevice === "PHONE" ? windowHeight - 70 : null,
      }}
    >
      <div
        className="targets-screen-content-container"
        style={{ backgroundColor: theme.palette.background.main }}
      >
        <div className="title-container">
          <Button
            color="contrast"
            onClick={() => setIsInfoOverlayVisible(true)}
            sx={{ height: 20, fontSize: 20, fontWeight: "bold", padding: 3 }}
          >
            Targets
            <FiInfo
              size={18}
              color={theme.palette.gray.dark}
              style={{ marginLeft: 5 }}
            />
          </Button>
        </div>

        {/* Title for the month */}
        <div className="title-navigation-container">
          {moment(
            user?.createdTime &&
              user.createdTime.toDate &&
              user.createdTime.toDate()
          )
            .startOf("day")
            .isBefore(moment(selectedDate).startOf("month")) ? (
            <div className="left-arrow-container">
              <button
                onClick={() => {
                  selectDate(moment(selectedDate).subtract(1, "months"));
                }}
                className="btn-nav-months"
              >
                <Badge
                  variant="standard"
                  color="error"
                  invisible={
                    lastMonth &&
                    !lastMonth.isSubmitted &&
                    lastMonthTargets &&
                    lastMonthTargets.length
                      ? false
                      : true
                  }
                  badgeContent="1"
                >
                  <FaAngleLeft
                    size={28}
                    style={{ color: theme.palette.gray.contrast }}
                  />
                </Badge>
                <label
                  className="btn-nav-months-text"
                  style={{ color: theme.palette.gray.contrast }}
                >
                  {moment(selectedDate)
                    .subtract(1, "months")
                    .format("MMM YYYY")}
                </label>
              </button>
            </div>
          ) : null}

          <button
            onClick={() => setShowMonthCalendar(!showMonthCalendar)}
            className="btn-nav-calendar"
            style={{ backgroundColor: theme.palette.background.main }}
          >
            <label
              className="title-month"
              style={{ color: theme.palette.gray.dark }}
            >
              {moment(selectedDate).format("MMMM YYYY")}
            </label>
          </button>

          <div className="right-arrow-container">
            <button
              onClick={() => {
                selectDate(moment(selectedDate).add(1, "months"));
              }}
              className="btn-nav-months"
            >
              <label
                className="btn-nav-months-text"
                style={{ color: theme.palette.gray.contrast }}
              >
                {moment(selectedDate).add(1, "months").format("MMM YYYY")}
              </label>
              <FaAngleRight
                size={28}
                style={{ color: theme.palette.gray.contrast }}
              />
            </button>
          </div>
        </div>

        <div
          className="targets-screen-scroll-section-container"
          style={styles.notForPhones}
        >
          <div className="targets-img-container">
            {currentDevice === Devices.PHONE ? (
              <TargetsHeader
                width="100%"
                height={100 * ((windowWidth - 20) / 347)}
              />
            ) : (
              <TargetsHeader height={100} />
            )}
          </div>

          {/* Back to this month button */}
          {moment(selectedDate).format("YYYYMM") !==
          moment().format("YYYYMM") ? (
            <div className="back-to-this-month-button-container">
              <Button
                variant="outlined"
                color="gray"
                onClick={() => {
                  selectDate();
                  targetScreenRef?.current?.focus();
                }}
                sx={{ height: 60, width: 180 }}
                fullWidth
              >
                <FiCornerDownLeft size={20} color={theme.palette.gray.main} />
                <label className="back-to-this-month-button-text">
                  This month
                </label>
              </Button>
            </div>
          ) : null}

          {/* Goals List */}
          {isLoadingTargets || isLoadingMonth ? (
            <div className="loading-spinner-container">
              <CircularProgress color="primary" className="loading-spinner" />
            </div>
          ) : (
            <div
              style={{
                paddingBottom: currentDevice === Devices.PHONE ? 170 : 120,
              }}
            >
              {/* unsubmitted months goals list */}
              {targets && targets.length ? (
                <div>
                  <GoalsList
                    goals={targets}
                    goalBoxPressedHandler={targetBoxPressed}
                    isSubmitted={
                      (month && month.isSubmitted) ||
                      (!month &&
                        selectedDate <
                          moment().subtract(1, "months").startOf("month"))
                    }
                    sortHandler={sortTargets}
                    sortByIsDone={isSortByIsDone}
                    sortBy={
                      isSortBy && isSortBy !== "position" ? isSortBy : "name"
                    }
                    orderBy={isOrderBy} // By default: 'ASC'
                    date={selectedDate}
                    setAllowNavigation={setAllowNavigation}
                    isDataInputVisible={showInput}
                    setIsSubmitMonthOverlayVisible={
                      setIsSubmitMonthOverlayVisible
                    }
                    itemToHighlight={targetToHighlight}
                    isTarget // Use this instead of editing in this file in order to prevent doing weird things passing dates
                  />

                  {/* Client and coach comments and images */}
                  {month &&
                    month.isSubmitted &&
                    (!!month.clientComment ||
                      !!month.coachComment ||
                      (!!month.images && month.images.length > 0)) && (
                      <div className="month-submitted-content-container">
                        {!!month.clientComment && (
                          <div className="messageSentContainer">
                            <MessageBubble
                              mine
                              text={month.clientComment}
                              subjectiveWellBeingScale={
                                month.subjectiveWellBeingScale !== undefined
                                  ? month.subjectiveWellBeingScale
                                  : null
                              }
                            />
                          </div>
                        )}
                        {!!month.coachComment && (
                          <div className="messageReceivedContainer">
                            <MessageBubble
                              text={month.coachComment}
                              isConversation
                            />
                          </div>
                        )}
                        {!!month.images && month.images.length > 0 && (
                          <div className="images-preview-container">
                            {month.images.map((image, index) => (
                              <div key={index} className="img-wrap">
                                <img
                                  src={image}
                                  className="image-preview"
                                  onClick={() => {
                                    setShowImageFullScreenModal(true);
                                    setImageForFullScreenModal(image);
                                  }}
                                  height="100"
                                  width="100"
                                  alt="day img preview"
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}

                  {!showInput &&
                    targets &&
                    targets.length &&
                    month &&
                    !month.isSubmitted > 0 && (
                      <div
                        style={{
                          marginLeft: 35,
                          marginTop: 10,
                          paddingBottom: 25,
                        }}
                      >
                        <Button
                          variant="text"
                          color="secondary"
                          onClick={addTargetPressed}
                          sx={{
                            height: 30,
                            // width: 150,
                          }}
                        >
                          Add target
                        </Button>
                      </div>
                    )}
                </div>
              ) : (
                <div className="no-targets-container">
                  <div className="no-targets-content">
                    <label
                      className="no-targets-title"
                      style={{ color: theme.palette.text.main }}
                    >
                      Nothing yet
                    </label>
                    <label
                      className="no-targets-text"
                      style={{ color: theme.palette.gray.light }}
                    >
                      {moment(selectedDate).format("YYYYMM") ===
                      moment().format("YYYYMM")
                        ? "Add your first target for the month \n eg " +
                          targetsExamples[randomNumberTargetsExample]
                        : moment(selectedDate).format("YYYYMM") >
                          moment().format("YYYYMM")
                        ? "What do you want to achieve in this month?"
                        : "No targets for the month"}
                    </label>
                  </div>
                </div>
              )}

              {/* Submit Month button and overlay */}
              {/* Show if
                - Selected date is present or past
                AND
                -  Month has targets
                  AND
                    - Month exists and is not submitted
                    OR
                    - Month doesn't exist
                OR
                - Submit day overlay is visible
              */}
              {((moment(selectedDate).startOf("month") <=
                moment().startOf("month") &&
                targets?.length &&
                ((month && !month.isSubmitted) || !month)) ||
                isSubmitMonthOverlayVisible) && (
                <>
                  {!isSubmitMonthOverlayVisible && !showInput && (
                    <Button
                      variant="contained"
                      color={
                        moment() >
                        moment(selectedDate).endOf("month").subtract(6, "days")
                          ? "primary"
                          : "quaternary"
                      }
                      sx={{
                        width: 180,
                        height: 60,
                        position: "absolute",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        bottom: 28,
                        color:
                          moment() >
                          moment(selectedDate)
                            .endOf("month")
                            .subtract(6, "days")
                            ? theme.palette.light.main
                            : theme.palette.gray.dark,
                      }}
                      onClick={() => {
                        setAllowNavigation(false);
                        setIsSubmitMonthOverlayVisible(true);
                      }}
                    >
                      Submit month
                      {isLoadingMonth
                        ? null
                        : moment(selectedDate).format("YMD") ===
                            moment().subtract(1, "months").format("YMD") && (
                            <CustomTooltip text="⏰ 😅" />
                          )}
                    </Button>
                  )}
                  <SubmitMonthOverlay
                    isVisible={isSubmitMonthOverlayVisible}
                    setIsVisible={setIsSubmitMonthOverlayVisible}
                    date={selectedDate}
                    clientComment={(month && month.clientComment) || ""}
                    monthImages={
                      (month &&
                        month.images &&
                        month.images.length &&
                        month.images) ||
                      ""
                    }
                    subjectiveWellBeingScale={
                      (month && month.subjectiveWellBeingScale) || null
                    }
                    targets={targets}
                    customSubmitMessage={user && user.customSubmitMonthMessage}
                    progress={
                      (targets &&
                        targets.filter((target) => target.isDone).length /
                          targets.length) * 100
                    }
                    setAllowNavigation={setAllowNavigation}
                  />
                </>
              )}

              {/* Undo Submit Month */}
              {(month?.isSubmitted && !isSubmitMonthOverlayVisible) ||
              (!month &&
                targets.length &&
                moment(selectedDate).startOf("month") >
                  moment().startOf("month")) ? (
                <Button
                  variant="outlined"
                  color="gray"
                  onClick={() => {
                    setAllowNavigation(false);
                    undoSubmitMonth();
                  }}
                  fullWidth
                  sx={{
                    width: 180,
                    height: 60,
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    bottom: 28,
                    backgroundColor: theme.palette.background.main,
                  }}
                >
                  {isLoadingUndoSubmitMonth ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <>
                      <FaReply /> &nbsp; Undo submit
                    </>
                  )}
                </Button>
              ) : null}
            </div>
          )}

          {!isLoadingTargets && !isLoadingMonth && showInput && (
            <DataInput
              onSubmitHandler={submitNewTarget}
              placeholder={
                !showedTargetExampleAt ||
                moment(showedTargetExampleAt).format("YYYYMMDD") <
                  moment().format("YYYYMMDD")
                  ? "eg " + targetsExamples[randomNumberTargetsExample]
                  : "What's your next target?"
              }
              value={targetName}
              setValue={setTargetName}
              setIsVisible={setShowInput}
              setAllowNavigation={setAllowNavigation}
              handleLastTimeShowedExampleAt={handleLastTimeShowedExampleAt}
              btnAddTitle="Add target"
              autoFocus
            />
          )}
        </div>
      </div>

      <div
        className="float-btns-container"
        style={{
          position: currentDevice === Devices.PHONE ? "fixed" : "absolute",
          bottom: currentDevice === Devices.PHONE ? 50 : 0,
        }}
      >
        {/* Add button */}
        {!isSubmitMonthOverlayVisible &&
          ((month && !month.isSubmitted) ||
            (!month &&
              moment(selectedDate).startOf("month").format() >=
                moment().subtract(1, "months").startOf("month").format())) && (
            <FAB
              position="right"
              icon={<FiPlus size={38} />}
              // title={currentDevice !== Devices.PHONE ? "Create" : ""}
              isVisible={!showInput}
              handleClick={addTargetPressed}
            />
          )}

        {/* Undo delete button */}
        <FAB
          position="left"
          icon={<FaReply size={30} />}
          isVisible={deletedTarget}
          handleClick={restoreDeletedTarget}
        />
      </div>

      <InfoOverlay
        isVisible={isInfoOverlayVisible}
        setIsVisible={setIsInfoOverlayVisible}
        title={en.INFO_TITLE_TARGETS}
        subtitle={en.INFO_TARGETS}
        textOk={"Got it"}
      />

      {/* Calendar Picker Modal */}
      <MonthCalendarPickerOverlay
        isVisible={showMonthCalendar}
        setIsVisible={setShowMonthCalendar}
        selectedDate={selectedDate}
        minDate={moment(
          user?.createdTime &&
            user.createdTime.toDate &&
            user.createdTime.toDate()
        ).startOf("day")}
        onDateChangeHandler={selectDate}
      />

      {/* Modal for fullscreen images */}
      <ImageModal
        isVisible={showImageFullScreenModal}
        setIsVisible={setShowImageFullScreenModal}
        image={imageForFullScreenModal}
      />
    </div>
  );
};

export default React.memo(TargetsScreen);
