import React, { useEffect, useState } from "react";

import { useTheme, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { lastSeenRelease } from "../../redux/actions/local";

import { updateUser } from "../../api/firestore/users";

import { playSound } from "../../api/filesHandler";
import { version } from "../../api/constants";

import new_version_update from "../../assets/sounds/new_version_update.wav";
import logo from "../../assets/img/goalswon_logo_rounded.png";
import "./style.scss";

const ReleasesScreen = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const releases = useSelector((state) => state.release.releases);
  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );

  const [isMandatory, setIsMandatory] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (releases?.length) {
      setIsMandatory(!!releases.find((el) => el.isMandatory));
      setIsUpdated(releases[0]?.version <= version);
    }
  }, [releases]);

  useEffect(() => {
    if (isSoundEffectsEnabled) {
      playSound(new_version_update);
    }
  }, [isSoundEffectsEnabled]);

  const setRelaseAsSeen = () => {
    if (releases?.length) {
      dispatch(lastSeenRelease(releases[0]?.version));
      updateUser({
        lastSeenRelease: releases[0]?.version,
      });
    }
  };

  return (
    <div
      className="releases-container"
      style={{ backgroundColor: theme.palette.background.main }}
    >
      <div className="body">
        <div className="title-container">
          <img src={logo} alt="GoalsWon logo" width={100} />
          <label className="title" style={{ color: theme.palette.text.main }}>
            GoalsWon just got better!
          </label>
        </div>

        <div className="releases">
          {releases?.length &&
            releases.map((release) => (
              <div className="release" key={`release-${release}`}>
                {!!release.version && (
                  <label
                    className="release-version"
                    style={{ color: theme.palette.text.main }}
                  >
                    Version {release.version}
                  </label>
                )}
                {!!release.intro && (
                  <label
                    className="release-intro"
                    style={{ color: theme.palette.text.main }}
                  >
                    {release.intro}
                  </label>
                )}
                <div className="release-notes-container">
                  {!!release.items &&
                    release.items.length &&
                    release.items.map((el) => (
                      <div
                        className="release-note-container"
                        key={`release-${release}-item-${el}`}
                      >
                        <label
                          className="release-note"
                          style={{ color: theme.palette.text.main }}
                        >
                          {"\u2022"} {el}
                        </label>
                      </div>
                    ))}
                </div>
                {!!release.outro && (
                  <label
                    className="release-outro"
                    style={{ color: theme.palette.text.main }}
                  >
                    {release.outro}
                  </label>
                )}
              </div>
            ))}
        </div>

        {(!isMandatory || isUpdated) && (
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              setRelaseAsSeen();
            }}
          >
            <label
              className="buttonText"
              style={{ color: theme.palette.primary.main }}
            >
              {isUpdated
                ? (releases?.length && releases[0]?.ok1BtnLabel) ||
                  "Ok, I guess..."
                : (releases?.length && releases[0]?.skipBtnLabel) || "Skip"}
            </label>
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            setRelaseAsSeen();
            if (!isUpdated) {
              window.location.reload(true); // TODO - Check if works, should hard reload
            }
          }}
          sx={{ marginTop: 2 }}
        >
          <label className="buttonText">
            {isUpdated
              ? (releases?.length && releases[0]?.ok2BtnLabel) ||
                "Great, thanks!"
              : (releases?.length && releases[0]?.updateBtnLabel) || "Update"}
          </label>
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ReleasesScreen);
