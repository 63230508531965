import React from "react";

import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  Divider,
} from "@mui/material";

import "./style.scss";

const DeleteConfirmationOverlay = ({
  isVisible,
  setIsVisible,
  title,
  subtitle,
  handleConfirmation,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      TransitionComponent={Slide}
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">{title}</label>
      </DialogTitle>

      <DialogContent>
        <label className="subtitle">{subtitle}</label>
      </DialogContent>

      <Divider />

      <div className="delete-confirmation-modal-footer">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => setIsVisible(false)}
        >
          Cancel
        </Button>

        <Divider
          className="divider"
          sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
          orientation="vertical"
          flexItem
        />

        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="error"
          onClick={() => {
            handleConfirmation();
            setIsVisible(false);
          }}
        >
          Delete
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(DeleteConfirmationOverlay);
