export const googleSignInWebClientId =
  "776739064516-ln4brmlv3he8rk8t7it4nr7b069sl8o3.apps.googleusercontent.com";
export const firebaseAPIKey = "AIzaSyBaKEVnRKKx_Gt4dYF4HQD3uWAhPv_VXX0";
export const slackKeyOnboarding =
  "T0167G5C876/B04LUMC2L21/owOPbI4d5GE9mlr381MKl7O7";
export const sentryDSN =
  "https://daf5ee0d94a14b499d8895f553f7a62b@o4505165984563200.ingest.sentry.io/4505187991289856";
export const fcmKey =
  "AAAAtNlBisQ:APA91bHtO1XZa5yKf3RUwh18yhytj0_WDowAGSdsouoer7XcTETwUg3aEJlLCmm8W7jOH5WiPSm6mHzF_Lh9wFyDXxbi94cQlw6eZluAMCEb9udYs1ZZHLfLzRBe4K5PVVT9gOy-TkRQ";
export const backendApiKey = "b788ba56-68a7-49e5-96ba-a0248337e1fb";
