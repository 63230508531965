import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { auth } from "../../api/firebase";
import moment from "moment";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { darkMode } from "../../redux/actions/local";
import { setIsMoodButtonsMandatory } from "../../redux/actions/local";

// Components
import { useTheme, Switch } from "@mui/material";
import ScreenHeader from "../../components/ScreenHeader";
import SignOutButton from "../../components/SignOutButton";

// Firestore
import { updateUser } from "../../api/firestore/users";

// Assets
import logo from "../../assets/img/goalswon_logo.svg";

// Own api
import { playSound } from "../../api/filesHandler";
import light_mode from "../../assets/sounds/light_mode.wav";
import dark_mode from "../../assets/sounds/dark_mode.wav";
import {
  Devices,
  SUBSCRIPTION_PLATFORM,
  USER_TYPE,
  version,
} from "../../api/constants";
import "./style.scss";

const SettingsScreen = ({ subscribedAt }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const currentDevice = useSelector((state) => state.local.currentDevice);
  const isDarkModeEnabled = useSelector(
    (state) => state.local.isDarkModeEnabled
  );
  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );
  const user = useSelector((state) => state.user.user);
  const isMoodButtonsMandatory = useSelector(
    (state) => state.local.isMoodButtonsMandatory
  );

  const switchTheme = async () => {
    dispatch(darkMode(!isDarkModeEnabled));
    updateSetting("isDarkModeEnabled", !isDarkModeEnabled);

    if (isSoundEffectsEnabled) {
      if (!isDarkModeEnabled) {
        playSound(dark_mode);
      } else {
        playSound(light_mode);
      }
    }
  };

  const switchMoodButtonsOptionality = async () => {
    dispatch(setIsMoodButtonsMandatory(!isMoodButtonsMandatory));
    updateSetting("isMoodButtonsMandatory", !isMoodButtonsMandatory);
  };

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateSetting = useCallback(
    debounce((property, value) => {
      updateUser({
        [property]: value,
      });
    }, 300),
    []
  );

  return (
    <div
      className="settings-screen-container"
      style={{
        overflowY: currentDevice !== Devices.PHONE && "scroll",
        height: currentDevice !== Devices.PHONE && "100vh",
        width: currentDevice === Devices.DESKTOP && "50%",
        paddingBottom: currentDevice === Devices.PHONE && 70,
        backgroundColor: theme.palette.background.main,
      }}
    >
      <div className="settings-screen-content-container">
        <ScreenHeader title="Settings" />

        <div>
          <ul>
            <Link to={"profile"} style={{ textDecoration: "none" }}>
              <li
                style={{
                  borderColor: theme.palette.gray.contrast,
                  height: 70,
                }}
              >
                <div className="client-img-container">
                  <img
                    src={user?.avatar || logo}
                    className="client-img"
                    alt="profile pic"
                  />
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", flex: 1 }}
                >
                  {user?.name ? (
                    <span
                      style={{
                        cursor: "pointer",
                        color: theme.palette.text.main,
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      {user?.name}
                    </span>
                  ) : null}
                  <span
                    style={{
                      cursor: "pointer",
                      color: theme.palette.text.main,
                      fontSize: 13,
                    }}
                  >
                    View profile
                  </span>
                </div>
              </li>
            </Link>

            <Link to={"sortGoals"} style={{ textDecoration: "none" }}>
              <li style={{ border: "none" }}>
                <span
                  style={{ cursor: "pointer", color: theme.palette.text.main }}
                >
                  Sort goals
                </span>
              </li>
            </Link>
            <Link to={"sortTargets"} style={{ textDecoration: "none" }}>
              <li style={{ border: "none" }}>
                <span
                  style={{ cursor: "pointer", color: theme.palette.text.main }}
                >
                  Sort targets
                </span>
              </li>
            </Link>
            <li style={{ border: "none" }}>
              <span style={{ color: theme.palette.text.main }}>Dark mode</span>
              <Switch checked={isDarkModeEnabled} onChange={switchTheme} />
            </li>
            <li style={{ border: "none" }}>
              <span style={{ color: theme.palette.text.main }}>
                Require mood on submit
              </span>
              <Switch
                checked={isMoodButtonsMandatory}
                onChange={switchMoodButtonsOptionality}
              />
            </li>
            <Link to={"soundsSettings"} style={{ textDecoration: "none" }}>
              <li style={{ border: "none" }}>
                <span
                  style={{ cursor: "pointer", color: theme.palette.text.main }}
                >
                  Sounds
                </span>
              </li>
            </Link>
            <Link to={"nominateSupporter"} style={{ textDecoration: "none" }}>
              <li style={{ border: "none" }}>
                <span
                  style={{ cursor: "pointer", color: theme.palette.text.main }}
                >
                  Nominate a supporter
                </span>
              </li>
            </Link>
            <Link to={"holidayMode"} style={{ textDecoration: "none" }}>
              <li style={{ border: "none" }}>
                <span
                  style={{
                    cursor: "pointer",
                    color: theme.palette.text.main,
                  }}
                >
                  Holiday mode
                </span>
                <div className="new-badge">
                  <span className="new-badge-span">NEW</span>
                </div>
              </li>
            </Link>
            {/* TODO - Uncomment once all moments are set with manual timezone */}
            {/* <Link to={"dateAndTime"} style={{ textDecoration: "none" }}>
              <li style={{ borderColor: theme.palette.gray.contrast }}>
                <span
                  style={{ cursor: "pointer", color: theme.palette.text.main }}
                >
                  Date & time
                </span>
              </li>
            </Link> */}

            {/* <Link to={"exports"} style={{ textDecoration: "none" }}>
              <li style={{ border: "none" }}>
                <span
                  style={{ cursor: "pointer", color: theme.palette.text.main }}
                >
                  Export
                </span>
              </li>
            </Link> */}

            <Link to={"shortcuts"} style={{ textDecoration: "none" }}>
              <li style={{ borderColor: theme.palette.gray.contrast }}>
                <span
                  style={{ cursor: "pointer", color: theme.palette.text.main }}
                >
                  Shortcuts
                </span>
              </li>
            </Link>

            {/* <li style={{ borderColor: theme.palette.gray.contrast }}></li> */}

            <li
              style={{
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.goalswon.accountability.coach.android",
                  "_blank"
                )
              }
            >
              <span
                style={{ cursor: "pointer", color: theme.palette.text.main }}
              >
                Try our Android app
              </span>
            </li>
            <li
              style={{
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                window.open("https://apps.apple.com/app/id1544973743", "_blank")
              }
            >
              <span
                style={{ cursor: "pointer", color: theme.palette.text.main }}
              >
                Try our iOS app
              </span>
            </li>
            <li
              style={{
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                window.open("https://www.goalswon.com/releases", "_blank")
              }
            >
              <span
                style={{ cursor: "pointer", color: theme.palette.text.main }}
              >
                Release notes
              </span>
            </li>
            <li
              style={{
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                window.open("https://www.goalswon.com/roadmap", "_blank")
              }
            >
              <span
                style={{ cursor: "pointer", color: theme.palette.text.main }}
              >
                Roadmap
              </span>
            </li>
            <li
              style={{
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                window.open("https://www.goalswon.com/blog", "_blank")
              }
            >
              <span
                style={{ cursor: "pointer", color: theme.palette.text.main }}
              >
                Blog
              </span>
            </li>
            <li
              style={{
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                window.open(
                  "https://www.goalswon.com/terms-of-service",
                  "_blank"
                )
              }
            >
              <span
                style={{ cursor: "pointer", color: theme.palette.text.main }}
              >
                Terms of use
              </span>
            </li>
            <li
              style={{
                cursor: "pointer",
                borderColor: theme.palette.gray.contrast,
              }}
              onClick={() =>
                window.open("https://www.goalswon.com/privacy", "_blank")
              }
            >
              <span
                style={{ cursor: "pointer", color: theme.palette.text.main }}
              >
                Privacy policy
              </span>
            </li>

            {/* <li style={{ borderColor:  theme.palette.gray.contrast }}></li> */}

            <li
              style={{
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                window.open(
                  "https://unvdjyc4orc.typeform.com/to/kJ7d9JuM",
                  "_blank"
                )
              }
            >
              <span
                style={{ cursor: "pointer", color: theme.palette.text.main }}
              >
                Report a bug
              </span>
            </li>

            <li
              style={{
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                window.open(
                  "https://unvdjyc4orc.typeform.com/to/rB5T4mbw",
                  "_blank"
                )
              }
            >
              <span
                style={{
                  cursor: "pointer",
                  color: theme.palette.text.main,
                }}
              >
                Request a feature
              </span>
            </li>

            {/* Show only for clients that:
            - Have a type (premium, EA, free, inactive, etc)
            */}
            {user?.type ? (
              <>
                <li
                  style={{
                    cursor: "pointer",
                    border: "none",
                  }}
                  onClick={() =>
                    window.open(
                      "https://unvdjyc4orc.typeform.com/to/Ypoxt3l8",
                      "_blank"
                    )
                  }
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: theme.palette.text.main,
                    }}
                  >
                    Request change of coach
                  </span>
                </li>
              </>
            ) : null}

            {user?.type === USER_TYPE.PREMIUM ||
            user?.type === USER_TYPE.INACTIVE ? (
              <li
                style={{
                  cursor: "pointer",
                  border: "none",
                }}
                onClick={() =>
                  user?.subscriptionPlatform === SUBSCRIPTION_PLATFORM.APPLE
                    ? window.open(
                        "https://support.apple.com/en-us/HT202039",
                        "_blank"
                      )
                    : user?.subscriptionPlatform ===
                      SUBSCRIPTION_PLATFORM.PAYPAL
                    ? window.open(
                        "mailto:hello@goalswon.com?subject=Request%20a%20change%20on%20my%20Paypal%20subscription&body=Please%20let%20us%20know%20how%20we%20can%20help%20you...",
                        "_blank"
                      )
                    : window.open(
                        "https://billing.stripe.com/p/login/28o3cVgVy9YB2D63cc",
                        "_blank"
                      )
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    color: theme.palette.text.main,
                  }}
                >
                  Manage subscription
                </span>
              </li>
            ) : null}

            {user?.type && user?.type !== USER_TYPE.INACTIVE ? (
              <li
                style={{
                  cursor: "pointer",
                  border: "none",
                }}
                onClick={() => {
                  auth.currentUser.email && auth.currentUser.uid
                    ? user?.type === USER_TYPE.PREMIUM
                      ? user?.subscriptionPlatform ===
                          SUBSCRIPTION_PLATFORM.APPLE ||
                        user?.subscriptionPlatform ===
                          SUBSCRIPTION_PLATFORM.PAYPAL
                        ? window.open(
                            `https://unvdjyc4orc.typeform.com/to/gLtvHwpb#email=${
                              auth.currentUser.email
                            }&gwid=${auth.currentUser.uid}&days=${
                              subscribedAt
                                ? moment().diff(moment(subscribedAt), "days")
                                : 0
                            }`,
                            "_blank"
                          )
                        : window.open(
                            `https://unvdjyc4orc.typeform.com/to/x6xz3OC9#email=${
                              auth.currentUser.email
                            }&gwid=${auth.currentUser.uid}&days=${
                              subscribedAt
                                ? moment().diff(moment(subscribedAt), "days")
                                : 0
                            }`,
                            "_blank"
                          )
                      : window.open(
                          `https://unvdjyc4orc.typeform.com/to/gLtvHwpb#email=${auth.currentUser.email}`,
                          "_blank"
                        )
                    : alert("Error. Please contact hello@goalswon.com");
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    color: theme.palette.text.main,
                  }}
                >
                  Cancel subscription
                </span>
              </li>
            ) : null}

            <SignOutButton />
          </ul>
        </div>

        <div className="bottom-section">
          <div className="version">
            <span style={{ color: theme.palette.text.main }}>
              Version {version}
            </span>
          </div>
          {/* <Button variant="contained" color="error" sx={{ borderRadius: 0 }}>
          Delete account
        </Button> */}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SettingsScreen);
