import { firestore, auth } from "../../api/firebase";

import { captureException } from "@sentry/react";

export const unpauseAllGoalsAndAddSkippedDates = async (
  goalsToUnpause,
  daysToSkip,
  holidayMode
) => {
  try {
    const userUid = auth.currentUser.uid;

    // Start transaction
    const batch = firestore.batch();

    if (goalsToUnpause?.length) {
      const unpauseGoals = async () => {
        await Promise.all(
          // Loop through the recurrent goals that are paused
          goalsToUnpause.map(async (recurrentGoalToUnpause) => {
            // To prevent adding duplicated skippedDates to the recurrent goal
            const recurrentGoalUpdatedSkippedDates = new Set([
              ...(recurrentGoalToUnpause.skippedDates || []),
              ...daysToSkip,
            ]);

            // Get ref of paused recurrent goal
            const recurrentGoalRef = firestore
              .collection("recurrentGoals")
              .doc(userUid)
              .collection("recurrentGoals")
              .doc(recurrentGoalToUnpause.id);

            // Set recurrent goal isPaused to false and update the skippedDates
            batch.update(recurrentGoalRef, {
              skippedDates: [...recurrentGoalUpdatedSkippedDates],
              isPaused: false,
            });
          })
        );
      };

      await unpauseGoals();
    }

    // Get ref of user
    const userRef = firestore.collection("users").doc(userUid);

    // Update the holidayMode for the user
    batch.update(userRef, { holidayMode });

    // Finish transaction
    return batch.commit();
  } catch (error) {
    captureException("Failed while disabling holiday mode", error);
    console.error("Failed while disabling holiday mode: " + error);
  }
};
