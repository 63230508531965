import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  Checkbox,
  Divider,
} from "@mui/material";

import { deleteGoal } from "../../api/firestore/goals";
import {
  deleteRecurrentGoal,
  updateRecurrentGoal,
} from "../../api/firestore/recurrentGoals";

import "./style.scss";

const DeleteRecurringGoalOverlay = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [thisGoal, setThisGoal] = useState(true);
  const [thisAndFollowing, setThisAndFollowing] = useState(false);

  const onDeleteChange = (selected) => {
    setThisGoal(false);
    setThisAndFollowing(false);

    switch (selected) {
      case 1:
        setThisGoal(true);
        break;
      case 2:
        setThisAndFollowing(true);
        break;
      default:
        break;
    }
  };

  const saveChanges = () => {
    if (props.goal.date) {
      // If it is an instantiated recurrent goal being deleted, delete goal
      deleteGoal(props.goal);
    }

    if (thisAndFollowing) {
      if (moment(props.goal.startDate).isBefore(moment(props.date), "day")) {
        // If goal being deleted starts before date selected, just update its endDate to keep previous goals
        updateRecurrentGoal({
          ...props.goal,
          endDate: moment(props.date).subtract(1, "days"),
        });
      } else {
        // If goal being deleted has same startDate as date selected, it means that it doesn't have past goals, so just delete it
        deleteRecurrentGoal(props.goal.id);
      }
    }

    if (thisGoal) {
      updateRecurrentGoal({
        ...props.goal,
        skippedDates: [
          ...(props.goal?.skippedDates || []),
          moment(props.date).format("YYYYMMDD"),
        ],
      });
      // TODO - Add: when should I deleteRecurrentGoal if I'm setting a skippedDate and shouldn't appear in any day anymore? Eg: skippedDate is same startDate day and also ends today or in a couple of days and those days are skipped too
      // This could happen: {startDate: April 17, 2024 at 9:00:00 PM UTC-3, endDate: April 18, 2024 at 8:59:59 PM UTC-3, skippedDates: ["20240418]}
    }

    navigate("/", {
      state: {
        date: props.date.toDate(),
        goal: {
          ...props.goal,
          ...(props.goal.date && {
            date:
              props.goal.date &&
              props.goal.date.toDate &&
              props.goal.date.toDate(),
          }),
          startDate:
            props.goal.startDate &&
            props.goal.startDate.toDate &&
            props.goal.startDate.toDate(),
          ...(props.goal.endDate && {
            endDate:
              props.goal.endDate &&
              props.goal.endDate.toDate &&
              props.goal.endDate.toDate(),
          }),
          ...(thisGoal && {
            skippedDates: [
              ...(props.goal?.skippedDates || []),
              moment(props.date).format("YYYYMMDD"),
            ],
          }),
        },
      },
    });

    props.setIsVisible(false);
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => props.setIsVisible(false)}
      TransitionComponent={Slide}
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">Delete recurring goal</label>
      </DialogTitle>
      <DialogContent>
        <div className="options-container">
          <div className="option-container">
            <Checkbox
              color="primary"
              icon={<FaRegCircle size={22} />}
              checkedIcon={<FaCheckCircle size={22} />}
              checked={thisGoal}
              onChange={() => {
                onDeleteChange(1);
              }}
            />
            <label
              className="ends-option-text"
              onClick={() => onDeleteChange(1)}
            >
              Only for this day
            </label>
          </div>
          <div className="option-container">
            <Checkbox
              color="primary"
              icon={<FaRegCircle size={22} />}
              checkedIcon={<FaCheckCircle size={22} />}
              checked={thisAndFollowing}
              onChange={() => {
                onDeleteChange(2);
              }}
            />
            <label
              className="ends-option-text"
              onClick={() => onDeleteChange(2)}
            >
              This and following days
            </label>
          </div>
        </div>
      </DialogContent>

      <Divider />

      <div className="delete-recurring-goal-footer">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => props.setIsVisible(false)}
        >
          Cancel
        </Button>

        <Divider
          className="divider"
          sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
          orientation="vertical"
          flexItem
        />

        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="error"
          onClick={() => saveChanges()}
        >
          Delete
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(DeleteRecurringGoalOverlay);
