import { firestore, auth } from "../../api/firebase";

import { FETCH_MILESTONES, SET_LOADING_MILESTONES } from "../types";

import { captureException } from "@sentry/react";

var unsubscribers = [];
var fetchedMilestones = [];
var start = null;

const fetchMilestonesSuccess = (milestones) => ({
  type: FETCH_MILESTONES,
  payload: milestones,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_MILESTONES,
  payload: isLoading,
});

export const fetchMilestones = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    dispatch(setLoading(true));

    const milestonesSubscriber = firestore
      .collection("milestones")
      .doc(userUid)
      .collection("milestones")
      .orderBy("date", "desc")
      .limit(20)
      .onSnapshot(
        (snapshot) => {
          // save startAt snapshot
          if (snapshot.docs.length > 0) {
            start = snapshot.docs[snapshot.docs.length - 1];
          }

          fetchedMilestones = []; // Reset fetched milestones

          // append new milestones to array
          snapshot.docs.forEach((milestone) => {
            // filter out any duplicates (from modify/delete events)
            fetchedMilestones = fetchedMilestones.filter(
              (x) => x.id !== milestone.id
            );
            fetchedMilestones.push(milestone.data());
          });

          // Dispatch milestones to redux
          dispatch(fetchMilestonesSuccess(fetchedMilestones));
          dispatch(setLoading(false));
        },
        (error) => {
          dispatch(setLoading(false));
          captureException("Error fetching milestones onSnapshot", error);
          console.log("Error fetching milestones onSnapshot: " + error);
        }
      );
    unsubscribers.push(milestonesSubscriber);
  } catch (error) {
    dispatch(setLoading(false));
    captureException("Error fetching milestones", error);
    console.log("Error fetching milestones: " + error);
  }
};

export const fetchMoreMilestones = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    const milestonesSubscriber = firestore
      .collection("milestones")
      .doc(userUid)
      .collection("milestones")
      .orderBy("date", "desc")
      .startAfter(start)
      .limit(20)
      .get()
      .then((res) => {
        // previous starting boundary becomes new ending boundary
        if (res.docs.length > 0) {
          start = res.docs[res.docs.length - 1];
        }

        // append new milestones to array
        res.docs.forEach((milestone) => {
          // filter out any duplicates (from modify/delete events)
          fetchedMilestones = fetchedMilestones.filter(
            (x) => x.id !== milestone.id
          );
          fetchedMilestones.push(milestone.data());
        });

        // Displatch milestones to redux
        dispatch(fetchMilestonesSuccess(fetchedMilestones));
      })
      .catch((error) => {
        captureException("Error fetching more milestones", error);
        console.log("Error fetching more milestones: " + error);
      });
    unsubscribers.push(milestonesSubscriber);
  } catch (error) {
    captureException("Error fetching milestones", error);
    console.log("Error fetching milestones: " + error);
  }
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeMilestoneActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
