import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Dialog, DialogTitle, DialogContent, Slide } from "@mui/material";

import "./style.scss";

const MonthCalendarPickerOverlay = (props) => {
  return (
    <Dialog
      open={props.isVisible}
      onClose={() => props.setIsVisible(false)}
      TransitionComponent={Slide}
      className="month-calendar-picker-container"
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">Month</label>
      </DialogTitle>
      <DialogContent>
        <div className="monthCalendarPicker-container">
          {/* ATTENTION!!! - DatePicker Component does not use momentjs, but date-fns */}
          <DatePicker
            style={{ borderColor: "transparent" }}
            selected={moment(props.selectedDate).toDate()} // selectedDate already is a moment obj
            onChange={(date) => props.onDateChangeHandler(moment(date))}
            minDate={moment(props.minDate).toDate()}
            inline
            fixedHeight
            peekNextMonth
            showMonthYearPicker
            dropdownMode="select"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(MonthCalendarPickerOverlay);
