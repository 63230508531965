import React, { useEffect, useState, useRef, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import moment from "moment";
import { captureException } from "@sentry/react";

// Icons
import { FaAngleDown, FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { IoChatbubble } from "react-icons/io5";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMessages,
  fetchMoreMessages,
  unsubscribeChatActions,
} from "../../redux/actions/chat";
import {
  addMessage,
  updateMessage,
  markMessagesAsRead,
  deleteMessage,
} from "../../api/firestore/chats";
import { updateUser } from "../../api/firestore/users";

// Libs
import { useTheme } from "@mui/material";

// Components
import { CircularProgress, Button, Badge } from "@mui/material";
import ChatHeader from "../../components/ChatHeader";
import ChatInput from "../../components/ChatInput";
import ChatMessageBubble from "../../components/ChatMessageBubble";
import EditChatMessageOverlay from "../../components/EditChatMessageOverlay";
import DeleteConfirmationOverlay from "../../components/DeleteConfirmationOverlay";
import ImageModal from "../../components/ImageModal";
import VideoModal from "../../components/VideoModal";
import InfoOverlay from "../../components/InfoOverlay";
import StatusOverlay from "../../components/StatusOverlay";
import ReactChatMessageOverlay from "../../components/ReactChatMessageOverlay";

// Analytics
import { analytics } from "../../api/firebase";

// Own api
import {
  useWindowSize,
  sendOnboardingSlackNotification,
} from "../../api/helper";
import { getImagePath, uploadImage } from "../../api/filesHandler";
import { Devices, ScreenNames, USER_TYPE } from "../../api/constants";
import { en } from "../../api/languages/en";
// import { removeAllDeliveredNotifications } from "../../api/notifications/handler";
import "./style.scss";

const Chat = (props) => {
  const theme = useTheme();
  const scrollViewRef = useRef();
  const effectRan = useRef(false);
  const dispatch = useDispatch();

  const [, windowHeight] = useWindowSize();

  const currentScreen = useSelector((state) => state.local.currentScreen);
  const currentDevice = useSelector((state) => state.local.currentDevice);
  const messages = useSelector((state) => state.chat.messages);
  const isLoadingMessages = useSelector(
    (state) => state.chat.isLoadingMessages
  );

  const [hasMore, setHasMore] = useState(true);
  const [lastMessage, setLastMessage] = useState(messages[0]);
  const [showNewMessageBadge, setShowNewMessageBadge] = useState(false);
  const [elementIdToScrollTo, setElementIdToScrollTo] = useState(null);
  const [showStarredMessages, setShowStarredMessages] = useState("");

  const [messageText, setMessageText] = useState("");
  const [messageToHandle, setMessageToHandle] = useState(null);

  const [showReplyMessage, setShowReplyMessage] = useState(false);
  const [showEditMessageOverlay, setShowEditMessageOverlay] = useState(false);
  const [showReactMessageOverlay, setShowReactMessageOverlay] = useState(false);
  const [showDeleteMessageOverlay, setShowDeleteMessageOverlay] =
    useState(false);
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [isBubbleOptionsOpen, setIsBubbleOptionsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [imagesToSend, setImagesToSend] = useState([]);
  const [imagesToPreview, setImagesToPreview] = useState([]);

  const [showImageFullScreenModal, setShowImageFullScreenModal] =
    useState(false);
  const [imageForFullScreenModal, setImageForFullScreenModal] = useState([{}]);

  const [showVideoFullScreenModal, setShowVideoFullScreenModal] =
    useState(false);
  const [VideoForFullScreenModal, setVideoForFullScreenModal] = useState(null);

  const [showScrollToBottomButton, setShowScrollToBottomButton] =
    useState(false);

  const [isInactiveInfoOverlayVisible, setIsInactiveInfoOverlayVisible] =
    useState(false);
  const [isCoachStatusOverlayVisible, setIsCoachStatusOverlayVisible] =
    useState(false);

  useEffect(() => {
    const filterProperty = showStarredMessages ? "clientStarred" : null;

    dispatch(fetchMessages(filterProperty));

    if (!effectRan.current) {
      if (
        currentDevice === Devices.DESKTOP ||
        currentScreen === ScreenNames.CHAT_SCREEN
      ) {
        markMessagesAsRead(props.user?.tokens);
      }
    }

    return () => {
      effectRan.current = true;
    };
  }, [showStarredMessages]);

  const toggleMessagesFilter = (property) => {
    unsubscribeChatActions();
    setHasMore(true); // Reset, cause maybe you reached top of messages during a filter and after toggling no new messages would be retrieved
    switch (property) {
      case "starred":
        setShowStarredMessages(!showStarredMessages);
        break;
      default:
        break;
    }
  };

  const timeToSendFirstClientMessage = () => {
    var startDate = moment(
      props.user &&
        props.user.firstCoachMessage &&
        props.user.firstCoachMessage.toDate &&
        props.user.firstCoachMessage.toDate()
    );
    var endDate = moment();
    var diffTime = moment(endDate).diff(startDate);

    var duration = moment.duration(diffTime);
    var days = duration.days(),
      hrs = duration.hours(),
      mins = duration.minutes(),
      secs = duration.seconds();

    return (
      (days ? days + " days " : "") +
      hrs +
      " hrs " +
      mins +
      " mins " +
      secs +
      " secs"
    );
  };

  const sendNewMessage = async (text) => {
    setShowStarredMessages(false); // Added to prevent staying on starred messages screen after adding a message when there, otherwise you'll see nothing happens when you add a message

    if (messageToHandle && showReplyMessage) {
      if (!!text) {
        await addMessage({
          text,
          repliedMessage: {
            id: messageToHandle.id,
            sentBy: messageToHandle.sentBy,
            ...(!!messageToHandle.text && { text: messageToHandle.text }),
            ...(!!messageToHandle.image && { image: messageToHandle.image }),
            ...(!!messageToHandle.video && {
              video: {
                url: messageToHandle.video.url,
                thumbnail: messageToHandle.video?.thumbnail || "",
              },
            }),
          },
          isSystemMessage: false,
        });
      }
      if (imagesToSend.length) {
        setIsLoading(true);
        try {
          const uploadedImages = [];
          for (let image of imagesToSend) {
            const uploadedImage = await uploadImage(image);
            uploadedImages.push(uploadedImage);
          }
          const imagesPath = [];
          for (let image of uploadedImages) {
            const path = await getImagePath(image);
            imagesPath.push(path);
          }
          for (let image of imagesPath) {
            await addMessage({
              image,
              isImageMessage: true,
              repliedMessage: {
                id: messageToHandle.id,
                sentBy: messageToHandle.sentBy,
                ...(!!messageToHandle.text && { text: messageToHandle.text }),
                ...(!!messageToHandle.image && {
                  image: messageToHandle.image,
                }),
                ...(!!messageToHandle.video && {
                  video: {
                    url: messageToHandle.video.url,
                    thumbnail: messageToHandle.video?.thumbnail || "",
                  },
                }),
              },
              isSystemMessage: false,
            });
          }
          setImagesToSend([]);
        } catch (error) {
          captureException(
            `Error while uploading images for chat message: ${error}`
          );
          alert(`Error while uploading images: ${error}`);
        }
        setIsLoading(false);
      }
      setShowReplyMessage(false);
    } else {
      if (!!text) {
        await addMessage({ text, isSystemMessage: false });
      }
      if (imagesToSend.length) {
        setIsLoading(true);
        try {
          const uploadedImages = [];
          for (let image of imagesToSend) {
            const uploadedImage = await uploadImage(image);
            uploadedImages.push(uploadedImage);
          }
          const imagesPath = [];
          for (let image of uploadedImages) {
            const path = await getImagePath(image);
            imagesPath.push(path);
          }
          for (let image of imagesPath) {
            await addMessage({
              image,
              isImageMessage: true,
              isSystemMessage: false,
            });
          }
          setImagesToSend([]);
        } catch (error) {
          captureException(
            `Error while uploading images for chat message: ${error}`
          );
          alert(`Error while uploading images: ${error}`);
        }
        setIsLoading(false);
      }
    }

    scrollToBottomAnimated();

    // First client message
    if (
      (!props.user?.firstClientMessage ||
        props.user?.firstClientMessage === undefined) &&
      props.user?.firstCoachMessage
    ) {
      // Only if client hasn't replied first coach message yet and coach has already sent first message
      if (
        props.user?.coach &&
        props.user?.type &&
        props.user?.type !== USER_TYPE.INACTIVE
      ) {
        // Only send notification if client:
        // has coach
        // has type and it's different from "inactive"

        // Send to general channel (#onboarding)
        sendOnboardingSlackNotification(
          `📩 TIME TO FIRST REPLY - ${timeToSendFirstClientMessage()}
          
Client: ${props.user?.name}
Coach: ${props.coach?.name}

<https://goalswon-coaching-app.web.app/client/${
            props.user?.id
          } | Reply to this message>`
        );

        // Send to coach channel (#coach-X-new-client)
        if (props.coach?.slackNewClients) {
          fetch(props.coach.slackNewClients, {
            method: "POST",
            body: JSON.stringify({
              text: `📩 TIME TO FIRST REPLY - ${timeToSendFirstClientMessage()}
          
Client: ${props.user?.name}
            
<https://goalswon-coaching-app.web.app/client/${
                props.user?.id
              } | Reply to this message>`,
            }),
          })
            .then((response) => response.json())
            .then((result) => {})
            .catch((error) => {
              captureException(
                "Error while sending time to first reply slack notification",
                error
              );
              console.log(
                "Error while sending time to first reply slack notification: " +
                  error
              );
            });
        }
      }

      updateUser({ firstClientMessage: moment() }); // WARNING - Change if tutorial changes
    }

    analytics.logEvent("message_sent", {
      sentBy: props.user?.id,
      sentTo: props.user?.coach,
    });
  };

  // ==================================== MESSAGE HANDLING ====================================
  const handleSelectedMessage = (message, action) => {
    switch (action) {
      case "react":
        setMessageToHandle(message);
        setShowReplyMessage(false);
        setShowReactMessageOverlay(true);
        break;
      case "copy":
        navigator.clipboard.writeText(message.text).then(() => {
          toast.success("Copied to clipboard!");
        });
        break;
      case "reply":
        setMessageToHandle(message);
        setShowReplyMessage(true);
        document.getElementById("messageInput").focus();
        break;
      case "star":
        updateMessage(message?.id, {
          clientStarred:
            message?.clientStarred !== undefined
              ? !message?.clientStarred
              : true,
        });
        analytics.logEvent("used_star_message");
        toast.success(
          message?.clientStarred !== undefined
            ? message?.clientStarred
              ? "Message unstarred!"
              : "Message starred!"
            : "Message starred!",
          { autoClose: 600 }
        );
        break;
      case "edit":
        setMessageToHandle(message);
        setShowReplyMessage(false);
        setShowEditMessageOverlay(true);
        break;
      case "delete":
        setMessageToHandle(message);
        setShowReplyMessage(false);
        setShowDeleteMessageOverlay(true);
        break;
      default:
        break;
    }
  };

  // ==================================== IMAGE HANDLING ====================================
  const handleImagesInputChange = (e) => {
    var imageArray = e.target.files;
    let temp = [...imagesToSend, ...imageArray]; // Add new whole image file to array of images (commentPhotos)
    if (temp.length > 8) {
      alert("Up to 8 photos are allowed");
    } else {
      setImagesToSend(temp); // Set array of images
    }
    handleImagesToPreview(temp);
  };

  const handlePastedInChat = (e) => {
    // Get the data of clipboard
    const clipboardItems = e.clipboardData.items;

    // Filter data from clipboard keeping only images (if there's any)
    const clipboardImages = [].slice
      .call(clipboardItems)
      .filter(function (item) {
        // Filter the image items only
        return item.type.indexOf("image") !== -1;
      });

    // If there are images in the clipboard run following code to prevent pasting image along with its title
    if (clipboardImages.length !== 0) {
      e.preventDefault();
    } else {
      return;
    }

    // Get the blobs of images
    const blobs = [];
    clipboardImages.forEach((clipboardImage) => {
      blobs.push(clipboardImage.getAsFile());
    });
    let temp = [...imagesToSend, ...blobs]; // Add image files to array of images
    if (temp.length > 8) {
      alert("Up to 8 photos are allowed");
    } else {
      setImagesToSend(temp); // Set array of images
    }
    handleImagesToPreview(temp);
  };

  const handleDroppedInChat = (e) => {
    // Get the files from the drop event
    const droppedFiles = e.dataTransfer.files;

    // Filter dropped files keeping only images (if there's any)
    const droppedImages = [].slice.call(droppedFiles).filter(function (file) {
      // Filter the image files only
      return file.type.indexOf("image") !== -1;
    });

    // If there were no images dropped, exit function
    if (droppedImages.length === 0) {
      return;
    }

    let temp = [...imagesToSend, ...droppedImages]; // Add image files to array of images
    if (temp.length > 8) {
      alert("Up to 8 photos are allowed");
    } else {
      setImagesToSend(temp); // Set array of images
    }
    handleImagesToPreview(temp);
  };

  const handleImagesToPreview = (imagesArray) => {
    let newImagesToPreview = [];
    imagesArray.map((img) => {
      newImagesToPreview.push(URL.createObjectURL(img));
    });
    setImagesToPreview(newImagesToPreview);
  };

  const handleRemoveImage = (index) => {
    let preview = [...imagesToPreview];
    let images = [...imagesToSend];
    preview.splice(index, 1);
    images.splice(index, 1);
    setImagesToPreview(preview);
    setImagesToSend(images);
  };

  // ==================================== SCROLL TO BOTTOM ====================================
  useEffect(() => {
    if (props.isShow) {
      const positionY = scrollViewRef.current.scrollTop;

      // Scroll down if received new message and user is positioned 499 over bottom of chat,
      // otherwise, show badge to prevent bothering user
      if (messages.length === 1) {
        scrollToTopAnimated();
      } else if (messages && lastMessage) {
        if (
          positionY > -499 &&
          positionY < 0 &&
          messages[0].sentBy !== props.user?.id &&
          messages[0].text !== lastMessage.text
        ) {
          scrollToBottomAnimated();
        } else if (
          positionY < -499 &&
          messages[0].sentBy !== props.user?.id &&
          messages[0].text !== lastMessage.text
        ) {
          setShowNewMessageBadge(true);
        }
      }
    }
    setLastMessage(messages[0]);
  }, [messages]);

  const handleScroll = (event) => {
    if (props.isShow) {
      const positionY = scrollViewRef.current.scrollTop;
      if (positionY < -500) {
        setShowScrollToBottomButton(true);
      } else {
        setShowScrollToBottomButton(false);
        setShowNewMessageBadge(false);
      }
    }
  };

  const scrollToBottomAnimated = useCallback(() => {
    if (props.isShow) {
      scrollViewRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
    setShowNewMessageBadge(false);
  }, [props.isShow]);

  const scrollToTopAnimated = useCallback(() => {
    if (props.isShow) {
      scrollViewRef.current.scrollTo({
        top: -scrollViewRef.current.clientHeight,
        behavior: "smooth",
      });
    }
    setShowNewMessageBadge(false);
  }, [props.isShow, messages]);

  // ==================================== SCROLL TO REPLIED MESSAGE ====================================
  const scrollToRepliedMessage = useCallback(() => {
    const element = document.getElementById(elementIdToScrollTo);
    if (element) {
      document.getElementById(elementIdToScrollTo).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      highlightMessage(elementIdToScrollTo);
      setElementIdToScrollTo(null);
    } else {
      const filterProperty = showStarredMessages ? "clientStarred" : null;

      dispatch(fetchMoreMessages(filterProperty));
    }
  }, [elementIdToScrollTo]);

  useEffect(() => {
    if (elementIdToScrollTo) {
      scrollToRepliedMessage();
    }
  }, [elementIdToScrollTo, messages.length, scrollToRepliedMessage]);

  const highlightMessage = (elementId) => {
    document.getElementById(elementId).style.opacity = 0.7;
    setTimeout(() => {
      if (document.getElementById(elementId)) {
        document.getElementById(elementId).style.opacity = 1;
      }
    }, 2000);
  };

  // ==================================== Reaction ====================================
  const handleAddEditReaction = (messageId, emoji) => {
    updateMessage(messageId, { clientReaction: emoji });
    setShowReactMessageOverlay(false);
    analytics.logEvent("used_chat_message_reaction");
  };

  const handleDeleteReaction = (messageId) => {
    updateMessage(messageId, { clientReaction: "" });
  };

  // ==================================== Delete ====================================
  const handleDeleteMessage = () => {
    deleteMessage(messageToHandle);
  };

  // ================================== Render ITEM ==================================
  const renderChatMessage = useCallback(
    (item, index) => {
      const sentAt = moment(item.sentAt?.toDate && item.sentAt.toDate());
      const userBecameInactive = props.user?.becameInactive
        ? moment(
            props.user.becameInactive.toDate &&
              props.user.becameInactive.toDate()
          )
        : false;

      let previousMessageSentAt = null;
      if (index > 0) {
        const prevItem = messages[index - 1];
        previousMessageSentAt = moment(
          prevItem.sentAt?.toDate && prevItem.sentAt.toDate()
        );
      }

      return (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          key={"message-container-" + item.id}
        >
          {/* //////////////////// Date of day //////////////////// */}
          {/* (only for first message)*/}
          {index + 1 === messages?.length && (
            <span
              className="dateOfDay"
              style={{ color: theme.palette.text.main }}
            >
              {moment(sentAt).isSame(moment(), "year")
                ? moment(sentAt).format("ddd, D MMM")
                : moment(sentAt).format("ddd, D MMM YYYY")}
            </span>
          )}
          <ChatMessageBubble
            key={item.id}
            index={index}
            item={{ ...item, sentAt }}
            previousMessageSentAt={previousMessageSentAt}
            setShowImageFullScreenModal={setShowImageFullScreenModal}
            setImageForFullScreenModal={setImageForFullScreenModal}
            setShowVideoFullScreenModal={setShowVideoFullScreenModal}
            setVideoForFullScreenModal={setVideoForFullScreenModal}
            handleSelectedMessage={handleSelectedMessage}
            elementIdToScrollTo={elementIdToScrollTo}
            setElementIdToScrollTo={setElementIdToScrollTo}
            setIsInactiveInfoOverlayVisible={setIsInactiveInfoOverlayVisible}
            userId={props.user?.id}
            coachName={props.coach?.name}
            userBecameInactive={userBecameInactive}
            userType={props.user?.type}
            handleDeleteReaction={handleDeleteReaction}
            handleAddEditReaction={handleAddEditReaction}
            clientStarred={item?.clientStarred}
            setIsBubbleOptionsOpen={setIsBubbleOptionsOpen}
          />
          {/* //////////////////// Date of day //////////////////// */}
          {index > 0 &&
            !moment(previousMessageSentAt).isSame(
              moment(sentAt).format("YYYYMMDD"),
              "day"
            ) && (
              <span
                className="dateOfDay"
                style={{ color: theme.palette.text.main }}
              >
                {moment(previousMessageSentAt).isSame(moment(), "year")
                  ? moment(previousMessageSentAt).format("ddd, D MMM")
                  : moment(previousMessageSentAt).format("ddd, D MMM YYYY")}
              </span>
            )}
        </span>
      );
    },
    [
      messages,
      elementIdToScrollTo,
      props.user?.id,
      props.coach?.name,
      props.user?.becameInactive,
      props.user?.coach,
      props.user?.type,
      setShowImageFullScreenModal,
      setImageForFullScreenModal,
      handleSelectedMessage,
      setElementIdToScrollTo,
      setIsInactiveInfoOverlayVisible,
    ]
  );

  // ==================================== RENDER ====================================
  if (props.isShow) {
    return (
      <>
        <div
          className="chat-section"
          style={{
            height: currentDevice === "PHONE" ? windowHeight - 69 : "100vh",
            backgroundColor: theme.palette.background.main,
          }}
        >
          <ChatHeader
            userId={props.user?.id}
            userGuide={props.user?.guide}
            userType={props.user?.type}
            userCreatedTime={props.user?.createdTime}
            tour={props.tour}
            coachId={props.coach?.id}
            coachName={props.coach?.name}
            coachAvatar={props.coach?.avatar}
            coachAvatarWeekend={props.coach?.avatarWeekend}
            coachStatus={props.coach?.status}
            toggleMessagesFilter={toggleMessagesFilter}
            showStarredMessages={showStarredMessages}
            setIsCoachInfoOverlayVisible={props.setIsCoachInfoOverlayVisible}
            setIsCoachStatusOverlayVisible={setIsCoachStatusOverlayVisible}
          />

          <div
            ref={scrollViewRef}
            className="chat-content"
            id="reverse-chat"
            style={{
              backgroundColor: theme.palette.chatBackground.main,
              paddingBottom: paddingBottom,
              overflowY: !isBubbleOptionsOpen ? "scroll" : "hidden", // Good idea, like WhatsApp Web but if view is too small you might need to scroll to see all the options
            }}
          >
            {isLoading || isLoadingMessages ? (
              <div className="sending-message-loader">
                <CircularProgress color="secondary" />
              </div>
            ) : messages?.length ? (
              <InfiniteScroll
                dataLength={messages?.length}
                onScroll={(event) => {
                  handleScroll(event);
                }}
                scrollEventThrottle={16}
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  overflow: "visible", // TODO - Check | Used to fix bubble menu hiden when too few messages and it's cut outside of infinite scroll view
                }} // To put endMessage and loader to the top.
                inverse={true}
                hasMore={hasMore}
                next={() => {
                  const filterProperty = showStarredMessages
                    ? "clientStarred"
                    : null;

                  dispatch(fetchMoreMessages(filterProperty))
                    .then((res) => {
                      setHasMore(res?.hasMore);
                    })
                    .catch((error) => {
                      console.log("Error fetching more messages: ", error);
                    });
                }}
                loader={
                  <div className="loading-more-messages-container">
                    <CircularProgress size={20} color="secondary" />
                  </div>
                }
                scrollableTarget="reverse-chat"
              >
                {/* Messages */}
                {messages.map(renderChatMessage)}
                {/* Scroll to bottom button */}
                {showScrollToBottomButton && (
                  <div className="scrollToBottomButtonContainer">
                    <Badge
                      variant="dot"
                      color="error"
                      overlap="circular"
                      invisible={!showNewMessageBadge}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={scrollToBottomAnimated}
                        sx={{ width: 40, height: 40 }}
                      >
                        <FaAngleDown
                          size={20}
                          color={theme.palette.light.main}
                        />
                      </Button>
                    </Badge>
                  </div>
                )}
              </InfiniteScroll>
            ) : (
              <div className="no-messages-yet">
                <span style={{ color: theme.palette.text.main }}>
                  No {showStarredMessages ? "starred" : ""} messages yet
                </span>
              </div>
            )}
          </div>

          {!isLoading && (
            <ChatInput
              placeholder="Type a message..."
              value={messageText}
              setValue={setMessageText}
              onSubmitHandler={sendNewMessage}
              isLoading={isLoading}
              imagesToSend={imagesToSend}
              imagesToPreview={imagesToPreview}
              handleRemoveImage={handleRemoveImage}
              handleImagesInputChange={handleImagesInputChange}
              handlePastedInChat={handlePastedInChat}
              handleDroppedInChat={handleDroppedInChat}
              messageToHandle={messageToHandle}
              setMessageToHandle={setMessageToHandle}
              showReplyMessage={showReplyMessage}
              setShowReplyMessage={setShowReplyMessage}
              userId={props.user?.id}
              userTokens={props.user?.tokens}
              coachName={props.coach?.name}
              setPaddingBottom={setPaddingBottom}
            />
          )}

          {props.isShowToggleIcon && (
            <div onClick={() => props.toggleChat(false)} className="arrow-btn">
              <button
                className="btn-toggle"
                style={{
                  backgroundColor: theme.palette.toggleCollapseBtn.main,
                  borderColor: theme.palette.text.main,
                }}
              >
                <FaAngleRight size={16} color={theme.palette.text.main} />
              </button>
            </div>
          )}
        </div>

        {/* Modal for fullscreen images */}
        <ImageModal
          isVisible={showImageFullScreenModal}
          setIsVisible={setShowImageFullScreenModal}
          image={imageForFullScreenModal}
        />

        {/* Modal for fullscreen videos */}
        <VideoModal
          isVisible={showVideoFullScreenModal}
          setIsVisible={setShowVideoFullScreenModal}
          video={VideoForFullScreenModal}
        />

        {/* Overlay to edit messages */}
        <EditChatMessageOverlay
          isVisible={showEditMessageOverlay}
          setIsVisible={setShowEditMessageOverlay}
          messageId={messageToHandle?.id}
          messageText={messageToHandle?.text}
        />

        {/* Overlay to react messages */}
        <ReactChatMessageOverlay
          isVisible={showReactMessageOverlay}
          setIsVisible={setShowReactMessageOverlay}
          messageId={messageToHandle?.id}
          handleAddEditReaction={handleAddEditReaction}
        />

        {/* Overlay to delete messages */}
        <DeleteConfirmationOverlay
          isVisible={showDeleteMessageOverlay}
          setIsVisible={setShowDeleteMessageOverlay}
          title={"Delete Message"}
          subtitle={"Are you sure you want to delete this message?"}
          handleConfirmation={handleDeleteMessage}
        />

        {/* Coach info overlay */}
        <StatusOverlay
          isVisible={isCoachStatusOverlayVisible}
          setIsVisible={setIsCoachStatusOverlayVisible}
          status={props.coach?.status}
        />

        {/* Coach info overlay */}
        <InfoOverlay
          isVisible={props.isCoachInfoOverlayVisible}
          setIsVisible={props.setIsCoachInfoOverlayVisible}
          setIsCoachStatusOverlayVisible={setIsCoachStatusOverlayVisible}
          coach={props.coach}
          userId={props.user?.id}
          userType={props.user?.type}
          isCoachInfoOverlay
        />

        {/* Inactive info overlay */}
        <InfoOverlay
          isVisible={isInactiveInfoOverlayVisible}
          setIsVisible={setIsInactiveInfoOverlayVisible}
          title="⚠️"
          subtitle={en.INFO_INACTIVE_ACCOUNT}
        />
      </>
    );
  } else {
    return (
      <div
        className="chat-section chat-wrap"
        style={{
          backgroundColor: theme.palette.secondaryBackground.main,
        }}
      >
        <span className="chat-icon">
          <IoChatbubble
            size={25}
            style={{ color: theme.palette.primary.main }}
          />
        </span>
        <div onClick={() => props.toggleChat(true)} className="arrow-btn arrow">
          <button
            className="btn-toggle"
            style={{
              backgroundColor: theme.palette.toggleCollapseBtn.main,
              borderColor: theme.palette.text.main,
            }}
          >
            <FaAngleLeft size={16} color={theme.palette.text.main} />
          </button>
        </div>
      </div>
    );
  }
};

export default React.memo(Chat);
