export const en = {
  // Login
  YOUR_EMAIL: "Your email",
  YOUR_NAME: "Your name",
  YOUR_PASSWORD: "Your password",
  SIGN_IN: "Login",
  SIGN_UP: "Sign Up",
  OR: "or",
  SIGN_IN_WITH_GOOGLE: "Sign in with Google",
  FORGOT_PASSWORD_1: "Forgot your password?",
  FORGOT_PASSWORD_2: "Reset it",
  FORGOT_PASSWORD_3:
    "Don't worry! It happens. Please enter the address associated with your account.",
  NO_ACCOUNT_1: "Don't have an account?",
  NO_ACCOUNT_2: "Get started!",
  RESET_PASSWORD: "Reset password",
  BACK_TO_SIGN_IN: "Back to login",
  ALREADY_HAVE_ACCOUNT_1: "Already have an account?",
  ALREADY_HAVE_ACCOUNT_2: "Log in",
  NAME_REQUIRED: "The name field is required",
  EMAIL_REQUIRED: "The email field is required",
  EMAIL_INVALID: "The email address is invalid",
  EMAIL_ALREADY_IN_USE: "That email address is already in use!",
  EMAIL_HAS_BEEN_DISABLED: "That email address has been disabled!",
  EMAIL_HAS_NO_USER: "This email has not been registered.",
  PASSWORD_REQUIRED: "The password field is required",
  PASSWORD_INVALID: "The password field must have at least 6 characters",
  PASSWORD_NOT_STRONG_ENOUGH: "Thrown if the password is not strong enough.",
  PASSWORD_WRONG_OR_NOT_SET: "The password is incorrect or has not been set.",
  SUCCESS_SEND_EMAIL_PASSWORD_RESET:
    "Done! Please check your email for further instructions.",

  // Info modals
  INFO_TITLE_GOALS: "About goals",
  INFO_GOALS:
    "Goals with a specific deadline go here! Whether they're recurring or one-time, they're the roadmap to your larger vision. Remember, every step counts! 💪",
  INFO_TITLE_INBOX: "About your Undated",
  INFO_INBOX:
    "Here's where goals without a date go. From here you can mark them done or move them to any day... well, not ones in the past - we don't support time travel yet 🌌",
  INFO_TITLE_TARGETS: "About Targets",
  INFO_TARGETS:
    "Daily goals are best paired with longer term ambition. Set your monthly targets here, for example hike the Mariana Trench by December 🤿",
  INFO_TITLE_MILESTONES: "About Milestones",
  INFO_MILESTONES:
    "These are special achievements awarded by your coach on your journey of personal progress. Let's celebrate! 🌞",
  INFO_PARTIALLY_DONE_GOAL_TITLE: "About partially done goals",
  INFO_PARTIALLY_DONE_GOAL:
    "A second tap over a goal will set it as partially done, which means you have got started with it but haven't finished yet ⏳ Tap it once more to revert to not done.",
  INFO_EDIT_GOAL_SCREEN_TITLE: "About edit screen ",
  INFO_EDIT_GOAL_SCREEN:
    "Here you can edit, change day, choose color, set a repeat cycle and more… ",
  INFO_INACTIVE_ACCOUNT:
    "If you’d like an expert human coach giving you direct and personalized accountability, you can get a subscription here:\n\nhttps://www.goalswon.com/",
  INFO_TITLE_UNSUBMITTED_DAY_RESULTS: "About Unsubmitted Results",
  INFO_UNSUBMITTED_DAY_RESULTS:
    "It's time to take a moment to reflect, and add comments or learnings that you've gathered along the way 📝 Your coach will review, provide feedback, and celebrate your progress!",

  // Tutorial
  TUTORIAL_MAIN: "Accountability towards your life goals begins now...",
  TUTORIAL_1:
    "Now, a quick intro then we'll pick starting habits and then meet your coach, ",
  TUTORIAL_2: "Each morning, you'll set your goals for the day...",
  TUTORIAL_3: "At the end of the day, submit your results and reflections…",
  TUTORIAL_4:
    "will review and reply each day: to encourage, offer best practices and keep you accountable",
  TUTORIAL_5:
    "Together you'll set monthly targets and make long term progress that matters to you!",
  TUTORIAL_6:
    "Now, tap some habits to begin with (max 3, we can adjust them later)...",
  TUTORIAL_7: "Please add a target for ",
  TUTORIAL_8: "Lastly, please add a goal for ",
};
