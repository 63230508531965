import React from "react";

import { useTheme } from "@mui/material";

import { alertColors } from "../../api/constants";
import "./style.scss";

const CustomTooltip = (props) => {
  const theme = useTheme();

  return (
    <>
      <label
        className="tooltip-text"
        style={{
          backgroundColor: alertColors.borderWarning,
          color: theme.palette.light.main,
        }}
      >
        {props.text}
      </label>
      <div
        className="tooltip-arrow"
        style={{ borderBottomColor: alertColors.borderWarning }}
      ></div>
    </>
  );
};

export default React.memo(CustomTooltip);
