import React from "react";
import { useSelector } from "react-redux";

import { useTheme, Button } from "@mui/material";
import { FaAngleRight } from "react-icons/fa";

import { ReactComponent as Tutorial0 } from "../../assets/svg/tutorial_0.svg";

import { useWindowSize } from "../../api/helper";
import { Devices } from "../../api/constants";
import { en } from "../../api/languages/en";
import "./style.scss";

const Screen0 = (props) => {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  return (
    <div
      className="tutorial-container"
      style={{
        background: "linear-gradient(0deg, #fbc305 5%, #fee001 100%)",
      }}
    >
      {currentDevice === Devices.PHONE ? (
        <Tutorial0 width="100%" height={529 * (windowWidth / 375)} />
      ) : (
        <Tutorial0 width={375} height={529} />
      )}

      <div className="body">
        <label
          className="text"
          style={{ color: theme.palette.light.main, fontWeight: 500 }}
        >
          {en.TUTORIAL_MAIN}
        </label>
      </div>

      <div className="footer">
        <div></div>
        <Button
          id="right-click-btn"
          variant="contained"
          color="quaternary"
          onClick={() => props.tutorialRef?.current?.go(1)}
          sx={{ height: 50, width: 50 }}
        >
          <FaAngleRight size={24} />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(Screen0);
