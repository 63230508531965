import { firestore } from "../../api/firebase";
import { captureException } from "@sentry/react";

import { FETCH_COACH, SET_LOADING_COACH } from "../types";

let unsubscribers = [];

const fetchCoachSuccess = (coach) => ({
  type: FETCH_COACH,
  payload: coach,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_COACH,
  payload: isLoading,
});

export const fetchCoach = (coachUid) => (dispatch) => {
  try {
    dispatch(setLoading(true));

    const coachSubscriber = firestore
      .collection("coaches")
      .doc(coachUid)
      .onSnapshot(
        (snapshot) => {
          const coach = {
            ...snapshot.data(),
            id: snapshot.id,
          };
          dispatch(fetchCoachSuccess(coach));
          dispatch(setLoading(false));
        },
        (error) => {
          dispatch(setLoading(false));
          captureException("Error fetching coach onSnapshot", error);
          console.log("Error fetching coach onSnapshot: " + error);
        }
      );
    unsubscribers.push(coachSubscriber);
  } catch (error) {
    dispatch(setLoading(false));
    captureException("Error fetching coach", error);
    console.log("Error fetching coach: " + error);
  }
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeCoachActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
