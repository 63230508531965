import { FETCH_MILESTONES, SET_LOADING_MILESTONES } from "../types";

const initialState = {
  milestones: [],
  isLoading: false,
};

const milestoneReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_MILESTONES:
      return {
        ...state,
        milestones: payload,
      };

    case SET_LOADING_MILESTONES:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default milestoneReducer;
