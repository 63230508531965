import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FaAngleLeft } from "react-icons/fa";
import { useTheme } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

// Assets
import logo from "../../../assets/img/goalswon_logo_rounded.png";

import "./style.scss";

const SortingTargets = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const btnBackClicked = () => {
    navigate("/settings");
  };

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="plans-container"
      style={{ backgroundColor: theme.palette.background.main }}
    >
      <div className="plans-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Settings"
          title="Premium Access"
        />
        <div className="header">
          <button
            className="btn-back"
            style={{ color: theme.palette.primary.main }}
            onClick={() => navigate("/settings")}
          >
            <FaAngleLeft /> Settings
          </button>
          <label
            className="header-title"
            style={{ color: theme.palette.text.main }}
          >
            Premium access
          </label>
        </div>
        <div className="content">
          <img src={logo} alt="GoalsWon logo" width={80}></img>
          {/* <label>
          You've taken the first step to lasting change. We look forward to
          helping you achieve your goals! Your coach has been assigned and will
          reach out through the in-app chat within the next 12 hours to begin.
        </label> */}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SortingTargets);
