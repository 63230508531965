import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  Divider,
} from "@mui/material";

import "./style.scss";

const DeleteConfirmationOverlay = ({
  isVisible,
  setIsVisible,
  title,
  handleImagesInputChange,
  setIsDeleteProfilePicOverlayVisible,
}) => {
  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      TransitionComponent={Slide}
      className="manage-profile-picture-modal"
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">{title}</label>
      </DialogTitle>

      <DialogContent className="content">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="secondary"
          component="label"
        >
          Upload Image
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImagesInputChange}
          />
        </Button>
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="error"
          onClick={() => {
            setIsVisible(false);
            setIsDeleteProfilePicOverlayVisible(true);
          }}
        >
          Delete current picture
        </Button>
      </DialogContent>

      <Divider />

      <div className="btn-footer-container">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => setIsVisible(false)}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(DeleteConfirmationOverlay);
