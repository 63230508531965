import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/analytics";

import { firebaseAPIKey } from "../api/credentials";

const firebaseConfig = {
  apiKey: firebaseAPIKey,
  authDomain: "goalswon-rebuilt-app.firebaseapp.com",
  projectId: "goalswon-rebuilt-app",
  storageBucket: "goalswon-rebuilt-app.appspot.com",
  messagingSenderId: "776739064516",
  appId: "1:776739064516:web:74b4ed447282e85c82d791",
  measurementId: "G-C18ZP7SHT2",
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firestore = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const storage = firebaseApp.storage();
export const analytics = firebaseApp.analytics();
