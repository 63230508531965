import firebase from "firebase/compat/app";
import { firestore, auth } from "../firebase";
import { toast } from "react-toastify";
import moment from "moment";

import { getTimestamp } from "../helper";
import { addGoal, deleteGoalComingFromInbox } from "./goals";
import { updateLastTimeActive, updateLastTimeGoalsInteraction } from "./users";

import { captureException } from "@sentry/react";

export const addInbox = async (inbox, showToast = false) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Set new inbox
    const inboxRef = firestore
      .collection("inboxes")
      .doc(userUid)
      .collection("inboxes")
      .doc();
    batch.set(inboxRef, { ...inbox, createdAt: getTimestamp() });

    // Increment inboxesTotal
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        inboxesTotal: firebase.firestore.FieldValue.increment(1),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch
      .commit()
      .then(() => {
        showToast && toast.success("Goal added to Undated!");
      })
      .catch((error) => {
        captureException("Error while commiting inbox transaction", error);
        console.error("Error while commiting inbox transaction: " + error);
      });

    updateLastTimeActive();
    updateLastTimeGoalsInteraction();

    return inboxRef.id;
  } catch (error) {
    captureException("Error while adding inbox", error);
    console.error("Error while adding inbox: " + error);
  }
};

export const updateInbox = (inbox) => {
  try {
    const userUid = auth.currentUser.uid;

    const { id, ...inboxDestructured } = inbox;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Update inbox
    const inboxRef = firestore
      .collection("inboxes")
      .doc(userUid)
      .collection("inboxes")
      .doc(id);
    batch.set(
      inboxRef,
      {
        ...inboxDestructured,
        updatedAt: getTimestamp(),
        ...(inboxDestructured.createdAt && {
          createdAt: getTimestamp(
            moment(
              inboxDestructured?.createdAt?.toDate &&
                inboxDestructured?.createdAt?.toDate()
            )
          ),
        }),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting inbox update transaction", error);
      console.error("Error while commiting inbox update transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating inbox", error);
    console.error("Error while updating inbox: " + error);
  }
};

export const sortInboxes = async (inboxes) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    inboxes.forEach((inbox) => {
      const { id, ...inboxWithoutId } = inbox;
      // Update inbox
      const inboxRef = firestore
        .collection("inboxes")
        .doc(userUid)
        .collection("inboxes")
        .doc(id);
      batch.set(inboxRef, inboxWithoutId, { merge: true });
    });

    // ---- Finish transaction ----
    await batch.commit().catch((error) => {
      captureException("Error while commiting inbox update transaction", error);
      console.error("Error while commiting inbox update transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating inbox", error);
    console.error("Error while updating inbox: " + error);
  }
};

export const deleteInbox = (inbox) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Delete inbox
    const inboxRef = firestore
      .collection("inboxes")
      .doc(userUid)
      .collection("inboxes")
      .doc(inbox.id);
    batch.delete(inboxRef);

    // Decrement inboxesTotal and inboxesDone
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        inboxesTotal: firebase.firestore.FieldValue.increment(-1),
        inboxesDone: firebase.firestore.FieldValue.increment(
          inbox.isDone ? -1 : 0
        ),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting delete inbox transaction", error);
      console.error("Error while commiting delete inbox transaction: " + error);
    });
  } catch (error) {
    captureException("Error while deleting inbox", error);
    console.error("Error while deleting inbox: " + error);
  }
};

export const toggleInboxDone = (inbox) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    const id = inbox.fromInbox || inbox.id;

    // Change inbox isDone value
    const inboxRef = firestore
      .collection("inboxes")
      .doc(userUid)
      .collection("inboxes")
      .doc(id);
    batch.set(
      inboxRef,
      { isDone: inbox.isDone, updatedAt: getTimestamp() },
      { merge: true }
    );

    // Update inboxesDone
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        inboxesDone: firebase.firestore.FieldValue.increment(
          inbox.isDone ? 1 : -1
        ),
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch
      .commit()
      .then(() => {
        if (inbox.isDone) {
          const goal = {
            name: inbox.name,
            isDone: true,
            fromInbox: inbox.id,
            date: moment(),
            tag: inbox.tag || "",
            description: inbox.description || "",
            isRolledOver: true,
          };
          addGoal(goal);
        } else {
          deleteGoalComingFromInbox(id);
        }

        if (inbox.isDone) {
          setTimeout(() => {
            toast.success("Moved to goals", 2);
          }, 1);
        } else {
          toast.success("Moved to Undated!");
        }
      })
      .catch((error) => {
        captureException(
          "Error while commiting toggle inbox transaction",
          error
        );
        console.error(
          "Error while commiting toggle inbox transaction: " + error
        );
      });

    updateLastTimeActive();
    updateLastTimeGoalsInteraction();
  } catch (error) {
    captureException("Error while toggling inbox", error);
    console.error("Error while toggling inbox: " + error);
  }
};

export const undoDeleteInbox = (inboxReceived) => {
  try {
    const { id, updatedAt, ...inboxWithoutId } = inboxReceived;

    addInbox(inboxWithoutId, false);
  } catch (error) {
    captureException("Error while undoing delete inbox", error);
    console.error("Error while undoing delete inbox: " + error);
  }
};

export const undoMarkAsDoneInbox = (inbox) => {
  try {
    deleteGoalComingFromInbox(inbox.id);
    updateInbox(inbox);
  } catch (error) {
    captureException("Error while undoing mark inbox as done", error);
    console.error("Error while undoing mark inbox as done: " + error);
  }
};
