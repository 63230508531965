import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";

import { FaApple } from "react-icons/fa";
import {
  useTheme,
  Alert,
  CircularProgress,
  Button,
  TextField,
  Box,
} from "@mui/material";

import { ReactComponent as SignUp } from "../../assets/svg/signUp.svg";
import { ReactComponent as Email } from "../../assets/svg/email.svg";
import { ReactComponent as Username } from "../../assets/svg/userName.svg";
import { ReactComponent as Password } from "../../assets/svg/password.svg";
import { ReactComponent as Google } from "../../assets/svg/google.svg";
import { ReactComponent as Divider } from "../../assets/svg/divider.svg";

import { emailSignUp } from "../../api/auth";
import {
  validateName,
  validateEmail,
  validatePassword,
  useWindowSize,
} from "../../api/helper";
import { googleSignIn, appleSignIn } from "../../api/auth";
import { en } from "../../api/languages/en";
import { Devices } from "../../api/constants";
import "./style.scss";

const SignUpScreen = () => {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signUpError, setSignUpError] = useState(undefined);
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [loadingGoogleSignIn, setLoadingGoogleSignIn] = useState(false);
  const [loadingAppleSignIn, setLoadingAppleSignIn] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validate = () => {
    const validatedName = validateName(name);
    setNameError(validatedName.message);

    const validatedEmail = validateEmail(email);
    setEmailError(validatedEmail.message);

    const validatedPassword = validatePassword(password);
    setPasswordError(validatedPassword.message);

    return (
      validatedName.isValid &&
      validatedEmail.isValid &&
      validatedPassword.isValid
    );
  };

  const signUp = async () => {
    setLoadingSignUp(true);
    setSignUpError(undefined);
    if (validate()) {
      const response = await emailSignUp(name, email, password);

      // response is an error, so stays undefined if nothing went wrong
      if (response) {
        setSignUpError(response);
      }
    }
    setLoadingSignUp(false);
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setLoadingGoogleSignIn(true);
      googleSignIn(tokenResponse);
      setLoadingGoogleSignIn(false);
    },
  });

  const signInWithApple = () => {
    setLoadingAppleSignIn(true);
    appleSignIn();
    setLoadingAppleSignIn(false);
  };

  return (
    <div
      className="auth-container"
      style={{ backgroundColor: theme.palette.background.main }}
    >
      {currentDevice === Devices.PHONE ? (
        <SignUp width="100%" height={256 * (windowWidth / 375)} />
      ) : (
        <SignUp width={375} height={256} />
      )}

      <div className="auth-content-container">
        <label className="title">Sign up</label>

        <form onSubmit={signUp}>
          <div className="auth-inputs-container">
            <Box className="auth-input-container">
              <Box className="adornment-container">
                <Username />
              </Box>
              <TextField
                variant="standard"
                fullWidth
                margin="dense"
                type="text"
                id="name"
                label="Your name"
                name="name"
                autoComplete="name"
                value={name}
                error={nameError ? true : false}
                helperText={nameError}
                onChange={handleNameChange}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                  "& .MuiInput-underline:hover": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                }}
              />
            </Box>
            <Box className="auth-input-container">
              <Box className="adornment-container">
                <Email />
              </Box>
              <TextField
                variant="standard"
                fullWidth
                margin="dense"
                type="text"
                id="email"
                label="Your email"
                name="email"
                autoComplete="email"
                value={email}
                error={emailError ? true : false}
                helperText={emailError}
                onChange={handleEmailChange}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                  "& .MuiInput-underline:hover": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                }}
              />
            </Box>
            <Box className="auth-input-container">
              <Box className="adornment-container">
                <Password />
              </Box>
              <TextField
                variant="standard"
                fullWidth
                margin="dense"
                type="password"
                id="password"
                label="Your password"
                name="password"
                autoComplete="password"
                value={password}
                error={passwordError ? true : false}
                helperText={passwordError}
                onChange={handlePasswordChange}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                  "& .MuiInput-underline:hover": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: theme.palette.gray.main,
                  },
                }}
              />
            </Box>
          </div>

          {signUpError && (
            <Alert variant="filled" severity="error">
              {signUpError}
            </Alert>
          )}

          <div className="btn-auth-container">
            <Button
              onClick={(e) => {
                e.preventDefault();
                signUp();
              }}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ height: 45, borderRadius: 2 }}
              type="submit"
            >
              <div className="btn-auth-text">
                {loadingSignUp ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Sign Up"
                )}
              </div>
            </Button>
          </div>
        </form>

        <Box className="divider-container">
          <Divider />
        </Box>

        <div className="btn-auth-container">
          <Button
            onClick={() => login()}
            variant="contained"
            color="quaternary"
            fullWidth
            sx={{ height: 45, borderRadius: 2 }}
          >
            {loadingGoogleSignIn ? (
              <CircularProgress color="inherit" />
            ) : (
              <div className="btn-google-sign-in-label">
                <Google />
                &nbsp; Sign in with Google
              </div>
            )}
          </Button>
        </div>

        <div className="btn-auth-container">
          <Button
            title="Login with Apple"
            onClick={signInWithApple}
            variant="contained"
            color="dark"
            fullWidth
            sx={{ height: 45, borderRadius: 2 }}
          >
            {loadingAppleSignIn ? (
              <CircularProgress color="inherit" />
            ) : (
              <div>
                <FaApple />
                &nbsp; Sign in with Apple
              </div>
            )}
          </Button>
        </div>

        <div className="btns-auth-nav-container">
          <div className="btn-auth-nav-container">
            <Link to="/SignIn" className="btn-auth-nav">
              <label
                className="clear_btn_text_1"
                style={{
                  color: theme.palette.gray.main,
                }}
              >
                {en.ALREADY_HAVE_ACCOUNT_1}
              </label>
              <label
                className="clear_btn_text_2"
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                {" "}
                {en.ALREADY_HAVE_ACCOUNT_2}
              </label>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SignUpScreen);
