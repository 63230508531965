import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import moment from "moment";

import { FaRegCircle, FaCheckCircle } from "react-icons/fa";
import { useTheme, Checkbox } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

// Redux
import { sortByIsDone, sortBy, orderBy } from "../../../redux/actions/local";

// Firestore
import { updateUser } from "../../../api/firestore/users";

// Analytics
import { analytics } from "../../../api/firebase";

// Own api
import { Devices } from "../../../api/constants";
import "./style.scss";

const SortingGoals = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentDevice = useSelector((state) => state.local.currentDevice);
  const isSortByIsDone = useSelector((state) => state.local.isSortByIsDone);
  const isSortBy = useSelector((state) => state.local.isSortBy);
  const isOrderBy = useSelector((state) => state.local.isOrderBy);

  const handleSortByIsDone = (property) => {
    dispatch(sortByIsDone(property));
    updateSortingGoalsSettings("sortByIsDone", property);
  };

  const sort = (property) => {
    dispatch(sortBy(property));
    updateSortingGoalsSettings("sortBy", property);
  };

  const order = (property) => {
    dispatch(orderBy(property));
    updateSortingGoalsSettings("orderBy", property);
  };

  const btnBackClicked = () => {
    navigate("/settings");
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateSortingGoalsSettings = useCallback(
    debounce((property, value) => {
      updateUser({
        [property]: value,
      });
      updateUser({ usedSortGoals: moment() }); // TODO - Remove
      analytics.logEvent("used_sort_goals");
    }, 300),
    []
  );

  return (
    <div
      className="sorting-goals-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
      }}
    >
      <div className="sorting-goals-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Settings"
          title="Sort Goals"
        />
        <div className="content">
          <label className="title" style={{ color: theme.palette.text.main }}>
            Sort by done
          </label>
          <div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isSortByIsDone === "ASC"}
                onChange={() => {
                  handleSortByIsDone("ASC");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleSortByIsDone("ASC");
                }}
              >
                Yes (top)
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isSortByIsDone === "DESC"}
                onChange={() => {
                  handleSortByIsDone("DESC");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleSortByIsDone("DESC");
                }}
              >
                Yes (bottom)
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isSortByIsDone === "no"}
                onChange={() => {
                  handleSortByIsDone("no");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  handleSortByIsDone("no");
                }}
              >
                No
              </label>
            </div>
          </div>

          <label className="title" style={{ color: theme.palette.text.main }}>
            Sort by
          </label>
          <div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isSortBy === "position"}
                onChange={() => {
                  sort("position");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  sort("position");
                }}
              >
                Created time
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isSortBy === "name"}
                onChange={() => {
                  sort("name");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  sort("name");
                }}
              >
                Name
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isSortBy === "tag"}
                onChange={() => {
                  sort("tag");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  sort("tag");
                }}
              >
                Color
              </label>
            </div>
          </div>

          <label className="title" style={{ color: theme.palette.text.main }}>
            Order by
          </label>
          <div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isOrderBy === "ASC"}
                onChange={() => {
                  order("ASC");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  order("ASC");
                }}
              >
                Ascending
              </label>
            </div>
            <div className="option">
              <Checkbox
                color="primary"
                icon={<FaRegCircle size={22} />}
                checkedIcon={<FaCheckCircle size={22} />}
                checked={isOrderBy === "DESC"}
                onChange={() => {
                  order("DESC");
                }}
              />
              <label
                className="repeat-option-text"
                style={{ color: theme.palette.text.main }}
                onClick={() => {
                  order("DESC");
                }}
              >
                Descending
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SortingGoals);
