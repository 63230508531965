import React from "react";
import { useSelector } from "react-redux";

import { useTheme, Button, LinearProgress } from "@mui/material";
import { FaAngleRight } from "react-icons/fa";

import tutorial2 from "../../assets/img/tutorial_2.png";
// import { ReactComponent as Tutorial2 } from "../../assets/svg/tutorial_2.svg";

import { useWindowSize } from "../../api/helper";
import { Devices } from "../../api/constants";
import { en } from "../../api/languages/en";
import "./style.scss";

const SecondScreen = (props) => {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  return (
    <div
      className="tutorial-container"
      style={{ backgroundColor: theme.palette.background.main }}
    >
      {currentDevice === Devices.PHONE ? (
        <img
          src={tutorial2}
          width="100%"
          height={529 * (windowWidth / 375)}
          alt="2nd tutorial"
        />
      ) : (
        <img src={tutorial2} width={375} height={529} alt="2nd tutorial" />
      )}
      {/* {currentDevice === Devices.PHONE ? (
        <Tutorial2 width="100%" height={529 * (windowWidth / 375)} />
      ) : (
        <Tutorial2 width={375} height={529} />
      )} */}

      <div className="body">
        <label className="text" style={{ color: theme.palette.text.main }}>
          {en.TUTORIAL_2}
        </label>
      </div>

      <div className="footer">
        <LinearProgress
          variant="determinate"
          color="tertiary"
          value={(2 * 100) / 7}
          style={{ width: 150 }}
        />
        <Button
          id="right-click-btn"
          variant="contained"
          color="quaternary"
          onClick={() => props.tutorialRef?.current?.go(3)}
          sx={{ height: 50, width: 50 }}
        >
          <FaAngleRight size={24} />
        </Button>
      </div>
    </div>
  );
};

export default React.memo(SecondScreen);
