import React, { useEffect, useRef } from "react";

import { FaRegTimesCircle, FaPlayCircle } from "react-icons/fa";
import { FiCamera } from "react-icons/fi";
import { IoMdSend } from "react-icons/io";

import { useTheme, IconButton, TextField } from "@mui/material";

import { markMessagesAsRead } from "../../api/firestore/chats";

import { colors } from "../../api/constants";
import "./style.scss";

const ChatInput = (props) => {
  const theme = useTheme();
  const chatInputRef = useRef();
  const effectRan = useRef();

  // ---------- Bottom padding ----------
  useEffect(() => {
    const updatePaddingBottom = () => {
      const chatInput = document.getElementById("chat-input");
      if (chatInput) {
        if (effectRan.current) {
          props.setPaddingBottom(chatInput.offsetHeight + 5);
        } else {
          effectRan.current = true;
          props.setPaddingBottom(chatInput.offsetHeight + 5 + 4); // Don't know why on first render is 4 pixels shorter until I type a letter
        }
      }
    };

    updatePaddingBottom(); // Initial calculation
    window.addEventListener("input", updatePaddingBottom); // Recalculate on resize

    return () => {
      window.removeEventListener("input", updatePaddingBottom); // Cleanup on unmount
    };
  }, [props.showReplyMessage, props.imagesToPreview]);

  const handleOnSendClicked = () => {
    if (props.value.trim() || props.imagesToSend.length) {
      props.onSubmitHandler(props.value.trim());
    }

    // Reset
    props.setValue("");
  };

  const keyDownHandler = (e) => {
    if (e.shiftKey && e.keyCode === 13) {
      // console.log("is line break");
    } else if (e.keyCode === 13) {
      e.preventDefault();
      handleOnSendClicked();
    }
  };

  return (
    <div
      id="chat-input"
      style={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        minHeight: 82,
      }}
    >
      {props.messageToHandle && props.showReplyMessage && (
        <div
          className="message-to-reply-container"
          style={{
            backgroundColor: theme.palette.quaternary.main,
            borderLeftColor:
              props.messageToHandle.sentBy === props.userId
                ? theme.palette.primary.main
                : theme.palette.reply.main,
          }}
        >
          <div className="message-content">
            <label
              className="message-sender"
              style={{
                color:
                  props.messageToHandle.sentBy === props.userId
                    ? theme.palette.primary.main
                    : theme.palette.reply.main,
              }}
            >
              {props.messageToHandle.sentBy === props.userId
                ? "You"
                : props.coachName}
            </label>
            {props.messageToHandle?.image ? (
              <div className="img-wrap">
                <img
                  src={props.messageToHandle.image}
                  className="image-preview"
                  alt="preview"
                  height="75"
                  width="75"
                />
              </div>
            ) : null}
            {props.messageToHandle?.video ? (
              props.messageToHandle.video.thumbnail ? (
                <div className="img-wrap">
                  <img
                    src={props.messageToHandle.video.thumbnail}
                    className="image-preview"
                    alt="preview"
                    height="75"
                    width="75"
                  />
                  <div className="play-icon-container">
                    <FaPlayCircle
                      size={20}
                      color={colors.light}
                      style={{ marginRight: 5 }}
                    />
                  </div>
                </div>
              ) : (
                <div className="video-wrap">
                  <div
                    className="video"
                    style={{ backgroundColor: theme.palette.tertiary.main }}
                  >
                    <FaPlayCircle size={40} color={colors.light} />
                  </div>
                </div>
              )
            ) : null}
            {props.messageToHandle?.text ? (
              <>
                <label
                  className="message-text"
                  style={{ color: theme.palette.text.main }}
                >
                  {props.messageToHandle.text}
                </label>
                {props.messageToHandle.text.length > 100 && (
                  <label style={{ color: theme.palette.text.main }}>...</label>
                )}
              </>
            ) : null}
          </div>
          <IconButton
            variant="outlined"
            color="secondary"
            size={"medium"}
            onClick={() => {
              props.setMessageToHandle(null);
              props.setShowReplyMessage(false);
            }}
          >
            <FaRegTimesCircle />
          </IconButton>
        </div>
      )}

      <div
        className="chatInput-container"
        id="chatInput-container"
        style={{ backgroundColor: theme.palette.secondaryBackground.main }}
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
          document
            .getElementById("chatInput-container")
            .classList.add("highlight");
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
          document
            .getElementById("chatInput-container")
            .classList.remove("highlight");
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.handleDroppedInChat(e);
          document
            .getElementById("chatInput-container")
            .classList.remove("highlight");
        }}
      >
        {props.imagesToSend.length > 0 && (
          <div className="images-preview-container">
            {props.imagesToPreview.map((img, index) => (
              <div key={index} className="img-wrap">
                <img
                  src={img}
                  className="image-preview"
                  alt="preview"
                  height="100"
                  width="100"
                />
                <button
                  className="close-preview-img"
                  onClick={() => props.handleRemoveImage(index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="input-container">
          <TextField
            ref={chatInputRef}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              "& textarea": {
                scrollbarWidth: "thin",
                scrollbarColor: `${theme.palette.gray.contrast} transparent`,
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.gray.contast,
                },
              },
              padding: 0.5,
            }}
            inputProps={{
              style: {
                maxHeight: "30vh",
                overflow: "scroll",
              },
            }}
            fullWidth
            autoComplete="off"
            multiline
            minRows={3}
            // maxRows={10}
            required
            id="messageInput"
            name="messageInput"
            placeholder={props.placeholder}
            value={props.value}
            onChange={() =>
              props.setValue(document.getElementById("messageInput").value)
            }
            onKeyDown={(e) => {
              keyDownHandler(e);
            }}
            onPaste={(e) => {
              props.handlePastedInChat(e);
            }}
          />

          {props.imagesToSend.length < 8 ? (
            <div>
              <label htmlFor="chat-files-upload">
                <FiCamera
                  color={theme.palette.gray.main}
                  size={22}
                  className="add-files-icon"
                />
              </label>
              <input
                id="chat-files-upload"
                type="file"
                multiple
                accept="image/*"
                onChange={props.handleImagesInputChange}
                onFocus={() => markMessagesAsRead(props.user?.tokens)}
              />
            </div>
          ) : (
            <label>8/8</label>
          )}

          <button
            className="btn-add-message-container"
            type="submit"
            onClick={() => {
              handleOnSendClicked();
            }}
          >
            <IoMdSend color={theme.palette.secondary.main} size={28} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChatInput);
