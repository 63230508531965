import React from "react";
import { IoSettings } from "react-icons/io5";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { changeCurrentScreen } from "../../redux/actions/local";

// Components
import { useTheme, Badge } from "@mui/material";

// Assets
import logo from "../../assets/img/goalswon_logo.svg";
import { ReactComponent as GoalsIcon } from "../../assets/svg/goals.svg";
import { ReactComponent as InboxIcon } from "../../assets/svg/inbox.svg";
import { ReactComponent as TargetsIcon } from "../../assets/svg/targets.svg";
import { ReactComponent as MilestonesIcon } from "../../assets/svg/milestones.svg";
import { ReactComponent as CoachIcon } from "../../assets/svg/coach.svg";

// Own api

import { ScreenNames } from "../../api/constants";
import "./style.scss";

const SideNav = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFocus = () => {
    switch (props.currentScreen) {
      case ScreenNames.GOALS_SCREEN:
        if (document.getElementById("goals-screen")) {
          document.getElementById("goals-screen").focus();
        }
        break;
      case ScreenNames.INBOX_SCREEN:
        if (document.getElementById("inbox-screen")) {
          document.getElementById("inbox-screen").focus();
        }
        break;
      case ScreenNames.TARGETS_SCREEN:
        if (document.getElementById("targets-screen")) {
          document.getElementById("targets-screen").focus();
        }
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={props.isShow ? "sidebar-wrapper" : "sidebar-wrapper open"}
      style={{ backgroundColor: theme.palette.secondaryBackground.main }}
    >
      <div
        className="sidebar-heading"
        onClick={() => {
          navigate("/");
          dispatch(changeCurrentScreen(ScreenNames.GOALS_SCREEN));
        }}
      >
        <img
          src={logo}
          alt="goals icon"
          width={80}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
            dispatch(changeCurrentScreen("GOALS_SCREEN"));
          }}
        />
      </div>

      <div
        className="content-item-wrap"
        onClick={() => {
          handleFocus();
        }}
      >
        <div className="item-list">
          <ul>
            <Link to={"/"}>
              <li
                className={
                  props.currentScreen === ScreenNames.GOALS_SCREEN
                    ? "active"
                    : null
                }
                style={{
                  backgroundColor:
                    props.currentScreen === ScreenNames.GOALS_SCREEN &&
                    theme.palette.background.main,
                  borderColor: theme.palette.gray.contrast,
                }}
                onClick={() => {
                  props.setCurrentScreen(ScreenNames.GOALS_SCREEN);
                  if (document.getElementById("goals-screen")) {
                    document.getElementById("goals-screen").focus();
                  }
                }}
              >
                <span>
                  <Badge
                    variant="standard"
                    color="error"
                    invisible={props.haveToSubmitYesterday ? false : true}
                    badgeContent="1"
                  >
                    {props.currentScreen === ScreenNames.GOALS_SCREEN ? (
                      <GoalsIcon
                        height={25}
                        width={25}
                        fill={theme.palette.primary.main}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    ) : (
                      <GoalsIcon
                        height={25}
                        width={25}
                        fill={theme.palette.gray.dark}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    )}
                  </Badge>
                  <h5
                    style={{
                      color:
                        props.currentScreen === ScreenNames.GOALS_SCREEN
                          ? theme.palette.primary.main
                          : theme.palette.gray.dark,
                    }}
                  >
                    Goals
                  </h5>
                </span>
              </li>
            </Link>

            <Link to={"/inbox"}>
              <li
                className={
                  props.currentScreen === ScreenNames.INBOX_SCREEN
                    ? "active"
                    : null
                }
                style={{
                  backgroundColor:
                    props.currentScreen === ScreenNames.INBOX_SCREEN &&
                    theme.palette.background.main,
                  borderColor: theme.palette.gray.contrast,
                }}
                onClick={() => {
                  props.setCurrentScreen(ScreenNames.INBOX_SCREEN);
                  if (document.getElementById("inbox-screen")) {
                    document.getElementById("inbox-screen").focus();
                  }
                }}
              >
                <span>
                  <span>
                    {props.currentScreen === ScreenNames.INBOX_SCREEN ? (
                      <InboxIcon
                        height={25}
                        width={25}
                        fill={theme.palette.primary.main}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    ) : (
                      <InboxIcon
                        height={25}
                        width={25}
                        fill={theme.palette.gray.dark}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    )}
                  </span>
                  <h5
                    style={{
                      color:
                        props.currentScreen === ScreenNames.INBOX_SCREEN
                          ? theme.palette.primary.main
                          : theme.palette.gray.dark,
                    }}
                  >
                    Undated
                  </h5>
                </span>
              </li>
            </Link>

            <Link to={"/targets"}>
              <li
                className={
                  props.currentScreen === ScreenNames.TARGETS_SCREEN
                    ? "active"
                    : null
                }
                style={{
                  backgroundColor:
                    props.currentScreen === ScreenNames.TARGETS_SCREEN &&
                    theme.palette.background.main,
                  borderColor: theme.palette.gray.contrast,
                }}
                onClick={() => {
                  props.setCurrentScreen(ScreenNames.TARGETS_SCREEN);
                  if (document.getElementById("targets-screen")) {
                    document.getElementById("targets-screen").focus();
                  }
                }}
              >
                <span>
                  <Badge
                    variant="standard"
                    color="error"
                    invisible={
                      props.haveToSubmitLastMonth && props.lastMonthHasTargets
                        ? false
                        : true
                    }
                    badgeContent={1}
                  >
                    {props.currentScreen === ScreenNames.TARGETS_SCREEN ? (
                      <TargetsIcon
                        height={25}
                        width={25}
                        fill={theme.palette.primary.main}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    ) : (
                      <TargetsIcon
                        height={25}
                        width={25}
                        fill={theme.palette.gray.dark}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    )}
                  </Badge>
                  <h5
                    style={{
                      color:
                        props.currentScreen === ScreenNames.TARGETS_SCREEN
                          ? theme.palette.primary.main
                          : theme.palette.gray.dark,
                    }}
                  >
                    Targets
                  </h5>
                </span>
              </li>
            </Link>

            <Link to={"/milestones"}>
              <li
                className={
                  props.currentScreen === ScreenNames.MILESTONES_SCREEN
                    ? "active"
                    : null
                }
                style={{
                  backgroundColor:
                    props.currentScreen === ScreenNames.MILESTONES_SCREEN &&
                    theme.palette.background.main,
                  borderColor: theme.palette.gray.contrast,
                }}
                onClick={() =>
                  props.setCurrentScreen(ScreenNames.MILESTONES_SCREEN)
                }
              >
                <span>
                  <Badge
                    variant="standard"
                    color="error"
                    invisible={props.unreadMilestones ? false : true}
                    badgeContent={props.unreadMilestones}
                  >
                    {props.currentScreen === ScreenNames.MILESTONES_SCREEN ? (
                      <MilestonesIcon
                        height={25}
                        width={25}
                        fill={theme.palette.primary.main}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    ) : (
                      <MilestonesIcon
                        height={25}
                        width={25}
                        fill={theme.palette.gray.dark}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    )}
                  </Badge>
                  <h5
                    style={{
                      color:
                        props.currentScreen === ScreenNames.MILESTONES_SCREEN
                          ? theme.palette.primary.main
                          : theme.palette.gray.dark,
                    }}
                  >
                    Milestones
                  </h5>
                </span>
              </li>
            </Link>

            {props.isShowChat &&
            props.currentScreen !== ScreenNames.CHAT_SCREEN ? (
              <Link to={"coach"}>
                <li
                  className="tab-active-device"
                  style={{
                    backgroundColor:
                      props.currentScreen === ScreenNames.CHAT_SCREEN &&
                      theme.palette.background.main,
                    borderColor: theme.palette.gray.contrast,
                  }}
                  onClick={() => {
                    props.setCurrentScreen(ScreenNames.CHAT_SCREEN);
                  }}
                >
                  <span>
                    <Badge
                      variant="standard"
                      color="error"
                      invisible={props.unreadCoachMessages ? false : true}
                      badgeContent={props.unreadCoachMessages}
                    >
                      <CoachIcon
                        height={25}
                        width={25}
                        fill={theme.palette.gray.dark}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    </Badge>
                    <h5
                      style={{
                        color:
                          props.currentScreen === ScreenNames.CHAT_SCREEN
                            ? theme.palette.primary.main
                            : theme.palette.gray.dark,
                      }}
                    >
                      Coach
                    </h5>
                  </span>
                </li>
              </Link>
            ) : (
              <Link to={"coach"}>
                <li
                  className="tab-device"
                  style={{
                    backgroundColor:
                      props.currentScreen === ScreenNames.CHAT_SCREEN &&
                      theme.palette.background.main,
                    borderColor: theme.palette.gray.contrast,
                  }}
                  onClick={() =>
                    props.setCurrentScreen(ScreenNames.CHAT_SCREEN)
                  }
                >
                  <span>
                    <Badge
                      variant="standard"
                      color="error"
                      invisible={props.unreadCoachMessages ? false : true}
                      badgeContent={props.unreadCoachMessages}
                    >
                      <CoachIcon
                        height={25}
                        width={25}
                        fill={theme.palette.primary.main}
                        style={{
                          marginRight: 12,
                        }}
                      />
                    </Badge>
                    <h5
                      style={{
                        color:
                          props.currentScreen === ScreenNames.CHAT_SCREEN
                            ? theme.palette.primary.main
                            : theme.palette.gray.dark,
                      }}
                    >
                      Coach
                    </h5>
                  </span>
                </li>
              </Link>
            )}
          </ul>
        </div>

        <Link to={"settings"}>
          <div
            className={
              props.currentScreen === ScreenNames.SETTINGS_SCREEN
                ? "setting-item active"
                : "setting-item"
            }
            style={{
              backgroundColor:
                props.currentScreen === ScreenNames.SETTINGS_SCREEN &&
                theme.palette.background.main,
            }}
            onClick={() => props.setCurrentScreen(ScreenNames.SETTINGS_SCREEN)}
          >
            <span>
              <span>
                {props.currentScreen === ScreenNames.SETTINGS_SCREEN ? (
                  <IoSettings
                    size={25}
                    style={{
                      marginRight: 20,
                      color: theme.palette.primary.main,
                    }}
                  />
                ) : (
                  <IoSettings
                    size={25}
                    style={{
                      marginRight: 20,
                      color: theme.palette.gray.dark,
                    }}
                  />
                )}
              </span>
              <label
                style={{
                  cursor: "pointer",
                  color:
                    props.currentScreen === ScreenNames.SETTINGS_SCREEN
                      ? theme.palette.primary.main
                      : theme.palette.gray.main,
                }}
              >
                Settings
              </label>
            </span>
          </div>
        </Link>
      </div>

      <div onClick={props.TogglSideNav} className="arrow-btn">
        <button
          className="btn-toggle"
          style={{ backgroundColor: theme.palette.toggleCollapseBtn.main }}
        >
          {props.isShow ? (
            <FaAngleLeft size={16} color={theme.palette.text.main} />
          ) : (
            <FaAngleRight size={16} color={theme.palette.text.main} />
          )}
        </button>
      </div>
    </div>
  );
};

export default React.memo(SideNav);
