import { firestore, auth } from "../firebase";
import { getTimestamp } from "../helper";

import { captureException } from "@sentry/react";

export const addFirstMilestone = (userUid) => {
  try {
    const milestone = {
      badge: "🌄",
      date: getTimestamp(),
      name: "Started your journey!",
    };

    // ---- Start transaction ----
    const batch = firestore.batch();

    const milestoneRef = firestore
      .collection("milestones")
      .doc(userUid)
      .collection("milestones")
      .doc();
    batch.set(milestoneRef, milestone, { merge: true });

    // Update milestonesTotal
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        milestonesTotal: 1,
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting milestone transaction", error);
      console.error("Error while commiting milestone transaction: " + error);
    });
  } catch (error) {
    captureException("Error while adding milestone", error);
    console.error("Error while adding milestone: " + error);
  }
};

export const markMilestonesAsRead = () => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    // Reset unreadMilestones
    const counterRef = firestore.collection("counters").doc(userUid);
    batch.set(
      counterRef,
      {
        unreadMilestones: 0,
      },
      { merge: true }
    );

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting markMilestonesAsRead transaction",
        error
      );
      console.error(
        "Error while commiting markMilestonesAsRead transaction: " + error
      );
    });
  } catch (error) {
    captureException("Error while markMilestonesAsRead", error);
    console.error("Error while markMilestonesAsRead: " + error);
  }
};
