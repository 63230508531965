import { createTheme } from "@mui/material";

export const lightTheme = createTheme({
  palette: {
    // Just colors
    primary: {
      main: "#6ab04c",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#4079e4",
      contrastText: "#FFFFFF",
    },
    tertiary: {
      main: "#fbce02",
    },
    quaternary: {
      main: "#f1f6f7",
      contrastText: "#898989",
    },
    light: {
      main: "#FFFFFF",
    },
    dark: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    gray: {
      main: "#ababab",
      dark: "#898989",
      light: "#cccccc",
      contrast: "#8C939F",
    },
    error: {
      main: "#eb4d4b",
      contrastText: "#FFFFFF",
    },
    contrast: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    hyperlinkReceived: {
      main: "#6ab04c",
    },
    hyperlinkSent: {
      main: "#6ab04c",
    },
    reactionContainer: {
      main: "#f1f6f7",
    },
    // Components
    text: {
      main: "#1c1c1e",
    },
    subtext: {
      main: "#808080",
    },
    card: {
      main: "#FFFFFF",
    },
    background: {
      main: "#FFFFFF",
      contrastText: "#FFFFFF",
    },
    secondaryBackground: {
      main: "#f3f3f3",
    },
    chatBackground: {
      main: "#ECE5DD",
    },
    messageBubbleSentBackground: {
      main: "#FFFFFF",
    },
    messageBubbleSentBackgroundOverWhite: {
      main: "#F2F2F2",
    },
    messageBubbleReceivedBackground: {
      main: "#FDF8E2",
    },
    toggleCollapseBtn: {
      main: "#FFFFFF",
    },
    reply: {
      main: "#4079e4",
    },
  },
  typography: {
    // fontFamily: "Quicksand",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          minWidth: 1,
          minHeight: 1,
          textTransform: "none",
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    // Just colors
    primary: {
      main: "#6ab04c",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#4079e4",
      contrastText: "#FFFFFF",
    },
    tertiary: {
      main: "#fbce02",
    },
    quaternary: {
      main: "#262D31",
      contrastText: "#000000",
    },
    light: {
      main: "#FFFFFF",
    },
    dark: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    gray: {
      main: "#ababab",
      dark: "#898989",
      light: "#cccccc",
      contrast: "#ababab",
    },
    error: {
      main: "#eb4d4b",
      contrastText: "#FFFFFF",
    },
    contrast: {
      main: "#FFFFFF",
    },
    hyperlinkReceived: {
      main: "#6ab04c",
    },
    hyperlinkSent: {
      main: "#5c2b92",
    },
    reactionContainer: {
      main: "#0D1418",
    },
    // Components
    text: {
      main: "#e5e5e7",
    },
    subtext: {
      main: "#808080",
    },
    card: {
      main: "#444950",
    },
    background: {
      main: "#0D1418",
      contrastText: "#FFFFFF",
    },
    secondaryBackground: {
      main: "#121212",
    },
    chatBackground: {
      main: "#000000",
    },
    messageBubbleSentBackground: {
      main: "#4079e4",
    },
    messageBubbleSentBackgroundOverWhite: {
      main: "#4079e4",
    },
    messageBubbleReceivedBackground: {
      main: "#262D31",
    },
    toggleCollapseBtn: {
      main: "#898989",
    },
    reply: {
      main: "#fbce02",
    },
  },
  typography: {
    // fontFamily: "Quicksand",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          minWidth: 1,
          minHeight: 1,
          textTransform: "none",
        },
      },
    },
  },
});
