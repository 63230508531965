import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { FaPen } from "react-icons/fa";
import { useTheme, CircularProgress, TextField } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";
import DeleteConfirmationOverlay from "../../../components/DeleteConfirmationOverlay";
import ManageProfilePictureOverlay from "./ManageProfilePictureOverlay";

import { updateUser } from "../../../api/firestore/users";

// Assets
import logo from "../../../assets/img/goalswon_logo.svg";

import { validateEmail } from "../../../api/helper";
import { uploadImage, getImagePath } from "../../../api/filesHandler";
import { Devices } from "../../../api/constants";
import "./style.scss";
import { captureException } from "@sentry/react";

const ProfileScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const currentDevice = useSelector((state) => state.local.currentDevice);
  const user = useSelector((state) => state.user.user);
  const [
    isDeleteProfilePicOverlayVisible,
    setIsDeleteProfilePicOverlayVisible,
  ] = useState(false);
  const [
    isManageProfilePictureOverlayVisible,
    setIsManageProfilePictureOverlayVisible,
  ] = useState(false);

  const [userName, setUserName] = useState(user && user.name);
  const [userContactEmail, setUserContactEmail] = useState(
    user?.contactEmail || user.email
  );

  const [isLoading, setIsLoading] = useState(false);

  // Navigation
  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const btnBackClicked = () => {
    navigate("/settings");
  };

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  // Images
  const handleImagesInputChange = async (e) => {
    setIsManageProfilePictureOverlayVisible(false);

    setIsLoading(true);
    try {
      const image = e.target.files[0];
      const uploadedImage = await uploadImage(image);
      const imagePath = await getImagePath(uploadedImage);
      updateUser({ avatar: imagePath, avatarFromCoach: imagePath });
    } catch (error) {
      captureException(`Error while uploading profile picture: ${error}`);
      alert(`Error while uploading images: ${error}`);
    }
    setIsLoading(false);
  };

  const handleDeleteProfilePic = () => {
    updateUser({ avatar: "" });
  };

  // Name and email
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    validateUserName(userName);
    validateUserEmail(userContactEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateUserName = (nameSet) => {
    if (nameSet === "") {
      setNameError("Name is required");
    } else {
      setNameError("");
    }
  };

  const validateUserEmail = (emailSet) => {
    const validatedEmail = validateEmail(emailSet);

    if (
      emailSet ===
      (user && user.supporterSettings && user.supporterSettings.email)
    ) {
      setEmailError("Their email cannot be the same as your contact email");
    } else {
      setEmailError(validatedEmail.message);
    }

    return validatedEmail.isValid;
  };

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateUserProperty = useCallback(
    debounce((property, value) => {
      updateUser({
        [property]: value,
      });
    }, 300),
    []
  );

  return (
    <>
      <div
        className="profile-container"
        style={{
          backgroundColor: theme.palette.background.main,
          width: currentDevice === Devices.DESKTOP && "50%",
        }}
      >
        <div className="profile-content-container">
          <ScreenHeader
            hasBtnBack
            handleBtnBackClicked={btnBackClicked}
            btnBackTitle="Settings"
            title="Profile"
          />
          <div className="content">
            <div className="image-handling-container">
              {isLoading ? (
                <CircularProgress color="primary" />
              ) : (
                <div className="profile-pic-upload-container">
                  {/* <div className="profile-pic-upload-container">
                    <label htmlFor="profile-pic-upload">
                      <div className="img-wrap">
                        <img
                          src={user?.avatar || logo}
                          className="img-preview"
                          alt="profile pic"
                        />
                        <div className="edit-image-section">
                          <FaPen size={11} color={theme.palette.light.main} />
                        </div>
                      </div>
                    </label>
                    <input
                      id="profile-pic-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImagesInputChange}
                    />
                  </div> */}
                  <div
                    className="img-wrap"
                    onClick={() => {
                      setIsManageProfilePictureOverlayVisible(true);
                    }}
                  >
                    <img
                      src={user?.avatar || logo}
                      className="img-preview"
                      alt="profile pic"
                    />
                    <div className="edit-image-section">
                      <FaPen size={11} color={theme.palette.light.main} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="data">
              <TextField
                id="userName"
                variant="outlined"
                color="primary"
                value={userName}
                error={nameError ? true : false}
                helperText={nameError}
                label="Name"
                fullWidth
                sx={{ marginBottom: 3 }}
                onChange={(e) => {
                  validateUserName(e.target.value);
                  setUserName(e.target.value);
                  updateUserProperty("name", e.target.value);
                }}
              />
              <TextField
                variant="outlined"
                color="primary"
                value={userContactEmail}
                error={emailError ? true : false}
                helperText={emailError}
                label="Email"
                fullWidth
                sx={{ marginBottom: 3 }}
                onChange={(e) => {
                  validateUserEmail(e.target.value);
                  setUserContactEmail(e.target.value);
                  updateUserProperty("contactEmail", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <ManageProfilePictureOverlay
        isVisible={isManageProfilePictureOverlayVisible}
        setIsVisible={setIsManageProfilePictureOverlayVisible}
        title="Manage profile picture"
        handleImagesInputChange={handleImagesInputChange}
        setIsDeleteProfilePicOverlayVisible={
          setIsDeleteProfilePicOverlayVisible
        }
      />

      <DeleteConfirmationOverlay
        isVisible={isDeleteProfilePicOverlayVisible}
        setIsVisible={setIsDeleteProfilePicOverlayVisible}
        title="Delete profile picture"
        subtitle="Are you sure you want to delete your profile picture?"
        handleConfirmation={handleDeleteProfilePic}
      />
    </>
  );
};

export default React.memo(ProfileScreen);
