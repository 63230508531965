import React, { useEffect, useState, forwardRef, useMemo } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  Checkbox,
  Divider,
} from "@mui/material";

import {
  addRecurrentGoal,
  updateRecurrentGoal,
  deleteRecurrentGoal,
} from "../../api/firestore/recurrentGoals";
import { addGoal, updateGoal } from "../../api/firestore/goals";
import { colors, lightTheme, recurrencePatternEnum } from "../../api/constants";

import "./style.scss";

const RepeatPatternPickerOverlay = (props) => {
  const theme = useTheme();

  // ------------------------ Initialize starting values for first render (retrieved from fs) ------------------------
  const repeatOptionsInitialization = [
    {
      id: 1,
      recurrencePattern: recurrencePatternEnum.NONE,
      showPattern: "None",
      date: null,
      isSelected: props.goal.recurrencePattern
        ? props.goal.recurrencePattern === recurrencePatternEnum.NONE
          ? true
          : false
        : true,
    },
    {
      id: 2,
      recurrencePattern: recurrencePatternEnum.DAILY,
      showPattern: "Daily",
      date: null,
      isSelected:
        props.goal.recurrencePattern === recurrencePatternEnum.DAILY
          ? true
          : false,
    },
    {
      id: 3,
      recurrencePattern: recurrencePatternEnum.WEEKLY,
      showPattern: "Weekly (eg- weekdays only or each Wed)",
      date: null,
      isSelected:
        props.goal.recurrencePattern === recurrencePatternEnum.WEEKLY
          ? true
          : false,
    },
    {
      id: 4,
      recurrencePattern: recurrencePatternEnum.MONTHLY,
      showPattern: "Monthly (every " + moment(props.date).format("Do") + ")",
      date: moment(props.date),
      isSelected:
        props.goal.recurrencePattern === recurrencePatternEnum.MONTHLY
          ? true
          : false,
    },
    {
      id: 5,
      recurrencePattern: recurrencePatternEnum.YEARLY,
      showPattern:
        "Yearly (every " + moment(props.date).format("MMMM Do") + ")",
      date: moment(props.date),
      isSelected:
        props.goal.recurrencePattern === recurrencePatternEnum.YEARLY
          ? true
          : false,
    },
  ];

  // ------------------------ Initialize starting values for first render (retrieved from fs) ------------------------
  const daysOfTheWeekInitialization = [
    {
      id: 7,
      day: "Sunday",
      firstLetter: "S",
      isSelected:
        props.goal.days &&
        props.goal.days.includes &&
        props.goal.days.includes(7)
          ? true
          : (!props.goal.recurrencePattern ||
              props.goal.recurrencePattern !== recurrencePatternEnum.WEEKLY) &&
            moment(props.goal.date).format("d") === "7"
          ? true
          : false,
    },
    {
      id: 1,
      day: "Monday",
      firstLetter: "M",
      isSelected:
        props.goal.days &&
        props.goal.days.includes &&
        props.goal.days.includes(1)
          ? true
          : (!props.goal.recurrencePattern ||
              props.goal.recurrencePattern !== recurrencePatternEnum.WEEKLY) &&
            moment(props.goal.date).format("d") === "1"
          ? true
          : false,
    },
    {
      id: 2,
      day: "Tuesday",
      firstLetter: "T",
      isSelected:
        props.goal.days &&
        props.goal.days.includes &&
        props.goal.days.includes(2)
          ? true
          : (!props.goal.recurrencePattern ||
              props.goal.recurrencePattern !== recurrencePatternEnum.WEEKLY) &&
            moment(props.goal.date).format("d") === "2"
          ? true
          : false,
    },
    {
      id: 3,
      day: "Wednesday",
      firstLetter: "W",
      isSelected:
        props.goal.days &&
        props.goal.days.includes &&
        props.goal.days.includes(3)
          ? true
          : (!props.goal.recurrencePattern ||
              props.goal.recurrencePattern !== recurrencePatternEnum.WEEKLY) &&
            moment(props.goal.date).format("d") === "3"
          ? true
          : false,
    },
    {
      id: 4,
      day: "Thursday",
      firstLetter: "T",
      isSelected:
        props.goal.days &&
        props.goal.days.includes &&
        props.goal.days.includes(4)
          ? true
          : (!props.goal.recurrencePattern ||
              props.goal.recurrencePattern !== recurrencePatternEnum.WEEKLY) &&
            moment(props.goal.date).format("d") === "4"
          ? true
          : false,
    },
    {
      id: 5,
      day: "Friday",
      firstLetter: "F",
      isSelected:
        props.goal.days &&
        props.goal.days.includes &&
        props.goal.days.includes(5)
          ? true
          : (!props.goal.recurrencePattern ||
              props.goal.recurrencePattern !== recurrencePatternEnum.WEEKLY) &&
            moment(props.goal.date).format("d") === "5"
          ? true
          : false,
    },
    {
      id: 6,
      day: "Saturday",
      firstLetter: "S",
      isSelected:
        props.goal.days &&
        props.goal.days.includes &&
        props.goal.days.includes(6)
          ? true
          : (!props.goal.recurrencePattern ||
              props.goal.recurrencePattern !== recurrencePatternEnum.WEEKLY) &&
            moment(props.goal.date).format("d") === "6"
          ? true
          : false,
    },
  ];

  //  ------------------------ Necessary consts for UI  ------------------------
  const [showWeeklyOption, setShowWeeklyOption] = useState(
    props.goal.recurrencePattern === recurrencePatternEnum.WEEKLY
  ); // Days to pick are shown
  const [showEnds, setShowEnds] = useState(
    props.goal.recurrencePattern &&
      props.goal.recurrencePattern !== recurrencePatternEnum.NONE
  );
  const [isRolledOver, setIsRolledOver] = useState(
    props.goal.isRolledOver !== undefined ? props.goal.isRolledOver : true
  ); // TODO - Check. True for old goals that don't have this setting or common goals

  const minimumDate = useMemo(() => new Date(moment().endOf("day")), []);

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      onClick={onClick}
      ref={ref}
      style={{
        backgroundColor: ends
          ? ends === ""
            ? colors.lightGray
            : lightTheme.colors.primary
          : colors.lightGray,
        borderColor: ends
          ? ends === ""
            ? colors.lightGray
            : lightTheme.colors.primary
          : colors.lightGray,
        color: ends
          ? ends === ""
            ? colors.light
            : colors.light
          : colors.darkGray,
      }}
    >
      {ends
        ? ends.toDate && moment(ends.toDate()).format("MMM D, Y")
        : moment(props.date).format("MMM D, Y")}
    </Button>
  ));

  //  ------------------------ Declare consts for recurrence patters, weekdays picker and ends, and set initial values  ------------------------
  const [repeatOptions, setRepeatOptions] = useState(
    repeatOptionsInitialization
  );
  const [daysOfTheWeek, setDaysOfTheWeek] = useState(
    daysOfTheWeekInitialization
  );
  const [ends, setEnds] = useState(props.goal.endDate);

  useEffect(() => {
    setRepeatOptions(repeatOptionsInitialization);
    setDaysOfTheWeek(daysOfTheWeekInitialization);
    setEnds(props.goal.endDate);
    setShowWeeklyOption(
      props.goal.recurrencePattern === recurrencePatternEnum.WEEKLY
    );
    setShowEnds(
      props.goal.recurrencePattern &&
        props.goal.recurrencePattern !== recurrencePatternEnum.NONE
    );
    setIsRolledOver(
      props.goal.isRolledOver !== undefined ? props.goal.isRolledOver : true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  // ------------------------ Functions to update ------------------------
  //////////Activates when a recurrence Pattern is checked, receives an entire object
  const onRecurrencePatternChange = (item) => {
    // Hide or show weekdays picker depending on recurrence pattern selected
    item.recurrencePattern === recurrencePatternEnum.WEEKLY
      ? setShowWeeklyOption(true)
      : setShowWeeklyOption(false);

    item.recurrencePattern !== recurrencePatternEnum.NONE
      ? setShowEnds(true)
      : setShowEnds(false);

    // Reset "isSelected" recurrence pattern of repeatOptions state for checkboxes and set "isSelected=true" the corresponding one
    setRepeatOptions(
      [...repeatOptions].map((el) => {
        if (el.id === item.id) {
          return {
            ...el,
            isSelected: true,
          };
        } else {
          return {
            ...el,
            isSelected: false,
          };
        }
      })
    );
  };

  //////////Activates when a day is checked or unchecked, receives an object
  const onWeeklyPatternChange = (item) => {
    // Set new daysOfTheWeek state
    setDaysOfTheWeek(
      [...daysOfTheWeek].map((el) => {
        if (el.id === item.id) {
          return {
            ...el,
            isSelected: !item.isSelected,
          };
        } else {
          return {
            ...el,
          };
        }
      })
    );
  };

  // //////////Activates When Ends is selected, receives a Date (empty when Never is chosen, moment when On is chosen, selectedDate when picked on DatePicker)
  const onEndsChange = (selectedDate) => {
    // Set new ends state (received moment obj)
    selectedDate ? setEnds(moment(selectedDate)) : setEnds(null);
  };

  const saveChanges = () => {
    const { id, date, isDone, ...newGoal } = props.goal;
    const repeatSelected = repeatOptions.find((el) => el.isSelected);

    if (
      repeatSelected.recurrencePattern === recurrencePatternEnum.WEEKLY &&
      !daysOfTheWeek.filter((day) => day.isSelected).length
    ) {
      toast.error("You must select at least one day!");
    } else {
      if (repeatSelected.recurrencePattern !== recurrencePatternEnum.NONE) {
        const daysSelected = daysOfTheWeek.reduce((result, el) => {
          el.isSelected && result.push(el.id);
          return result;
        }, []);

        if (props.goal.fromRecurrentGoal) {
          // If goal is instantiated from previous recurrent > Update referenced recurrent
          updateRecurrentGoal(
            {
              ...newGoal,
              startDate: date,
              fromRecurrentGoal: null,
              recurrencePattern: repeatSelected.recurrencePattern,
              days: daysSelected,
              endDate: ends || null,
              isRolledOver: isRolledOver,
            },
            newGoal.fromRecurrentGoal
          );
        } else {
          // If goal is instantiated and was never recurrent > Create recurrent to reference to
          addRecurrentGoal(
            {
              ...newGoal,
              startDate: props.date,
              recurrencePattern: repeatSelected.recurrencePattern,
              days: daysSelected,
              endDate: ends || null,
              isRolledOver: isRolledOver,
            },
            id
          );
        }

        if (props.goal.date) {
          // If goal is instantiated > Update goal
          updateGoal({
            ...props.goal,
            fromRecurrentGoal: newGoal.fromRecurrentGoal || id,
            recurrencePattern: repeatSelected.recurrencePattern,
            days: daysSelected,
            startDate: date,
            endDate: ends || null,
            isRolledOver: isRolledOver,
          });
        }

        // Update goal in edit screen UI too
        props.setGoal({
          ...props.goal,
          fromRecurrentGoal: newGoal.fromRecurrentGoal || id,
          recurrencePattern: repeatSelected.recurrencePattern,
          days: daysSelected,
          startDate: props.goal.fromRecurrentGoal ? date : props.date,
          endDate: ends || null,
          isRolledOver: isRolledOver,
        });
      } else {
        // Reason for conditional: if goal received is a recurrent non instantiated, it would be updating a goal that does not exist
        if (props.goal.date) {
          // If goal exists
          updateGoal({
            ...props.goal,
            fromRecurrentGoal: null,
            startDate: null,
            recurrencePattern: null,
            days: [],
            endDate: ends || null,
            isRolledOver: isRolledOver,
          });
        } else {
          // If goal does NOT exist
          addGoal({
            date: props.date,
            description: props.goal.description,
            isDone: props.goal.isDone,
            name: props.goal.name,
            position: props.goal.position,
            tag: props.goal.tag,
            isRolledOver: isRolledOver,
          });
        }

        // This applies from "this and following" logic
        if (moment(props.goal.startDate).isBefore(moment(props.date), "day")) {
          // If goal being deleted starts before date selected, just update its endDate to keep previous goals
          updateRecurrentGoal({
            ...props.goal,
            endDate: moment(props.date).subtract(1, "days"),
          });
        } else {
          // If goal being deleted has same startDate as date selected, it means that it doesn't have past goals, so just delete it
          deleteRecurrentGoal(props.goal?.fromRecurrentGoal || props.goal.id); // Beware it can be a goal instantiated from recurrent or a recurrent goal
        }

        // Update goal in edit screen UI too
        props.setGoal({
          ...props.goal,
          fromRecurrentGoal: null,
          startDate: null,
          recurrencePattern: null,
          days: [],
          endDate: ends || null,
          isRolledOver: isRolledOver,
        });
      }

      props.setIsVisible(false);
    }
  };

  // ------------------------ Render  ------------------------
  return (
    <Dialog
      open={props.isVisible}
      onClose={() => props.setIsVisible(false)}
      TransitionComponent={Slide}
      fullWidth
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">Repeat</label>
      </DialogTitle>
      <DialogContent>
        <div>
          {/* ------------------------ Repeating options section ------------------------ */}
          <div>
            {repeatOptions.map((option) => (
              <div key={`repeatOptionContainer-${option.id}`}>
                <div className="repeat-options-container">
                  <Checkbox
                    color="primary"
                    icon={<FaRegCircle size={22} />}
                    checkedIcon={<FaCheckCircle size={22} />}
                    checked={option.isSelected}
                    onChange={() => {
                      onRecurrencePatternChange(option);
                    }}
                  />
                  <label
                    className="repeat-option-text"
                    onClick={() => {
                      onRecurrencePatternChange(option);
                    }}
                  >
                    {option.showPattern}
                  </label>
                </div>
                {/* Days of the week */}
                {option.recurrencePattern === recurrencePatternEnum.WEEKLY &&
                  showWeeklyOption && (
                    <div className="days-section">
                      {daysOfTheWeek.map((day) => (
                        <div
                          key={`dayOfWeek-${day.id}`}
                          className="day-container"
                        >
                          <Checkbox
                            color="primary"
                            icon={<FaRegCircle size={22} />}
                            checkedIcon={<FaCheckCircle size={22} />}
                            checked={day.isSelected}
                            onChange={() => {
                              onWeeklyPatternChange(day);
                            }}
                          />
                          <label
                            onClick={() => {
                              onWeeklyPatternChange(day);
                            }}
                            className="repeat-day-text"
                          >
                            {day.firstLetter}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            ))}
          </div>

          {/* ------------------------ Roll over until complete ------------------------ */}
          {repeatOptions.find(
            (option) =>
              option.recurrencePattern === recurrencePatternEnum.DAILY &&
              option.isSelected !== true
          ) ? (
            <div className="roll-over-container">
              <Checkbox
                color="primary"
                checked={isRolledOver}
                onChange={() => setIsRolledOver(!isRolledOver)}
              />
              <label>Roll over until complete</label>
            </div>
          ) : null}

          {/* ------------------------ Ends section ------------------------ */}
          {showEnds && (
            <div className="ends-container">
              <label className="ends-title">Ends</label>
              {/* Never */}
              <div className="ends-option">
                <Checkbox
                  color="primary"
                  icon={<FaRegCircle size={22} />}
                  checkedIcon={<FaCheckCircle size={22} />}
                  checked={!ends}
                  onChange={() => {
                    onEndsChange("");
                  }}
                />
                <label
                  className="ends-option-text"
                  onClick={() => {
                    onEndsChange("");
                  }}
                >
                  Never
                </label>
              </div>
              {/* Sometime */}
              <div className="ends-option">
                <Checkbox
                  color="primary"
                  icon={<FaRegCircle size={22} />}
                  checkedIcon={<FaCheckCircle size={22} />}
                  checked={!!ends}
                  onChange={() => {
                    onEndsChange(moment(props.date)); // Same day by default
                  }}
                />
                <label
                  className="ends-option-text"
                  onClick={() => {
                    onEndsChange(moment(props.date)); // Same day by default
                  }}
                >
                  On
                </label>
                <div className="custom-ends-picker-container">
                  <DatePicker
                    selected={
                      ends
                        ? ends.toDate && ends.toDate()
                        : moment(props.date).toDate()
                    }
                    onChange={(date) => {
                      onEndsChange(date);
                    }}
                    customInput={<CustomDateInput />}
                    disabled={!ends}
                    minDate={minimumDate}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </DialogContent>

      <Divider />

      <div className="recurrence-pattern-picker-modal-footer">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => {
            props.setIsVisible(false);
          }}
        >
          Cancel
        </Button>

        <Divider
          className="divider"
          sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
          orientation="vertical"
          flexItem
        />

        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="primary"
          onClick={() => {
            saveChanges();
          }}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(RepeatPatternPickerOverlay);
