import { firestore } from "../firebase";
import { updateUser } from "./users";

import { captureException } from "@sentry/react";

export const getCoach = async (coachId) => {
  try {
    return await firestore
      .collection("coaches")
      .doc(coachId)
      .get()
      .then(async (query) => {
        const coach = {
          ...query.data(),
          id: query.id, // References the coach UID
        };

        return coach;
      });
  } catch (error) {
    captureException("Failed to get coach", error);
    console.error("Failed to get coach: " + error);
  }
};

export const updateCoach = (coachId, properties) => {
  try {
    // ---- Start transaction ----
    const batch = firestore.batch();

    const coachRef = firestore.collection("coaches").doc(coachId);
    batch.set(coachRef, properties, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException("Error while commiting coach transaction", error);
      console.error("Error while commiting coach transaction: " + error);
    });
  } catch (error) {
    captureException("Error while updating coach", error);
    console.error("Error while updating coach: " + error);
  }
};

export const writeCoachNotes = async (clientEmail) => {
  try {
    const querySnapshot = await firestore
      .collection("quizzes")
      .where("email", "==", clientEmail)
      .limit(1)
      .get();

    if (!querySnapshot.empty) {
      const quizData = querySnapshot.docs[0].data();

      const coachNotesString = Object.entries(quizData)
        .map(([key, value]) => {
          return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`;
        })
        .join("\n");

      updateUser({
        coachNotes: coachNotesString,
      });
    }
  } catch (error) {
    captureException("Failed to write coach notes", error);
    console.error("Failed to write coach notes: " + error);
  }
};
