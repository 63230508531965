import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import { FiCalendar } from "react-icons/fi";
import { useTheme, Divider, TextField, Tooltip } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

import { tagColors, Devices } from "../../../api/constants";
import "./style.scss";

const ViewTargetScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  const target = {
    ...location.state.goalToEdit,
    ...(location.state.goalToEdit.date && {
      date: moment(location.state.goalToEdit.date)
        .utc()
        .utcOffset(moment().utcOffset(), true),
    }), // is received as moment.toString() in utc and converted back into moment with the corresponding offset
    ...(location.state.goalToEdit.createdAt && {
      createdAt: moment(location.state.goalToEdit.createdAt),
    }), // is received as "date" and converted back into moment
    ...(location.state.goalToEdit.updatedAt && {
      updatedAt: moment(location.state.goalToEdit.updatedAt),
    }), // is received as "date" and converted back into moment
  };
  const tag = target.tag;
  const name = target.name;
  const description = target.description;
  const date = moment(location.state.date); // date is received as "date" and converted to moment obj

  const btnBackClicked = () => {
    navigate("/targets", {
      state: {
        date: date.toDate(),
      },
    });
  };

  return (
    <div
      className="target-preview-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        height: currentDevice !== Devices.PHONE && "100vh",
        overflowY: currentDevice !== "PHONE" && "scroll",
      }}
    >
      <div className="target-preview-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Targets"
          title="Target Preview"
        />

        <Divider />

        <div className="body">
          <div className="edit-btns">
            <div className="edit-btns-first-section">
              <Tooltip title="Month">
                <button
                  className="btn-edit"
                  onClick={() =>
                    toast.error(
                      "You can't change the month of already achieved targets"
                    )
                  }
                >
                  <FiCalendar style={{ color: theme.palette.gray.dark }} />
                  <label
                    className="btn-edit-date"
                    style={{ color: theme.palette.gray.dark }}
                  >
                    {date
                      ? moment(date).year() === moment().year()
                        ? moment(date).format("MMMM")
                        : moment(date).format("MMMM, Y")
                      : "No month"}
                  </label>
                </button>
              </Tooltip>
            </div>
          </div>

          <Divider />

          <div className="title-container">
            {tag ? (
              <div
                className="target-tag"
                style={{
                  backgroundColor: tag
                    ? tagColors[tag - 1].color
                    : "transparent",
                }}
              />
            ) : null}

            <TextField
              type="text"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  display: "flex",
                  color: theme.palette.text.main,
                  fontSize: 20,
                  fontWeight: "bold",
                },
              }}
              inputProps={{
                maxLength: 140,
              }}
              fullWidth
              autoComplete="off"
              multiline
              id="targetNameInput"
              placeholder="Add target name..."
              value={name}
              className="target-name"
              style={{
                paddingLeft: tag ? 10 : 0,
                color: theme.palette.text.main,
              }}
              disabled
            />
          </div>

          <Divider />

          <div className="target-description-section">
            <label
              className="target-description-title"
              style={{ color: theme.palette.text.main }}
            >
              Target description
            </label>
            <TextField
              type="text"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: { color: theme.palette.text.main },
              }}
              sx={{ paddingTop: 1.5 }}
              fullWidth
              autoComplete="off"
              multiline
              minRows={10}
              id="targetDescriptionInput"
              placeholder="When, where and how will you get this done - and any reward once completed?"
              value={description}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ViewTargetScreen);
