import firebase from "firebase/compat/app";
import { firestore, auth } from "../../api/firebase";

import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment-timezone";
import { FaImages } from "react-icons/fa";
import { debounce } from "lodash";
import { captureException } from "@sentry/react";

import {
  useTheme,
  Dialog,
  DialogContent,
  CircularProgress,
  Slide,
  IconButton,
  TextField,
  Button,
  Divider,
} from "@mui/material";

// Firestore
import {
  addDay,
  updateDay,
  isDayCreated,
  isDaySubmitted,
} from "../../api/firestore/days";
import { updateUser, updateLastTimeActive } from "../../api/firestore/users";

// Analytics
import { analytics } from "../../api/firebase";

// Assets
import submit_all_ticked from "../../assets/sounds/submit_all_ticked.wav";
import submit from "../../assets/sounds/submit.wav";

// Own api
import { getTimestamp } from "../../api/helper";
import { getImagePath, uploadImage, playSound } from "../../api/filesHandler";
import { recurrencePatternEnum } from "../../api/constants";
import "./style.scss";

const SubmitDayOverlay = (props) => {
  const theme = useTheme();

  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );
  const isSortByIsDone = useSelector((state) => state.local.isSortByIsDone);
  const isSortBy = useSelector((state) => state.local.isSortBy);
  const isOrderBy = useSelector((state) => state.local.isOrderBy);
  const isMoodButtonsMandatory = useSelector(
    (state) => state.local.isMoodButtonsMandatory
  );

  const [clientComment, setClientComment] = useState(props.clientComment);
  const [subjectiveWellBeingScale, setSubjectiveWellBeingScale] = useState(
    props.subjectiveWellBeingScale || null
  );
  const [imagesToSubmit, setImagesToSubmit] = useState([]);
  const [imagesToPreview, setImagesToPreview] = useState([]);
  const [oldSubmissionImages, setOldSubmissionImages] = useState(
    props.dayImages || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isMoodButtonsAlertVisible, setIsMoodButtonsAlertVisible] =
    useState(false);

  useEffect(() => {
    setIsMoodButtonsAlertVisible(false);
  }, [props.isVisible]);

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateClientComment = useCallback(
    debounce((value) => {
      updateDay(props.date, {
        clientComment: value,
      });
    }, 300),
    []
  );

  const handleSubmit = useCallback(async () => {
    if (!subjectiveWellBeingScale && isMoodButtonsMandatory) {
      setIsMoodButtonsAlertVisible(true);
    } else {
      setIsLoading(true);

      // Start transaction
      const batch = firestore.batch();

      const userUid = auth.currentUser.uid;

      let goalsTotal = 0;
      let goalsDone = 0;
      let unreadMessagesCount = 0;

      let message = `Results of ${moment(props.date).format(
        "ddd MMM D, Y"
      )} \n`;

      const movedGoals = [];

      const moveUndoneGoals = async () => {
        if (props.goals?.length) {
          await Promise.all(
            props.goals
              // Instantiate sorting variables in case they don't exist (might cause app crash)
              .map((el) => ({
                ...el,
                name: el.name || "",
                isDone: !!el.isDone, // If isDone is undefined (non-instantiated recurrent goals) '!' initializes it to true, and the other '!' to false
                isPartiallyDone: !!el.isPartiallyDone, // If isPartiallyDone is undefined (non-instantiated recurrent goals) '!' initializes it to true, and the other '!' to false
                tag: el.tag || "",
                position: el.position || 0,
              }))
              // Sort goals before sending automessage
              .sort(
                (a, b) =>
                  // First. Sort by done if set
                  (isSortByIsDone === "ASC"
                    ? b["isDone"]
                        .toString()
                        .localeCompare(a["isDone"].toString(), undefined, {
                          numeric: true,
                        })
                    : isSortByIsDone === "DESC"
                    ? a["isDone"]
                        .toString()
                        .localeCompare(b["isDone"].toString(), undefined, {
                          numeric: true,
                        })
                    : null) ||
                  // Then. Sort by isPartiallyDone
                  (isSortByIsDone === "ASC"
                    ? b["isPartiallyDone"]
                        .toString()
                        .localeCompare(
                          a["isPartiallyDone"].toString(),
                          undefined,
                          {
                            numeric: true,
                          }
                        )
                    : isSortByIsDone === "DESC"
                    ? a["isPartiallyDone"]
                        .toString()
                        .localeCompare(
                          b["isPartiallyDone"].toString(),
                          undefined,
                          {
                            numeric: true,
                          }
                        )
                    : null) ||
                  // Then, client's custom sort and order (created time (position), or name, or color (tag))
                  (isOrderBy === "ASC"
                    ? a[isSortBy || "name"]
                        .toString()
                        .localeCompare(
                          b[isSortBy || "name"].toString(),
                          undefined,
                          {
                            numeric: true,
                          }
                        )
                    : b[isSortBy || "name"]
                        .toString()
                        .localeCompare(
                          a[isSortBy || "name"].toString(),
                          undefined,
                          {
                            numeric: true,
                          }
                        )) ||
                  // Last, sort by name ASC if sort by name is not selected already
                  (isSortBy !== "name"
                    ? a["name"]
                        .toString()
                        .localeCompare(b["name"].toString(), undefined, {
                          numeric: true,
                        })
                    : null)
              )
              // Iterate all sorted goals to write the automessage
              .map(async (item) => {
                // Increment goals total and goals done
                goalsTotal++;
                if (!!item.isDone) {
                  goalsDone++;
                }

                // Create message
                message += `\n${
                  item.isPartiallyDone ? "⏳" : item.isDone ? "✅ " : "❌ "
                } ${item.name || "(No title)"}`;
                //  ${
                //   item?.originalDate
                //     ? moment(item.originalDate).format("MMM D")
                //     : ""
                // }`

                // If goal is not done
                if (!item.isDone) {
                  let dayToRollOverGoalsTo;

                  const isToday = moment(props.date).isSame(moment(), "day");

                  if (isToday) {
                    // If today is being submitted, roll over goals to tomorrow
                    dayToRollOverGoalsTo = moment().add(1, "days");
                  } else {
                    // If another day is being submitted, check:
                    // 1) If today is not submitted, roll over goals to today.
                    // 2) If today is already submitted, roll over goals to tomorrow.
                    const isTodaySubmitted = await isDaySubmitted();
                    dayToRollOverGoalsTo = moment().add(
                      isTodaySubmitted ? 1 : 0,
                      "days"
                    );
                  }

                  // Create day to which goals will move to, if it's not created already.
                  const isDayToRollOverGoalsToCreated = await isDayCreated(
                    dayToRollOverGoalsTo
                  );
                  if (!isDayToRollOverGoalsToCreated) {
                    addDay(dayToRollOverGoalsTo);
                  }

                  // Instantiate recurrent goal in day being submitted
                  if (!item.date) {
                    const { id, originalGoalId, ...goalToMove } = item; // Destructuring to remove all the fields we don't want to add

                    const newMovedGoalRef = firestore
                      .collection("goals")
                      .doc(userUid)
                      .collection("goals")
                      .doc();

                    batch.set(newMovedGoalRef, {
                      ...goalToMove,
                      date: getTimestamp(
                        moment(props.date).startOf("day").utcOffset(0, true)
                      ),
                      ...(item?.startDate && {
                        startDate: getTimestamp(
                          moment(item.startDate)
                            .startOf("day")
                            .utcOffset(0, true)
                        ),
                      }),
                      ...(item?.endDate && {
                        endDate: getTimestamp(
                          moment(item.endDate).endOf("day").utcOffset(0, true)
                        ),
                      }),
                      fromRecurrentGoal: item.id,
                      isDone: false,
                    });
                  }

                  // Move goal if:
                  // - should be rolled over (isRolledOver=true or is old and doesn't have isRolledOver field)
                  // - doesn't come from recurrent
                  // - is not daily
                  // - is weekly/monthly/yearly and is not due the day to roll over to
                  if (
                    (item.isRolledOver === true ||
                      item.isRolledOver === undefined) &&
                    (!item.recurrencePattern ||
                      (item.recurrencePattern !== recurrencePatternEnum.DAILY &&
                        ((item?.recurrencePattern ===
                          recurrencePatternEnum.WEEKLY &&
                          !item?.days.includes(
                            moment(dayToRollOverGoalsTo).isoWeekday()
                          )) ||
                          (item?.recurrencePattern ===
                            recurrencePatternEnum.MONTHLY &&
                            !(
                              moment(item.startDate.toDate()).date() ===
                              moment(dayToRollOverGoalsTo).date()
                            )) ||
                          (item?.recurrencePattern ===
                            recurrencePatternEnum.YEARLY &&
                            !(
                              moment(item.startDate.toDate()).date() ===
                                moment(dayToRollOverGoalsTo).date() &&
                              moment(item.startDate.toDate()).month() ===
                                moment(dayToRollOverGoalsTo).month()
                            )))))
                  ) {
                    // Destructuring to remove all the fields we don't want to add
                    const {
                      days,
                      endDate,
                      fromRecurrentGoal,
                      recurrencePattern,
                      startDate,
                      originalGoalId,
                      ...goalToMove
                    } = item;

                    // Move repeatTilDone goals to today
                    const newMovedGoalRef = firestore
                      .collection("goals")
                      .doc(userUid)
                      .collection("goals")
                      .doc();

                    batch.set(newMovedGoalRef, {
                      ...goalToMove,
                      id: item.originalGoalId || item.id, // item.originalGoalId if goal is copy of original (rolled more than once). Or item.id if goal IS the original (first time rolled)
                      date: getTimestamp(
                        moment(dayToRollOverGoalsTo)
                          .startOf("day")
                          .utcOffset(0, true)
                      ),
                      originalDate: item?.originalDate
                        ? getTimestamp(
                            moment(item.originalDate).utcOffset(0, true)
                          )
                        : getTimestamp(
                            moment(props.date).endOf("day").utcOffset(0, true)
                          ),
                    });

                    movedGoals.push(newMovedGoalRef?.id);

                    // Set lastTimeUsedPartiallyDoneGoal for analytics
                    if (item.isPartiallyDone) {
                      updateUser({
                        lastTimeUsedPartiallyDoneGoals: moment(),
                      });
                    }
                  }
                }
              })
          );
        }
      };

      await moveUndoneGoals();

      if (clientComment) {
        message += `\n\n${clientComment}`;
      }

      const messageRef = firestore
        .collection("chats")
        .doc(userUid)
        .collection("messages")
        .doc();

      batch.set(messageRef, {
        sentAt: firebase.firestore.FieldValue.serverTimestamp(),
        sentBy: userUid,
        text: message,
        isDailyResults: getTimestamp(
          moment(props.date).startOf("day").utcOffset(0, true)
        ),
        subjectiveWellBeingScale: subjectiveWellBeingScale || null,
      });

      unreadMessagesCount++;

      try {
        const uploadedImages = [];

        for (let image of imagesToSubmit) {
          const uploadedImage = await uploadImage(image);
          uploadedImages.push(uploadedImage);
        }

        const imagesPath = [];
        for (let image of uploadedImages) {
          const path = await getImagePath(image);
          imagesPath.push(path);
        }
        for (let image of oldSubmissionImages) {
          const path = image;
          imagesPath.push(path);
        }

        const imagesId = [];
        for (let image of imagesPath) {
          const imageMessageRef = firestore
            .collection("chats")
            .doc(userUid)
            .collection("messages")
            .doc();

          batch.set(imageMessageRef, {
            sentAt: firebase.firestore.FieldValue.serverTimestamp(),
            sentBy: userUid,
            image,
            isDailyResults: getTimestamp(
              moment(props.date).startOf("day").utcOffset(0, true)
            ),
          });

          unreadMessagesCount++;

          imagesId.push(imageMessageRef?.id);
        }

        // Update unreadClientMessages
        const counterRef = firestore.collection("counters").doc(userUid);
        batch.set(
          counterRef,
          {
            unreadClientMessages:
              firebase.firestore.FieldValue.increment(unreadMessagesCount),
          },
          { merge: true }
        );

        // Update day
        batch.set(
          firestore.doc(
            `days/${userUid}/days/${moment(props.date).format("YYYYMMDD")}`
          ),
          {
            isSubmitted: true,
            date: getTimestamp(
              moment(props.date).startOf("day").utcOffset(0, true)
            ),
            images: imagesPath,
            clientComment,
            subjectiveWellBeingScale: subjectiveWellBeingScale || null,
            messageId: messageRef?.id,
            imagesId,
            movedGoals,
            completion: parseFloat(((goalsDone * 100) / goalsTotal).toFixed(2)),
            lastSubmissionType: "manual",
          },
          { merge: true }
        );

        await batch.commit();

        updateLastTimeActive();

        if (isSoundEffectsEnabled) {
          if (props.progress === 100) {
            playSound(submit_all_ticked);
          } else {
            playSound(submit);
          }
        }

        analytics.logEvent("day_manual_submission", {
          completion: parseFloat(((goalsDone * 100) / goalsTotal).toFixed(2)),
        });

        console.log("Day manual submit completed.");
      } catch (error) {
        captureException(
          `Error while uploading images for manual day submission: ${error}`
        );
        alert(`Error while uploading images: ${error}`);
      }

      setIsLoading(false);
      props.setAllowNavigation(true);
      props.setIsVisible(false);
    }
  }, [
    clientComment,
    imagesToSubmit,
    isMoodButtonsMandatory,
    isOrderBy,
    isSortBy,
    isSortByIsDone,
    isSoundEffectsEnabled,
    oldSubmissionImages,
    subjectiveWellBeingScale,
    props.date,
    props.goals,
    props.goals?.length,
    props.progress,
    props.setAllowNavigation,
    props.setIsVisible,
  ]);

  // ==================================== IMAGE HANDLING ====================================
  const handleImagesInputChange = (e) => {
    var imageArray = e.target.files;
    let temp = [...imagesToSubmit, ...imageArray]; // Add new whole image file to array of images (commentPhotos)
    if (temp.length > 8) {
      alert("Up to 8 photos are allowed");
    } else {
      setImagesToSubmit(temp); // Set array of images
    }
    handleImagesToPreview(temp);
  };

  const handleImagesToPreview = (imagesArray) => {
    let newImagesToPreview = [];
    imagesArray.map((img) => {
      newImagesToPreview.push(URL.createObjectURL(img));
    });
    setImagesToPreview(newImagesToPreview);
  };

  const handleRemoveImage = (index) => {
    let preview = [...imagesToPreview];
    let images = [...imagesToSubmit];
    preview.splice(index, 1);
    images.splice(index, 1);
    setImagesToPreview(preview);
    setImagesToSubmit(images);
  };

  const handleRemoveOldSubmissionImage = (index) => {
    let photos = [...oldSubmissionImages];
    photos.splice(index, 1);
    setOldSubmissionImages(photos);
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => {
        props.setAllowNavigation(true);
        props.setIsVisible(false);
      }}
      TransitionComponent={Slide}
      fullWidth
      className="submit-day-overlay-container"
    >
      {/* <DialogTitle className="modal-title">
        <label className="modal-title-label">Submit day</label>
      </DialogTitle>
      <Box position="absolute" top={15} right={15}>
        <IconButton
          onClick={() => {
            props.setAllowNavigation(true);
            props.setIsVisible(false);
          }}
        >
          <FaTimes />
        </IconButton>
      </Box> */}
      <DialogContent>
        <div className="comment-container">
          <TextField
            type="text"
            rows={10}
            id="submitDayInput"
            name="goalNameInput"
            placeholder={props.customSubmitMessage || "How did the day go?"}
            value={clientComment}
            onChange={(e) => {
              setClientComment(e.target.value);
              updateClientComment(e.target.value);
            }}
            maxLength={140}
            multiline
            autoComplete="off"
            fullWidth
            autoFocus
          />
        </div>

        {(imagesToSubmit.length > 0 || oldSubmissionImages.length > 0) && (
          <div className="images-preview-container">
            {imagesToPreview.map((img, index) => (
              <div key={`image-${index}`} className="img-wrap">
                <img
                  src={img}
                  className="image-preview"
                  alt="preview"
                  height="100"
                  width="100"
                />
                <button
                  className="close-preview-img"
                  onClick={() => handleRemoveImage(index)}
                >
                  &times;
                </button>
              </div>
            ))}
            {oldSubmissionImages.map((img, index) => (
              <div key={`old-submission-image-${index}`} className="img-wrap">
                <img
                  src={img}
                  className="image-preview"
                  alt="preview"
                  height="100"
                  width="100"
                />
                <button
                  className="close-preview-img"
                  onClick={() => handleRemoveOldSubmissionImage(index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Old way with text */}
        {/* {imagesToSubmit.length > 0 && (
          <div className="images-preview-container">
            {imagesToSubmit.map((item, index) => (
              <button
                key={`preview-${index}`}
                className="btn-image-container"
                onClick={() => onImageDelete(item.index)}
              >
                <label className="img-preview-label">{item.name}</label>
                <FaRegTimesCircle />
                <Image
                  id={`preview-${index}`}
                  source={item.currentSource}
                  className="image"
                />
              </button>
            ))}
          </div>
        )} */}

        <div className="btn-add-img-container">
          {imagesToSubmit.length < 8 ? (
            <div
              className="btn-add-image"
              style={{
                color: theme.palette.gray.dark,
                backgroundColor: theme.palette.gray.light,
              }}
            >
              <label htmlFor="submit-day-files-upload">
                <FaImages className="btn-add-image-icon" size={26} />
              </label>
              <input
                id="submit-day-files-upload"
                type="file"
                multiple
                accept="image/*"
                onChange={handleImagesInputChange}
              />
            </div>
          ) : (
            <label
              className="images-limit-reached"
              style={{ backgroundColor: theme.palette.gray.light }}
            >
              8/8
            </label>
          )}
        </div>

        {isMoodButtonsAlertVisible ? (
          <label
            className="subjective-well-being-alert"
            style={{ color: theme.palette.text.main }}
          >
            Pick an emoji to rate your mood first!
          </label>
        ) : null}

        <div className="subjective-well-being-scale">
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 1 ? null : 1
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 1 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 1 ? 35 : 30,
              }}
            >
              😭
            </label>
          </IconButton>
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 2 ? null : 2
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 2 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 2 ? 35 : 30,
              }}
            >
              🙁
            </label>
          </IconButton>
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 3 ? null : 3
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 3 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 3 ? 35 : 30,
              }}
            >
              😐
            </label>
          </IconButton>
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 4 ? null : 4
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 4 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 4 ? 35 : 30,
              }}
            >
              🙂
            </label>
          </IconButton>
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 5 ? null : 5
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 5 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 5 ? 35 : 30,
              }}
            >
              😀
            </label>
          </IconButton>
        </div>
      </DialogContent>

      <Divider />

      <div className="submit-day-modal-footer">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => {
            props.setAllowNavigation(true);
            props.setIsVisible(false);
          }}
        >
          Cancel
        </Button>

        <Divider
          className="divider"
          sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
          orientation="vertical"
          flexItem
        />

        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="primary"
          onClick={handleSubmit}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(SubmitDayOverlay);
