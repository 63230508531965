import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { useTheme, Switch } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

import { goalCompleteSound, soundEffects } from "../../../redux/actions/local";
import { updateUser } from "../../../api/firestore/users";

import { Devices } from "../../../api/constants";
import "./style.scss";

const SoundsSettings = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // -------------------------------- Redux -------------------------------- //
  const currentDevice = useSelector((state) => state.local.currentDevice);
  const reduxIsGoalCompleteSoundEnabled = useSelector(
    (state) => state.local.isGoalCompleteSoundEnabled
  );
  // const reduxIsChatMessagesSoundEnabled = useSelector(
  //   state => state.local.isChatMessagesSoundEnabled,
  // );
  const reduxIsSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );

  // -------------------------------- States -------------------------------- //
  const [isGoalCompleteSoundEnabled, setIsGoalCompleteSoundEnabled] = useState(
    reduxIsGoalCompleteSoundEnabled
  );
  // const [isChatMessagesSoundEnabled, setIsChatMessagesSoundEnabled] = useState(
  //   reduxIsChatMessagesSoundEnabled,
  // );
  const [isSoundEffectsEnabled, setIsSoundEffectsEnabled] = useState(
    reduxIsSoundEffectsEnabled
  );

  // -------------------------------- Functions -------------------------------- //
  const toggleGoalCompleteSoundEnabled = () => {
    setIsGoalCompleteSoundEnabled(!isGoalCompleteSoundEnabled);
    updateGoalCompleteSoundEnabled(!isGoalCompleteSoundEnabled);
  };

  // const toggleChatMessagesSoundEnabled = () => {
  //   setisChatMessagesSoundEnabled(!isChatMessagesSoundEnabled);
  //   updateChatMessageSoundEnabled(!isChatMessagesSoundEnabled);
  // };

  const toggleSoundEffectsEnabled = () => {
    setIsSoundEffectsEnabled(!isSoundEffectsEnabled);
    updateSoundEffectsEnabled(!isSoundEffectsEnabled);
  };

  const btnBackClicked = () => {
    navigate("/settings");
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateGoalCompleteSoundEnabled = useCallback(
    debounce((value) => {
      dispatch(goalCompleteSound(value));
      updateUser({
        isGoalCompleteSoundEnabled: value,
      });
    }, 300),
    []
  );

  // const updateChatMessageSoundEnabled = useCallback(
  //   debounce(value => {
  //     dispatch(chatMessagesSound(value));
  //     updateUser({
  //       isChatMessagesSoundEnabled: value,
  //     });
  //   }, 300),
  //   [],
  // );

  const updateSoundEffectsEnabled = useCallback(
    debounce((value) => {
      dispatch(soundEffects(value));
      updateUser({
        isSoundEffectsEnabled: value,
      });
    }, 300),
    []
  );

  return (
    <div
      className="sounds-settings-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
      }}
    >
      <div className="sounds-settings-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Settings"
          title="Sounds"
        />
        <div className="content">
          <ul>
            <li style={{ borderColor: theme.palette.gray.contrast }}>
              <label style={{ color: theme.palette.text.main }}>
                Goal complete
              </label>
              <Switch
                checked={isGoalCompleteSoundEnabled}
                onChange={toggleGoalCompleteSoundEnabled}
              />
            </li>
            <li style={{ borderColor: theme.palette.gray.contrast }}>
              <label style={{ color: theme.palette.text.main }}>
                Other sound effects
              </label>
              <Switch
                checked={isSoundEffectsEnabled}
                onChange={toggleSoundEffectsEnabled}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SoundsSettings);
