import { FETCH_COACH, SET_LOADING_COACH } from "../types";

const initialState = {
  coach: null,
  isLoading: false,
};

const coachReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_COACH:
      return {
        ...state,
        coach: payload,
      };

    case SET_LOADING_COACH:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default coachReducer;
