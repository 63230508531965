import firebase from "firebase/compat/app";
import { firestore, auth } from "../../api/firebase";
import { captureException } from "@sentry/react";

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment-timezone";
import { FaImages } from "react-icons/fa";
import { debounce } from "lodash";

import {
  useTheme,
  Dialog,
  DialogContent,
  CircularProgress,
  Slide,
  IconButton,
  TextField,
  Button,
  Divider,
} from "@mui/material";

import {
  addMonth,
  updateMonth,
  isMonthCreated,
  isMonthSubmitted,
} from "../../api/firestore/months";
import { updateLastTimeActive } from "../../api/firestore/users";
import { getTimestamp } from "../../api/helper";
import { getImagePath, uploadImage, playSound } from "../../api/filesHandler";

// Analytics
import { analytics } from "../../api/firebase";

// Assets
import submit_all_ticked from "../../assets/sounds/submit_all_ticked.wav";
import submit from "../../assets/sounds/submit.wav";

import "./style.scss";

const SubmitMonthOverlay = (props) => {
  const theme = useTheme();

  // Redux
  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );
  const isTargetsSortByIsDone = useSelector(
    (state) => state.local.isTargetsSortByIsDone
  );
  const isTargetsSortBy = useSelector((state) => state.local.isTargetsSortBy);
  const isTargetsOrderBy = useSelector((state) => state.local.isTargetsOrderBy);
  const isMoodButtonsMandatory = useSelector(
    (state) => state.local.isMoodButtonsMandatory
  );

  //States
  const [clientComment, setClientComment] = useState(props.clientComment);
  const [subjectiveWellBeingScale, setSubjectiveWellBeingScale] = useState(
    props.subjectiveWellBeingScale || null
  );
  const [imagesToSubmit, setImagesToSubmit] = useState([]);
  const [imagesToPreview, setImagesToPreview] = useState([]);
  const [oldSubmissionImages, setOldSubmissionImages] = useState(
    props.monthImages || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isMoodButtonsAlertVisible, setIsMoodButtonsAlertVisible] =
    useState(false);

  // Sets previous submission's client comment and images every time modal opens
  useEffect(() => {
    if (props.isVisible) {
      setClientComment(props.clientComment);
      setOldSubmissionImages(props.monthImages);
    } else {
      setClientComment(props.clientComment);
      setOldSubmissionImages(props.monthImages || []);
    }

    setIsMoodButtonsAlertVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateClientComment = useCallback(
    debounce((value) => {
      updateMonth(props.date, {
        clientComment: value,
      });
    }, 300),
    []
  );

  const handleSubmit = useCallback(async () => {
    if (!subjectiveWellBeingScale && isMoodButtonsMandatory) {
      setIsMoodButtonsAlertVisible(true);
    } else {
      setIsLoading(true);

      // Start transaction
      const batch = firestore.batch();

      const userUid = auth.currentUser.uid;

      let targetsTotal = 0;
      let targetsDone = 0;
      let unreadMessagesCount = 0;

      let message = `Results of ${moment(props.date).format("MMMM, Y")} \n`;

      const movedTargets = [];

      const moveUndoneTargets = async () => {
        if (props.targets?.length) {
          await Promise.all(
            props.targets
              // Instantiate sorting variables in case they don't exist (might cause app crash)
              .map((el) => ({
                ...el,
                name: el.name || "",
                isDone: !!el.isDone, // If isDone is undefined '!' initializes it to true, and the other '!' to false
                isPartiallyDone: !!el.isPartiallyDone, // If isPartiallyDone is undefined '!' initializes it to true, and the other '!' to false
                tag: el.tag || "",
                position: el.position || 0,
              }))
              // Sort targets
              .sort(
                (a, b) =>
                  // First. Sort by done if set
                  (isTargetsSortByIsDone === "ASC"
                    ? b["isDone"]
                        .toString()
                        .localeCompare(a["isDone"].toString(), undefined, {
                          numeric: true,
                        })
                    : isTargetsSortByIsDone === "DESC"
                    ? a["isDone"]
                        .toString()
                        .localeCompare(b["isDone"].toString(), undefined, {
                          numeric: true,
                        })
                    : null) ||
                  // Then. Sort by isPartiallyDone
                  (isTargetsSortByIsDone === "ASC"
                    ? b["isPartiallyDone"]
                        .toString()
                        .localeCompare(
                          a["isPartiallyDone"].toString(),
                          undefined,
                          {
                            numeric: true,
                          }
                        )
                    : isTargetsSortByIsDone === "DESC"
                    ? a["isPartiallyDone"]
                        .toString()
                        .localeCompare(
                          b["isPartiallyDone"].toString(),
                          undefined,
                          {
                            numeric: true,
                          }
                        )
                    : null) ||
                  // Then. Client's custom sort and order (name, or color (tag))
                  (isTargetsOrderBy === "ASC"
                    ? a[isTargetsSortBy || "name"]
                        .toString()
                        .localeCompare(
                          b[isTargetsSortBy || "name"].toString(),
                          undefined,
                          {
                            numeric: true,
                          }
                        )
                    : b[isTargetsSortBy || "name"]
                        .toString()
                        .localeCompare(
                          a[isTargetsSortBy || "name"].toString(),
                          undefined,
                          {
                            numeric: true,
                          }
                        )) ||
                  // Last. Sort by name ASC if sort by name is not selected already
                  (isTargetsSortBy !== "name"
                    ? a["name"]
                        .toString()
                        .localeCompare(b["name"].toString(), undefined, {
                          numeric: true,
                        })
                    : null)
              )
              // Iterate all sorted targets to write the automessage
              .map(async (item) => {
                // Increment targets total and targets done
                targetsTotal++;
                if (!!item.isDone) {
                  targetsDone++;
                }

                // Create message
                message += `\n${
                  item.isPartiallyDone ? "⏳" : item.isDone ? "✅ " : "❌ "
                } ${item.name || "(No title)"}`;

                // Move targets that are not done, allowed to roll over or are old and don't have isRolledOver var
                if (
                  !item.isDone &&
                  (item.isRolledOver === true ||
                    item.isRolledOver === undefined)
                ) {
                  // Destructuring to remove all the fields we don't want to add
                  const { originalGoalId, ...targetToMove } = item;

                  const isThisMonth =
                    moment(props.date).format("YYYYMM") ===
                    moment().format("YYYYMM");

                  let date;

                  if (isThisMonth) {
                    // If current month is being submitted, roll over targets to next month
                    date = moment().add(1, "months");
                  } else {
                    // If another month is being submitted, check:
                    // 1) If current month is not submitted, roll over targets to this month.
                    // 2) If current month is already submitted, roll over targets to next month.
                    const isThisMonthSubmitted = await isMonthSubmitted();
                    date = moment().add(isThisMonthSubmitted ? 1 : 0, "months");
                  }

                  // Create month to which targets will move to, if it's not created already
                  const isTheMonthCreated = await isMonthCreated(date);
                  if (!isTheMonthCreated) {
                    addMonth(date);
                  }

                  // Move repeatTilDone targets to this month
                  const newMovedTargetRef = firestore
                    .collection("targets")
                    .doc(userUid)
                    .collection("targets")
                    .doc();

                  batch.set(newMovedTargetRef, {
                    ...targetToMove,
                    id: item.originalGoalId || item.id, // item.originalId if goal is copy of original (rolled more than once). Or item.id if goal IS the original (first time rolled)
                    date: getTimestamp(
                      moment(date).startOf("month").utcOffset(0, true)
                    ),
                    isPartiallyDone: false,
                    isRolledOver:
                      item.isRolledOver === undefined
                        ? true
                        : item.isRolledOver,
                  });
                  movedTargets.push(newMovedTargetRef?.id);
                }
              })
          );
        }
      };

      await moveUndoneTargets();

      if (clientComment) {
        message += `\n\n${clientComment}`;
      }

      const messageRef = firestore
        .collection("chats")
        .doc(userUid)
        .collection("messages")
        .doc();

      batch.set(messageRef, {
        sentAt: firebase.firestore.FieldValue.serverTimestamp(),
        sentBy: userUid,
        text: message,
        isMonthlyResults: getTimestamp(
          moment(props.date).startOf("month").utcOffset(0, true)
        ),
        subjectiveWellBeingScale: subjectiveWellBeingScale || null,
      });

      unreadMessagesCount++;

      try {
        const uploadedImages = [];

        for (let image of imagesToSubmit) {
          const uploadedImage = await uploadImage(image);
          uploadedImages.push(uploadedImage);
        }

        const imagesPath = [];
        for (let image of uploadedImages) {
          const path = await getImagePath(image);
          imagesPath.push(path);
        }
        for (let image of oldSubmissionImages) {
          const path = image;
          imagesPath.push(path);
        }

        const imagesId = [];
        for (let image of imagesPath) {
          const imageMessageRef = firestore
            .collection("chats")
            .doc(userUid)
            .collection("messages")
            .doc();

          batch.set(imageMessageRef, {
            sentAt: firebase.firestore.FieldValue.serverTimestamp(),
            sentBy: userUid,
            image,
            isMonthlyResults: getTimestamp(
              moment(props.date).startOf("month").utcOffset(0, true)
            ),
          });

          unreadMessagesCount++;

          imagesId.push(imageMessageRef?.id);
        }

        // Update unreadClientMessages
        const counterRef = firestore.collection("counters").doc(userUid);
        batch.set(
          counterRef,
          {
            unreadClientMessages:
              firebase.firestore.FieldValue.increment(unreadMessagesCount),
          },
          { merge: true }
        );

        // Update month
        batch.set(
          firestore.doc(
            `months/${userUid}/months/${moment(props.date).format("YYYYMM")}`
          ),
          {
            isSubmitted: true,
            date: getTimestamp(
              moment(props.date).startOf("month").utcOffset(0, true)
            ),
            images: imagesPath,
            clientComment,
            subjectiveWellBeingScale: subjectiveWellBeingScale || null,
            messageId: messageRef?.id,
            imagesId,
            movedTargets,
            completion: parseFloat(
              ((targetsDone * 100) / targetsTotal).toFixed(2)
            ),
            lastSubmissionType: "manual",
          },
          { merge: true }
        );

        await batch.commit();

        updateLastTimeActive();

        if (isSoundEffectsEnabled) {
          if (props.progress === 100) {
            playSound(submit_all_ticked);
          } else {
            playSound(submit);
          }
        }

        analytics.logEvent("month_manual_submission", {
          completion: parseFloat(
            ((targetsDone * 100) / targetsTotal).toFixed(2)
          ),
        });

        console.log("Month manual submit completed.");
      } catch (error) {
        captureException(
          `Error while uploading images for manual month submission: ${error}`
        );
        alert(`Error while uploading images: ${error}`);
      }

      setImagesToPreview([]);

      setIsLoading(false);
      props.setAllowNavigation(true);
      props.setIsVisible(false);
    }
  }, [
    clientComment,
    imagesToSubmit,
    isMoodButtonsMandatory,
    isSoundEffectsEnabled,
    isTargetsOrderBy,
    isTargetsSortBy,
    isTargetsSortByIsDone,
    oldSubmissionImages,
    subjectiveWellBeingScale,
    props.date,
    props.targets,
    props.targets?.length,
    props.progress,
    props.setAllowNavigation,
    props.setIsVisible,
  ]);

  // ==================================== IMAGE HANDLING ====================================
  const handleImagesInputChange = (e) => {
    var imageArray = e.target.files;
    let temp = [...imagesToSubmit, ...imageArray]; // Add new whole image file to array of images (commentPhotos)
    if (temp.length > 8) {
      alert("Up to 8 photos are allowed");
    } else {
      setImagesToSubmit(temp); // Set array of images
    }
    handleImagesToPreview(temp);
  };

  const handleImagesToPreview = (imagesArray) => {
    let newImagesToPreview = [];
    imagesArray.map((img) => {
      newImagesToPreview.push(URL.createObjectURL(img));
    });
    setImagesToPreview(newImagesToPreview);
  };

  const handleRemoveImage = (index) => {
    let preview = [...imagesToPreview];
    let images = [...imagesToSubmit];
    preview.splice(index, 1);
    images.splice(index, 1);
    setImagesToPreview(preview);
    setImagesToSubmit(images);
  };

  const handleRemoveOldSubmissionImage = (index) => {
    let photos = [...oldSubmissionImages];
    photos.splice(index, 1);
    setOldSubmissionImages(photos);
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => {
        props.setAllowNavigation(true);
        props.setIsVisible(false);
      }}
      TransitionComponent={Slide}
      fullWidth
      className="submit-month-overlay-container"
    >
      {/* <DialogTitle className="modal-title">
        <label className="modal-title-label">Submit month</label>
      </DialogTitle>
      <Box position="absolute" top={15} right={15}>
        <IconButton
          onClick={() => {
            props.setAllowNavigation(true);
            props.setIsVisible(false);
          }}
        >
          <FaTimes />
        </IconButton>
      </Box> */}
      <DialogContent>
        <div className="comment-container">
          <TextField
            type="text"
            rows={10}
            id="submitMonthInput"
            name="goalNameInput"
            placeholder={
              props.customSubmitMessage ||
              "How did " + moment(props.date).format("MMMM") + " go?"
            }
            value={clientComment}
            onChange={(e) => {
              setClientComment(e.target.value);
              updateClientComment(e.target.value);
            }}
            maxLength={140}
            multiline
            autoComplete="off"
            fullWidth
            autoFocus
          />
        </div>
        {(imagesToSubmit.length > 0 || oldSubmissionImages.length > 0) && (
          <div className="images-preview-container">
            {imagesToPreview.map((img, index) => (
              <div key={`image-${index}`} className="img-wrap">
                <img
                  src={img}
                  className="image-preview"
                  alt="preview"
                  height="100"
                  width="100"
                />
                <button
                  className="close-preview-img"
                  onClick={() => handleRemoveImage(index)}
                >
                  &times;
                </button>
              </div>
            ))}
            {oldSubmissionImages.length > 0 &&
              oldSubmissionImages.map((img, index) => (
                <div key={`old-submission-image-${index}`} className="img-wrap">
                  <img
                    src={img}
                    className="image-preview"
                    alt="preview"
                    height="100"
                    width="100"
                  />
                  <button
                    className="close-preview-img"
                    onClick={() => handleRemoveOldSubmissionImage(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
          </div>
        )}

        <div className="btn-add-img-container">
          {imagesToSubmit.length < 8 ? (
            <div
              className="btn-add-image"
              style={{
                color: theme.palette.gray.dark,
                backgroundColor: theme.palette.gray.light,
              }}
            >
              <label htmlFor="submit-month-files-upload">
                <FaImages className="btn-add-image-icon" size={30} />
              </label>
              <input
                id="submit-month-files-upload"
                type="file"
                multiple
                accept="image/*"
                onChange={handleImagesInputChange}
              />
            </div>
          ) : (
            <label
              className="images-limit-reached"
              style={{ backgroundColor: theme.palette.gray.light }}
            >
              8/8
            </label>
          )}
        </div>

        {isMoodButtonsAlertVisible ? (
          <label
            className="subjective-well-being-alert"
            style={{ color: theme.palette.text.main }}
          >
            Pick an emoji to rate your mood first!
          </label>
        ) : null}

        <div className="subjective-well-being-scale">
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 1 ? null : 1
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 1 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 1 ? 35 : 30,
              }}
            >
              😭
            </label>
          </IconButton>
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 2 ? null : 2
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 2 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 2 ? 35 : 30,
              }}
            >
              🙁
            </label>
          </IconButton>
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 3 ? null : 3
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 3 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 3 ? 35 : 30,
              }}
            >
              😐
            </label>
          </IconButton>
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 4 ? null : 4
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 4 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 4 ? 35 : 30,
              }}
            >
              🙂
            </label>
          </IconButton>
          <IconButton
            onClick={() =>
              setSubjectiveWellBeingScale(
                subjectiveWellBeingScale === 5 ? null : 5
              )
            }
            sx={{
              height: 55,
              width: 55,
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            <label
              style={{
                cursor: "pointer",
                opacity: subjectiveWellBeingScale === 5 ? 1 : 0.5,
                fontSize: subjectiveWellBeingScale === 5 ? 35 : 30,
              }}
            >
              😀
            </label>
          </IconButton>
        </div>
      </DialogContent>

      <Divider />

      <div className="submit-month-modal-footer">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => {
            props.setAllowNavigation(true);
            props.setIsVisible(false);
          }}
        >
          Cancel
        </Button>

        <Divider
          className="divider"
          sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
          orientation="vertical"
          flexItem
        />

        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="primary"
          onClick={handleSubmit}
          disabled={
            isMoodButtonsMandatory
              ? subjectiveWellBeingScale
                ? false
                : true
              : false
          }
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(SubmitMonthOverlay);
