import React from "react";

import { Dialog, Slide } from "@mui/material";

import "./style.scss";

const VideoModal = ({ isVisible, setIsVisible, video }) => {
  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      TransitionComponent={Slide}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Adjust the alpha (last value) to make it darker or lighter
        },
      }}
    >
      <div className="video-modal-container">
        <video className="video" autoPlay controls>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </Dialog>
  );
};

export default React.memo(VideoModal);
