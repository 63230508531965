import { useLayoutEffect, useState } from "react";
import validator from "validator";
import { en } from "./languages/en";
import firebase from "firebase/compat/app";
import moment from "moment";
import "moment-timezone";
import { captureException } from "@sentry/react";

import { deleteToken } from "./firestore/users";

import { DeviceSize, Devices } from "./constants";
import { slackKeyOnboarding, fcmKey, backendApiKey } from "./credentials";

export const validateName = (name) => {
  if (!name?.length) {
    return { isValid: false, message: en.NAME_REQUIRED };
  } else {
    return { isValid: true, message: "" };
  }
};

export const validateEmail = (email) => {
  if (!email?.length) {
    return { isValid: false, message: en.EMAIL_REQUIRED };
  } else if (!validator.isEmail(email)) {
    return { isValid: false, message: en.EMAIL_INVALID };
  } else {
    return { isValid: true, message: "" };
  }
};

export const validatePassword = (password) => {
  if (!password.length) {
    return { isValid: false, message: en.PASSWORD_REQUIRED };
  } else if (password.length < 6) {
    return {
      isValid: false,
      message: en.PASSWORD_INVALID,
    };
  } else {
    return { isValid: true, message: "" };
  }
};

export const getTimestamp = (date = moment()) => {
  return firebase.firestore.Timestamp.fromDate(date.toDate());
};

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export function getVerticalPos(el) {
  var rect = el.getBoundingClientRect();
  return rect.top;
}

export const guessTimezone = () => {
  const supportsIntl = typeof global.Intl !== undefined;
  let oldIntl;
  let timezone;

  // do not use buggy Intl for timezone guess
  // See: https://github.com/moment/moment-timezone/issues/441
  if (supportsIntl) {
    oldIntl = global.Intl;
    global.Intl = undefined;
    timezone = moment.tz.guess();
    global.Intl = oldIntl;
  } else {
    timezone = moment.tz.guess();
  }

  // const timezone = moment.tz.guess();

  return timezone;
};

export const getDeviceSize = () => {
  let temp = window.innerWidth;
  let result = null;
  switch (true) {
    case temp < DeviceSize.PHONE:
      result = Devices.PHONE;
      break;
    case temp > DeviceSize.PHONE && temp < DeviceSize.TABLET:
      result = Devices.TABLET;
      break;
    case temp > DeviceSize.TABLET:
      result = Devices.DESKTOP;
      break;
    default:
      console.log("Error");
  }
  return result;
};

export const simulateRightArrowDown = () => {
  const ke = new KeyboardEvent("keydown", {
    bubbles: true,
    cancelable: true,
    code: "ArrowRight",
    key: "ArrowRight",
    keyCode: 39,
  });
  document.body.dispatchEvent(ke);
};

export const compareVersions = (a, b) => {
  // Split the version numbers into an array of integers
  const aParts = a.version.split(".").map(Number);
  const bParts = b.version.split(".").map(Number);

  // Compare the integers at each position in the version numbers
  for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
    const aNum = i < aParts.length ? aParts[i] : 0;
    const bNum = i < bParts.length ? bParts[i] : 0;

    if (aNum > bNum) {
      return -1;
    } else if (aNum < bNum) {
      return 1;
    }
  }

  // The version numbers are equal
  return 0;
};

export const hasOnlyIdField = (obj) => {
  // Get an array of the object's keys
  const keys = Object.keys(obj);

  // Check if there's only one key and it's 'id'
  return keys.length === 1 && keys[0] === "id";
};

export const sendOnboardingSlackNotification = (message) => {
  fetch("https://hooks.slack.com/services/" + slackKeyOnboarding, {
    method: "POST",
    body: JSON.stringify({ text: message }),
  })
    .then((response) => {})
    .then((result) => {})
    .catch((error) => {
      captureException(
        "Error while sending onboarding slack notification",
        error
      );
      console.log(
        "Error while sending onboarding slack notification: " + error
      );
    });
};

export const clearNotificationsBadge = (tokens) => {
  if (tokens?.length) {
    // Send notification
    fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers: {
        Authorization: "key=" + fcmKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        registration_ids: tokens,
        notification: {
          badge: "0",
        },
      }),
    })
      .then((response) => {})
      .then((result) => {
        // console.log(result);
        // For each message check if there was an error.
        if (result?.results?.length) {
          result.results.forEach((result, index) => {
            const error = result.error;
            if (
              error === "InvalidRegistrationToken" ||
              error === "NotRegistered"
            ) {
              // Remove the token from your registry/database
              deleteToken(tokens[index]);
            }
          });
        }
      })
      .catch((error) => {
        console.log("Error while clearing notifications badge: ", error);
      });
  }
};

export const getRandomAvailableCoach = async () => {
  try {
    const response = await fetch(
      "https://us-central1-goalswon-rebuilt-app.cloudfunctions.net/app2/api/getRandomAvailableCoach",
      {
        method: "GET",
        headers: {
          "x-api-key": backendApiKey,
          "Content-Type": "application/json", // Add this if needed
        },
      }
    );

    if (!response.ok) {
      captureException(
        `HTTP error on geetRandomAvailableCoach! Status: ${response.status}`
      );
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    return data.data;
  } catch (error) {
    // Handle network errors and other exceptions
    captureException(
      "Error while retrieving random currently available coach",
      error
    );
    console.error(
      "Error while retrieving random currently available coach: " + error
    );
    throw error; // Re-throw the error to propagate it to the caller
  }
};

export const howLongAgo = (dateTime) => {
  const now = Date.now();
  const then = new Date(dateTime).getTime();
  const diffInMs = now - then;

  // Get individual units (seconds, minutes, hours)
  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  let timeAgo;
  if (seconds < 60) {
    timeAgo = `${seconds} seconds ago`;
  } else if (minutes < 60) {
    timeAgo = `${minutes} minutes ago`;
  } else if (hours < 24) {
    timeAgo = `${hours} hours ago`;
  } else {
    // Handle days or more (logic can be extended)
    timeAgo = moment(dateTime).fromNow(); // Use moment here for complex formatting
  }

  return timeAgo;
};
