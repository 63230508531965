import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { debounce } from "lodash";

// Components
import { FiTrash2, FiCalendar, FiRefreshCw, FiCopy } from "react-icons/fi";
import { MdOutlinePalette } from "react-icons/md";
import {
  useTheme,
  IconButton,
  TextField,
  Divider,
  Tooltip,
} from "@mui/material";

import DateTimePickerOverlay from "../../../components/DateTimePickerOverlay";
import RepeatPatternPickerOverlay from "../../../components/RepeatPatternPickerOverlay";
import TagPickerOverlay from "../../../components/TagPickerOverlay";
import DeleteRecurringGoalOverlay from "../../../components/DeleteRecurringGoalOverlay";
import ScreenHeader from "../../../components/ScreenHeader";
import InfoOverlay from "../../../components/InfoOverlay";

// Firestore
import { updateUser } from "../../../api/firestore/users";
import { addInbox, updateInbox } from "../../../api/firestore/inboxes";
import { addGoal, deleteGoal, updateGoal } from "../../../api/firestore/goals";
import {
  addRecurrentGoal,
  updateRecurrentGoal,
  deleteRecurrentGoal,
} from "../../../api/firestore/recurrentGoals";

// Analytics
import { analytics } from "../../../api/firebase";

// Own api
import {
  tagColors,
  Devices,
  recurrencePatternEnum,
} from "../../../api/constants";
import { en } from "../../../api/languages/en";
import "./style.scss";

const EditGoalScreen = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  // Redux
  const currentDevice = useSelector((state) => state.local.currentDevice);

  // States
  const [goal, setGoal] = useState({
    ...location.state.goalToEdit,
    ...(location.state.goalToEdit.date && {
      date: moment(location.state.goalToEdit.date)
        .utc()
        .utcOffset(moment().utcOffset(), true),
    }), // is received as moment.toString() in utc and converted back into moment with the corresponding offset
    ...(location.state.goalToEdit.startDate && {
      startDate: moment(location.state.goalToEdit.startDate)
        .utc()
        .utcOffset(moment().utcOffset(), true),
    }), // is received as moment.toString() in utc and converted back into moment with the corresponding offset
    ...(location.state.goalToEdit.endDate && {
      endDate: moment(location.state.goalToEdit.endDate)
        .utc()
        .utcOffset(moment().utcOffset(), true),
    }), // is received as moment.toString() in utc and converted back into moment with the corresponding offset
  }); // date is received as "date" and converted to timestamp
  const [date, setDateChange] = useState(moment(location.state.date)); // date is received as "date" and converted to moment obj
  const [tag, setTagChange] = useState(goal.tag);
  const [name, setNameChange] = useState(goal.name);
  const [description, setDescriptionChange] = useState(goal.description);
  const [isTagPickerOverlayVisible, setIsTagPickerOverlayVisible] =
    useState(false);
  const [
    isRepeatPatternPickerOverlayVisible,
    setIsRepeatPatternPickerOverlayVisible,
  ] = useState(location.state.autoOpenRepeatPatternPickerOverlay || false);
  const [isDateTimePickerOverlayVisible, setIsDateTimePickerOverlayVisible] =
    useState(false);
  const [
    isDeleteRecurringGoalOverlayVisible,
    setIsDeleteRecurringGoalOverlayVisible,
  ] = useState(false);
  const [
    isEditGoalScreenInfoOverlayVisible,
    setIsEditGoalScreenInfoOverlayVisible,
  ] = useState(false);

  useEffect(() => {
    if (props?.user?.seenEditGoalScreenInfo === undefined) {
      setIsEditGoalScreenInfoOverlayVisible(true);
      updateUser({ seenEditGoalScreenInfo: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = (item) => {
    if (item.newDate === "none") {
      // If goal is turned into inbox (date is removed)
      if (item?.fromInbox) {
        // If goal had been an inbox, set the inbox as undone
        updateInbox({
          id: item.fromInbox,
          name: item.name,
          description: item.description,
          tag: item.tag,
          isDone: false,
          updatedAt: moment(),
        });
      } else {
        // If goal hasn't been an inbox, create an inbox
        addInbox({
          name: item.name,
          description: item.description || "",
          tag: item.tag || "",
          position: "", // TODO
          // position: inboxes.length.toString(), // TODO
          isDone: false,
        });
      }

      if (
        item.recurrencePattern &&
        item.recurrencePattern !== recurrencePatternEnum.NONE
      ) {
        deleteRecurrentGoal(item);
      } else {
        deleteGoal(item);
      }

      navigate("/", {
        state: {
          date: date.toDate(),
        },
      });

      toast.success("Moved to Undated!");
    } else {
      // If goal is still a goal (so has a date)
      const { newDate, ...newGoal } = item;

      // Update recurrent goal
      if (
        newGoal.recurrencePattern &&
        newGoal.recurrencePattern !== recurrencePatternEnum.NONE
      ) {
        if (newDate) {
          updateRecurrentGoal(
            { ...newGoal, startDate: newDate },
            newGoal.fromRecurrentGoal
          );
        } else {
          updateRecurrentGoal(newGoal, newGoal.fromRecurrentGoal);
        }
      }

      // Update regular goal
      if (item.date) {
        if (newDate) {
          updateGoal({
            ...newGoal,
            ...(item.startDate && { startDate: newDate }),
          });
        } else {
          updateGoal(newGoal);
        }
      }

      setGoal(newGoal);
      setTagChange(newGoal.tag);
      setNameChange(newGoal.name);

      if (newDate) {
        navigate("/", {
          state: {
            date: date.toDate(),
          },
        });
        toast.success(
          `Moved to ${moment(newDate.toDate && newDate.toDate()).format(
            "MMM D"
          )}!`
        );
      }
    }
  };

  const handleDelete = (item) => {
    if (!item.date || item.fromRecurrentGoal) {
      setIsDeleteRecurringGoalOverlayVisible(true);
    } else {
      deleteGoal(item);
      // Undo delete goal
      // WARNING - Temporal fix for this version of navigation, pass params in navigate. And yeah, have to do all that to make date work
      navigate("/", {
        state: {
          date: date.toDate(),
          goal: {
            ...item,
            date: moment(item.date).utcOffset(0, true).toString(), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
            ...(item.startDate && {
              startDate: moment(item.startDate).utcOffset(0, true).toString(),
            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
            ...(item.endDate && {
              endDate: moment(item.endDate).utcOffset(0, true).toString(),
            }), // WARNING - Have to convert like this to pass a UTC serializable value within the history (moment obj is not serializable and therefore can't be passed as is, and toDate() uses incorrect timezone)
          },
        },
      });
    }
  };

  const handleDuplicate = async (item) => {
    let duplicatedGoalId;

    if (!item.date || item.fromRecurrentGoal) {
      duplicatedGoalId = await addRecurrentGoal({
        name: (item.name || "(No title)") + " COPY",
        position: 0, // TODO
        isDone: false,
        tag: item.tag,
        description: item.description,
        startDate: date,
        recurrencePattern: item.recurrencePattern,
        days: item.days,
        endDate: item.endDate || null,
        isRolledOver: item.isRolledOver,
      });
    } else {
      duplicatedGoalId = await addGoal({
        name: (item.name || "(No title)") + " COPY",
        date: item.date,
        description: item.description,
        isDone: false,
        position: item.position,
        tag: item.tag,
        isRolledOver: item.isRolledOver,
      });
    }

    analytics.logEvent("used_duplicate_btn");

    navigate("/", {
      state: {
        date: date.toDate(),
        duplicatedGoalId: duplicatedGoalId,
      },
    });
  };

  // -------------------------------- Debounce controlled inputs -------------------------------- //
  const updateNameOrDescription = useCallback(
    debounce((property, value) => {
      handleUpdate({
        ...goal,
        [property]: value,
      });
    }, 300),
    [goal]
  );

  const btnBackClicked = () => {
    navigate("/", {
      state: {
        date: date.toDate(),
      },
    });
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        // If goal is recurrent, it won't have a "date", that's why date is needed
        navigate("/", {
          state: {
            date: date.toDate(),
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="edit-goal-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        height: currentDevice !== Devices.PHONE && "100vh",
        overflowY: currentDevice !== "PHONE" && "scroll",
      }}
    >
      <div className="edit-goal-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Goals"
          title="Edit Goal"
        />

        <Divider />

        <div className="body">
          <div className="edit-btns">
            <div className="edit-btns-first-section">
              <Tooltip title="Date">
                <button
                  className="btn-edit"
                  onClick={() => setIsDateTimePickerOverlayVisible(true)}
                >
                  <FiCalendar style={{ color: theme.palette.gray.dark }} />
                  <label
                    className="btn-edit-date"
                    style={{ color: theme.palette.gray.dark }}
                  >
                    {date && moment(date.toDate()).format("MMM D")}
                  </label>
                </button>
              </Tooltip>

              <Divider
                className="divider"
                sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
                orientation="vertical"
                flexItem
              />

              <Tooltip title="Recurrence pattern">
                <IconButton
                  className="btn-edit"
                  onClick={() => setIsRepeatPatternPickerOverlayVisible(true)}
                >
                  <FiRefreshCw
                    color={
                      !goal.recurrencePattern ||
                      goal.recurrencePattern === recurrencePatternEnum.NONE
                        ? theme.palette.gray.dark
                        : theme.palette.primary.main
                    }
                  />
                </IconButton>
              </Tooltip>

              <Divider
                className="divider"
                sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
                orientation="vertical"
                flexItem
              />

              <Tooltip title="Color">
                <IconButton
                  className="btn-edit"
                  style={{ fontSize: 24 }}
                  onClick={() => setIsTagPickerOverlayVisible(true)}
                >
                  <MdOutlinePalette
                    style={{
                      color: !goal.tag
                        ? theme.palette.gray.dark
                        : tagColors[goal.tag - 1].color,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </div>

            <div className="edit-btns-first-section">
              <Tooltip title="Duplicate">
                <IconButton
                  className="btn-edit"
                  onClick={() => handleDuplicate(goal)}
                >
                  <FiCopy style={{ color: theme.palette.gray.dark }} />
                </IconButton>
              </Tooltip>

              <Divider
                className="divider"
                sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
                orientation="vertical"
                flexItem
              />

              <Tooltip title="Delete">
                <IconButton
                  className="btn-edit"
                  onClick={() => handleDelete(goal)}
                >
                  <FiTrash2 style={{ color: theme.palette.gray.dark }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Divider />

          <div className="title-container">
            {tag ? (
              <div
                className="goal-tag"
                style={{
                  backgroundColor: tag
                    ? tagColors[tag - 1].color
                    : "transparent",
                }}
              />
            ) : null}

            <TextField
              type="text"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: {
                  display: "flex",
                  color: theme.palette.text.main,
                  fontSize: 20,
                  fontWeight: "bold",
                },
              }}
              inputProps={{
                maxLength: 140,
              }}
              fullWidth
              autoComplete="off"
              multiline
              id="goalNameInput"
              placeholder="Add goal name..."
              value={name}
              onChange={(e) => {
                setNameChange(e.target.value);
                updateNameOrDescription("name", e.target.value);
              }}
              className="goal-name"
              style={{
                paddingLeft: tag ? 10 : 0,
                color: theme.palette.text.main,
              }}
              required
            />
          </div>

          <Divider />

          <div className="goal-description-section">
            <label
              className="goal-description-title"
              style={{ color: theme.palette.text.main }}
            >
              Goal description
            </label>
            <TextField
              type="text"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                style: { color: theme.palette.text.main },
              }}
              sx={{ marginTop: 1.5 }}
              fullWidth
              autoComplete="off"
              multiline
              minRows={10}
              id="goalDescriptionInput"
              placeholder="When, where and how will you get this done?"
              value={description}
              onChange={(e) => {
                setDescriptionChange(e.target.value);
                updateNameOrDescription("description", e.target.value);
              }}
              autoFocus={location.state.autoFocusDescription}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              } // Fix for autofocus on end of text
            />
          </div>
        </div>
      </div>

      {/* Overlays */}
      <TagPickerOverlay
        isVisible={isTagPickerOverlayVisible}
        setIsVisible={setIsTagPickerOverlayVisible}
        tag={tag}
        setTagChange={setTagChange}
        goal={goal}
        handleUpdate={handleUpdate}
      />

      <RepeatPatternPickerOverlay
        isVisible={isRepeatPatternPickerOverlayVisible}
        setIsVisible={setIsRepeatPatternPickerOverlayVisible}
        goal={goal}
        setGoal={setGoal}
        date={moment(date || undefined)}
      />

      <DateTimePickerOverlay
        isVisible={isDateTimePickerOverlayVisible}
        setIsVisible={setIsDateTimePickerOverlayVisible}
        date={date} // Pass as moment obj
        setDateChange={setDateChange}
        goal={goal}
        handleUpdate={handleUpdate}
        minDate={moment(
          props.user?.createdTime &&
            props.user.createdTime.toDate &&
            props.user.createdTime.toDate()
        ).startOf("day")}
        showRemoveDate
      />

      <DeleteRecurringGoalOverlay
        isVisible={isDeleteRecurringGoalOverlayVisible}
        setIsVisible={setIsDeleteRecurringGoalOverlayVisible}
        goal={goal}
        date={date && moment(date)}
      />

      <InfoOverlay
        isVisible={isEditGoalScreenInfoOverlayVisible}
        setIsVisible={setIsEditGoalScreenInfoOverlayVisible}
        title={en.INFO_EDIT_GOAL_SCREEN_TITLE}
        subtitle={en.INFO_EDIT_GOAL_SCREEN}
        textOk={"Got it"}
      />
    </div>
  );
};

export default React.memo(EditGoalScreen);
