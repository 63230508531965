import React from "react";

import { useTheme, Dialog, Slide } from "@mui/material";
import EmojiPicker from "emoji-picker-react";

const ReactChatMessageOverlay = ({
  isVisible,
  setIsVisible,
  messageId,
  handleAddEditReaction,
}) => {
  const theme = useTheme();

  const handleEmojiPressed = (event) => {
    handleAddEditReaction(messageId, event.emoji);
  };

  return (
    <Dialog
      open={isVisible}
      onClose={() => setIsVisible(false)}
      TransitionComponent={Slide}
      PaperProps={{
        style: {
          background: "none",
        },
      }}
    >
      <EmojiPicker
        theme={theme.palette.mode}
        onEmojiClick={handleEmojiPressed}
        emojiStyle="native"
        // reactionsDefaultOpen
        // onReactionClick={handleEmojiPressed}
        // searchDisabled
        // previewConfig={{ showPreview: false }}
      />
    </Dialog>
  );
};

export default React.memo(ReactChatMessageOverlay);
