import React, { useEffect, useState, useRef, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { toast } from "react-toastify";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMilestones,
  fetchMoreMilestones,
} from "../../redux/actions/milestone";
import { markMilestonesAsRead } from "../../api/firestore/milestones";
import { updateUser } from "../../api/firestore/users";

// Components
import { useTheme, Button, Badge, CircularProgress } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { FiInfo } from "react-icons/fi";
import InfoOverlay from "../../components/InfoOverlay";

// Assets
// import MilestonesIcon from "../../assets/svg/milestones.svg";
import { ReactComponent as MilestonesIcon } from "../../assets/svg/milestones.svg";

// Own api
import milestone_notification from "../../assets/sounds/milestone_notification.wav";
import { useWindowSize } from "../../api/helper";
import { playSound } from "../../api/filesHandler";
import { en } from "../../api/languages/en";
import { Devices } from "../../api/constants";
import "./style.scss";

const MilestonesScreen = () => {
  const theme = useTheme();
  const milestonesRef = useRef();
  const [
    windowWidth,
    // windowHeight
  ] = useWindowSize();
  const dispatch = useDispatch();

  // Redux
  const currentDevice = useSelector((state) => state.local.currentDevice);
  const fetchedMilestones = useSelector((state) => state.milestone.milestones);
  const isLoadingMilestones = useSelector((state) => state.milestone.isLoading);
  const user = useSelector((state) => state.user.user);
  const counters = useSelector((state) => state.counter.counters);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [isInfoOverlayVisible, setIsInfoOverlayVisible] = useState(false);
  const [unreadMilestonesNumber, setUnreadMilestonesNumber] = useState(0);

  const isSoundEffectsEnabled = useSelector(
    (state) => state.local.isSoundEffectsEnabled
  );

  /////////////////// Fetch milestones ///////////////////
  useEffect(() => {
    dispatch(fetchMilestones());
  }, []);

  /////////////////// Show info popup on first time user ///////////////////
  useEffect(() => {
    if (user?.seenMilestonesInfo === undefined) {
      setIsInfoOverlayVisible(true);
      updateUser({ seenMilestonesInfo: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO - Check
  useEffect(
    useCallback(() => {
      if (isSoundEffectsEnabled && counters?.unreadMilestones) {
        playSound(milestone_notification);
        setUnreadMilestonesNumber(counters.unreadMilestones);
      }
      markMilestonesAsRead();
    }, [counters, isSoundEffectsEnabled])
  );

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!");
    });
  };

  const loadMoreMilestones = () => {
    if (!isLoadingMore) {
      setIsLoadingMore(true);
      setTimeout(() => {
        dispatch(fetchMoreMilestones());
        setIsLoadingMore(false);
      }, 500);
    }
  };

  // ================================== Render ITEM ==================================
  const renderItem = (item, index) => {
    return (
      <TimelineItem key={`milestone-${index}`}>
        <TimelineOppositeContent
          sx={{ m: "auto 0", flex: 0.1 }}
          align="left"
          variant="body2"
          color="text.secondary"
        >
          {item?.date
            ? moment().year() ===
              moment(item.date?.toDate && item.date.toDate()).year()
              ? moment(item.date?.toDate && item.date.toDate()).format("MMM D")
              : moment(item.date?.toDate && item.date.toDate()).format(
                  "MMM D, Y"
                )
            : null}
        </TimelineOppositeContent>
        <TimelineSeparator>
          {index !== 0 ? <TimelineConnector sx={{ minHeight: 30 }} /> : null}
          <Badge
            variant="standard"
            color="error"
            badgeContent="NEW"
            invisible={unreadMilestonesNumber - 1 < index}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.palette.quaternary.main,
              borderRadius: 50,
              width: 70,
              height: 70,
            }}
          >
            <label className="emoji">{item.badge}</label>
          </Badge>
          {index !== fetchedMilestones?.length - 1 ? (
            <TimelineConnector sx={{ minHeight: 30 }} />
          ) : null}
        </TimelineSeparator>
        <TimelineContent
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label
            className="milestone-name"
            style={{
              color: theme.palette.text.main,
            }}
            onClick={() => {
              let text =
                item.badge +
                ' "' +
                item.name +
                '" on ' +
                (item?.date
                  ? moment().year() ===
                    moment(item.date?.toDate && item.date.toDate()).year()
                    ? moment(item.date?.toDate && item.date.toDate()).format(
                        "MMM D"
                      )
                    : moment(item.date?.toDate && item.date.toDate()).format(
                        "MMM D, Y"
                      )
                  : null);
              copyToClipboard(text);
            }}
          >
            {item.name}
          </label>
        </TimelineContent>
      </TimelineItem>
    );
  };

  // ================================== Render ==================================
  return (
    <div
      className="milestones-screen-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
        paddingBottom: currentDevice === Devices.PHONE && 70,
        // height: currentDevice === "PHONE" ? windowHeight - 70 : null,
      }}
    >
      <div className="milestones-screen-content-container">
        {/* Title */}
        <div className="title-container">
          <Button
            color="contrast"
            onClick={() => setIsInfoOverlayVisible(true)}
            sx={{ height: 20, fontSize: 20, fontWeight: "bold", padding: 3 }}
          >
            Milestones
            <FiInfo
              size={18}
              color={theme.palette.gray.dark}
              style={{ marginLeft: 5 }}
            />
          </Button>
        </div>

        <div className="milestones-header-container">
          <div
            className="milestones-header-content"
            style={{ backgroundColor: theme.palette.quaternary.main }}
          >
            <label
              style={{ color: theme.palette.text.main }}
            >{`${counters.milestonesTotal} achieved!`}</label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 60,
                width: 60,
                borderRadius: 50,
                backgroundColor: theme.palette.tertiary.main,
              }}
            >
              <MilestonesIcon
                height={40}
                width={40}
                fill={theme.palette.light.main}
              />
            </div>
          </div>
        </div>

        <div
          ref={milestonesRef}
          className="milestones-screen-scroll-section-container"
          id="milestones-container"
        >
          {isLoadingMilestones ? (
            <div className="loading-spinner-container">
              <CircularProgress />
            </div>
          ) : (
            <Timeline
              position="right"
              sx={{
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <InfiniteScroll
                dataLength={fetchedMilestones?.length}
                height="calc(100vh - 210px)"
                // scrollEventThrottle={16}
                hasMore={
                  fetchedMilestones?.length < counters?.milestonesTotal
                    ? true
                    : false
                }
                next={() => loadMoreMilestones()}
                loader={
                  <div className="loading-more-milestones-container">
                    <CircularProgress size={20} color="secondary" />
                  </div>
                }
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {fetchedMilestones.map((item, index) =>
                  renderItem(item, index)
                )}
              </InfiniteScroll>
            </Timeline>
          )}
        </div>
      </div>

      <InfoOverlay
        isVisible={isInfoOverlayVisible}
        setIsVisible={setIsInfoOverlayVisible}
        title={en.INFO_TITLE_MILESTONES}
        subtitle={en.INFO_MILESTONES}
        textOk={"Got it"}
      />
    </div>
  );
};

export default React.memo(MilestonesScreen);
