import { firestore, auth } from "../../api/firebase";
import moment from "moment";
import "moment-timezone";

import {
  FETCH_TARGETS,
  SET_LOADING_TARGETS,
  FETCH_MONTH_TARGETS,
  SET_LOADING_MONTH_TARGETS,
  FETCH_LAST_MONTH_TARGETS,
  SET_LOADING_LAST_MONTH_TARGETS,
  FETCH_CURRENT_MONTH_TARGETS,
  SET_LOADING_CURRENT_MONTH_TARGETS,
  FETCH_NEXT_MONTH_TARGETS,
  SET_LOADING_NEXT_MONTH_TARGETS,
} from "../types";

import { captureException } from "@sentry/react";

let unsubscribers = [];
let unsubscribersTargets = [];

const fetchTargetsSuccess = (targets) => ({
  type: FETCH_TARGETS,
  payload: targets,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_TARGETS,
  payload: isLoading,
});

const fetchMonthTargetsSuccess = (targets) => ({
  type: FETCH_MONTH_TARGETS,
  payload: targets,
});

const setLoadingMonthTargets = (isLoading) => ({
  type: SET_LOADING_MONTH_TARGETS,
  payload: isLoading,
});

const fetchLastMonthTargetsSuccess = (targets) => ({
  type: FETCH_LAST_MONTH_TARGETS,
  payload: targets,
});

const setLoadingLastMonthTargets = (isLoading) => ({
  type: SET_LOADING_LAST_MONTH_TARGETS,
  payload: isLoading,
});

const fetchCurrentMonthTargetsSuccess = (targets) => ({
  type: FETCH_CURRENT_MONTH_TARGETS,
  payload: targets,
});

const setLoadingCurrentMonthTargets = (isLoading) => ({
  type: SET_LOADING_CURRENT_MONTH_TARGETS,
  payload: isLoading,
});

const fetchNextMonthTargetsSuccess = (targets) => ({
  type: FETCH_NEXT_MONTH_TARGETS,
  payload: targets,
});

const setLoadingNextMonthTargets = (isLoading) => ({
  type: SET_LOADING_NEXT_MONTH_TARGETS,
  payload: isLoading,
});

export const fetchTargets =
  (date = moment()) =>
  (dispatch) => {
    try {
      const userUid = auth.currentUser.uid;

      dispatch(setLoading(true));

      const targetsSubscriber = firestore
        .collection("targets")
        .doc(userUid)
        .collection("targets")
        .where(
          "date",
          ">=",
          moment(date).startOf("month").utcOffset(0, true).toDate()
        )
        .where(
          "date",
          "<=",
          moment(date).endOf("month").utcOffset(0, true).toDate()
        )
        .onSnapshot(
          (snapshot) => {
            const targets = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id, // References the target UID
              originalGoalId: doc.data().id || null, // References the original target UID (this means, this is a duplicated target)
              ...(doc.data()?.date && {
                date: moment(
                  doc.data().date?.toDate && doc.data().date.toDate()
                )
                  .utc()
                  .utcOffset(moment().utcOffset(), true),
              }),
            }));
            dispatch(fetchTargetsSuccess(targets));
            dispatch(setLoading(false));
          },
          (error) => {
            dispatch(setLoading(false));
            captureException("Error fetching targets onSnapshot", error);
            console.log("Error fetching targets onSnapshot: " + error);
          }
        );
      unsubscribers.push(targetsSubscriber);
      unsubscribersTargets.push(targetsSubscriber);
    } catch (error) {
      dispatch(setLoading(false));
      captureException("Error fetching targets", error);
      console.log("Error fetching targets: " + error);
    }
  };

export const fetchMonthTargets =
  (date = moment()) =>
  (dispatch) => {
    try {
      const userUid = auth.currentUser.uid;

      dispatch(setLoadingMonthTargets(true));

      const monthTargetsSubscriber = firestore
        .collection("targets")
        .doc(userUid)
        .collection("targets")
        .where(
          "date",
          ">=",
          moment(date).startOf("month").utcOffset(0, true).toDate()
        )
        .where(
          "date",
          "<=",
          moment(date).endOf("month").utcOffset(0, true).toDate()
        )
        .onSnapshot(
          (snapshot) => {
            const targets = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id, // References the target UID
              originalGoalId: doc.data().id || null, // References the original target UID (this means, this is a duplicated target)
              ...(doc.data()?.date && {
                date: moment(
                  doc.data().date?.toDate && doc.data().date.toDate()
                )
                  .utc()
                  .utcOffset(moment().utcOffset(), true),
              }),
            }));

            dispatch(fetchMonthTargetsSuccess(targets));
            dispatch(setLoadingMonthTargets(false));
          },
          (error) => {
            dispatch(setLoadingMonthTargets(false));
            captureException(
              "Error fetching month's targets onSnapshot",
              error
            );
            console.log("Error fetching month's targets onSnapshot: " + error);
          }
        );
      unsubscribers.push(monthTargetsSubscriber);
    } catch (error) {
      dispatch(setLoadingMonthTargets(false));
      captureException("Error fetching month's targets", error);
      console.log("Error fetching month's targets: " + error);
    }
  };

export const fetchLastMonthTargets = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    dispatch(setLoadingLastMonthTargets(true));

    const lastMonthTargetsSubscriber = firestore
      .collection("targets")
      .doc(userUid)
      .collection("targets")
      .where(
        "date",
        ">=",
        moment()
          .subtract(1, "months")
          .startOf("month")
          .utcOffset(0, true)
          .toDate()
      )
      .where(
        "date",
        "<=",
        moment()
          .subtract(1, "months")
          .endOf("month")
          .utcOffset(0, true)
          .toDate()
      )
      .onSnapshot(
        (snapshot) => {
          const targets = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id, // References the target UID
            originalGoalId: doc.data().id || null, // References the original target UID (this means, this is a duplicated target)
            ...(doc.data()?.date && {
              date: moment(doc.data().date?.toDate && doc.data().date.toDate())
                .utc()
                .utcOffset(moment().utcOffset(), true),
            }),
          }));

          dispatch(fetchLastMonthTargetsSuccess(targets));
          dispatch(setLoadingLastMonthTargets(false));
        },
        (error) => {
          dispatch(setLoadingLastMonthTargets(false));
          captureException(
            "Error fetching last month's targets onSnapshot",
            error
          );
          console.log(
            "Error fetching last month's targets onSnapshot: " + error
          );
        }
      );
    unsubscribers.push(lastMonthTargetsSubscriber);
  } catch (error) {
    dispatch(setLoadingLastMonthTargets(false));
    captureException("Error fetching last month's targets", error);
    console.log("Error fetching last month's targets: " + error);
  }
};

export const fetchCurrentMonthTargets = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    dispatch(setLoadingCurrentMonthTargets(true));

    const currentMonthTargetsSubscriber = firestore
      .collection("targets")
      .doc(userUid)
      .collection("targets")
      .where(
        "date",
        ">=",
        moment().startOf("month").utcOffset(0, true).toDate()
      )
      .where("date", "<=", moment().endOf("month").utcOffset(0, true).toDate())
      .onSnapshot(
        (snapshot) => {
          const targets = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id, // References the target UID
            originalGoalId: doc.data().id || null, // References the original target UID (this means, this is a duplicated target)
            ...(doc.data()?.date && {
              date: moment(doc.data().date?.toDate && doc.data().date.toDate())
                .utc()
                .utcOffset(moment().utcOffset(), true),
            }),
          }));

          dispatch(fetchCurrentMonthTargetsSuccess(targets));
          dispatch(setLoadingCurrentMonthTargets(false));
        },
        (error) => {
          dispatch(setLoadingCurrentMonthTargets(false));
          captureException(
            "Error fetching current month's targets onSnapshot",
            error
          );
          console.log(
            "Error fetching current month's targets onSnapshot: " + error
          );
        }
      );
    unsubscribers.push(currentMonthTargetsSubscriber);
  } catch (error) {
    dispatch(setLoadingCurrentMonthTargets(false));
    captureException("Error fetching current month's targets", error);
    console.log("Error fetching current month's targets: " + error);
  }
};

export const fetchNextMonthTargets = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;

    dispatch(setLoadingNextMonthTargets(true));

    const nextMonthTargetsSubscriber = firestore
      .collection("targets")
      .doc(userUid)
      .collection("targets")
      .where(
        "date",
        ">=",
        moment().add(1, "months").startOf("month").utcOffset(0, true).toDate()
      )
      .where(
        "date",
        "<=",
        moment().add(1, "months").endOf("month").utcOffset(0, true).toDate()
      )
      .onSnapshot(
        (snapshot) => {
          const targets = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id, // References the target UID
            originalGoalId: doc.data().id || null, // References the original target UID (this means, this is a duplicated target)
            ...(doc.data()?.date && {
              date: moment(doc.data().date?.toDate && doc.data().date.toDate())
                .utc()
                .utcOffset(moment().utcOffset(), true),
            }),
          }));

          dispatch(fetchNextMonthTargetsSuccess(targets));
          dispatch(setLoadingNextMonthTargets(false));
        },
        (error) => {
          dispatch(setLoadingNextMonthTargets(false));
          captureException(
            "Error fetching next month's targets onSnapshot",
            error
          );
          console.log(
            "Error fetching next month's targets onSnapshot: " + error
          );
        }
      );
    unsubscribers.push(nextMonthTargetsSubscriber);
  } catch (error) {
    dispatch(setLoadingNextMonthTargets(false));
    captureException("Error fetching next month's targets", error);
    console.log("Error fetching next month's targets: " + error);
  }
};

// This is used to close onSnapshots every time the date is changed while navigating months
export const unsubscribeTargets = () => {
  unsubscribersTargets.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribersTargets = [];
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeTargetActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
