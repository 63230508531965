import React from "react";

import { FaAngleLeft } from "react-icons/fa";
import { useTheme, Button } from "@mui/material";

import "./style.scss";

const ScreenHeader = (props) => {
  const theme = useTheme();

  return (
    <div className="screen-header-container">
      {props.hasBtnBack && (
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            props.handleBtnBackClicked();
          }}
          className="btn-back"
        >
          <FaAngleLeft size={24} />
          {props.btnBackTitle}
        </Button>
      )}

      <label className="title" style={{ color: theme.palette.text.main }}>
        {props.title}
      </label>
    </div>
  );
};

export default React.memo(ScreenHeader);
