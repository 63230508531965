import React, { useEffect, useState } from "react";

import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  TextField,
  Divider,
} from "@mui/material";

import { updateMessage } from "../../api/firestore/chats";

import "./style.scss";

const EditChatMessageOverlay = (props) => {
  const theme = useTheme();

  const [messageText, setMessageText] = useState(props.messageText || "");
  const [messageTextError, setMessageTextError] = useState(props.messageText);

  useEffect(() => {
    setMessageText(props.messageText || "");
    setMessageTextError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  const handleOnSendClicked = () => {
    if (messageText.trim() !== "") {
      updateMessage(
        props.messageId,
        {
          text: messageText.trim(),
          isEdited: true,
        },
        "Message updated successfully"
      );

      props.setIsVisible(false);
    } else {
      setMessageTextError("Field is required");
    }
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => props.setIsVisible(false)}
      TransitionComponent={Slide}
      className="edit-chat-message-modal"
      fullWidth
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">Edit Message</label>
      </DialogTitle>

      <DialogContent>
        <TextField
          type="text"
          variant="outlined"
          label="Message text"
          margin="dense"
          fullWidth
          autoComplete="off"
          id="chatMessageText"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          multiline
          rows={5}
          error={messageTextError ? true : false}
          helperText={messageTextError}
        />
      </DialogContent>

      <Divider />

      <div className="btn-footer-container">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => {
            props.setIsVisible(false);
          }}
        >
          Cancel
        </Button>

        <Divider
          className="divider"
          sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
          orientation="vertical"
          flexItem
        />

        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="error"
          onClick={handleOnSendClicked}
        >
          Submit
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(EditChatMessageOverlay);
