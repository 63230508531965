import { FETCH_RELEASES, SET_LOADING_RELEASES } from "../types";

const initialState = {
  releases: [],
  isLoading: false,
};

const releaseReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_RELEASES:
      return {
        ...state,
        releases: payload,
      };

    case SET_LOADING_RELEASES:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default releaseReducer;
