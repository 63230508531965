import { version, ScreenNames } from "../../api/constants";

import {
  DARK_MODE,
  SUBMIT_DAY_REMINDER,
  ADD_GOALS_REMINDER,
  CHAT_NOTIFICATIONS,
  SORT_BY_IS_DONE,
  SORT_BY,
  ORDER_BY,
  TARGETS_SORT_BY_IS_DONE,
  TARGETS_SORT_BY,
  TARGETS_ORDER_BY,
  LAST_SEEN_RELEASE,
  GOAL_COMPLETE_SOUND,
  CHAT_MESSAGES_SOUND,
  SOUND_EFFECTS,
  SHOWED_GOAL_EXAMPLE_AT,
  SHOWED_TARGET_EXAMPLE_AT,
  RANDOM_NUMBER_MOTIVATE_MESSAGE_START,
  RANDOM_NUMBER_MOTIVATE_MESSAGE,
  RANDOM_NUMBER_GOALS_EXAMPLE,
  RANDOM_NUMBER_TARGETS_EXAMPLE,
  CHANGE_CURRENT_SCREEN,
  CURRENT_DEVICE,
  SUPPORTER_SETTINGS,
  SHOW_CURRENT_MONTH_TARGETS,
  MOOD_BUTTONS_OPTIONALITY,
  LAST_TIME_TOGGLED_GOAL_DONE,
  // MANUAL_TIMEZONE,
} from "../types";

const initialState = {
  isDarkModeEnabled: false,
  isSubmitDayReminderEnabled: true,
  isAddGoalsReminderEnabled: true,
  isChatNotificationsEnabled: true,
  isSortByIsDone: "ASC",
  isSortBy: "position",
  isOrderBy: "ASC",
  isTargetsSortByIsDone: "ASC",
  isTargetsSortBy: "name",
  isTargetsOrderBy: "ASC",
  lastSeenRelease: version,
  isGoalCompleteSoundEnabled: true,
  isChatMessagesSoundEnabled: false,
  isSoundEffectsEnabled: true,
  showedGoalExampleAt: null,
  showedTargetExampleAt: null,
  randomNumberMotivateMessageStart: 0,
  randomNumberMotivateMessage: 0,
  randomNumberGoalsExample: 0,
  randomNumberTargetsExample: 0,
  currentScreen: ScreenNames.GOALS_SCREEN,
  currentDevice: null,
  supporterSettings: {
    name: "",
    email: "",
    getCcd: true,
    dailyResults: true,
    monthlyResults: true,
    milestonesAwarded: true,
    targetAdded: false,
  },
  showCurrentMonthTargets: true,
  isMoodButtonsMandatory: true,
  lastTimeToggledGoalDone: null,
  // manualTimezone: false,
};

const localReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case DARK_MODE:
      return {
        ...state,
        isDarkModeEnabled: payload,
      };

    case SUBMIT_DAY_REMINDER:
      return {
        ...state,
        isSubmitDayReminderEnabled: payload,
      };

    case ADD_GOALS_REMINDER:
      return {
        ...state,
        isAddGoalsReminderEnabled: payload,
      };

    case CHAT_NOTIFICATIONS:
      return {
        ...state,
        isChatNotificationsEnabled: payload,
      };

    case SORT_BY_IS_DONE:
      return {
        ...state,
        isSortByIsDone: payload,
      };

    case SORT_BY:
      return {
        ...state,
        isSortBy: payload,
      };

    case ORDER_BY:
      return {
        ...state,
        isOrderBy: payload,
      };

    case TARGETS_SORT_BY_IS_DONE:
      return {
        ...state,
        isTargetsSortByIsDone: payload,
      };

    case TARGETS_SORT_BY:
      return {
        ...state,
        isTargetsSortBy: payload,
      };

    case TARGETS_ORDER_BY:
      return {
        ...state,
        isTargetsOrderBy: payload,
      };

    case LAST_SEEN_RELEASE:
      return {
        ...state,
        lastSeenRelease: payload,
      };

    case GOAL_COMPLETE_SOUND:
      return {
        ...state,
        isGoalCompleteSoundEnabled: payload,
      };

    case CHAT_MESSAGES_SOUND:
      return {
        ...state,
        isChatMessagesSoundEnabled: payload,
      };

    case SOUND_EFFECTS:
      return {
        ...state,
        isSoundEffectsEnabled: payload,
      };

    case SHOWED_GOAL_EXAMPLE_AT:
      return {
        ...state,
        showedGoalExampleAt: payload,
      };

    case SHOWED_TARGET_EXAMPLE_AT:
      return {
        ...state,
        showedTargetExampleAt: payload,
      };

    case RANDOM_NUMBER_MOTIVATE_MESSAGE_START:
      return {
        ...state,
        randomNumberMotivateMessageStart: payload,
      };

    case RANDOM_NUMBER_MOTIVATE_MESSAGE:
      return {
        ...state,
        randomNumberMotivateMessage: payload,
      };

    case RANDOM_NUMBER_GOALS_EXAMPLE:
      return {
        ...state,
        randomNumberGoalsExample: payload,
      };

    case RANDOM_NUMBER_TARGETS_EXAMPLE:
      return {
        ...state,
        randomNumberTargetsExample: payload,
      };

    case CHANGE_CURRENT_SCREEN:
      return (state = {
        ...state,
        currentScreen: payload,
      });

    case CURRENT_DEVICE:
      return (state = {
        ...state,
        currentDevice: payload,
      });

    case SUPPORTER_SETTINGS:
      return {
        ...state,
        supporterSettings: payload,
      };

    case SHOW_CURRENT_MONTH_TARGETS:
      return {
        ...state,
        showCurrentMonthTargets: payload,
      };

    case MOOD_BUTTONS_OPTIONALITY:
      return {
        ...state,
        isMoodButtonsMandatory: payload,
      };

    case LAST_TIME_TOGGLED_GOAL_DONE:
      return {
        ...state,
        lastTimeToggledGoalDone: payload,
      };

    // case MANUAL_TIMEZONE:
    //   return {
    //     ...state,
    //     manualTimezone: payload,
    //   };

    default:
      return state;
  }
};

export default localReducer;
