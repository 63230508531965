import { unsubscribeChatActions } from "../../redux/actions/chat";
import { unsubscribeDayActions } from "../../redux/actions/day";
import { unsubscribeGoalActions } from "../../redux/actions/goal";
import { unsubscribeInboxActions } from "../../redux/actions/inbox";
import { unsubscribeMilestoneActions } from "../../redux/actions/milestone";
import { unsubscribeTargetActions } from "../../redux/actions/target";
import { unsubscribeRecurrentGoalActions } from "../../redux/actions/recurrentGoal";
import { unsubscribeCounterActions } from "../../redux/actions/counter";
import { unsubscribeCoachActions } from "../../redux/actions/coach";
import { unsubscribeUserActions } from "../../redux/actions/user";
import { unsubscribeMonthActions } from "../../redux/actions/month";
import { unsubscribeQuizActions } from "../../redux/actions/quiz";
import { unsubscribeTagsActions } from "../../redux/actions/tags";
import { unsubscribeAlertActions } from "../../redux/actions/alert";

// Unsubscribe all snapshots (add more here if necessary)
export const unsubscribeAllSnapshots = () => {
  unsubscribeChatActions();
  unsubscribeCounterActions();
  unsubscribeDayActions();
  unsubscribeGoalActions();
  unsubscribeInboxActions();
  unsubscribeMilestoneActions();
  unsubscribeRecurrentGoalActions();
  unsubscribeTargetActions();
  unsubscribeCoachActions();
  unsubscribeUserActions();
  unsubscribeMonthActions();
  unsubscribeQuizActions();
  unsubscribeTagsActions();
  unsubscribeAlertActions();
};
