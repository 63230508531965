import React, { useState, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
} from "@mui/material";

import "./style.scss";

const DateTimePickerOverlay = (props) => {
  const theme = useTheme();

  const [date, setDate] = useState(props.date);

  //Calendar picker
  const updateDate = (calendarDate) => {
    setDate(calendarDate);

    const newDateTime = moment(calendarDate);

    props.setDateChange(newDateTime);
    props.handleUpdate({
      ...props.goal,
      date: newDateTime,
      newDate: newDateTime,
    });
    props.setIsVisible(!props.isVisible);
  };

  const updateDateShortcut = (shortcutDate) => {
    setDate(shortcutDate);

    const newDateTime = moment(shortcutDate);

    props.setDateChange(newDateTime);
    props.handleUpdate({
      ...props.goal,
      date: newDateTime,
      newDate: newDateTime,
    });
    props.setIsVisible(!props.isVisible);
  };

  const removeDate = useCallback(() => {
    setDate(null);

    props.setDateChange(null);
    props.handleUpdate({ ...props.goal, date: null, newDate: "none" });
    props.setIsVisible(!props.isVisible);
  }, []);

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => props.setIsVisible(false)}
      TransitionComponent={Slide}
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">Date</label>
      </DialogTitle>
      <DialogContent>
        <div className="date-section-container">
          <div className="shortcuts-container">
            <button
              className="shortcut-btn"
              style={{
                backgroundColor: theme.palette.quaternary.main,
                color: theme.palette.primary.main,
              }}
              onClick={() => updateDateShortcut(moment())}
            >
              <label className="shortcut-label">Today</label>
            </button>
            <button
              className="shortcut-btn"
              style={{
                backgroundColor: theme.palette.quaternary.main,
                color: theme.palette.primary.main,
              }}
              onClick={() => updateDateShortcut(moment().add(1, "days"))}
            >
              <label className="shortcut-label">Tomorrow</label>
            </button>
            <button
              className="shortcut-btn"
              style={{
                backgroundColor: theme.palette.quaternary.main,
                color: theme.palette.primary.main,
              }}
              onClick={() =>
                updateDateShortcut(moment().startOf("isoWeek").add(1, "week"))
              }
            >
              <label className="shortcut-label">Next Monday</label>
            </button>
          </div>

          <div className="custom-date-input-container">
            {/* ATTENTION!!! - DatePicker Component does not use momentjs, but date-fns */}
            <DatePicker
              style={{ borderColor: "transparent" }}
              selected={moment(date).toDate()}
              onChange={(date) => updateDate(moment(date))}
              minDate={props.minDate && moment(props.minDate).toDate()}
              inline
              fixedHeight
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
      </DialogContent>

      <DialogContent className="modal-footer">
        {props.showRemoveDate && (
          <Button
            className="btn"
            color="quaternary"
            variant="contained"
            onClick={removeDate}
            sx={{
              borderRadius: 1,
              marginBottom: 2,
              color: theme.palette.gray.dark,
            }}
          >
            Remove date
          </Button>
        )}

        <Button
          className="btn"
          color="quaternary"
          variant="contained"
          onClick={() => props.setIsVisible(false)}
          sx={{ borderRadius: 1, color: theme.palette.gray.dark }}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(DateTimePickerOverlay);
