import {
  DARK_MODE,
  SUBMIT_DAY_REMINDER,
  ADD_GOALS_REMINDER,
  CHAT_NOTIFICATIONS,
  SORT_BY_IS_DONE,
  SORT_BY,
  ORDER_BY,
  TARGETS_SORT_BY_IS_DONE,
  TARGETS_SORT_BY,
  TARGETS_ORDER_BY,
  LAST_SEEN_RELEASE,
  GOAL_COMPLETE_SOUND,
  CHAT_MESSAGES_SOUND,
  SOUND_EFFECTS,
  SHOWED_GOAL_EXAMPLE_AT,
  SHOWED_TARGET_EXAMPLE_AT,
  RANDOM_NUMBER_MOTIVATE_MESSAGE_START,
  RANDOM_NUMBER_MOTIVATE_MESSAGE,
  RANDOM_NUMBER_GOALS_EXAMPLE,
  RANDOM_NUMBER_TARGETS_EXAMPLE,
  CHANGE_CURRENT_SCREEN,
  CURRENT_DEVICE,
  SUPPORTER_SETTINGS,
  SHOW_CURRENT_MONTH_TARGETS,
  MOOD_BUTTONS_OPTIONALITY,
  LAST_TIME_TOGGLED_GOAL_DONE,
  // MANUAL_TIMEZONE,
  HOLIDAY_MODE,
} from "../types";

export const darkMode = (isDarkModeEnabled) => ({
  type: DARK_MODE,
  payload: isDarkModeEnabled,
});

export const submitDayReminder = (isSubmitDayReminderEnabled) => ({
  type: SUBMIT_DAY_REMINDER,
  payload: isSubmitDayReminderEnabled,
});

export const addGoalsReminder = (isAddGoalsReminderEnabled) => ({
  type: ADD_GOALS_REMINDER,
  payload: isAddGoalsReminderEnabled,
});

export const chatNotifications = (isChatNotificationsEnabled) => ({
  type: CHAT_NOTIFICATIONS,
  payload: isChatNotificationsEnabled,
});

export const sortByIsDone = (isSortByIsDone) => ({
  type: SORT_BY_IS_DONE,
  payload: isSortByIsDone,
});

export const sortBy = (isSortBy) => ({
  type: SORT_BY,
  payload: isSortBy,
});

export const orderBy = (isOrderBy) => ({
  type: ORDER_BY,
  payload: isOrderBy,
});

export const targetsSortByIsDone = (isTargetsSortByIsDone) => ({
  type: TARGETS_SORT_BY_IS_DONE,
  payload: isTargetsSortByIsDone,
});

export const targetsSortBy = (isTargetsSortBy) => ({
  type: TARGETS_SORT_BY,
  payload: isTargetsSortBy,
});

export const targetsOrderBy = (isTargetsOrderBy) => ({
  type: TARGETS_ORDER_BY,
  payload: isTargetsOrderBy,
});

export const lastSeenRelease = (release) => ({
  type: LAST_SEEN_RELEASE,
  payload: release,
});

export const goalCompleteSound = (isGoalCompleteSoundEnabled) => ({
  type: GOAL_COMPLETE_SOUND,
  payload: isGoalCompleteSoundEnabled,
});

export const chatMessagesSound = (isChatMessagesSoundEnabled) => ({
  type: CHAT_MESSAGES_SOUND,
  payload: isChatMessagesSoundEnabled,
});

export const soundEffects = (isSoundEffectsEnabled) => ({
  type: SOUND_EFFECTS,
  payload: isSoundEffectsEnabled,
});

export const lastTimeShowedGoalExampleAt = (showedGoalExampleAt) => ({
  type: SHOWED_GOAL_EXAMPLE_AT,
  payload: showedGoalExampleAt,
});

export const lastTimeShowedTargetExampleAt = (showedTargetExampleAt) => ({
  type: SHOWED_TARGET_EXAMPLE_AT,
  payload: showedTargetExampleAt,
});

export const setRandomNumberMotivateMessageStart = (
  randomNumberMotivateMessageStart
) => ({
  type: RANDOM_NUMBER_MOTIVATE_MESSAGE_START,
  payload: randomNumberMotivateMessageStart,
});

export const setRandomNumberMotivateMessage = (
  randomNumberMotivateMessage
) => ({
  type: RANDOM_NUMBER_MOTIVATE_MESSAGE,
  payload: randomNumberMotivateMessage,
});

export const setRandomNumberGoalsExample = (randomNumberGoalsExample) => ({
  type: RANDOM_NUMBER_GOALS_EXAMPLE,
  payload: randomNumberGoalsExample,
});

export const setRandomNumberTargetsExample = (randomNumberTargetsExample) => ({
  type: RANDOM_NUMBER_TARGETS_EXAMPLE,
  payload: randomNumberTargetsExample,
});

export const changeCurrentScreen = (currentScreen) => ({
  type: CHANGE_CURRENT_SCREEN,
  payload: currentScreen,
});

export const setCurrentDevice = (currentDevice) => ({
  type: CURRENT_DEVICE,
  payload: currentDevice,
});

export const setSupporterSettings = (supporterSettings) => ({
  type: SUPPORTER_SETTINGS,
  payload: supporterSettings,
});

export const setShowCurrentMonthTargets = (showCurrentMonthTargets) => ({
  type: SHOW_CURRENT_MONTH_TARGETS,
  payload: showCurrentMonthTargets,
});

export const setIsMoodButtonsMandatory = (isMoodButtonsMandatory) => ({
  type: MOOD_BUTTONS_OPTIONALITY,
  payload: isMoodButtonsMandatory,
});

export const setLastTimeToggledGoalDone = (lastTimeToggledGoalDone) => {
  // console.log("REDUX: ", lastTimeToggledGoalDone);
  return {
    type: LAST_TIME_TOGGLED_GOAL_DONE,
    payload: lastTimeToggledGoalDone,
  };
};

// export const setManualTimezone = (manualTimezone) => ({
//   type: MANUAL_TIMEZONE,
//   payload: manualTimezone,
// });

export const setHolidayMode = (holidayMode) => ({
  type: HOLIDAY_MODE,
  payload: holidayMode,
});
