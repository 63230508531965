import {
  FETCH_DAY,
  SET_LOADING_DAY,
  FETCH_TODAY,
  SET_LOADING_TODAY,
  FETCH_YESTERDAY,
  SET_LOADING_YESTERDAY,
} from "../types";

const initialState = {
  day: null,
  isLoadingDay: false,
  today: null,
  isLoadingToday: false,
  yesterday: null,
  isLoadingYesterday: false,
};

const dayReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_DAY:
      return {
        ...state,
        day: payload,
      };

    case SET_LOADING_DAY:
      return {
        ...state,
        isLoading: payload,
      };

    case FETCH_TODAY:
      return {
        ...state,
        today: payload,
      };

    case SET_LOADING_TODAY:
      return {
        ...state,
        isLoadingToday: payload,
      };

    case FETCH_YESTERDAY:
      return {
        ...state,
        yesterday: payload,
      };

    case SET_LOADING_YESTERDAY:
      return {
        ...state,
        isLoadingYesterday: payload,
      };

    default:
      return state;
  }
};

export default dayReducer;
