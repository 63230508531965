import {
  FETCH_TARGETS,
  SET_LOADING_TARGETS,
  FETCH_MONTH_TARGETS,
  SET_LOADING_MONTH_TARGETS,
  FETCH_LAST_MONTH_TARGETS,
  SET_LOADING_LAST_MONTH_TARGETS,
  FETCH_CURRENT_MONTH_TARGETS,
  SET_LOADING_CURRENT_MONTH_TARGETS,
  FETCH_NEXT_MONTH_TARGETS,
  SET_LOADING_NEXT_MONTH_TARGETS,
} from "../types";

const initialState = {
  targets: [],
  isLoading: false,
  monthTargets: [],
  isLoadingMonthTargets: false,
  lastMonthTargets: [],
  isLoadingLastMonthTargets: false,
  currentMonthTargets: [],
  isLoadingCurrentMonthTargets: false,
  nextMonthTargets: [],
  isLoadingNextMonthTargets: false,
};

const targetReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_TARGETS:
      return {
        ...state,
        targets: payload,
      };

    case SET_LOADING_TARGETS:
      return {
        ...state,
        isLoading: payload,
      };

    case FETCH_MONTH_TARGETS:
      return {
        ...state,
        monthTargets: payload,
      };

    case SET_LOADING_MONTH_TARGETS:
      return {
        ...state,
        isLoadingMonthTargets: payload,
      };

    case FETCH_LAST_MONTH_TARGETS:
      return {
        ...state,
        lastMonthTargets: payload,
      };

    case SET_LOADING_LAST_MONTH_TARGETS:
      return {
        ...state,
        isLoadingLastMonthTargets: payload,
      };

    case FETCH_CURRENT_MONTH_TARGETS:
      return {
        ...state,
        currentMonthTargets: payload,
      };

    case SET_LOADING_CURRENT_MONTH_TARGETS:
      return {
        ...state,
        isLoadingCurrentMonthTargets: payload,
      };

    case FETCH_NEXT_MONTH_TARGETS:
      return {
        ...state,
        nextMonthTargets: payload,
      };

    case SET_LOADING_NEXT_MONTH_TARGETS:
      return {
        ...state,
        isLoadingNextMonthTargets: payload,
      };

    default:
      return state;
  }
};

export default targetReducer;
