import React from "react";

import { useTheme } from "@mui/material";

import "./style.scss";

const MessageBubble = (props) => {
  const theme = useTheme();

  return props.mine ? (
    <>
      <div
        className="sentBubbleContainer"
        style={{
          backgroundColor:
            theme.palette.messageBubbleSentBackgroundOverWhite.main,
        }}
      >
        <label
          className="sentMessage"
          style={{ color: theme.palette.text.main }}
        >
          {props.text}
        </label>
        {props.subjectiveWellBeingScale ? (
          <div className="subjective-well-being-rate">
            {props.subjectiveWellBeingScale === 1 ? (
              <label style={{ fontSize: 21 }}>😭</label>
            ) : props.subjectiveWellBeingScale === 2 ? (
              <label style={{ fontSize: 21 }}>🙁</label>
            ) : props.subjectiveWellBeingScale === 3 ? (
              <label style={{ fontSize: 21 }}>😐</label>
            ) : props.subjectiveWellBeingScale === 4 ? (
              <label style={{ fontSize: 21 }}>🙂</label>
            ) : props.subjectiveWellBeingScale === 5 ? (
              <label style={{ fontSize: 21 }}>😀</label>
            ) : null}
            <label style={{ color: theme.palette.text.main, marginLeft: 5 }}>
              {props.subjectiveWellBeingScale}/5
            </label>
          </div>
        ) : null}
        <div
          className="leftArrow"
          style={{
            backgroundColor:
              theme.palette.messageBubbleSentBackgroundOverWhite.main,
          }}
        />
        <div
          className="leftArrowOverlap"
          style={{ backgroundColor: theme.palette.background.main }}
        />
      </div>
    </>
  ) : (
    <div
      className="receivedBubbleContainer"
      style={{
        maxWidth: props.isConversation ? "50%" : "auto",
        backgroundColor: theme.palette.messageBubbleReceivedBackground.main,
      }}
    >
      <label
        className="receivedMessage"
        style={{ color: theme.palette.text.main }}
      >
        {props.text}
      </label>
      <div
        className="rightArrow"
        style={{
          backgroundColor: theme.palette.messageBubbleReceivedBackground.main,
        }}
      />
      <div
        className="rightArrowOverlap"
        style={{ backgroundColor: theme.palette.background.main }}
      />
    </div>
  );
};

export default React.memo(MessageBubble);
