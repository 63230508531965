import { firestore } from "../../api/firebase";
import { FETCH_RELEASES, SET_LOADING_RELEASES } from "../types";
import { captureException } from "@sentry/react";

import { compareVersions } from "../../api/helper";
import { version } from "../../api/constants";

const semver = require("semver");

const fetchReleaseSuccess = (releases) => ({
  type: FETCH_RELEASES,
  payload: releases,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_RELEASES,
  payload: isLoading,
});

export const fetchReleases =
  (lastSeenRelease = version) =>
  (dispatch) => {
    try {
      dispatch(setLoading(true));
      firestore
        .collection("releases")
        // .where('version', '>', lastSeenRelease) // WARNING: Might get heavy when too many releases? Can't fetch in where clause cause comparisson is lexicographical, not semantic (eg: 2.4.2 is bigger than 2.4.10 )
        .where("isWeb", "==", true)
        .get()
        .then((query) => {
          const releases = query.docs.map((doc) => doc.data());

          // Remove releases smaller than lastSeenRelease (lexicographic comparisson)
          const unseenReleases = releases.filter((release) =>
            semver.gt(release.version, lastSeenRelease)
          );

          // Order releases DESC lexicographic
          unseenReleases.sort(compareVersions);

          dispatch(fetchReleaseSuccess(unseenReleases));
          dispatch(setLoading(false));
        })
        .catch((error) => {
          dispatch(setLoading(false));
          captureException("Error fetching release get", error);
          console.log("Error fetching release get: " + error);
        });
    } catch (error) {
      dispatch(setLoading(false));
      captureException("Error fetching release: ", error);
      console.log("Error fetching release: " + error);
    }
  };
