import React from "react";
import { Button } from "@mui/material";

const FAB = (props) => {
  return (
    <>
      {props.isVisible && (
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleClick}
          sx={{
            height: 60,
            width: props.position === "right" ? "auto" : 60,
            minWidth: 60,
            position: "absolute",
            bottom: 40,
            left:
              props.position === "left"
                ? 30
                : props.position === "center" && "50%",
            right: props.position === "right" && 30,
            marginLeft: props.position === "center" && -30,
            padding: 0,
          }}
        >
          {props.icon}
          {props.title && <label>&nbsp; {props.title}</label>}
        </Button>
      )}
    </>
  );
};

export default React.memo(FAB);
