import { FETCH_COUNTERS, SET_LOADING_COUNTERS } from "../types";

const initialState = {
  counters: [],
  isLoading: false,
};

const counterReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_COUNTERS:
      return {
        ...state,
        counters: payload,
      };

    case SET_LOADING_COUNTERS:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default counterReducer;
