import { combineReducers } from "redux";
import localReducer from "./local";
import dayReducer from "./day";
import goalReducer from "./goal";
import recurrentGoalReducer from "./recurrentGoal";
import inboxReducer from "./inbox";
import milestoneReducer from "./milestone";
import targetReducer from "./target";
import chatReducer from "./chat";
import counterReducer from "./counter";
import userReducer from "./user";
import coachReducer from "./coach";
import releaseReducer from "./release";
import monthReducer from "./month";
import quizReducer from "./quiz";
import tagsReducer from "./tags";
import alertsReducer from "./alert";

const appReducer = combineReducers({
  local: localReducer,
  day: dayReducer,
  goal: goalReducer,
  recurrentGoal: recurrentGoalReducer,
  inbox: inboxReducer,
  milestone: milestoneReducer,
  target: targetReducer,
  chat: chatReducer,
  counter: counterReducer,
  user: userReducer,
  coach: coachReducer,
  release: releaseReducer,
  month: monthReducer,
  quiz: quizReducer,
  tags: tagsReducer,
  alert: alertsReducer,
});

export default appReducer;
