import { FETCH_ALERTS, SET_LOADING_ALERTS } from "../types";

const initialState = {
  alerts: [],
  isLoading: false,
};

const alertReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_ALERTS:
      return {
        ...state,
        alerts: payload,
      };

    case SET_LOADING_ALERTS:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default alertReducer;
