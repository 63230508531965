import React from "react";

import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  Divider,
} from "@mui/material";

import "./style.scss";

const AlertOverlay = ({ alert, handleAction }) => {
  const theme = useTheme();

  const confirmPressed = () => {
    handleAction(alert?.id, "confirm");
  };

  const dismissPressed = () => {
    handleAction(alert?.id, "dismiss");
  };

  return (
    <Dialog
      open={true}
      onClose={dismissPressed}
      TransitionComponent={Slide}
      maxWidth="xs"
      fullWidth
      slotProps={{
        backdrop: {
          style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Adjust the alpha (last value) to make it darker or lighter
        },
      }}
      PaperProps={{
        style: {
          ...(alert?.backgroundColor1 !== "#000000" &&
          alert?.backgroundColor2 !== "#000000"
            ? {
                background:
                  "linear-gradient(180deg, " +
                  alert?.backgroundColor1 +
                  " 5%, " +
                  alert?.backgroundColor2 +
                  " 100%)",
              }
            : alert?.backgroundColor1 !== "#000000"
            ? { backgroundColor: alert?.backgroundColor1 }
            : alert?.backgroundColor2 !== "#000000"
            ? { backgroundColor: alert?.backgroundColor2 }
            : null),
        },
      }}
    >
      {console.log(alert?.title)}
      {alert?.title ? (
        <DialogTitle className="modal-title-container">
          <span
            style={{
              color:
                alert?.fontColor !== "#000000"
                  ? alert?.fontColor
                  : theme.palette.text.main,
            }}
          >
            {alert.title}
          </span>
        </DialogTitle>
      ) : null}

      <DialogContent>
        <div className="alert-overlay-content">
          {alert?.emoji ? (
            <h1
              style={{
                color:
                  alert?.fontColor !== "#000000"
                    ? alert?.fontColor
                    : theme.palette.text.main,
              }}
            >
              {alert.emoji}
            </h1>
          ) : null}

          {alert?.image ? (
            <img
              className="img"
              src={alert?.image}
              alt="Alert"
              width={"100%"}
            />
          ) : null}

          {alert?.description ? (
            <label
              className="description"
              style={{
                color:
                  alert?.fontColor !== "#000000"
                    ? alert?.fontColor
                    : theme.palette.text.main,
              }}
            >
              {alert.description}
            </label>
          ) : null}
        </div>
      </DialogContent>

      <Divider />

      <div className="modal-footer">
        <Button
          sx={{
            borderRadius: 0,
            height: 50,
          }}
          color="primary"
          onClick={confirmPressed}
        >
          <span
            style={{
              color:
                alert?.fontColor !== "#000000"
                  ? alert?.fontColor
                  : theme.palette.text.main,
            }}
          >
            {alert?.okBtnLabel || "Ok"}
          </span>
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(AlertOverlay);
