import {
  FETCH_MONTH,
  SET_LOADING_MONTH,
  FETCH_CURRENT_MONTH,
  SET_LOADING_CURRENT_MONTH,
  FETCH_LAST_MONTH,
  SET_LOADING_LAST_MONTH,
} from "../types";

const initialState = {
  month: null,
  isLoadingMonth: false,
  currentMonth: null,
  isLoadingCurrentMonth: false,
  lastMonth: null,
  isLoadingLastMonth: false,
};

const monthReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_MONTH:
      return {
        ...state,
        month: payload,
      };

    case SET_LOADING_MONTH:
      return {
        ...state,
        isLoadingMonth: payload,
      };

    case FETCH_CURRENT_MONTH:
      return {
        ...state,
        currentMonth: payload,
      };

    case SET_LOADING_CURRENT_MONTH:
      return {
        ...state,
        isLoadingCurrentMonths: payload,
      };

    case FETCH_LAST_MONTH:
      return {
        ...state,
        lastMonth: payload,
      };

    case SET_LOADING_LAST_MONTH:
      return {
        ...state,
        isLoadingLastMonth: payload,
      };

    default:
      return state;
  }
};

export default monthReducer;
