import { firestore, auth } from "../firebase";
import moment from "moment";

import { getTimestamp } from "../helper";

import { captureException } from "@sentry/react";

export const updateClientAlert = (alertReceived) => {
  try {
    const userUid = auth.currentUser.uid;

    // ---- Start transaction ----
    const batch = firestore.batch();

    const { id, ...clientAlertDestructured } = alertReceived; // Destructuring to remove all the fields we don't want to add

    const clientAlert = {
      ...clientAlertDestructured,
      ...(clientAlertDestructured.seenAt && {
        seenAt: getTimestamp(moment(clientAlertDestructured.seenAt)),
      }),
    };

    // Set new client alert
    const clientAlertRef = firestore
      .collection("clientAlert")
      .doc(userUid)
      .collection("alerts")
      .doc(id);

    batch.set(clientAlertRef, clientAlert, { merge: true });

    // ---- Finish transaction ----
    batch.commit().catch((error) => {
      captureException(
        "Error while commiting client alert transaction: ",
        error
      );
      console.error("Error while commiting client alert transaction: ", error);
    });
  } catch (error) {
    captureException("Error while updating client alert: ", error);
    console.error("Error while updating client alert: ", error);
  }
};
