import {
  FETCH_RECURRENT_GOALS,
  SET_LOADING_RECURRENT_GOALS,
  FETCH_YESTERDAY_RECURRENT_GOALS,
  SET_LOADING_YESTERDAY_RECURRENT_GOALS,
} from "../types";

const initialState = {
  recurrentGoals: [],
  isLoading: false,
  yesterdayRecurrentGoals: [],
  isLoadingYesterdayRecurrentGoals: [],
};

const recurrentGoalReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_RECURRENT_GOALS:
      return {
        ...state,
        recurrentGoals: payload,
      };

    case SET_LOADING_RECURRENT_GOALS:
      return {
        ...state,
        isLoading: payload,
      };

    case FETCH_YESTERDAY_RECURRENT_GOALS:
      return {
        ...state,
        yesterdayRecurrentGoals: payload,
      };

    case SET_LOADING_YESTERDAY_RECURRENT_GOALS:
      return {
        ...state,
        isLoadingYesterdayRecurrentGoals: payload,
      };

    default:
      return state;
  }
};

export default recurrentGoalReducer;
