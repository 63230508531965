import { FETCH_INBOXES, SET_LOADING_INBOXES } from "../types";

const initialState = {
  inboxes: [],
  isLoading: false,
};

const inboxReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_INBOXES:
      return {
        ...state,
        inboxes: payload,
      };

    case SET_LOADING_INBOXES:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};

export default inboxReducer;
