import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material";
import ScreenHeader from "../../../components/ScreenHeader";

import { Devices } from "../../../api/constants";
import "./style.scss";

const ShortcutsScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const currentDevice = useSelector((state) => state.local.currentDevice);

  // Navigation
  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  });

  const btnBackClicked = () => {
    navigate("/settings");
  };

  const downHandler = (key) => {
    switch (key.keyCode) {
      case 27:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="settings-shortcuts-container"
      style={{
        backgroundColor: theme.palette.background.main,
        width: currentDevice === Devices.DESKTOP && "50%",
      }}
    >
      <div className="shortcuts-content-container">
        <ScreenHeader
          hasBtnBack
          handleBtnBackClicked={btnBackClicked}
          btnBackTitle="Settings"
          title="Shortcuts"
        />
        <div className="content">
          <div className="row">
            <label style={{ color: theme.palette.text.main }}>Go back</label>
            <div className="badge">
              <label className="badge-label">ESC</label>
            </div>
          </div>
          <div className="row">
            <label style={{ color: theme.palette.text.main }}>
              Open Data Input to add a goal
            </label>
            <div className="badge">
              <label className="badge-label">ENTER</label>
            </div>
          </div>
          <div className="row">
            <label style={{ color: theme.palette.text.main }}>Add goal</label>
            <div className="badge">
              <label className="badge-label">ENTER</label>
            </div>
          </div>
          <div className="row">
            <label style={{ color: theme.palette.text.main }}>
              Close Data Input
            </label>
            <div className="badge">
              <label className="badge-label">ESC</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShortcutsScreen);
