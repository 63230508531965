import { firestore, auth } from "../../api/firebase";

import { FETCH_USER, SET_LOADING_USER } from "../types";

import { captureException } from "@sentry/react";

let unsubscribers = [];

const fetchUserSuccess = (user) => ({
  type: FETCH_USER,
  payload: user,
});

const setLoading = (isLoading) => ({
  type: SET_LOADING_USER,
  payload: isLoading,
});

export const fetchUser = () => (dispatch) => {
  try {
    const userUid = auth.currentUser.uid;
    dispatch(setLoading(true));

    const userSubscriber = firestore
      .collection("users")
      .doc(userUid)
      .onSnapshot(
        (snapshot) => {
          const user = {
            ...snapshot.data(),
            id: snapshot.id,
          };
          dispatch(fetchUserSuccess(user));
          dispatch(setLoading(false));
        },
        (error) => {
          dispatch(setLoading(false));
          captureException("Error fetching user onSnapshot", error);
          console.log("Error fetching user onSnapshot: " + error);
        }
      );
    unsubscribers.push(userSubscriber);
  } catch (error) {
    dispatch(setLoading(false));
    captureException("Error fetching user", error);
    console.log("Error fetching user: " + error);
  }
};

// This unsubscribes all onSnapshots from this action
export const unsubscribeUserActions = () => {
  unsubscribers.forEach(
    (unsubscriber) => unsubscriber instanceof Function && unsubscriber()
  );
  unsubscribers = [];
};
