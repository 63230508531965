import React, { useCallback } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Redux
import { changeCurrentScreen } from "../../redux/actions/local";

// Assets
import { ReactComponent as InboxIcon } from "../../assets/svg/inbox.svg";

// Components
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Badge,
} from "@mui/material";

import { ScreenNames } from "../../api/constants";
import "./style.scss";

const CalendarPickerOverlay = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onDateChangeShortcut = useCallback((val) => {
    props.onDateChangeHandler(val);
  }, []);

  const navigateToUndatedView = useCallback(() => {
    props.setIsVisible(false);
    dispatch(changeCurrentScreen(ScreenNames.INBOX_SCREEN));
    navigate("/inbox");
  }, []);

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => props.setIsVisible(false)}
      TransitionComponent={Slide}
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">Date</label>
      </DialogTitle>
      <DialogContent>
        <div className="shortcuts-container">
          <button
            className="shortcut-btn"
            style={{
              backgroundColor: theme.palette.quaternary.main,
              color: theme.palette.primary.main,
            }}
            onClick={() => onDateChangeShortcut(moment())}
          >
            <label className="shortcut-label">Today</label>
          </button>
          <button
            className="shortcut-btn"
            style={{
              backgroundColor: theme.palette.quaternary.main,
              color: theme.palette.primary.main,
            }}
            onClick={() => onDateChangeShortcut(moment().add(1, "days"))}
          >
            <label className="shortcut-label">Tomorrow</label>
          </button>
          <button
            className="shortcut-btn"
            style={{
              backgroundColor: theme.palette.quaternary.main,
              color: theme.palette.primary.main,
            }}
            onClick={() =>
              onDateChangeShortcut(moment().startOf("isoWeek").add(1, "week"))
            }
          >
            <label className="shortcut-label">Next Monday</label>
          </button>
        </div>
        <div className="datePicker-container">
          {/* ATTENTION!!! - DatePicker Component does not use momentjs, but date-fns */}
          <DatePicker
            style={{
              borderColor: "transparent",
            }}
            selected={moment(props.selectedDate).toDate()} // selectedDate already is a moment obj
            onChange={(date) => props.onDateChangeHandler(moment(date))}
            minDate={moment(props.minDate).toDate()}
            inline
            fixedHeight
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
        <div className="undated-goals-shortcut-container">
          <Badge
            className="undated-goals-btn-container"
            variant="standard"
            color="gray"
            invisible={props.undoneInboxes ? false : true}
            badgeContent={props.undoneInboxes}
            style={{
              color: theme.palette.light.main,
            }}
          >
            <button
              className="undated-goals-shortcut-btn"
              onClick={navigateToUndatedView}
              style={{ backgroundColor: theme.palette.quaternary.main }}
            >
              <InboxIcon
                height={25}
                width={25}
                fill={theme.palette.gray.contrast}
                style={{ marginRight: 5 }}
              />
              <span style={{ color: theme.palette.gray.contrast }}>
                Go to undated goals
              </span>
            </button>
          </Badge>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(CalendarPickerOverlay);
