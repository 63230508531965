import React from "react";

import "./style.scss";

const ImageModal = (props) => {
  const handleClick = (e) => {
    if (e.target.classList.contains("backdrop")) {
      props.setIsVisible(false);
    }
  };
  return (
    props.isVisible && (
      <div className="backdrop" onClick={handleClick}>
        <img src={props.image} alt="enlarged pic" />
      </div>
    )
  );
};

export default React.memo(ImageModal);
