import firebase from "firebase/compat/app";
import { auth } from "../api/firebase";
import moment from "moment";
import { captureException } from "@sentry/react";

// Firestore
import {
  addUser,
  assignUserStartingValues,
  getPreregisteredUser,
} from "./firestore/users";
import { getCoach } from "./firestore/coaches";
import { addFirstMilestone } from "./firestore/milestones";
import { addGoal } from "./firestore/goals";
import { addRecurrentGoal } from "./firestore/recurrentGoals";
import { writeCoachNotes } from "./firestore/coaches";
import { addDay, isDayCreated } from "./firestore/days";

// import { getToken, removeToken } from "./notifications/token";

// Analytics
import { analytics } from "./firebase";

// Own api
import {
  guessTimezone,
  sendOnboardingSlackNotification,
  getRandomAvailableCoach,
} from "./helper";
import { en } from "./languages/en";
import { version, recurrencePatternEnum, USER_TYPE } from "./constants";

const timeToSignUp = (preregisteredUser) => {
  var startDate = moment(
    preregisteredUser &&
      preregisteredUser.subscribedAt &&
      preregisteredUser.subscribedAt.toDate &&
      preregisteredUser.subscribedAt.toDate()
  );
  var endDate = moment();
  var diffTime = moment(endDate).diff(startDate);

  var duration = moment.duration(diffTime);
  var days = duration.days(),
    hrs = duration.hours(),
    mins = duration.minutes(),
    secs = duration.seconds();

  return (
    (days ? days + " days " : "") +
    hrs +
    " hrs " +
    mins +
    " mins " +
    secs +
    " secs"
  );
};

export const emailSignUp = async (name, email, password) => {
  try {
    return await auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        const newUser = {
          name,
          email: result.user.email,
          timezone: guessTimezone(),
          createdTime: moment(),
          lastTimeOpenedApp: moment(),
          platform: "web",
          version: version,
        };

        await addUser(result.user.uid, newUser);

        const preregisteredUser = await getPreregisteredUser(newUser.email);

        var coach;

        if (preregisteredUser?.coachId) {
          await assignUserStartingValues({
            ...(preregisteredUser?.coachId && {
              coach: preregisteredUser.coachId,
            }),
            ...(preregisteredUser?.type && {
              type: preregisteredUser.type,
            }),
            ...(preregisteredUser?.subscribedAt && {
              subscribedAt: preregisteredUser.subscribedAt,
            }),
            ...(preregisteredUser?.subscriptionPlatform && {
              subscriptionPlatform: preregisteredUser.subscriptionPlatform,
            }),
          });

          coach = await getCoach(preregisteredUser?.coachId);

          if (
            preregisteredUser?.type &&
            preregisteredUser?.type !== USER_TYPE.INACTIVE
          ) {
            // Only send notification if client has type and it's different from "inactive"

            // Send to general channel (#onboarding)
            sendOnboardingSlackNotification(
              `🙋 SIGN UP ${
                preregisteredUser?.subscribedAt
                  ? `- ${timeToSignUp(preregisteredUser)}`
                  : ""
              }

New client: ${name}
Coach: ${coach?.name}

<https://goalswon-coaching-app.web.app/client/${
                result.user.uid
              }/autoOpenQuiz | Customize the welcome message (if not done yet)>`
            );

            // Send to coach channel (#coach-X-new-client)
            if (coach?.slackNewClients) {
              fetch(coach.slackNewClients, {
                method: "POST",
                body: JSON.stringify({
                  text: `🙋 SIGN UP ${
                    preregisteredUser?.subscribedAt
                      ? `- ${timeToSignUp(preregisteredUser)}`
                      : ""
                  }

New client: ${name}

<https://goalswon-coaching-app.web.app/client/${
                    result.user.uid
                  }/autoOpenQuiz | Customize the welcome message (if not done yet)>`,
                }),
              })
                .then((response) => {})
                .then((result) => {})
                .catch((error) => {
                  captureException(
                    "Error while sending sign up slack notification",
                    error
                  );
                  console.log(
                    "Error while sending sign up slack notification: " + error
                  );
                });
            }
          }
        } else {
          const coach = await getRandomAvailableCoach();

          await assignUserStartingValues({
            ...(coach?.id && {
              coach: coach?.id,
            }),
            ...(preregisteredUser?.type && {
              type: preregisteredUser.type,
            }),
            ...(preregisteredUser?.subscribedAt && {
              subscribedAt: preregisteredUser.subscribedAt,
            }),
            ...(preregisteredUser?.subscriptionPlatform && {
              subscriptionPlatform: preregisteredUser.subscriptionPlatform,
            }),
          });
        }

        writeCoachNotes(newUser.email);

        addFirstMilestone(result.user.uid);

        addStartingGoals(coach?.name);

        // getToken(); // TODO - uncomment

        analytics.logEvent("sign_up", { method: "email_and_password" });
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          return en.EMAIL_ALREADY_IN_USE;
        }

        if (error.code === "auth/invalid-email") {
          return en.EMAIL_INVALID;
        }

        if (error.code === "auth/weak-password") {
          return en.PASSWORD_WRONG_OR_NOT_SET;
        }

        console.error(error);
      });
  } catch (error) {
    captureException("Error on email sign up", error);
    console.log("Error on email sign up: " + error);
  }
};

export const emailSignIn = async (email, password) => {
  try {
    return await auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        // getToken() // TODO - uncomment
        analytics.logEvent("login", { method: "email_and_password" });
      })
      .catch((error) => {
        if (error.code === "auth/user-disabled") {
          return en.EMAIL_HAS_BEEN_DISABLED;
        }

        if (error.code === "auth/invalid-email") {
          return en.EMAIL_INVALID;
        }

        if (error.code === "auth/user-not-found") {
          return en.EMAIL_HAS_NO_USER;
        }

        if (error.code === "auth/wrong-password") {
          return en.PASSWORD_WRONG_OR_NOT_SET;
        }

        console.error(error);
        alert(error);
      });
  } catch (error) {
    alert(error);
    captureException("Error on email sign in", error);
    console.log("Error on email sign in: " + error);
  }
};

export const sendPasswordResetEmail = async (email) => {
  try {
    return await auth
      .sendPasswordResetEmail(email)
      .then(() => {
        // TODO handle this
        alert(en.SUCCESS_SEND_EMAIL_PASSWORD_RESET);
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          return en.EMAIL_INVALID;
        }

        if (error.code === "auth/user-not-found") {
          return en.EMAIL_HAS_NO_USER;
        }

        console.error(error);
      });
  } catch (error) {
    captureException("Error on send password reset email", error);
    console.log("Error on send password reset email: " + error);
  }
};

export const googleSignIn = async (res) => {
  const credential = firebase.auth.GoogleAuthProvider.credential(
    res.id_token,
    res.access_token
  );
  return await auth
    .signInWithCredential(credential)
    .then(async (result) => {
      if (result.additionalUserInfo.isNewUser) {
        const newUser = {
          email: result.user.email,
          name: result.user.displayName,
          avatar: result.user.photoURL,
          timezone: guessTimezone(),
          createdTime: moment(),
          lastTimeOpenedApp: moment(),
          platform: "web",
          version: version,
        };

        await addUser(result.user.uid, newUser);

        const preregisteredUser = await getPreregisteredUser(newUser.email);

        var coach;

        if (preregisteredUser?.coachId) {
          await assignUserStartingValues({
            ...(preregisteredUser?.coachId && {
              coach: preregisteredUser.coachId,
            }),
            ...(preregisteredUser?.type && {
              type: preregisteredUser.type,
            }),
            ...(preregisteredUser?.subscribedAt && {
              subscribedAt: preregisteredUser.subscribedAt,
            }),
            ...(preregisteredUser?.subscriptionPlatform && {
              subscriptionPlatform: preregisteredUser.subscriptionPlatform,
            }),
          });

          coach = await getCoach(preregisteredUser?.coachId);

          if (
            preregisteredUser?.type &&
            preregisteredUser?.type !== USER_TYPE.INACTIVE
          ) {
            // Only send notification if client has type and it's different from "inactive"

            // Send to general channel (#onboarding)
            sendOnboardingSlackNotification(
              `🙋 SIGN UP ${
                preregisteredUser?.subscribedAt
                  ? `- ${timeToSignUp(preregisteredUser)}`
                  : ""
              }

New client: ${result.user.displayName}
Coach: ${coach?.name}
          
<https://goalswon-coaching-app.web.app/client/${
                result.user.uid
              }/autoOpenQuiz | Customize the welcome message (if not done yet)>`
            );

            // Send to coach channel (#coach-X-new-client)
            if (coach?.slackNewClients) {
              fetch(coach.slackNewClients, {
                method: "POST",
                body: JSON.stringify({
                  text: `🙋 SIGN UP ${
                    preregisteredUser?.subscribedAt
                      ? `- ${timeToSignUp(preregisteredUser)}`
                      : ""
                  }
                
  New client: ${result.user.displayName}
            
  <https://goalswon-coaching-app.web.app/client/${
    result.user.uid
  }/autoOpenQuiz | Customize the welcome message (if not done yet)>`,
                }),
              })
                .then((response) => {})
                .then((result) => {})
                .catch((error) => {
                  captureException(
                    "Error while sending sign up slack notification",
                    error
                  );
                  console.log(
                    "Error while sending sign up slack notification: " + error
                  );
                });
            }
          }
        } else {
          const coach = await getRandomAvailableCoach();

          await assignUserStartingValues({
            ...(coach?.id && {
              coach: coach?.id,
            }),
            ...(preregisteredUser?.type && {
              type: preregisteredUser.type,
            }),
            ...(preregisteredUser?.subscribedAt && {
              subscribedAt: preregisteredUser.subscribedAt,
            }),
            ...(preregisteredUser?.subscriptionPlatform && {
              subscriptionPlatform: preregisteredUser.subscriptionPlatform,
            }),
          });
        }

        writeCoachNotes(newUser.email);

        addFirstMilestone(result.user.uid);

        addStartingGoals(coach?.name);

        analytics.logEvent("sign_up", { method: "google" });
      } else {
        analytics.logEvent("login", { method: "google" });
      }
      // getToken(); // TODO - Uncomment
    })
    .catch((error) => {
      captureException("Error on google sign in", error);
      console.log("Error on google sign in: " + error);
    });
};

export const appleSignIn = async () => {
  var provider = new firebase.auth.OAuthProvider("apple.com");

  await auth
    .signInWithPopup(provider)
    .then(async (result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;

      // You can also get the Apple OAuth Access and ID Tokens.
      // var accessToken = credential.accessToken;
      var idToken = credential.idToken;

      if (idToken) {
        if (result.additionalUserInfo.isNewUser) {
          const newUser = {
            email: result.user.email,
            name: result.user.displayName,
            avatar: result.user.photoURL,
            timezone: guessTimezone(),
            createdTime: moment(),
            lastTimeOpenedApp: moment(),
            platform: "web",
            version: version,
          };

          await addUser(result.user.uid, newUser);

          const preregisteredUser = await getPreregisteredUser(newUser.email);

          var coach;

          if (preregisteredUser?.coachId) {
            await assignUserStartingValues({
              ...(preregisteredUser?.coachId && {
                coach: preregisteredUser.coachId,
              }),
              ...(preregisteredUser?.type && {
                type: preregisteredUser.type,
              }),
              ...(preregisteredUser?.subscribedAt && {
                subscribedAt: preregisteredUser.subscribedAt,
              }),
              ...(preregisteredUser?.subscriptionPlatform && {
                subscriptionPlatform: preregisteredUser.subscriptionPlatform,
              }),
            });

            coach = await getCoach(preregisteredUser?.coachId);

            if (
              preregisteredUser?.type &&
              preregisteredUser?.type !== USER_TYPE.INACTIVE
            ) {
              // Only send notification if client has type and it's different from "inactive"

              // Send to general channel (#onboarding)
              sendOnboardingSlackNotification(
                `🙋 SIGN UP ${
                  preregisteredUser?.subscribedAt
                    ? `- ${timeToSignUp(preregisteredUser)}`
                    : ""
                }
                
New client: ${result.user.displayName}
Coach: ${coach?.name}
          
<https://goalswon-coaching-app.web.app/client/${
                  result.user.uid
                }/autoOpenQuiz | Customize the welcome message (if not done yet)>`
              );

              // Send to coach channel (#coach-X-new-client)
              if (coach?.slackNewClients) {
                fetch(coach.slackNewClients, {
                  method: "POST",
                  body: JSON.stringify({
                    text: `🙋 SIGN UP ${
                      preregisteredUser?.subscribedAt
                        ? `- ${timeToSignUp(preregisteredUser)}`
                        : ""
                    }
                
  New client: ${result.user.displayName}
            
  <https://goalswon-coaching-app.web.app/client/${
    result.user.uid
  }/autoOpenQuiz | Customize the welcome message (if not done yet)>`,
                  }),
                })
                  .then((response) => {})
                  .then((result) => {})
                  .catch((error) => {
                    captureException(
                      "Error while sending sign up slack notification",
                      error
                    );
                    console.log(
                      "Error while sending sign up slack notification: " + error
                    );
                  });
              }
            }
          } else {
            const coach = await getRandomAvailableCoach();

            await assignUserStartingValues({
              ...(coach?.id && {
                coach: coach?.id,
              }),
              ...(preregisteredUser?.type && {
                type: preregisteredUser.type,
              }),
              ...(preregisteredUser?.subscribedAt && {
                subscribedAt: preregisteredUser.subscribedAt,
              }),
              ...(preregisteredUser?.subscriptionPlatform && {
                subscriptionPlatform: preregisteredUser.subscriptionPlatform,
              }),
            });
          }

          writeCoachNotes(newUser.email);

          addFirstMilestone(result.user.uid);

          addStartingGoals(coach?.name);

          analytics.logEvent("sign_up", { method: "apple" });
        } else {
          analytics.logEvent("login", { method: "apple" });
        }
      } else {
        console.log("no identity token??");
        // handle this - retry?
      }
    })
    .catch((error) => {
      var errorMessage = error.message;
      captureException("Error on Apple sign in", error);
      console.log("Error on Apple sign in: " + errorMessage);
    });
};

const addStartingGoals = async (coachName) => {
  // Create day to which goal will be created to, if it's not created already.
  const isDayToCreateGoalToCreated = await isDayCreated();
  if (!isDayToCreateGoalToCreated) {
    addDay();
  }

  addRecurrentGoal({
    name: `🌞 Submit my results ${
      coachName ? "to " + coachName + " " : ""
    }on time!`,
    position: 0, // TODO
    isDone: false,
    tag: null,
    description: "",
    startDate: moment(),
    recurrencePattern: recurrencePatternEnum.DAILY,
    days: [],
    endDate: null,
    isRolledOver: true,
  });

  addGoal({
    name: `👋 Share my background with ${coachName || "my coach"}`,
    isDone: false,
    date: moment(),
    tag: "",
    description: "",
    isRolledOver: true,
  });

  addRecurrentGoal({
    name: "🗓️ Review my monthly targets",
    position: 0, // TODO
    isDone: false,
    tag: null,
    description: "",
    // Monthly on the 1st
    startDate:
      moment().format("DD") === "01"
        ? moment()
        : moment(moment().date(1).add(1, "month")),
    recurrencePattern: recurrencePatternEnum.MONTHLY,
    days: [],
    endDate: null,
    isRolledOver: true,
  });
};
