import React from "react";

import { FiTrash2 } from "react-icons/fi";
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Button,
  Divider,
} from "@mui/material";

import { tagColors } from "../../api/constants";
import "./style.scss";

const TagPickerOverlay = (props) => {
  const theme = useTheme();

  const handleTagPressed = (tagSelected) => {
    props.setTagChange("");
    props.setTagChange(tagSelected);
    props.handleUpdate({ ...props.goal, tag: tagSelected });
    props.setIsVisible(false);
  };

  return (
    <Dialog
      open={props.isVisible}
      onClose={() => props.setIsVisible(false)}
      TransitionComponent={Slide}
      fullWidth
      sx={{ padding: 0 }}
    >
      <DialogTitle className="modal-title">
        <label className="modal-title-label">Tag</label>
      </DialogTitle>
      <DialogContent>
        <div className="tags">
          {tagColors.map((item) => (
            <div className="round" key={`tagContainer-${item.id}`}>
              <input
                type="checkbox"
                id={`tag-${item.id}`}
                checked={item.id === props.tag ? true : false}
                onChange={() => {
                  handleTagPressed(item.id);
                }}
              />
              <label
                htmlFor={`tag-${item.id}`}
                style={{
                  backgroundColor: item.color,
                  borderColor: item.color,
                }}
              ></label>
            </div>
          ))}
        </div>
      </DialogContent>

      <Divider />

      <div className="tag-picker-modal-footer">
        <Button
          sx={{ flex: 1, borderRadius: 0, height: 50 }}
          color="gray"
          onClick={() => {
            props.setIsVisible(false);
          }}
        >
          Cancel
        </Button>

        <Divider
          className="divider"
          sx={{ borderColor: theme.palette.text.main, opacity: 0.1 }}
          orientation="vertical"
          flexItem
        />

        <Button
          sx={{
            flex: 1,
            borderRadius: 0,
            height: 50,
            color: theme.palette.error.main,
          }}
          color="error"
          onClick={() => {
            handleTagPressed("");
          }}
        >
          <FiTrash2 style={{ marginRight: 5 }} />
          Delete
        </Button>
      </div>
    </Dialog>
  );
};

export default React.memo(TagPickerOverlay);
